import { useState } from "react";
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CancelIcon from "@mui/icons-material/Cancel";
import TextField from 'theme/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { LeftAlignedButton } from "theme/Button";
import ChatIcon from '@mui/icons-material/Chat';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import GroupIcon from '@mui/icons-material/Group';
import Card from "theme/Card";
import GLOBAL_SEARCH, {
    GlobalSearchInput,
    GlobalSearchPayload
} from "graphql/queries/GlobalSearchQuery";
import { useQuery } from "@apollo/client";
import useDebounce from "hooks/useDebounce";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Conversation from "types/Conversation";
import Customer from "types/Customer";
import ShopifyOrder from "types/ShopifyOrder";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { useHistory } from "react-router-dom";
import Chip from "theme/Chip";
import useTheme from "@mui/material/styles/useTheme";

export interface Props {
    open: boolean;
    onClose: () => void;
}

enum Selection {
    conversations = "CONVERSATIONS",
    files = "FILES",
    customers = "CUSTOMERS",
    orders = "ORDERS",
}

type EndIconProps = {
    value: number | string;
};

function EndIcon(props: EndIconProps) {
    const { value } = props;
    return (
        <>
            {!!value && <Stack sx={{ pl: 2 }}>
                <Chip variant="outlined" size="small" label={value} />
            </Stack>}
        </>
    );
}

function OrdersResults(props: { orders: ShopifyOrder[], handleClose: () => void }) {
    const history = useHistory();
    const { orders, handleClose } = props;

    if (orders.length === 0) {
        return (
            <Stack height="100%" spacing={2} alignItems="center" justifyContent="center" p={2}>
                <Typography variant="caption">No results</Typography>
            </Stack>
        );
    }

    return (
        <List disablePadding sx={{ width: '100%' }}>
            {orders.map((order, index) => {
                return (
                    <ListItemButton divider={index < orders.length - 1} key={order.id} onClick={() => {
                        history.push(`/order/${order.id}`);
                        handleClose();
                    }}>
                        <ListItem disableGutters disablePadding>
                            <Stack direction="row" spacing={1}>
                                <Typography variant="subtitle2">{order.customer?.name ?? order.customer?.email}</Typography>
                                <Typography variant="subtitle2" color="text.secondary">{order.lineItemsFormatted}</Typography>
                            </Stack>
                        </ListItem>
                    </ListItemButton>
                );
            })}
        </List>
    );
}


function CustomersResults(props: { customers: Customer[], handleClose: () => void }) {
    const history = useHistory();
    const { customers, handleClose } = props;

    if (customers.length === 0) {
        return (
            <Stack height="100%" spacing={2} alignItems="center" justifyContent="center" p={2}>
                <Typography variant="caption">No results</Typography>
            </Stack>
        );
    }

    return (
        <>
            <List disablePadding sx={{ width: '100%' }}>
                {customers.map((customer, index) => {
                    return (
                        <ListItemButton divider={index < customers.length - 1} key={customer.id} onClick={() => {
                            history.push(`/customer/${customer.id}`);
                            handleClose();
                        }}>
                            <ListItem disableGutters disablePadding>
                                <Stack direction="row" spacing={1}>
                                    {!!customer.name && <Typography variant="subtitle2">{customer.name}</Typography>}
                                    {!!customer.email && <Typography variant="subtitle2">{customer.email}</Typography>}
                                    <Typography variant="subtitle2" color="text.secondary">{customer.totalSpend}</Typography>
                                </Stack>
                            </ListItem>
                        </ListItemButton>
                    );
                })}
            </List>
        </>

    );
}

function ConversationsResults(props: { conversations: Conversation[], handleClose: () => void }) {
    const history = useHistory();
    const { conversations, handleClose } = props;
    const theme = useTheme();

    if (conversations.length === 0) {
        return (
            <Stack height="100%" spacing={2} alignItems="center" justifyContent="center" p={2}>
                <Typography variant="caption">No results</Typography>
            </Stack>
        );
    }

    return (
        <List disablePadding sx={{ width: '100%' }}>
            {conversations.map((conversation, index) => {
                return (
                    <ListItemButton divider={index < conversations.length - 1} key={conversation.id} onClick={() => {
                        history.push(`/conversation/${conversation.id}`);
                        handleClose();
                    }}>
                        <ListItem disableGutters disablePadding>
                            <Stack direction="row" spacing={1}>
                                <Typography variant="subtitle2">{conversation.customer.name ?? conversation.customer.email}</Typography>
                                <Typography variant="subtitle2" color="text.secondary">{conversation.latestMessage}</Typography>
                                <Stack
                                    direction="row"
                                    flexWrap="wrap"
                                    sx={{
                                        justifyContent: "flex-start",
                                        alignContent: "space-evenly",
                                        height: "100%",
                                    }}
                                >
                                    {conversation.labels.nodes.map((label) => (
                                        <Chip
                                            size="small"
                                            key={label.id}
                                            label={<Typography fontWeight={600} variant="caption">{label.title}</Typography>}
                                            variant="filled"
                                            sx={{
                                                marginRight: theme.spacing(1),
                                                bgcolor: label.colorHex,
                                                color: theme.palette.getContrastText(label.colorHex),
                                            }}
                                        />
                                    ))}
                                </Stack>
                            </Stack>
                        </ListItem>
                    </ListItemButton>
                );
            })}
        </List>
    );
}

export default function GlobalSearchDialog(props: Props) {

    const [searchInput, setSearchInput] = useState<string>("");
    const [selection, setSelection] = useState<Selection>(Selection.conversations);
    const [alertError, setAlertError] = useState<string | undefined>(undefined);

    const { onClose, open } = props;

    const debouncedSearchQuery = useDebounce<string>(searchInput, 500);

    const { data, loading } = useQuery<GlobalSearchPayload, GlobalSearchInput>(GLOBAL_SEARCH, {
        variables: { searchQuery: debouncedSearchQuery },
        fetchPolicy: "no-cache",
    });

    const handleClose = () => {
        onClose();
        setTimeout(() => {
            setAlertError(undefined);
            setSearchInput("");
            setSelection(Selection.conversations);
        }, 200);
    };

    const conversations = data?.globalSearch?.conversations?.pageInfo?.totalCount;
    const orders = data?.globalSearch?.orders?.pageInfo?.totalCount;
    const customers = data?.globalSearch?.customers?.pageInfo?.totalCount;

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="md">
            <DialogTitle>
                <Stack direction="row" spacing={2}>
                    <TextField
                        autoFocus
                        fullWidth
                        size="small"
                        placeholder="Search"
                        onChange={(e) => setSearchInput(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }} />
                    <IconButton onClick={handleClose}>
                        <CancelIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent dividers>
                <Stack direction="row" spacing={2}>
                    <Stack direction="column" spacing={1} sx={{ minWidth: 225 }}>
                        <LeftAlignedButton
                            startIcon={<ChatIcon />}
                            endIcon={<EndIcon value={conversations || ""} />}
                            color={
                                selection === Selection.conversations ? "primary" : "secondary"
                            }
                            variant={
                                selection === Selection.conversations ? "contained" : "text"
                            }
                            onClick={() => setSelection(Selection.conversations)}
                        >
                            <span style={{ width: "100%", textAlign: "left" }}>Conversations</span>
                        </LeftAlignedButton>
                        <LeftAlignedButton
                            startIcon={<ShoppingCartIcon />}
                            endIcon={<EndIcon value={orders || ""} />}
                            color={
                                selection === Selection.orders ? "primary" : "secondary"
                            }
                            variant={
                                selection === Selection.orders ? "contained" : "text"
                            }
                            onClick={() => setSelection(Selection.orders)}
                        >
                            <span style={{ width: "100%", textAlign: "left" }}>Orders</span>
                        </LeftAlignedButton>
                        <LeftAlignedButton
                            startIcon={<GroupIcon />}
                            endIcon={<EndIcon value={customers || ""} />}
                            color={
                                selection === Selection.customers ? "primary" : "secondary"
                            }
                            variant={
                                selection === Selection.customers ? "contained" : "text"
                            }
                            onClick={() => setSelection(Selection.customers)}
                        >
                            <span style={{ width: "100%", textAlign: "left" }}>Customers</span>
                        </LeftAlignedButton>
                    </Stack>
                    <Stack spacing={2} style={{ width: "100%" }}>
                        <Card variant="outlined" elevation={0} sx={{ height: "100%" }}>
                            {!loading && searchInput.length < 3 && <Stack height={"100%"} spacing={2} alignItems="center" justifyContent="center" p={2}>
                                <Typography variant="caption">Search for something...</Typography>
                            </Stack>}
                            {!!loading && searchInput.length >= 3 && <Stack height={"100%"} spacing={2} alignItems="center" justifyContent="center" p={2}>
                                <Typography variant="caption">Searching</Typography>
                                <CircularProgress />
                            </Stack>}
                            {selection === Selection.orders && !loading && searchInput.length >= 3 && <OrdersResults handleClose={handleClose} orders={data?.globalSearch?.orders?.nodes || []} />}
                            {selection === Selection.customers && !loading && searchInput.length >= 3 && <CustomersResults handleClose={handleClose} customers={data?.globalSearch?.customers?.nodes || []} />}
                            {selection === Selection.conversations && !loading && searchInput.length >= 3 && <ConversationsResults handleClose={handleClose} conversations={data?.globalSearch?.conversations?.nodes || []} />}
                        </Card>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}
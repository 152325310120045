import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import Collapse from '@mui/material/Collapse';
import { useSpring, animated } from 'react-spring';
import { TransitionProps } from '@mui/material/transitions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Card from "theme/Card";
import Stack from '@mui/material/Stack';
import AppBar from "theme/AppBar";
import Button from 'theme/Button';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import SELECTED_ORG, {
    SelectedOrgInput,
    SelectedOrgPayload
} from "graphql/queries/SelectedOrgQuery";
import TEAM_AND_ORG_FILES, {
    OrgFilesInput,
    OrgFilesPayload,
} from "graphql/queries/multiplexed/PrivateAndTeamOrgFilesQuery";
import ORG_FILES_UPDATED, {
    OrgFilesUpdatedInput, OrgFilesUpdatedPayload
} from 'graphql/subscriptions/OrgFilesUpdatedSubscription';
import { useQuery, useSubscription } from "@apollo/client";
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import OrgFile from 'types/OrgFile';
import Typography from '@mui/material/Typography';
import prettyBytes from "pretty-bytes";
import UploadFileDialog from "./UploadFileDialog";
import FileDetailsDialog from './FileDetailsDialog';
import { useHistory } from "react-router";

function TransitionComponent(props: TransitionProps) {
    const style = useSpring({
        from: {
            opacity: 1,
        },
        to: {
            opacity: props.in ? 1 : 0,
        },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
};

const StyledTreeItem = styled((props: TreeItemProps) => (<TreeItem {...props} TransitionComponent={TransitionComponent} />))(({ }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        '& .close': {
            opacity: 0.3,
        },
        width: "0px",
        marginRight: 4,
    },
    [`& .${treeItemClasses.group}`]: {
        marginTop: 4,
        marginLeft: 15,
        paddingLeft: 18,
        borderRadius: "4px",
    },
    [`& .${treeItemClasses.focused}`]: {
        borderRadius: "4px",
    },
    [`& .${treeItemClasses.content}`]: {
        borderRadius: "4px",
    },
    borderRadius: "4px",
}));


export default function FilesNavigator() {

    const history = useHistory();

    const [selectedFile, setSelectedFile] = useState<OrgFile | undefined>(undefined);
    const [uploadFileOpen, setUploadFileOpen] = useState<boolean>(false);

    const { data: orgData } = useQuery<SelectedOrgPayload, SelectedOrgInput>(SELECTED_ORG, {});

    const { data, refetch } = useQuery<OrgFilesPayload, OrgFilesInput>(TEAM_AND_ORG_FILES, {
        variables: {
            privateFilters: { privateFile: true },
            teamFilters: { privateFile: false }
        },
    });

    const { data: orgFilesUpdatedData } = useSubscription<OrgFilesUpdatedPayload, OrgFilesUpdatedInput>(ORG_FILES_UPDATED, {});

    const busy = !(!!data && !!orgData);
    const isPaidPlan = (!!orgData && !!orgData.selectedOrg && orgData.selectedOrg.isPaidPlan);

    const teamFiles = !!data ? data.team.nodes : [];
    const privateFiles = !!data ? data.private.nodes : [];

    useEffect(() => {
        if (!!orgFilesUpdatedData?.orgFilesUpdated) {
            refetch();
        }
    }, [orgFilesUpdatedData]);

    return (
        <Stack direction="column" alignItems="center" p={2}>
            <UploadFileDialog open={uploadFileOpen} onClose={() => setUploadFileOpen(false)} />
            <FileDetailsDialog open={Boolean(selectedFile)} fileId={selectedFile?.id} onClose={() => setSelectedFile(undefined)} />
            <Stack direction="column" spacing={2} sx={{ maxWidth: 900, width: "100%" }}>
                <AppBar sx={{ p: 0 }} elevation={0} color='transparent' position="static">
                    <Stack sx={{ pl: 1, pr: 1 }} direction="row">
                        {isPaidPlan && <Button disabled={busy} onClick={() => setUploadFileOpen(true)} variant='contained' color="secondary" size='small' startIcon={<CloudUploadOutlinedIcon />}>Upload new file</Button>}
                        {!isPaidPlan && <Tooltip title="Upgrade to the Pro plan to upload files."><Button disabled={busy} variant='contained' color="secondary" size='small' startIcon={<CloudUploadOutlinedIcon />}>Upload new file</Button></Tooltip>}
                    </Stack>
                </AppBar>
                {!busy && !isPaidPlan && <Alert sx={{ cursor: 'pointer' }} variant='outlined' severity="info" onClick={() => {
                    history.push('/org-settings/plan');
                }}>
                    <AlertTitle>Upgrade to Pro</AlertTitle>
                    To share files with your customers requires a <strong>Pro plan.</strong>
                </Alert>}
                <Card variant='outlined'>
                    <Stack p={2}>
                        <TreeView
                            sx={{ width: "100%" }}
                            defaultExpanded={['shared', 'private']}
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<KeyboardArrowRightIcon />}
                        >
                            <StyledTreeItem icon={false} nodeId="shared" label={<Stack direction="row" spacing={1} alignItems="center"><Typography sx={{ p: 0.5 }} variant='h6'>Team files {teamFiles.length > 0 ? `(${teamFiles.length})` : ''}</Typography></Stack>}>
                                {teamFiles.map((orgFile) => {
                                    return (
                                        <StyledTreeItem onClick={() => setSelectedFile(orgFile)} key={orgFile.id} nodeId={orgFile.id} label={<Stack direction="row" spacing={1}><Typography variant='body2'>{orgFile.givenFilename}</Typography> <Typography variant='body2' color="text.secondary">{prettyBytes(orgFile.fileSize)}</Typography></Stack>} icon={<InsertDriveFileOutlinedIcon />} />
                                    );
                                })}
                                {busy && <CircularProgress sx={{ ml: 1 }} size={15} />}
                                {teamFiles.length === 0 && !busy && <Typography sx={{ ml: 1 }} variant="caption">Empty</Typography>}
                            </StyledTreeItem>
                            <div style={{ height: "4px" }} />
                            <StyledTreeItem nodeId="private" label={<Stack direction="row" spacing={1} alignItems="center"><Typography sx={{ p: 0.5 }} fontWeight={600} variant='h6'>Your private files {privateFiles.length > 0 ? `(${privateFiles.length})` : ''}</Typography></Stack>}>
                                {privateFiles.map((orgFile) => {
                                    return (
                                        <StyledTreeItem onClick={() => setSelectedFile(orgFile)} key={orgFile.id} nodeId={orgFile.id} label={<Stack direction="row" spacing={1}><Typography variant='body2'>{orgFile.givenFilename}</Typography> <Typography variant='body2' color="text.secondary">{prettyBytes(orgFile.fileSize)}</Typography></Stack>} icon={<InsertDriveFileOutlinedIcon />} />
                                    );
                                })}
                                {busy && <CircularProgress sx={{ ml: 1 }} size={15} />}
                                {privateFiles.length === 0 && !busy && <Typography sx={{ ml: 1 }} variant="caption">Empty</Typography>}
                            </StyledTreeItem>
                        </TreeView>
                    </Stack>
                </Card>
            </Stack>
        </Stack>
    );
}
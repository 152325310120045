import { gql } from "@apollo/client";
import Layout from "types/Layout";
import LayoutFragment from "../fragments/LayoutFragment";

export interface CreateLayoutInput {
    input: {
        name: string;
    };
}

export interface CreateLayoutPayload {
    createLayout: {
        errors: string[];
        layout?: Layout;
    };
}

const CREATE_LAYOUT = gql`
  mutation CreateLayout($input: CreateLayoutMutationInput!) {
    createLayout(input: $input) {
      errors
      layout {
        ...LayoutFields
      }
    }
  }
  ${LayoutFragment}
`;

export default CREATE_LAYOUT;

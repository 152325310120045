import * as React from "react";
import { GridCellParams } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";

interface Props {
    value: boolean;
}

const Cell = React.memo(function Function(props: Props) {
    const { value } = props;

    return <Typography variant="subtitle2" fontWeight={600} color={value === false ? "error" : "success"}>{`${value}`}</Typography>;
});

export function renderLiveness(params: GridCellParams) {
    return <Cell value={params.value as boolean} />;
}
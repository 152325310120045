import { gql } from "@apollo/client";

export interface SignFileUploadInput {
    input: {
        fileName: string;
        privateFile: boolean;
        temporaryFile: boolean;
        userAvatar: boolean;
    };
}

export interface SignFileUploadPayload {
    signFileUpload: {
        errors: string[];
        uploadUrl?: string;
        uploadFields?: [key: string];
    };
}

const SIGN_FILE_UPLOAD = gql`
  mutation SignFileUpload($input: SignFileUploadUrlMutationInput!) {
    signFileUpload(input: $input) {
        uploadUrl
        uploadFields
        errors
    }
  }
`;

export default SIGN_FILE_UPLOAD;

import { useState, useEffect, useCallback, useMemo } from "react";
import { useDropzone, FileRejection, DropEvent } from 'react-dropzone';
import Stack from "@mui/material/Stack";
import Alert from '@mui/material/Alert';
import { v4 as uuidv4 } from 'uuid';
import SIGN_FILE_UPLOAD, {
    SignFileUploadInput,
    SignFileUploadPayload,
} from "graphql/mutations/SignAvatarFileUpload";
import FormData from "form-data";
import axios from "axios";
import useTheme from "@mui/material/styles/useTheme";
import PersonIcon from '@mui/icons-material/Person';
import ME, {
    MeInput,
    MePayload,
} from "graphql/queries/MeQuery";
import USER_UPDATED, {
    UserUpdatedInput,
    UserUpdatedPayload,
} from "graphql/subscriptions/UserUpdatedSubscription";
import { useQuery, useSubscription, useMutation } from "@apollo/client";
import Typography from "@mui/material/Typography";
import { fromImage } from 'imtool';
import CircularProgress from "@mui/material/CircularProgress";
import User from "types/User";

export interface FileUpload {
    key: string;
    name: string;
    uploading: boolean;
    completed: boolean;
    file: File;
    errors?: string[];
    uploadUrl?: string;
    uploadFields?: [key: string];
}

function AccountAvatarDropAreaWrapper(props: { avatarSet?: () => void }) {
    const { avatarSet } = props;

    const { data: meData, refetch } = useQuery<MePayload, MeInput>(ME, {
        variables: {}, fetchPolicy: "no-cache", pollInterval: 1000,
    });

    if (!!(meData?.me)) {
        return <AccountAvatarDropArea me={meData.me} refetch={refetch} avatarSet={avatarSet} />
    }

    return <div />;
}

function AccountAvatarDropArea(props: { me: User, refetch: any, avatarSet?: () => void }) {

    const { refetch, me, avatarSet } = props;

    const { data: userUpdatedData } = useSubscription<UserUpdatedPayload, UserUpdatedInput>(USER_UPDATED, {
        variables: { userId: me.id },
    });

    const [upload, setUpload] = useState<FileUpload | undefined>(undefined);
    const [fileAlert, setFileAlert] = useState<string | undefined>(undefined);

    const theme = useTheme();

    const baseStyle = {
        width: 150,
        height: 150,
        flex: 1,
        borderRadius: "4px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: theme.palette.border.main,
        borderStyle: 'dashed',
        backgroundColor: theme.palette.common.white,
        outline: 'none',
    };

    const focusedStyle = {
        borderColor: '#2196f3',
        color: '#2196f3',
    };

    const acceptStyle = {
        borderColor: '#00e676',
        color: '#00e676'
    };

    const rejectStyle = {
        borderColor: '#ff1744',
        color: '#ff1744'
    };

    const avatarStyle = {
        border: "none",
    };

    const [signFile, { }] = useMutation<SignFileUploadPayload, SignFileUploadInput>(SIGN_FILE_UPLOAD, {});

    const doUpload = useCallback(async (uploadFile: FileUpload, file: File) => {
        try {
            if (!!uploadFile.uploadFields && !!uploadFile.uploadUrl && !uploadFile.uploading && !uploadFile.completed) {
                uploadFile.uploading = true;

                setUpload(uploadFile);

                const form = new FormData();

                Object.entries(uploadFile.uploadFields).forEach(([field, value]) => {
                    form.append(field, value);
                });

                const tool = await fromImage(file);

                const thumbnail = await tool.thumbnail(300).toFile(file.name);

                form.append("file", thumbnail);

                const response = await axios
                    .post(uploadFile.uploadUrl, form, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    });

                uploadFile.completed = true;

                if (response.status == 201) {

                    uploadFile.uploading = false;

                    setUpload(uploadFile);
                } else {
                    uploadFile.uploading = false;

                    uploadFile.errors = ['There was a problem uploading this file'];

                    setUpload(uploadFile);
                }
            }
        } catch (error) {
            uploadFile.completed = true;
            uploadFile.uploading = false;
            uploadFile.errors = ['There was a problem uploading this file'];

            setUpload(uploadFile);
        }
    }, [upload]);

    const onDrop = useCallback(async (acceptedFiles: File[], rejectedFiles: FileRejection[], _: DropEvent) => {

        if (rejectedFiles.length > 0) {
            setFileAlert('Avatar image was too large (1MB limit)');
        } else {
            setFileAlert(undefined);
        }

        if (acceptedFiles.length == 0) {
            return;
        }

        let newUploads = acceptedFiles.map((file) => {
            let upload: FileUpload = {
                name: file.name,
                uploading: false,
                completed: false,
                key: uuidv4(),
                file: file
            };
            return upload;
        });

        for (let i = 0; i < newUploads.length; i++) {
            let upload = newUploads[i];

            try {
                const { data, errors } = await signFile({
                    variables: {
                        input: {
                            userAvatar: true,
                            fileName: upload.name,
                            privateFile: true,
                            temporaryFile: false
                        }
                    }
                });

                if (!!errors) {
                    upload.errors = errors.map((error) => error.message);
                }

                if (!!data) {
                    if (data.signFileUpload.errors.length > 0) {
                        upload.errors = data.signFileUpload.errors;
                        upload.uploading = false;
                    } else {
                        upload.uploadUrl = data.signFileUpload.uploadUrl;
                        upload.uploadFields = data.signFileUpload.uploadFields;
                    }
                }
            } catch (error) {
                upload.errors = ['There was a problem uploading this file'];
                upload.uploading = false;
            }
        }

        if (newUploads.length > 0) {
            setUpload(newUploads[0]);
            doUpload(newUploads[0], newUploads[0].file);
        }
    }, [upload]);

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        open
    } = useDropzone({
        accept: ['.png', '.jpg', '.jpeg'],
        noClick: true,
        noKeyboard: true,
        onDrop: onDrop,
        multiple: false
    });

    const hasAvatar = !!(me.avatarUrl);
    const isProcessingAvatar = !!(me.avatarProcessing);

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
        ...(hasAvatar ? avatarStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    useEffect(() => {
        if (!!upload && !!upload.errors) {
            setFileAlert(upload.errors[0]);
        }
    }, [upload]);

    useEffect(() => {
        if (!!(userUpdatedData?.userUpdated?.user)) {
            if (!!userUpdatedData.userUpdated.user.avatarUrl && !!avatarSet) {
                avatarSet();
            }
            refetch();
        }
    }, [userUpdatedData]);

    useEffect(() => {
        if (!!(me?.avatarUrl) && !!avatarSet) {
            avatarSet();
        }
    }, [me]);

    return (
        <Stack direction="column" spacing={1} sx={{ height: 150, width: 150 }}>
            {!!fileAlert && <Alert color="error">{fileAlert}</Alert>}
            <div {...getRootProps({ style })} onClick={open}>
                <input {...getInputProps()} />
                <Stack direction="column" alignItems="center" spacing={1}>
                    {hasAvatar && <img src={me.avatarUrl} width={150} height={150} style={{ objectFit: "cover", borderRadius: "6px" }} />}
                    {!hasAvatar && !isProcessingAvatar && <PersonIcon fontSize="large" color="disabled" />}
                    {!hasAvatar && !isProcessingAvatar && <Typography variant="caption" fontWeight={700} textAlign="center">Upload an avatar</Typography>}
                    {isProcessingAvatar && <CircularProgress />}
                </Stack>
            </div>
        </Stack>
    );
}

export default AccountAvatarDropAreaWrapper;
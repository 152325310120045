import { gql } from "@apollo/client";

export interface ExportCustomerGdprDataInput {
    input: {
        customerId: string;
    };
}

export interface ExportCustomerGdprDataPayload {
    exportCustomerGdprData?: {
        errors: string[];
        ok?: boolean;
    };
}

const DELETE_TEMPLATE = gql`
  mutation ExportCustomerGdprData($input: ExportCustomerGdprDataMutationInput!) {
    exportCustomerGdprData(input: $input) {
      errors
      ok
    }
  }
`;

export default DELETE_TEMPLATE;

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { formatDateStringToTimeAgo } from "helpers/DateFormatters";
import Message, { instanceOfCustomer } from "types/Message";
import Conversation from "types/Conversation";
import useTheme from "@mui/material/styles/useTheme";
import MessageShadowRoot from "./MessageShadowRoot";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { useApolloClient } from "@apollo/client";
import ORG_FILE_DOWNLOAD, { OrgFileInput, OrgFilePayload } from "graphql/queries/OrgFileDownloadQuery";
import User, { avatar } from "types/User";
import VerifiedIcon from '@mui/icons-material/Verified';
import Tooltip from "@mui/material/Tooltip";

type MessageCardProps = {
  message: Message;
  conversation: Conversation;
};

const ListItem = styled('li')(({ theme }) => ({
  margin: 0,
  marginRight: theme.spacing(1)
}));

function MessageCard(props: MessageCardProps) {
  const theme = useTheme();
  const { message } = props;
  const client = useApolloClient();

  return (<>
    <Card elevation={0} variant="outlined">
      <CardHeader
        sx={{ pl: 2, pr: 2, pt: 1, pb: 1, backgroundColor: message.sender.__typename == "User" ? theme.palette.background.paper : "#ddf4ff", borderBottom: `1px solid ${theme.palette.border.main}` }}
        avatar={message.sender.__typename == "User" ? avatar(message.sender as User) : undefined}
        title={
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography color="text.primary" variant="body2" fontWeight={700}>{message.sender.name ?? message.fromAddress}</Typography>
            <Typography color="text.primary" variant="caption">{instanceOfCustomer(message.sender) ? `${formatDateStringToTimeAgo(message.createdAt)} ${`${!!message.sender?.name ? `- ${message.fromAddress}` : ''}`}` : formatDateStringToTimeAgo(message.createdAt)}</Typography>
            <div style={{ flexGrow: 1 }} />
            {message.fromAddressType === "email" && <Tooltip arrow title={message.toAddress}>
              <Typography variant="caption" sx={{ fontWeight: 500, border: `1px solid ${theme.palette.border.main}`, borderRadius: "4px", color: theme.palette.text.secondary, padding: "2px", paddingLeft: "4px", paddingRight: "4px" }}>Email</Typography>
            </Tooltip>}
            {message.fromAddressType === "phone_number" && <Tooltip arrow title={message.toAddress}>
              <Typography variant="caption" sx={{ fontWeight: 500, border: `1px solid ${theme.palette.border.main}`, borderRadius: "4px", color: theme.palette.text.secondary, padding: "2px", paddingLeft: "4px", paddingRight: "4px" }}>SMS</Typography>
            </Tooltip>}
            {message.messageDeliveryStatus === "delivered" && <Stack direction="row" alignItems="center" spacing={0.5} style={{ color: theme.palette.secondary.main }}>
              <Tooltip arrow title="Verified as sent">
                <VerifiedIcon fontSize="small" color="secondary" />
              </Tooltip>
            </Stack>}
            {message.messageDeliveryStatus === "bounced" && <Stack direction="row" alignItems="center" spacing={0.5} style={{ color: theme.palette.error.main }}>
              <div style={{ width: 6, height: 6, borderRadius: 3, backgroundColor: theme.palette.error.main }} />
              <Typography fontWeight={700} color="inherit" variant="caption">Bounced</Typography>
            </Stack>}
            {message.messageDeliveryStatus === "error" && <Stack direction="row" alignItems="center" spacing={0.5} style={{ color: theme.palette.error.main }}>
              <div style={{ width: 6, height: 6, borderRadius: 3, backgroundColor: theme.palette.error.main }} />
              <Typography fontWeight={700} color="inherit" variant="caption">Error</Typography>
            </Stack>}
            {message.messageDeliveryStatus === "reported_as_spam" && <Stack direction="row" alignItems="center" spacing={0.5} style={{ color: theme.palette.error.main }}>
              <div style={{ width: 6, height: 6, borderRadius: 3, backgroundColor: theme.palette.error.main }} />
              <Typography fontWeight={700} color="inherit" variant="caption">Reported as spam</Typography>
            </Stack>}
          </Stack>
        }
      />
      <CardContent sx={{ pb: `0px !important`, pt: 2 }}>
        {instanceOfCustomer(message.sender) && !!message.subject && message.subject.length > 0 && <Typography component="div" sx={{ ml: 0, pb: 0.5 }} color="text.secondary" variant="caption">{message.subject}</Typography>}
        <MessageShadowRoot messageHtml={message.messageHtml || ""} />
      </CardContent>
      <CardActions sx={{ pr: 2 }}>
        {message.fileAttachments.pageInfo.totalCount > 0 && <Stack direction="row" alignItems="center" sx={{ listStyle: 'none', flexWrap: "wrap" }}>
          {message.fileAttachments.nodes.map((attachment) => {
            return (
              <ListItem key={attachment.id}>
                <Chip label={attachment.givenFilename} size="small" onClick={() => {
                  const newWindow = window.open();
                  try {
                    client.query<OrgFilePayload, OrgFileInput>({ query: ORG_FILE_DOWNLOAD, fetchPolicy: "no-cache", variables: { id: attachment.id } })
                      .then(({ data }) => {
                        if (!!data && !!data.orgFile && data.orgFile.signedUrl && !!newWindow) {
                          newWindow.location.href = data.orgFile.signedUrl;
                        }
                      });
                  } catch (error) { }
                }} />
              </ListItem>
            );
          })}
        </Stack>}
      </CardActions>
    </Card>
    {message.messageDeliveryStatus === "error" && <Alert severity="error">There was a problem sending this email.</Alert>}
    {message.messageDeliveryStatus === "reported_as_spam" && <Alert severity="error">This message was reported as spam by the customer.</Alert>}
    {message.messageDeliveryStatus === "bounced" && <Alert severity="error">This message was rejected by the customers email provider.</Alert>}
  </>
  );
}

export default MessageCard;

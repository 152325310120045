import gql from "graphql-tag";

const MessageFragment = gql`
  fragment MessageFields on Message {
    id
    createdAt
    messageHtml
    messageDeliveryStatus
    fromAddress
    fromAddressType
    toAddress
    subject
  }
`;

export default MessageFragment;

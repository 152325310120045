import { useState, useEffect } from "react";
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import DialogActions from "@mui/material/DialogActions";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import { useQuery, useMutation } from "@apollo/client";
import NOTIFICATIONS, {
    NotificationsInput,
    NotificationsPayload
} from "graphql/queries/NotificationsQuery";
import MARK_NOTIFICATIONS_AS_READ, {
    MarkNotificationsAsReadInput,
    MarkNotificationsAsReadPayload
} from "graphql/mutations/MarkNotificationsAsReadMutation";
import MARK_ALL_NOTIFICATIONS_AS_READ, {
    MarkAllNotificationsAsReadInput,
    MarkAllNotificationsAsReadPayload
} from "graphql/mutations/MarkAllNotificationsAsReadMutation";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import { NoScrollZenDataGrid } from "theme/DataGrid";
import { GridRowsProp, GridColDef, GridSelectionModel } from "@mui/x-data-grid-pro";
import { renderNotification } from "./RenderNotification";
import { renderTimeAgo } from "./RenderTimeAgo";
import Checkbox from "theme/Checkbox";
import { LoadingButton } from "theme/Button";
import { useHistory } from "react-router-dom";

interface Props {
    open: boolean;
    onClose: () => void;
}

export default function NotificationsDialog(props: Props) {

    const { onClose, open } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [alertError, setAlertError] = useState<string | undefined>(undefined);
    const theme = useTheme();
    const [page, setPage] = useState<number>(0);
    const [after, setAfter] = useState<string | undefined>(undefined);
    const [before, setBefore] = useState<string | undefined>(undefined);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const history = useHistory();

    const { error: notificiationsError, data: notificationsData, refetch } = useQuery<NotificationsPayload, NotificationsInput>(NOTIFICATIONS, {
        variables: {
            after: after,
            before: before,
            first: !after && !before ? 10 : !!after ? 10 : undefined,
            last: !!before ? 10 : undefined,
        },
    });

    const [markNotificationsAsRead, { }] = useMutation<MarkNotificationsAsReadPayload, MarkNotificationsAsReadInput>(MARK_NOTIFICATIONS_AS_READ, { refetchQueries: ["Notifications"] });
    const [markAllNotificationsAsRead, { }] = useMutation<MarkAllNotificationsAsReadPayload, MarkAllNotificationsAsReadInput>(MARK_ALL_NOTIFICATIONS_AS_READ, { refetchQueries: ["Notifications"] });

    const rows: GridRowsProp = !!(notificationsData?.myNotifications)
        ? notificationsData.myNotifications.nodes.map((row) => {
            return {
                id: row.id,
                notification: row,
                date: row.createdAt,
                conversationId: row.conversation?.id,
            };
        })
        : [];

    const columns: GridColDef[] = [
        {
            field: "notification",
            headerName: "Notification",
            flex: 1,
            renderCell: renderNotification
        },
        {
            field: "date",
            headerName: "Time Ago",
            width: 200,
            renderCell: renderTimeAgo
        },
    ];

    function navigateToAfter(after: string, page: number) {
        setPage(page);
        setBefore(undefined);
        setAfter(after);
    };

    function navigateToBefore(before: string, page: number) {
        setPage(page);
        setBefore(before);
        setAfter(undefined);
    };

    function navigateStart() {
        setPage(0);
        setBefore(undefined);
        setAfter(undefined);
    }

    useEffect(() => {
        refetch();
    }, [open]);

    useEffect(() => {
        if (!!notificiationsError) {
            setAlertError(notificiationsError.message);
        } else {
            setAlertError(undefined);
        }
    }, [notificiationsError]);

    const closeDialog = () => {
        onClose();

        setTimeout(() => {
            setAlertError(undefined);
            setSelectionModel([]);
            navigateStart();
        }, 200);
    }


    const handleClose = () => {
        if (loading) {
            return;
        }
        closeDialog();
    };

    const handleConfirm = async () => {
        try {
            setLoading(true);

            if (selectionModel.length === 0) {
                const { errors, data } = await markAllNotificationsAsRead({ variables: { input: {} } });

                if (!!errors && errors.length > 0) {
                    setAlertError(errors[0].message);
                } else if (!!data && data.markAllNotificationsAsRead.ok) {
                    closeDialog();
                }
            } else {
                const notificationIds = selectionModel as string[];

                const { errors, data } = await markNotificationsAsRead({ variables: { input: { notificationIds: notificationIds } } });

                if (!!errors && errors.length > 0) {
                    setAlertError(errors[0].message);
                } else if (!!data && data.markNotificationsAsRead.notifications.length > 0) {
                    closeDialog();
                }
            }
        } catch (e: unknown) {
            if (e instanceof Error) {
                setAlertError(e.message);
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <Dialog onClose={() => handleClose()} open={open} scroll="paper" maxWidth="lg" fullWidth>
            <DialogTitle>
                <Typography variant="h5">Notifications</Typography>
            </DialogTitle>
            <DialogContent dividers sx={{ backgroundColor: theme.palette.background.default }}>
                <Stack direction="column" spacing={2} sx={{
                    '& .notifications--Unread': {

                    },
                    '& .notifications--Read': {
                        bgcolor: (theme) => "rgba(234,238,242,0.5)",
                        '&:hover': {
                            bgcolor: (theme) => "rgba(234,238,242,0.9)",
                        }
                    },
                }}>
                    {!!alertError && <Alert severity="error" icon={false}>{alertError}</Alert>}
                    <NoScrollZenDataGrid
                        getRowClassName={(params) => {
                            return `notifications--${!!params.row.notification.read ? "Read" : "Unread"}`
                        }}
                        style={{ height: "100%" }}
                        disableColumnPinning
                        components={{
                            BaseCheckbox: Checkbox,
                            NoResultsOverlay: () => (<div />),
                        }}
                        autoHeight
                        disableColumnFilter
                        disableColumnReorder
                        disableColumnSelector
                        rows={rows}
                        columns={columns}
                        loading={!notificationsData}
                        page={page}
                        selectionModel={selectionModel}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        onPageChange={(newPage) => {
                            if (loading) {
                                return;
                            }
                            if (newPage > page) {
                                navigateToAfter(notificationsData!.myNotifications.pageInfo.endCursor, newPage);
                            } else {
                                navigateToBefore(notificationsData!.myNotifications.pageInfo.startCursor, newPage);
                            }
                        }}
                        rowHeight={55}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        rowCount={!!(notificationsData?.myNotifications) ? notificationsData.myNotifications.pageInfo.totalCount : 0}
                        paginationMode="server"
                        sortingMode="server"
                        pagination
                        disableSelectionOnClick
                        checkboxSelection
                        onRowClick={(row) => {
                            console.log(row);
                            const conversationId = (row.row as any).conversationId as string;
                            history.push(`/conversation/${conversationId}`);
                            closeDialog();
                        }}
                    />
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                {selectionModel.length > 0 && <LoadingButton size="small" loading={loading} disabled={selectionModel.length === 0 || loading} variant="contained" color="success" onClick={() => handleConfirm()}>Mark as read</LoadingButton>}
                {selectionModel.length === 0 && <LoadingButton size="small" loading={loading} disabled={loading} variant="contained" color="success" onClick={() => handleConfirm()}>Clear all</LoadingButton>}
            </DialogActions>
        </Dialog>
    );
}
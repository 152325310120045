import { useState } from "react";
import Stack from '@mui/material/Stack';
import DialogTitle from "theme/DialogTitle";
import DialogContent from 'theme/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Button from "theme/Button";
import Typography from '@mui/material/Typography';
import TextField from 'theme/TextField';
import { useMutation } from "@apollo/client";
import BULK_MARK_AS_RESOLVED, {
    BulkMarkAsResolvedInput,
    BulkMarkAsResolvedPayload,
} from "graphql/mutations/BulkMarkAsResolvedMutation";
import Alert from "@mui/material/Alert";
import { LoadingButton } from 'theme/Button';

export interface Props {
    open: boolean;
    resolve: boolean;
    conversationIds: string[];
    onClose: (completed: boolean) => void;
}

export default function ConfirmBulkMarkAsResolvedDialog(props: Props) {

    const { onClose, open, conversationIds, resolve } = props;
    const [resolutionNote, setResolutionNote] = useState<string>("");
    const [alertError, setAlertError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const [bulkMarkAsResolved, { }] = useMutation<BulkMarkAsResolvedPayload, BulkMarkAsResolvedInput>(BULK_MARK_AS_RESOLVED, {
        refetchQueries: ["Conversations", "Conversation", "SideBarQuery", "Customers"]
    });

    const handleClose = (completed: boolean) => {
        if (loading) {
            return;
        }
        onClose(completed);
        setTimeout(() => {
            setResolutionNote("");
            setAlertError(undefined);
        }, 200);
    };

    const handleResolveConversations = async () => {
        try {
            setLoading(true);

            const { data, errors } = await bulkMarkAsResolved({
                variables: {
                    input: {
                        ids: conversationIds,
                        reason: resolutionNote,
                        resolved: resolve,
                    }
                }
            });

            if (!!data) {
                if (data.bulkMarkAsResolved.errors.length > 0) {
                    setAlertError(data.bulkMarkAsResolved.errors[0]);
                } else {
                    handleClose(true);
                }
            } else if (!!errors && errors.length > 0) {
                setAlertError(errors[0].message);
            }

        } catch (e: unknown) {
            setAlertError("An error occured");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog onClose={() => handleClose(false)} open={open} fullWidth={true} maxWidth="xs">
            <DialogTitle>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h5" fontWeight={600}>{resolve ? "Resolve these conversations" : "Return to in progress"}</Typography>
                </Stack>
            </DialogTitle>
            <DialogContent dividers>
                <Stack direction="column" spacing={2}>
                    {!!alertError && <Alert severity="error">{alertError}</Alert>}
                    {resolve && <DialogContentText>Confirm you would like to resolve these ({conversationIds.length}) conversations.</DialogContentText>}
                    {!resolve && <DialogContentText>Confirm you would like to return these ({conversationIds.length}) conversations to in progress.</DialogContentText>}
                    <TextField disabled={loading} value={resolutionNote} onChange={(e) => setResolutionNote(e.target.value)} autoFocus placeholder={resolve ? "Note of the solution" : "Note for returning to in progress"} size="small" variant="outlined"></TextField>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button size="small" disabled={loading} onClick={() => handleClose(false)}>Close</Button>
                <LoadingButton size="small" loading={loading} disabled={loading || resolutionNote.length === 0} variant="contained" color={resolve ? "success" : "warning"} onClick={() => handleResolveConversations()}>{resolve ? "Resolve" : "Return to in progress"} </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import useTheme from "@mui/material/styles/useTheme";
import WelcomeToPaidPlanCard from "components/getting_started/WelcomeToPaidPlan";

function GettingStarted() {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                backgroundColor: theme.palette.prominent.main
            }}
        >
            <Backdrop open={true}>
                <WelcomeToPaidPlanCard />
            </Backdrop>
        </Box>
    );
}

export default GettingStarted;
import { gql } from "@apollo/client";
import PageInfo from "types/PageInfo";
import ShopifyOrder from "types/ShopifyOrder";
import Customer from "types/Customer";
import Conversation from "types/Conversation";

export interface GlobalSearchInput {
    searchQuery: string;
}

export interface GlobalSearchPayload {
    globalSearch: {
        conversations: {
            pageInfo: PageInfo;
            nodes: Conversation[];

        };
        customers: {
            pageInfo: PageInfo;
            nodes: Customer[];
        }
        orders: {
            pageInfo: PageInfo;
            nodes: ShopifyOrder[];
        }
    };
}

const GLOBAL_SEARCH = gql`
  query GlobalSearch($searchQuery: String!) {
    globalSearch(searchQuery: $searchQuery) {
        conversations {
            pageInfo {
                startCursor
                endCursor
                totalCount
            }
            nodes {
                id
                createdAt
                timeLeft
                messageCount
                number
                latestMessage
                totalOrderValue
                type {
                  id
                  title
                }
                customer {
                  id
                  name
                  email
                }
                assignees {
                  nodes {
                    id
                    name
                    avatarUrl
                  }
                }
                labels {
                  nodes {
                    id
                    title
                    colorHex
                  }
                }
            }
        }
        customers {
            pageInfo {
                startCursor
                endCursor
                totalCount
            }
            nodes {
                id
                name
                numberOfConversations
                numberOfOrders
                totalSpend
                email
                latestOrderDate
            }
        }
        orders {
            pageInfo {
                startCursor
                endCursor
                totalCount
            }
            nodes {
                ... on ShopifyOrder {
                    id
                    createdAt
                    name
                    lineItemsCount
                    lineItemsFormatted
                    financialStatus
                    fulfillmentStatus
                    closed
                    fulfilled
                    unpaid
                    currentSubtotalPriceSet {
                      shopAmount
                    }
                    customer {
                      id
                      name
                      email
                    }
                  }
                ... on AmazonOrder {
                    id
                }
                ... on ZenOrder {
                    id
                }
            }
        }
    }
  }
`;

export default GLOBAL_SEARCH;
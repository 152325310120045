import BaseObject from "./Base";
import User from "./User";
import Customer from "./Customer";
import OrgFile from "./OrgFile";
import PageInfo from "./PageInfo";

interface Message extends BaseObject {
  messageHtml: string;
  messageDeliveryStatus: string;
  sender: User | Customer;
  subject?: string;
  toAddress: string;
  fromAddress: string;
  fromAddressType: string;
  fileAttachments: {
    pageInfo: PageInfo;
    nodes: OrgFile[];
  }
}

export function instanceOfMessage(object: any): object is Message {
  return object.__typename === "Message";
}

export function instanceOfUser(object: any): object is User {
  return object.__typename === "User";
}

export function instanceOfCustomer(object: any): object is User {
  return object.__typename === "Customer";
}

export default Message;

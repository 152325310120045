import { gql } from "@apollo/client";
import Conversation from "types/Conversation";
import CompleteConversationFragment from "../fragments/CompleteConversationFragment";

export interface UpdateConversationWaitingInput {
  input: {
    id: string;
    waiting: boolean;
  };
}

export interface UpdateConversationWaitingPayload {
  updateConversationWaiting: {
    errors: string[];
    conversation: Conversation;
  };
}

const UPDATE_CONVERSATION_WAITING = gql`
  mutation UpdateConversationWaiting(
    $input: UpdateConversationWaitingMutationInput!
  ) {
    updateConversationWaiting(input: $input) {
      errors
      conversation {
        ...CompleteConversationFields
      }
    }
  }
  ${CompleteConversationFragment}
`;

export default UPDATE_CONVERSATION_WAITING;

import { gql } from "@apollo/client";

export interface CompleteOnboardingInput {
    input: {

    };
}

export interface CompleteOnboardingPayload {
    completeOnboarding: {
        ok: boolean;
    };
}

const COMPLETE_ONBOARDING = gql`
  mutation CompleteOnboarding($input: CompleteOnboardingMutationInput!) {
    completeOnboarding(input: $input) {
      ok
    }
  }
`;

export default COMPLETE_ONBOARDING;

import { gql } from "@apollo/client";
import Notification from "types/Notification";

export interface MarkNotificationsAsReadInput {
  input: {
    notificationIds: string[];
  };
}

export interface MarkNotificationsAsReadPayload {
  markNotificationsAsRead: {
    errors: string[];
    notifications: Notification[];
  };
}

const MARK_NOTIFICATIONS_AS_READ = gql`
  mutation MarkNotificationsAsRead($input: MarkNotificationsAsReadMutationInput!) {
    markNotificationsAsRead(input: $input) {
      errors
      notifications {
        id
        createdAt
        read
        title
      }
    }
  }
`;

export default MARK_NOTIFICATIONS_AS_READ;

import gql from "graphql-tag";

const OrgFileFragment = gql`
  fragment OrgFileFields on OrgFile {
    id
    createdAt
    givenFilename
    privateFile
    fileSize
    shortcut
  }
`;

export default OrgFileFragment;

import gql from "graphql-tag";

const MailingAddressFragment = gql`
  fragment MailingAddressFields on MailingAddress {
    id
    isDefault
    createdAt
    address1
    address2
    city
    province
    company
    countryCode
    firstName
    lastName
    zip
    phone
    formatted
  }
`;

export default MailingAddressFragment;

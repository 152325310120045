import * as React from "react";
import { GridCellParams } from "@mui/x-data-grid-pro";
import Avatar from "theme/Avatar";
import Stack from "@mui/material/Stack";
import useTheme from "@mui/material/styles/useTheme";
import IconButton from "@mui/material/IconButton";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

interface Assignee {
  id: string;
  avatarUrl?: string;
  name: string;
}

interface AssigneeProps {
  value: Assignee[];
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  let obj = {};
  try {
    let text = "";
    if (name.includes(" ")) {
      const splitName = name.split(" ");
      text = `${splitName[0][0]}${splitName[1][0]}`;
    } else {
      text = name.charAt(0);
    }
    obj = {
      children: text,
    };
  } catch {
  } finally {
    return obj;
  }
}

const AssigneeCell = React.memo(function AssigneeFunction(
  props: AssigneeProps
) {
  const { value } = props;
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      sx={{
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        color: theme.palette.text.secondary,
      }}
    >
      {value.map((assignee) => (
        <Avatar
          src={assignee.avatarUrl}
          key={assignee.id}
          variant="rounded"
          sx={{
            mr: theme.spacing(1),
            bgcolor: stringToColor(assignee.name),
          }}
          {...stringAvatar(assignee.name)}
        />
      ))}
      {value.length === 0 && (
        <IconButton
          size="large"
          color="inherit"
          aria-label="menu"
          sx={{ mr: theme.spacing(1) }}
        >
          <PersonAddIcon />
        </IconButton>
      )}
    </Stack>
  );
});

export function renderAssignee(params: GridCellParams) {
  return <AssigneeCell value={params.value as Assignee[]} />;
}

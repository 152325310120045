import { useState, lazy, Suspense, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Backdrop from "@mui/material/Backdrop";
import Button from "theme/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { DateRange } from '@mui/lab/DateRangePicker';
import { DatePickerView } from '@mui/lab/DatePicker/shared';
import DatePicker from '@mui/lab/DatePicker';
import DateRangeIcon from '@mui/icons-material/DateRange';
import format from "date-fns-tz/format";
import startOfYear from "date-fns/startOfYear";
import endOfYear from "date-fns/endOfYear";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import FormControl from "@mui/material/FormControl";
import { ZenNativeSelect } from "theme/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from '@mui/material/Collapse';

const OrdersDashboard = lazy(() => import("components/dashboard/OrdersDashboard"));
const TeamDashboard = lazy(() => import("components/dashboard/TeamDashboard"));

export enum Period {
    weekly = "weekly",
    monthly = "monthly",
    yearly = "yearly"
}

export enum DashboardType {
    orders = "orders",
    team = "team",
}

function Dashboard() {
    const [betaAlertOpen, setBetaAlertOpen] = useState((localStorage.getItem("dashboard_beta_nag") || "true") === "true");
    const [dashboard, setDashboard] = useState<DashboardType>(DashboardType.team);
    const [period, setPeriod] = useState<Period>(Period.monthly);
    const [open, setOpen] = useState<boolean>(false);
    const [pickerValue, setPickerValue] = useState<Date>(new Date());
    const [value, setValue] = useState<DateRange<Date>>([startOfMonth(new Date()), endOfMonth(new Date())]);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    useEffect(() => {
        switch (period) {
            case Period.weekly: {
                setValue([startOfWeek(pickerValue), endOfWeek(pickerValue)]);
                break;
            }
            case Period.monthly: {
                setValue([startOfMonth(pickerValue), endOfMonth(pickerValue)]);
                break;
            }
            case Period.yearly: {
                setValue([startOfYear(pickerValue), endOfYear(pickerValue)]);
                break;
            }
        }
    }, [period]);

    const openTo = (): DatePickerView => {
        switch (period) {
            case Period.weekly: {
                return 'day';
            }
            case Period.monthly: {
                return 'month';
            }
            case Period.yearly: {
                return 'year';
            }
        }
    }

    const views = (): DatePickerView[] => {
        switch (period) {
            case Period.weekly: {
                return ['year', 'month', 'day'];
            }
            case Period.monthly: {
                return ['year', 'month'];
            }
            case Period.yearly: {
                return ['year'];
            }
        }
    }

    return (
        <Stack direction="column">
            <Collapse in={betaAlertOpen}>
                <Alert severity="info" action={
                    <Button color="inherit" size="small" onClick={() => {
                        setBetaAlertOpen(false);
                        localStorage.setItem("dashboard_beta_nag", "false");
                    }}>
                        Dismiss
                    </Button>
                }>
                    <AlertTitle>This feature is in Beta</AlertTitle>
                    We&apos;re not done improving the Dashboard — <strong>please leave your feedback.</strong>
                </Alert>
            </Collapse>
            <Stack direction="column" spacing={2} p={2}>
                <div>
                    <Backdrop open={open} sx={{ zIndex: 2 }} />
                    <Stack alignItems="center" direction="row" spacing={1}>
                        <DatePicker
                            openTo={openTo()}
                            views={views()}
                            PopperProps={{ placement: "bottom-start", anchorEl: anchorEl }}
                            open={open}
                            value={pickerValue}
                            onChange={(newValue) => {
                                if (!!newValue) {
                                    switch (period) {
                                        case Period.weekly: {
                                            setValue([startOfWeek(newValue), endOfWeek(newValue)]);
                                            break;
                                        }
                                        case Period.monthly: {
                                            setValue([startOfMonth(newValue), endOfMonth(newValue)]);
                                            break;
                                        }
                                        case Period.yearly: {
                                            setValue([startOfYear(newValue), endOfYear(newValue)]);
                                            break;
                                        }
                                    }
                                    setPickerValue(newValue);
                                }
                            }}
                            onClose={() => setOpen(false)}
                            renderInput={() => <Button size="small" color="inherit" startIcon={<DateRangeIcon />} onClick={(event) => { setAnchorEl(event.currentTarget); setOpen(true); }} variant="outlined" endIcon={<KeyboardArrowDownIcon />}>{format(value[0] as Date, "do MMM")} - {format(value[1] as Date, "do MMM")}</Button>}
                        />
                        <FormControl variant="outlined">
                            <ZenNativeSelect defaultValue={Period.monthly} input={<OutlinedInput sx={{ height: 30 }} size="small" />} onChange={(e) => {
                                setPeriod(e.target.value as Period);
                            }}>
                                <option value={Period.weekly}>Weekly</option>
                                <option value={Period.monthly}>Monthly</option>
                                <option value={Period.yearly}>Annual</option>
                            </ZenNativeSelect>
                        </FormControl>
                        <FormControl variant="outlined">
                            <ZenNativeSelect defaultValue={DashboardType.team} input={<OutlinedInput sx={{ height: 30 }} size="small" />} onChange={(e) => {
                                setDashboard(e.target.value as DashboardType);
                            }}>
                                <option value={DashboardType.team}>Team</option>
                                <option value={DashboardType.orders}>Store</option>
                            </ZenNativeSelect>
                        </FormControl>
                    </Stack>
                </div>
                <Suspense fallback={<div>Loading dashboard</div>}>
                    {dashboard === DashboardType.team && <TeamDashboard dateRange={value} period={period} />}
                    {dashboard === DashboardType.orders && <OrdersDashboard dateRange={value} period={period} />}
                </Suspense>
            </Stack>
        </Stack>
    );
}

export default Dashboard;
import Stack from '@mui/material/Stack';
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import User from "types/User";
import IconButton from '@mui/material/IconButton';
import CancelIcon from "@mui/icons-material/Cancel";
import { useForm, Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import TextField from "theme/TextField";
import Alert from "@mui/material/Alert";
import * as EmailValidator from "email-validator";
import { useMutation } from "@apollo/client";
import INVITE_MEMBER, {
    InviteMemberInput,
    InviteMemberPayload,
} from "graphql/mutations/InviteMemberMutation";
import { useState, useEffect } from "react";
import { LoadingButton } from "theme/Button";
import { useSnackbar } from 'notistack';
import DialogActions from '@mui/material/DialogActions';

export interface MemberDialogProps {
    open: boolean;
    member?: User;
    onClose: () => void;
}

interface Form {
    email?: string;
}

export default function InviteMemberDialog(props: MemberDialogProps) {

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors, isSubmitting },
    } = useForm();

    const [inviteMember, { error: inviteError, loading }] = useMutation<InviteMemberPayload, InviteMemberInput>(
        INVITE_MEMBER,
        {}
    );

    const { enqueueSnackbar } = useSnackbar();

    const [error, setError] = useState<string | undefined>();

    const { onClose, open } = props;

    const handleClose = () => {
        onClose();
        reset({ keepValues: false, keepErros: false });
        setError(undefined);
    };

    const onSubmit = async (data: Form) => {

        try {
            const { data: payload } = await inviteMember({
                variables: {
                    input: {
                        email: data.email!,
                    },
                },
            });

            if (!!payload && !!payload.inviteMember.org) {
                handleClose();
                enqueueSnackbar(`Invitation sent to ${data.email!.toLowerCase()}`);
            }

            if (!!payload && payload.inviteMember.errors.length > 0) {
                setError(payload.inviteMember.errors[0]);
            }

        } catch { }

    };

    useEffect(() => {
        if (!!inviteError) {
            setError(inviteError.message);
        }
    }, [inviteError]);

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"xs"}>
            <DialogTitle>Invite to team
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 12,
                        color: "text.secondary",
                    }}
                >
                    <CancelIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Stack direction="column" spacing={2}>
                    {!!error && <Alert severity="error">{error}</Alert>}
                    <DialogContentText>They will receive a link to join after sending the invitation.</DialogContentText>
                    <FormControl fullWidth variant="outlined">
                        <Controller
                            name="email"
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    id="email"
                                    type="email"
                                    size="small"
                                    helperText={errors.email ? errors.email.message : null}
                                    variant="outlined"
                                    placeholder="Email"
                                    error={!!errors.email}
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "Please enter an email address",
                                validate: (value) =>
                                    EmailValidator.validate(value) || "Invalid email address",
                            }}
                        />
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <LoadingButton loading={loading} size="small" disabled={isSubmitting} variant="contained" color="success" type="submit" onClick={handleSubmit(onSubmit)}>
                    Invite
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
import { useEffect, useState } from "react";
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import Stack from '@mui/material/Stack';
import Button from 'theme/Button';
import Dialog from '@mui/material/Dialog';
import { useMutation, useQuery } from "@apollo/client";
import IconButton from '@mui/material/IconButton';
import CancelIcon from "@mui/icons-material/Cancel";
import { useSnackbar } from 'notistack';
import { useForm, Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import CREATE_TEMPLATE, { CreateTemplateInput, CreateTemplatePayload } from "graphql/mutations/CreateTemplateMutation";
import LAYOUTS, { LayoutsInput, LayoutsPayload } from "graphql/queries/LayoutsQuery";
import { useHistory } from "react-router-dom";
import TextField from 'theme/TextField';
import Alert from '@mui/material/Alert';
import Select from "theme/Select";
import Layout from "types/Layout";
import useTheme from "@mui/material/styles/useTheme";
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from "theme/Checkbox";
import Typography from "@mui/material/Typography";

export interface NewTemplateDialogProps {
    open: boolean;
    onClose: () => void;
}

export default function NewTemplateDialogWrapper(props: NewTemplateDialogProps) {

    const { data } = useQuery<LayoutsPayload, LayoutsInput>(LAYOUTS, {
        variables: { filters: {} },
    });


    if (!!data && !!data.layouts) {
        return <NewTemplateDialog layouts={data.layouts.nodes}  {...props} />
    }

    return null;
}

interface NewTemplateDialogInnerProps extends NewTemplateDialogProps {
    layouts: Layout[];
}

function NewTemplateDialog(props: NewTemplateDialogInnerProps) {

    const {
        control,
        trigger,
        handleSubmit,
        reset,
        setValue: setFormValue,
        formState: { errors, isValid },
    } = useForm();

    const [sharedTemplate, setSharedTemplate] = useState<"shared" | "private">("shared");
    const [alertError, setAlertError] = useState<string | undefined>(undefined);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const theme = useTheme();

    const [createTemplate, _] = useMutation<CreateTemplatePayload, CreateTemplateInput>(CREATE_TEMPLATE, { refetchQueries: ["Templates", "Workflows"] });

    const { onClose, open, layouts } = props;

    const handleClose = () => {
        onClose();
    };

    const onSubmit = async (formData: { [x: string]: any; }) => {
        try {
            const { data } = await createTemplate({
                variables: {
                    input: {
                        name: formData.template_name,
                        subject: formData.template_subject,
                        shortcut: formData.template_shortcut,
                        contentHtml: "<p></p>",
                        layoutId: formData.layout_id,
                        privateTemplate: sharedTemplate === "private",
                    }
                }
            });

            if (!!data?.createTemplate.template) {
                enqueueSnackbar(`Created Template`);
                history.push(`/template/${data.createTemplate.template.id}`)
            } else if (!!data?.createTemplate && data.createTemplate.errors.length > 0) {
                setAlertError(data.createTemplate.errors[0]);
            }
        } catch (e: unknown) {
            if (e instanceof Error) {
                setAlertError(e.message);
            }
        }
    };

    let options = layouts.map((layout) => <option key={layout.id} value={layout.id}>{layout.name}</option>) ?? [];

    useEffect(() => {
        if (!open) {
            reset();
        }
    }, [open]);

    useEffect(() => {
        if (layouts.length > 0) {
            setFormValue("layout_id", layouts.filter((o) => !o.deletable)[0].id);
        }
    }, [layouts]);

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
            <DialogTitle sx={{ m: 0, p: 2, pl: 3, pr: 12 }}>New template
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 12,
                        color: "text.secondary",
                    }}
                >
                    <CancelIcon />
                </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent dividers sx={{ backgroundColor: theme.palette.background.default }}>

                    <Stack direction="column" spacing={2}>
                        {!!alertError && <Alert severity="error">{alertError}</Alert>}
                        <FormControl variant="outlined" sx={{ pt: 1 }}>
                            <Controller
                                name="template_name"
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        id="template_name"
                                        type="template_name"
                                        size="small"
                                        helperText={errors.template_name ? errors.template_name.message : null}
                                        variant="outlined"
                                        placeholder="Name of template"
                                        error={!!errors.template_name}
                                        onChange={(e) => { onChange(e); trigger("template_name"); }}
                                        value={value}
                                    />
                                )}
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "Please enter a name for the template",
                                }}
                            />
                        </FormControl>
                        <FormControl variant="outlined">
                            <Controller
                                name="template_subject"
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        id="template_subject"
                                        type="template_subject"
                                        size="small"
                                        helperText={errors.template_subject ? errors.template_subject.message : null}
                                        variant="outlined"
                                        placeholder="Email subject"
                                        error={!!errors.template_subject}
                                        onChange={(e) => {
                                            onChange(e);
                                            trigger("template_subject");
                                        }}
                                        value={value}
                                    />
                                )}
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "Please enter a subject",
                                }}
                            />
                        </FormControl>
                        <FormControl variant="outlined">
                            <Controller
                                name="template_shortcut"
                                render={({ field: { value } }) => (
                                    <TextField
                                        id="template_shortcut"
                                        type="template_shortcut"
                                        size="small"
                                        helperText={errors.template_shortcut ? errors.template_shortcut.message : "Shortcuts can be used from the Conversations tab with / in the text box."}
                                        variant="outlined"
                                        placeholder="Shortcut"
                                        error={!!errors.template_shortcut}
                                        onChange={(e) => {
                                            setFormValue("template_shortcut", e.target.value.toLowerCase());
                                            trigger("template_shortcut");
                                        }}
                                        value={value}
                                    />
                                )}
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "Please enter a shortcut",
                                    pattern: {
                                        value: /^[a-z\-]+$/,
                                        message: "(only letters and hyphen allowed)",
                                    },
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth variant="outlined">
                            <Controller
                                name="layout_id"
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        labelId="layout-label"
                                        id="layout_id"
                                        value={value}
                                        onChange={onChange}
                                        native
                                        size="small"
                                    >
                                        {options}
                                    </Select>
                                )}
                                control={control}
                            />
                            <FormHelperText>The layout is used when sending messages by Email.</FormHelperText>
                        </FormControl>
                        <FormControl>
                            <Typography variant="body2" color="text.secondary" mb={1}>Permissions</Typography>
                            <RadioGroup sx={{ ml: 2 }} defaultValue={sharedTemplate} onChange={(e) => setSharedTemplate(e.target.value as any)}>
                                <FormControlLabel onClick={() => setSharedTemplate("shared")} value="shared" control={<Checkbox checked={sharedTemplate == "shared"} />} label={<Typography variant="subtitle2">Team (your team can use this template)</Typography>} />
                                <FormControlLabel onClick={() => setSharedTemplate("private")} value="private" control={<Checkbox checked={sharedTemplate == "private"} />} label={<Typography variant="subtitle2">Private (only you can use this template)</Typography>} />
                            </RadioGroup>
                        </FormControl>
                    </Stack>
                </DialogContent>
                <Stack direction="row" justifyContent="flex-end" sx={{ p: 2 }}>
                    <div>
                        <Button size="small" type="submit" disabled={!isValid} fullWidth color="success" variant="contained">Continue</Button>
                    </div>
                </Stack>
            </form>
        </Dialog>
    );
}
import gql from "graphql-tag";

const ActivityFragment = gql`
  fragment ActivityFields on Activity {
    id
    createdAt
    activityType
    mentionedOrderBy
  }
`;

export default ActivityFragment;

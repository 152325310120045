import React, { useState, useEffect } from "react";
import AppBar from "theme/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SearchButton } from "theme/Button";
import Stack from "@mui/material/Stack";
import { AdminButton, AdminIconButton } from "theme/Button";
import { useHistory } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useTheme from "@mui/material/styles/useTheme";
import { useApp } from "AppContext";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "theme/Divider";
import Avatar from "theme/Avatar";
import User from "types/User";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ChatIcon from '@mui/icons-material/Chat';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import Button, { UpgradeButton, LoadingButton } from "theme/Button";
import MuiButton from "@mui/material/Button";
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Badge from '@mui/material/Badge';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import FavoriteIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { useQuery, useMutation } from "@apollo/client";
import NotificationsMenu from "components/notifications/NotificationsMenu";
import { useSubscription } from "@apollo/client";
import MY_NOTIFICATIONS_UPDATED, {
  MyNotificationsUpdatedInput,
  MyNotificationsUpdatedPayload
} from "graphql/subscriptions/MyNotificationsUpdatedSubscription";
import SELECTED_ORG, {
  SelectedOrgInput,
  SelectedOrgPayload,
} from "graphql/queries/SelectedOrgQuery";
import MY_UNREAD_NOTIFICATIONS, {
  MyUnreadNotificationsInput,
  MyUnreadNotificationsPayload,
} from "graphql/queries/MyUnreadNotificationsQuery";
import { useSnackbar } from 'notistack';
import GlobalSearchDialog from "components/global_search/GlobalSearchDialog";
import SearchIcon from '@mui/icons-material/Search';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import IconButton from '@mui/material/IconButton';
import useInterval from 'hooks/useInterval';
import PING_USER_ACTIVITY, {
  PingUserActivityInput,
  PingUserActivityPayload
} from "graphql/mutations/PingUserActivityMutation";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import COMPLETE_ONBOARDING, {
  CompleteOnboardingInput,
  CompleteOnboardingPayload
} from "graphql/mutations/CompleteOnboardintMutation";
import ACKNOWLEDGE_FEATURE_DROP, {
  AcknowledgeFeatureDropInput,
  AcknowledgeFeatureDropPayload
} from "graphql/mutations/AcknowledgeFeatureDropMutation";
import { motion } from "framer-motion/dist/framer-motion";
import useSound from "use-sound";
import notification from './notification_pop.wav';

type Props = {
  children?: React.ReactNode;
  me: User;
};

declare module "@mui/material/AppBar" {
  interface AppBarPropsColorOverrides {
    prominent: true;
  }
}

export default function AdminAppBar(props: Props) {

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { children, me } = props;
  const app = useApp();
  const { data } = useQuery<SelectedOrgPayload, SelectedOrgInput>(SELECTED_ORG, { variables: {} });
  const { data: myUnreadNotificationsData, refetch: refetchNotifications } = useQuery<MyUnreadNotificationsPayload, MyUnreadNotificationsInput>(MY_UNREAD_NOTIFICATIONS, { variables: {} });
  const { data: notificationsUpdatedData } = useSubscription<MyNotificationsUpdatedPayload, MyNotificationsUpdatedInput>(MY_NOTIFICATIONS_UPDATED, { variables: { userId: me.id } });
  const [pingUserActivity, { }] = useMutation<PingUserActivityPayload, PingUserActivityInput>(PING_USER_ACTIVITY, {});
  const [completeOnboarding, { }] = useMutation<CompleteOnboardingPayload, CompleteOnboardingInput>(COMPLETE_ONBOARDING, { refetchQueries: ["Me"] });
  const [acknowledgeFeatureDrop, { }] = useMutation<AcknowledgeFeatureDropPayload, AcknowledgeFeatureDropInput>(ACKNOWLEDGE_FEATURE_DROP, { refetchQueries: ["Me"] });

  const [play] = useSound(notification);

  const history = useHistory();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [globalSearchOpen, setGlobalSearchOpen] = useState<boolean>(false);

  const [notificationsMenuOpen, setNotificationsMenuOpen] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [orgAnchorEl, setOrgAnchorEl] = useState<null | HTMLElement>(null);
  const orgOpen = Boolean(orgAnchorEl);

  const handleUserClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOrgClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOrgAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOrgAnchorEl(null);
  };

  const isConversation = window.location.pathname.includes("/conversation");
  const isOrder = window.location.pathname.includes("/order");
  const isAddressbook = window.location.pathname.includes("/address-book") || window.location.pathname.includes("/customer");
  const isFiles = window.location.pathname.includes("/files");

  useInterval(() => {
    try {
      refetchNotifications();
      pingUserActivity({ variables: { input: { availableForChat: true } } });
    } catch { }
  }, !!app.token ? 60000 : null);

  useEffect(() => {
    if (!!notificationsUpdatedData) {
      refetchNotifications();

      if (!!notificationsUpdatedData?.myNotificationsUpdated?.notification) {
        if (!notificationsUpdatedData.myNotificationsUpdated.notification.read) {
          enqueueSnackbar(notificationsUpdatedData.myNotificationsUpdated.notification.title);
          play();
        }
      }
    }
  }, [notificationsUpdatedData]);

  return (
    <Stack direction="column">
      {!data?.selectedOrg?.exceededQuota && !!me.onboardingNag && <Stack gap={2} flexWrap="wrap" sx={{ p: 1, color: "white", backgroundImage: "linear-gradient(135deg, rgb(14, 115, 204) 1.93%, rgb(98, 75, 187) 14.86%, rgb(255, 69, 93) 48.09%, rgb(243, 88, 21) 77.82%, rgb(242, 182, 0) 97.3%)" }} direction="row" justifyContent="flext-start" alignItems="center">
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} viewport={{ once: true }} transition={{ delay: 0 }}>
          <Typography variant="h5" color="inherit" sx={{
            fontSize: "20px",
            pl: 2,
            display: {
              xs: "none",
              sm: "none",
              md: "inline-flex",
              lg: "inline-flex"
            },
          }}>Learn more</Typography>
        </motion.div>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} viewport={{ once: true }} transition={{ delay: 0.2 }}>
          <IconButton size="small" color="warning" sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "inline-flex",
              lg: "inline-flex"
            },
          }}><ArrowForwardIcon /></IconButton>
        </motion.div>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} viewport={{ once: true }} transition={{ delay: 0.4 }}>
          <MuiButton sx={{ textTransform: "none", fontWeight: 700 }} color="inherit" variant="text" size="small" onClick={() => history.push("/articles?selection=messenger")}>Setup Helpdesk Messenger</MuiButton>
        </motion.div>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} viewport={{ once: true }} transition={{ delay: 0.6 }}>
          <IconButton size="small" color="warning"><ArrowForwardIcon /></IconButton>
        </motion.div>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} viewport={{ once: true }} transition={{ delay: 0.8 }}>
          <MuiButton sx={{ textTransform: "none", fontWeight: 700 }} color="inherit" variant="text" size="small" onClick={() => history.push("/articles?selection=create_draft_article")}>Create an FAQ article</MuiButton>
        </motion.div>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} viewport={{ once: true }} transition={{ delay: 1.0 }}>
          <IconButton size="small" color="warning"><ArrowForwardIcon /></IconButton>
        </motion.div>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} viewport={{ once: true }} transition={{ delay: 1.2 }}>
          <MuiButton sx={{ textTransform: "none", fontWeight: 700 }} color="inherit" variant="text" size="small" onClick={() => history.push("/org-settings/templates")}>Create a template</MuiButton>
        </motion.div>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} viewport={{ once: true }} transition={{ delay: 1.4 }}>
          <IconButton size="small" color="warning"><ArrowForwardIcon /></IconButton>
        </motion.div>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} viewport={{ once: true }} transition={{ delay: 1.6 }}>
          <MuiButton sx={{ textTransform: "none", fontWeight: 700 }} color="inherit" variant="text" size="small" onClick={() => history.push("/org-settings/types")}>Create a conversation type</MuiButton>
        </motion.div>
        <div style={{ flexGrow: 1 }} />
        <LoadingButton loading={isLoading} sx={{ textTransform: "none", fontWeight: 700, mr: 2 }} color="inherit" variant="contained" size="small" onClick={async () => {
          try {
            setIsLoading(true);
            await completeOnboarding({ variables: { input: {} }, refetchQueries: ["Me"] });
          } catch {

          } finally {
            setIsLoading(false);
          }
        }}>I&apos;m ready, hide this 🦄</LoadingButton>
      </Stack>}
      {!data?.selectedOrg?.exceededQuota && !!me.featureDropAlert && !me.onboardingNag! && <motion.div initial={{ height: 0 }} viewport={{ once: true }} animate={{ y: 0, height: 46 }} transition={{ delay: 3, type: "keyframes" }}><Stack gap={2} flexWrap="wrap" sx={{ p: 1, color: "white", backgroundImage: "linear-gradient(135deg, rgb(14, 115, 204) 1.93%, rgb(98, 75, 187) 14.86%, rgb(255, 69, 93) 48.09%, rgb(243, 88, 21) 77.82%, rgb(242, 182, 0) 97.3%)" }} direction="row" justifyContent="center" alignItems="center">
        <motion.div initial={{ y: -100 }} animate={{ y: 0 }} transition={{ delay: 3.3 }}>
          <Typography variant="h5" color="inherit" sx={{
            fontSize: "20px",
            pl: 2,
            display: {
              xs: "none",
              sm: "none",
              md: "inline-flex",
              lg: "inline-flex"
            },
          }}>Feature drop 🫳</Typography>
        </motion.div>
        <motion.div initial={{ opacity: 0, scale: 2 }} viewport={{ once: true }} animate={{ opacity: 1, scale: 1 }} transition={{ delay: 3.6 }}>
          <LoadingButton loading={isLoading} sx={{ textTransform: "none", fontWeight: 700 }} color="inherit" variant="contained" size="small" onClick={async () => {
            try {
              setIsLoading(true);
              await acknowledgeFeatureDrop({ variables: { input: {} }, refetchQueries: ["Me"] });
            } catch {

            } finally {
              setIsLoading(false);
              history.push("/org-settings/email");
            }
          }}>Custom mailbox domains have arrived!</LoadingButton>
        </motion.div>
      </Stack ></motion.div >}
      {
        !!data?.selectedOrg?.exceededQuota && <Stack spacing={1} sx={{ color: "white", height: 40, backgroundImage: "linear-gradient(135deg, rgb(14, 115, 204) 1.93%, rgb(98, 75, 187) 14.86%, rgb(255, 69, 93) 48.09%, rgb(243, 88, 21) 77.82%, rgb(242, 182, 0) 97.3%)" }} direction="row" justifyContent="center" alignItems="center">
          <Typography variant="subtitle2" color="inherit">You have exceeded your monthly quota</Typography>
          <Button color="inherit" variant="contained" size="small" onClick={() => history.push("/org-settings/plan")}>Upgrade plan</Button>
        </Stack>
      }
      <NotificationsMenu open={notificationsMenuOpen} onClose={() => setNotificationsMenuOpen(false)} />
      <GlobalSearchDialog open={globalSearchOpen} onClose={() => setGlobalSearchOpen(false)} />
      <Menu
        variant="menu"
        anchorEl={orgAnchorEl}
        open={orgOpen}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: 250,
            outline: "none",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 42,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={0.5}
          pl={theme.spacing(2)}
          pb={theme.spacing(1)}
        >
          <Typography variant="body2" fontWeight={600}>
            {data?.selectedOrg?.name}
          </Typography>
          <IconButton
            size="small"
            onClick={() => { history.push("/select-org") }}
            sx={{
              color: "text.secondary",
            }}
          >
            <ChangeCircleOutlinedIcon />
          </IconButton>
        </Stack>
        <Divider />
        <MenuItem onClick={() => {
          handleClose();
          window.open(process.env.REACT_APP_DOCS_URL, "_blank");
        }}>
          <Typography variant="body2">Documentation</Typography>
        </MenuItem>
        <Stack direction="column">
          <MenuItem onClick={() => {
            handleClose();
            window.open(process.env.REACT_APP_DISCORD_URL, "_blank");
          }}>
            <Typography variant="body2">Get support</Typography>
          </MenuItem>
          <Divider sx={{ margin: "0px !important" }} />
        </Stack>
        <MenuItem onClick={() => {
          handleClose();
          history.push("/org-settings");
        }}>
          <Typography variant="body2">All settings</Typography>
        </MenuItem>
        <MenuItem onClick={() => {
          handleClose();
          history.push("/org-settings/team");
        }}>
          <Typography variant="body2">Team</Typography>
        </MenuItem>
        <MenuItem onClick={() => {
          handleClose();
          history.push("/org-settings/templates");
        }}>
          <Typography variant="body2">Templates</Typography>
        </MenuItem>
        <MenuItem onClick={() => {
          handleClose();
          history.push("/org-settings/workflows");
        }}>
          <Typography variant="body2">Workflows</Typography>
        </MenuItem>
        <MenuItem onClick={() => {
          handleClose();
          history.push("/org-settings/email");
        }}>
          <Typography variant="body2">Mailbox settings</Typography>
        </MenuItem>
        <MenuItem onClick={() => {
          handleClose();
          history.push("/org-settings/labels");
        }}>
          <Typography variant="body2">Labels</Typography>
        </MenuItem>
        <Stack direction="column">
          <Divider />
          <Stack
            direction="row"
            spacing={0.5}
            pt={theme.spacing(1)}
            pl={theme.spacing(2)}
            pr={theme.spacing(2)}
            alignItems="center"
          >
            <Typography variant="body2">
              Plan
            </Typography>
            <Typography variant="body2" fontWeight={600}>
              {data?.selectedOrg?.plan}
            </Typography>
            <div style={{ flexGrow: 1 }} />
            <UpgradeButton startIcon={<FavoriteIcon />} size="small" variant="contained" color="secondary" onClick={() => {
              history.push("/org-settings/plan");
              handleClose();
            }}>Upgrade</UpgradeButton>
          </Stack>
        </Stack>
      </Menu>
      <Menu
        variant="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: 200,
            outline: "none",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 42,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Stack
          direction="column"
          spacing={0.5}
          pl={theme.spacing(2)}
          pb={theme.spacing(1)}
        >
          <Typography variant="body2">Signed in as</Typography>
          <Typography variant="body2" fontWeight={600}>
            {me.name}
          </Typography>
        </Stack>
        <Divider />
        <MenuItem onClick={() => {
          history.push("/account-settings/profile");
          handleClose();
        }}>
          <Typography variant="body2">Account settings</Typography>
        </MenuItem>
        <MenuItem onClick={() => {
          app.selectedOrgID = undefined;
          app.token = undefined;
          history.push("/login");
        }}>
          <Typography variant="body2">Logout</Typography>
        </MenuItem>
      </Menu>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" elevation={0}>
          <Toolbar
            variant="dense"
            sx={{ bgcolor: theme.palette.prominent.main, paddingLeft: `${theme.spacing(2)} !important`, paddingRight: `${theme.spacing(2)} !important` }}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ width: "100%" }}
            >
              {!!data?.selectedOrg && <>
                <AdminButton onClick={() => history.push("/")} color="inherit" variant="text" startIcon={<img src="/images/mini-unicorn-logo.png" srcSet='/images/mini-unicorn-logo.png 1x, /images/mini-unicorn-logo-2x.png 2x' width={30} height={30} />}>
                  <Typography variant="h4" fontSize={25} color={theme.palette.common.white}>zenshop</Typography>
                </AdminButton>
                <div>
                  <SearchButton
                    variant="text"
                    color="primary"
                    size="small"
                    onClick={() => { setGlobalSearchOpen(true); }}
                    sx={{
                      width: "250px",
                      height: "40px",
                      mt: 1,
                      mb: 1,
                      ml: 1,
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "block",
                        lg: "block"
                      },
                    }}>
                    <Stack alignItems="center" direction="row" spacing={0.5}>
                      <SearchIcon color="inherit" />
                      <Typography variant='subtitle2'>Search</Typography>
                    </Stack>
                  </SearchButton>
                </div>
                <AdminButton
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "none",
                      lg: "block"
                    },
                    fontWeight: 800
                  }}
                  color="inherit"
                  variant={isConversation ? "contained" : "text"}
                  onClick={() => history.push("/conversations")}
                >
                  Conversations
                </AdminButton>
                <AdminIconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  sx={{
                    display: {
                      xs: "block",
                      sm: "block",
                      md: "block",
                      lg: "none"
                    },
                  }}
                  onClick={() => history.push("/conversations")}
                >
                  <ChatIcon />
                </AdminIconButton>
                <AdminButton
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "none",
                      lg: "block"
                    },
                    fontWeight: 800
                  }}
                  color="inherit"
                  variant={isOrder ? "contained" : "text"}
                  onClick={() => history.push("/orders")}
                >
                  Orders
                </AdminButton>
                <AdminIconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  onClick={() => history.push("/orders")}
                  sx={{
                    display: {
                      xs: "block",
                      sm: "block",
                      md: "block",
                      lg: "none"
                    },
                  }}
                >
                  <ShoppingCartIcon />
                </AdminIconButton>
                <AdminIconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  onClick={() => history.push("/address-book")}
                  sx={{
                    display: {
                      xs: "block",
                      sm: "block",
                      md: "block",
                      lg: "none"
                    },
                  }}
                >
                  <ImportContactsIcon />
                </AdminIconButton>
                <AdminButton
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "none",
                      lg: "block"
                    },
                    fontWeight: 800
                  }}
                  color="inherit"
                  variant={isAddressbook ? "contained" : "text"}
                  onClick={() => history.push("/address-book")}
                >
                  Address Book
                </AdminButton>
                <AdminButton
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "none",
                      lg: "block"
                    },
                    fontWeight: 800
                  }}
                  color="inherit"
                  variant={isFiles ? "contained" : "text"}
                  onClick={() => history.push("/files")}
                >
                  Files
                </AdminButton>
              </>}
              <Box sx={{ flexGrow: 1 }}></Box>
              {!!data?.selectedOrg && <>
                {false && <AdminIconButton sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "none",
                    lg: "block"
                  },
                }} color="inherit" onClick={() => history.push("/dashboard")}>
                  <InsertChartOutlinedIcon color="inherit" />
                </AdminIconButton>}
                <AdminIconButton sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "none",
                    lg: "block"
                  },
                }} color="inherit" onClick={() => history.push("/articles?selection=messenger")}>
                  <ForumOutlinedIcon color="inherit" />
                </AdminIconButton>
                <AdminIconButton color="inherit" onClick={() => {
                  setNotificationsMenuOpen(true);
                }}>
                  {!!(myUnreadNotificationsData && myUnreadNotificationsData.myUnreadNotifications > 0) && <Badge badgeContent={myUnreadNotificationsData.myUnreadNotifications} color="error">
                    <NotificationsActiveIcon color="inherit" />
                  </Badge>}
                  {!!(myUnreadNotificationsData && myUnreadNotificationsData.myUnreadNotifications === 0) && <NotificationsNoneOutlinedIcon color="inherit" />}
                </AdminIconButton>
              </>}
              {!!data?.selectedOrg && <AdminButton
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "none",
                    lg: "flex"
                  },
                }}
                color="inherit"
                endIcon={<ArrowDropDownIcon />}
                onClick={handleOrgClick}
              >
                {data.selectedOrg.name}
              </AdminButton>}
              <Divider sx={{ width: "1px", height: "20px", backgroundColor: "white" }} orientation="vertical" />
              <AdminButton
                color="inherit"
                endIcon={<ArrowDropDownIcon />}
                onClick={handleUserClick}
              >
                <Avatar variant="rounded" sx={{ width: 30, height: 30 }} src={me.avatarUrl} />
              </AdminButton>
            </Stack>
          </Toolbar>
        </AppBar>
      </Box>
      {children}
    </Stack >
  );
}

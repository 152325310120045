import gql from "graphql-tag";

const ShopifyShippingLineFragment = gql`
  fragment ShopifyShippingLineFields on ShopifyShippingLine {
    id
    createdAt
    title
  }
`;

export default ShopifyShippingLineFragment;

import * as React from "react";
import { GridCellParams } from "@mui/x-data-grid-pro";
import CircularProgress from "@mui/material/CircularProgress";
import Chip from '@mui/material/Chip';
import { useSubscription } from "@apollo/client";
import EMAIL_DOMAIN_UPDATED, { EmailDomainUpdatedInput, EmailDomainUpdatedPayload } from "graphql/subscriptions/EmailDomainUpdatedSubscription";
import EmailDomain from "types/EmailDomain";

interface Props {
    value: EmailDomain;
}

const Cell = React.memo(function Function(props: Props) {

    const { value } = props;

    const { data } = useSubscription<EmailDomainUpdatedPayload, EmailDomainUpdatedInput>(EMAIL_DOMAIN_UPDATED, { variables: { emailDomainId: value.id } });

    if (!!(data?.emailDomainUpdated?.emailDomain)) {
        if (data.emailDomainUpdated.emailDomain.created === false) {
            return <Chip size="small" avatar={<CircularProgress size={15} color="inherit" />} color="default" label="Preparing domain"></Chip>;
        } else {
            return <Chip size="small" color="success" label="Ready to use"></Chip>
        }
    } else {
        if (!!value.created) {
            return <Chip size="small" color="success" label="Ready to use"></Chip>
        } else {
            return <Chip size="small" avatar={<CircularProgress size={15} color="inherit" />} color="default" label="Preparing domain"></Chip>;
        }
    }
});

export function renderDomainLive(params: GridCellParams) {
    return <Cell value={params.value as EmailDomain} />;
}
import { gql } from "@apollo/client";
import ShopifyOrder from "types/ShopifyOrder";

export interface OrdersSortInput {
  name?: "ASC" | "DESC",
  channel?: "ASC" | "DESC",
  date?: "ASC" | "DESC",
  customerName?: "ASC" | "DESC",
  lineItems?: "ASC" | "DESC",
  value?: "ASC" | "DESC",
  payment?: "ASC" | "DESC",
  fulfillment?: "ASC" | "DESC",
  itemCount?: "ASC" | "DESC",
}

export enum OrdersFilter {
  all = "ALL",
  amazon = "AMAZON",
  shopify = "SHOPIFY",
  zenquiries = "ZENQUIRIES",
  unpaid = "UNPAID",
  unfulfilled = "UNFULFILLED",
  open = "OPEN",
  closed = "CLOSED"
}

export interface OrdersFilterInput {
  filter?: OrdersFilter;
  searchQuery?: string;
  customerId?: string;
  sort?: OrdersSortInput;
}

export interface OrdersInput {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
  filters: OrdersFilterInput;
}

export interface OrdersPayload {
  orders: {
    pageInfo: {
      totalCount: number;
      startCursor: string;
      endCursor: string;
    };
    nodes: ShopifyOrder[];
  };
}

const ORDERS = gql`
  query Orders(
    $after: String
    $before: String
    $last: Int
    $first: Int,
    $filters: OrderFilterInput!
  ) {
    orders(
      first: $first
      last: $last
      after: $after
      before: $before,
      filters: $filters,
    ) {
      pageInfo {
        totalCount
        startCursor
        endCursor
      }
      nodes {
        ... on ShopifyOrder {
          id
          createdAt
          name
          lineItemsCount
          lineItemsFormatted
          financialStatus
          fulfillmentStatus
          closed
          fulfilled
          unpaid
          currentSubtotalPriceSet {
            shopAmount
          }
          customer {
            id
            name
            email
          }
        }
        ... on AmazonOrder {
          id
        }
        ... on ZenOrder {
          id
        }
      }
    }
  }
`;

export default ORDERS;

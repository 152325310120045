import { gql } from "@apollo/client";

export interface DeleteCustomerInput {
    input: {
        id: string;
    };
}

export interface DeleteCustomerPayload {
    deleteCustomer?: {
        errors: string[];
        ok?: boolean;
    };
}

const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($input: DeleteCustomerMutationInput!) {
    deleteCustomer(input: $input) {
      errors
      ok
    }
  }
`;

export default DELETE_CUSTOMER;

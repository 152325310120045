import { gql } from "@apollo/client";

export interface LoginInput {
  input: {
    email: string;
    password: string;
  }
}

export interface LoginPayload {
  login: {
    success: boolean;
    token?: string;
    mobileEndingIn?: string;
    errors: string[];
  }
}

const LOGIN = gql`
  mutation Login($input: LoginMutationInput!) {
    login(input: $input) {
      success
      token
      mobileEndingIn
      errors
    }
  }
`;

export default LOGIN;

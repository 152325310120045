import { gql } from "@apollo/client";
import MessageBirdIntegration from "types/MessageBirdIntegration";
import PageInfo from "types/PageInfo";

export interface Input { }

export interface Payload {
  messageBirdIntegrations: {
    pageInfo: PageInfo;
    nodes: MessageBirdIntegration[];
  };
}

const MESSAGE_BIRD_INTEGRATIONS = gql`
  query MessageBirdIntegrations {
    messageBirdIntegrations {
      pageInfo {
        totalCount
        startCursor
        endCursor
      }
      nodes {
          id
          createdAt
          updatedAt
          active
          messageBirdSmsPhoneNumbers {
            pageInfo {
                totalCount
                startCursor
                endCursor
            }
            nodes {
                id
                createdAt
                updatedAt
                smsNumber
            }
          }
      }
    }
  }
`;

export default MESSAGE_BIRD_INTEGRATIONS;
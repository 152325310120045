import { gql } from "@apollo/client";
import Field from "types/Field";
import FieldFragment from "../fragments/FieldFragment";

export interface UpdateFieldToDefaultInput {
    input: {
        id: string;
    };
}

export interface UpdateFieldToDefaultPayload {
    updateFieldToDefault: {
        field?: Field;
    };
}

const UPDATE_FIELD_TO_DEFAULT = gql`
  mutation UpdateFieldToDefault($input: UpdateFieldToDefaultMutationInput!) {
    updateFieldToDefault(input: $input) {
      field {
        ...FieldFields
      }
    }
  }
  ${FieldFragment},
`;

export default UPDATE_FIELD_TO_DEFAULT;

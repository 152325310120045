import { gql } from "@apollo/client";

export interface Input {
    input: {
        id: string;
    };
}

export interface Payload {
    checkMessageBirdIntegrationSmsPhoneNumbers: {
        ok?: boolean;
    };
}

const CHECK_SMS_NUMBERS = gql`
  mutation CheckMessageBirdIntegrationSmsPhoneNumbers($input: CheckMessageBirdIntegrationSmsPhoneNumbersMutationInput!) {
    checkMessageBirdIntegrationSmsPhoneNumbers(input: $input) {
      ok
    }
  }
`;

export default CHECK_SMS_NUMBERS;
import { useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Card from "theme/Card";
import Chip from "theme/Chip";
import MESSAGE_BIRD_INTEGRATIONS, {
    Input as MessageBirdIntegrationsInput,
    Payload as MessageBirdIntegrationsPayload
} from "graphql/queries/MessageBirdIntegrationsQuery";
import UPDATE_MESSAGE_BIRD_INTEGRATION, {
    Input as UpdateMessageBirdIntegrationInput,
    Payload as UpdateMessageBirdIntegrationPayload,
} from "graphql/mutations/UpdateMessageBirdIntegrationMutation";
import CHECK_SMS_NUMBERS, {
    Input as CheckSMSNumbersInput,
    Payload as CheckSMSNumbersPayload,
} from "graphql/mutations/CheckMessageBirdIntegrationSmsPhoneNumbersMutation";
import { useQuery, useMutation } from "@apollo/client";
import TextField from "theme/TextField";
import { LoadingButton } from "theme/Button";
import Alert from "@mui/material/Alert";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Link from '@mui/material/Link';
import RefreshIcon from '@mui/icons-material/Refresh';
import MessageBirdIntegration from "types/MessageBirdIntegration";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

export default function MessageBirdSettings() {

    const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
    const [alertError, setAlertError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [dirty, setDirty] = useState<boolean>(false);
    const { data } = useQuery<MessageBirdIntegrationsPayload, MessageBirdIntegrationsInput>(MESSAGE_BIRD_INTEGRATIONS, {});
    const [updateMessageBirdIntegration, { }] = useMutation<UpdateMessageBirdIntegrationPayload, UpdateMessageBirdIntegrationInput>(UPDATE_MESSAGE_BIRD_INTEGRATION, { refetchQueries: ["MessageBirdIntegrations"] });
    const [checkSMSNumbers, { }] = useMutation<CheckSMSNumbersPayload, CheckSMSNumbersInput>(CHECK_SMS_NUMBERS, { refetchQueries: ["MessageBirdIntegrations"] });

    const hasActiveMessageBirdIntegration = !!data && !!(data?.messageBirdIntegrations.pageInfo.totalCount > 0);
    const integration = !!data && !!(data?.messageBirdIntegrations.pageInfo.totalCount > 0) && data?.messageBirdIntegrations.nodes[0];
    const smsNumbers = (!!integration && integration.messageBirdSmsPhoneNumbers.nodes.length > 0) ? integration.messageBirdSmsPhoneNumbers.nodes : false;

    const handleUpdate = async () => {
        try {
            setAlertError(undefined);
            setLoading(true);
            await updateMessageBirdIntegration({
                variables: {
                    input: {
                        accessToken: accessToken || ""
                    }
                }
            })
        } catch (e: unknown) {
            if (e instanceof Error) {
                setAlertError(e.message);
            }
        } finally {
            setLoading(false);
            setDirty(false);
        }
    };

    const handleRefresh = async () => {
        try {
            setAlertError(undefined);
            setLoading(true);
            await checkSMSNumbers({
                variables: {
                    input: {
                        id: (integration as MessageBirdIntegration).id
                    }
                }
            })
        } catch (e: unknown) {
            if (e instanceof Error) {
                setAlertError(e.message);
            }
        } finally {
            setLoading(false);
            setDirty(false);
        }
    };

    return (
        <>
            <Card variant="outlined" elevation={0} sx={{ maxWidth: 900 }}>
                <Stack spacing={2} direction="column" p={2}>
                    <Typography variant="h5">MessageBird integration</Typography>
                    {!!data && hasActiveMessageBirdIntegration && <Stack alignItems="center" spacing={1} direction="row">
                        <Chip size="small" variant="filled" color="success" label="Active"></Chip>
                        <Typography variant="caption">Integration is active</Typography>
                    </Stack>}
                    {!!data && !hasActiveMessageBirdIntegration && <Stack alignItems="center" spacing={1} direction="row">
                        <Chip size="small" variant="filled" color="error" label="Not active"></Chip>
                        <Typography variant="caption">Integration is not active</Typography>
                    </Stack>}
                    {!!alertError && <Alert severity="error">{alertError}</Alert>}
                    {!!data && !hasActiveMessageBirdIntegration && <>
                        <Typography variant="subtitle2">Add MessageBird integration to zenshop to enable SMS functionality.</Typography>
                        <Typography variant="subtitle2">Create an account with MessageBird and paste your API token here.</Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <TextField type="password" value={accessToken} sx={{ maxWidth: "500px", width: "100%" }} size="small" placeholder="MessageBird API key" onChange={(e) => {
                                setDirty(true);
                                setAccessToken(e.currentTarget.value);
                            }} />
                            <LoadingButton loading={loading} onClick={handleUpdate} disabled={!dirty} size="small" variant="contained" color="success">Save</LoadingButton>
                        </Stack>
                    </>}
                    {!!data && hasActiveMessageBirdIntegration && <>
                        <Typography variant="subtitle2">MessageBird is ready to use with zenshop.</Typography>
                        <Typography variant="subtitle2">Please make sure to verify your MessageBird account and number before sending SMS.</Typography>
                        <Link href="https://dashboard.messagebird.com/en/settings/sms" variant="body2">Verify your MessageBird account</Link>
                        <Link href="https://docs.zenshop.app/role-specific-guides/for-admins/discord-integration" variant="body2">Getting started guide on using MessageBird with zenshop</Link>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <LoadingButton startIcon={<DeleteForeverIcon />} loading={loading} onClick={() => {
                                setAccessToken(undefined);
                                handleUpdate();
                            }} size="small" variant="contained" color="error">Remove MessageBird</LoadingButton>
                        </Stack>
                    </>}
                </Stack>
            </Card>
            {!!integration && <Card variant="outlined" elevation={0} sx={{ maxWidth: 900 }}>
                <Stack spacing={2} direction="column" p={2}>
                    <Typography variant="h5">SMS numbers</Typography>
                    {!!smsNumbers && <>
                        <Typography variant="subtitle2">You will be charged according to your MessageBird plan for SMS usage.</Typography>
                        <List dense sx={{ maxWidth: 300 }} disablePadding>
                            {smsNumbers.map((number) => {
                                return (
                                    <ListItem key={number.id} sx={{ m: 0, p: 0 }}>
                                        <ListItemButton sx={{ borderRadius: 1 }}>
                                            <ListItemText primary={<Typography variant="subtitle2" fontWeight={600}>{`+${number.smsNumber}`}</Typography>} />
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </List>
                        <Typography variant="subtitle2">If you have purchased or sold a number, please refresh the list.</Typography>
                        <div>
                            <LoadingButton onClick={handleRefresh} loading={loading} startIcon={<RefreshIcon />} size="small" variant="contained" color="secondary">Refresh</LoadingButton>
                        </div>
                    </>}
                    {!smsNumbers && <>
                        <Typography variant="subtitle2">You do not have any numbers to use with zenshop.</Typography>
                        <Link href="https://dashboard.messagebird.com/en/numbers/modal/buy-number" variant="body2">Buy a number with MessageBird</Link>
                        <div>
                            <LoadingButton onClick={handleRefresh} loading={loading} startIcon={<RefreshIcon />} size="small" variant="contained" color="secondary">Refresh</LoadingButton>
                        </div>
                    </>}
                </Stack>
            </Card>}
        </>


    );
}
import { useState, useEffect } from "react";
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from 'theme/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CancelIcon from "@mui/icons-material/Cancel";
import FileDropArea, { FileUpload } from "components/file_dropzone/FileDropArea";
import TextField from 'theme/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { GridColDef, GridSelectionModel } from '@mui/x-data-grid-pro';
import ORG_FILES, {
    OrgFilesInput,
    OrgFilesPayload,
} from "graphql/queries/OrgFilesQuery";
import { useQuery } from "@apollo/client";
import Checkbox from "theme/Checkbox";
import DataGrid from "theme/DataGrid";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import OrgFile from "types/OrgFile";
import Typography from "@mui/material/Typography";

const ListItem = styled('li')(({ theme }) => ({
    margin: 0,
    marginRight: theme.spacing(1)
}));

const columns: GridColDef[] = [
    {
        field: 'fileName',
        headerName: 'File Name',
        flex: 1,
    }
];

interface Props {
    open: boolean;
    onAttach: (attachments: OrgFile[]) => void;
    onClose: () => void;
}

interface FilesListProps {
    searchInput?: string;
    teamFiles?: boolean;
    setSelection: (selection: OrgFile[]) => void;
}

function FilesList(props: FilesListProps) {
    const { teamFiles, setSelection, searchInput } = props;

    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

    const { data, refetch } = useQuery<OrgFilesPayload, OrgFilesInput>(ORG_FILES, {
        variables: { filters: { privateFile: !teamFiles, searchQuery: searchInput } },
    });

    useEffect(() => {
        if ((!!searchInput) && searchInput.length > 0) {
            refetch({ filters: { searchQuery: searchInput } })
        }
    }, [searchInput]);

    useEffect(() => {
        if (!!data) {
            const selectedFiles = data.orgFiles.nodes.filter((file) => selectionModel.includes(file.id));
            setSelection(selectedFiles);
        }
    }, [selectionModel]);

    if (!!data) {

        let rows = data.orgFiles.nodes.map((orgFile) => {
            return {
                id: orgFile.id,
                fileName: orgFile.givenFilename
            }
        });

        return (
            <div style={{ height: 300, width: '100%' }}>
                <DataGrid
                    density="compact"
                    rows={rows}
                    selectionModel={selectionModel}
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    columns={columns}
                    checkboxSelection
                    hideFooter
                    autoHeight={false}
                    components={{
                        BaseCheckbox: Checkbox,
                        NoResultsOverlay: () => (<div />),
                    }}
                />
            </div>
        );
    } else {
        return <Stack direction="row" alignItems="center" justifyContent="center"><CircularProgress /></Stack>
    }
}

export default function FileDetailsDialog(props: Props) {

    const [searchInput, setSearchInput] = useState<string>("");
    const [searching, setSearching] = useState<boolean>(false);
    const [deleted, setDeleted] = useState<string[]>([]);
    const [uploads, setUploads] = useState<FileUpload[]>([]);
    const [selectedTeamFiles, setSelectedTeamFiles] = useState<OrgFile[]>([]);
    const [selectedPrivateFiles, setSelectedPrivateFiles] = useState<OrgFile[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<OrgFile[]>([]);
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [alertError, setAlertError] = useState<string | undefined>(undefined);
    const { onClose, open, onAttach } = props;

    useEffect(() => {
        const newValue = searchInput.length > 0;
        setSearching(newValue);
    }, [searchInput]);

    const handleClose = () => {
        onClose();
        setTimeout(() => {
            setAlertError(undefined);
            setUploads([]);
            setSearchInput("");
            setSelectedFiles([]);
            setSelectedTeamFiles([]);
            setSelectedPrivateFiles([]);
            setSelectedTab(0);
            setDeleted([]);
        }, 200);
    };

    const handleDelete = (uploadToDelete: FileUpload) => () => {
        setDeleted([uploadToDelete.key, ...deleted]);
        setUploads((uploads) => uploads.filter((upload) => upload.key !== uploadToDelete.key));
    };

    const handleSubmit = () => {
        onAttach(uploads.filter((upload) => !!upload.orgFile).map((upload) => upload.orgFile!).concat(selectedTeamFiles).concat(selectedPrivateFiles).concat(selectedFiles));
        handleClose();
    };

    const canAttach = (uploads.length > 0 || selectedPrivateFiles.length > 0 || selectedTeamFiles.length > 0 || selectedFiles.length > 0) && uploads.filter((upload) => upload.errors !== undefined).length == 0 && uploads.filter((upload) => upload.uploading).length == 0;
    const attachedFiles = uploads.length + selectedPrivateFiles.length + selectedTeamFiles.length + selectedFiles.length;

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm" scroll="paper">
            <DialogTitle>
                <Stack direction="row" spacing={2}>
                    <TextField
                        fullWidth
                        size="small"
                        placeholder="Search Files"
                        onChange={(e) => setSearchInput(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }} />
                    <IconButton onClick={handleClose}>
                        <CancelIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent dividers>
                {searching && <Stack direction="column" spacing={2}>
                    <Typography variant="caption" color="text.secondary">Search results</Typography>
                    <FilesList searchInput={searchInput} setSelection={(selection) => {
                        setSelectedFiles(selection);
                    }} />
                </Stack>}
                {!searching && <Stack direction="column" spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                        <Button variant={selectedTab === 0 ? "contained" : "text"} color={selectedTab === 0 ? "primary" : "inherit"} size="small" onClick={() => setSelectedTab(0)} sx={{ pl: 1, pr: 1 }}>Upload new file {uploads.length > 0 ? `(${uploads.length})` : ""}</Button>
                        <Button variant={selectedTab === 1 ? "contained" : "text"} color={selectedTab === 1 ? "primary" : "inherit"} size="small" onClick={() => setSelectedTab(1)} sx={{ pl: 1, pr: 1 }}>Team files {selectedTeamFiles.length > 0 ? `(${selectedTeamFiles.length})` : ""}</Button>
                        <Button variant={selectedTab === 2 ? "contained" : "text"} color={selectedTab === 2 ? "primary" : "inherit"} size="small" onClick={() => setSelectedTab(2)} sx={{ pl: 1, pr: 1 }}>Your private files {selectedPrivateFiles.length > 0 ? `(${selectedPrivateFiles.length})` : ""}</Button>
                    </Stack>
                    {!!alertError && <Alert severity="error" title={alertError} />}
                    {selectedTab === 0 && <>
                        <FileDropArea exclusive={false} temporaryFile={true} uploads={uploads} setUploads={setUploads} deleted={deleted} />
                    </>}
                    {selectedTab === 1 && <>
                        <div style={{ height: 300 }}>
                            <FilesList teamFiles setSelection={(selection) => {
                                setSelectedTeamFiles(selection);
                            }} />
                        </div>
                    </>}
                    {selectedTab === 2 && <>
                        <div style={{ height: 300 }}>
                            <FilesList teamFiles={false} setSelection={(selection) => {
                                setSelectedPrivateFiles(selection);
                            }} />
                        </div>
                    </>}
                    <Stack direction="row" alignItems="center" sx={{ listStyle: 'none', flexWrap: "wrap" }}>
                        {uploads.map((upload) => {
                            let chipIcon;
                            if (upload.uploading) {
                                chipIcon = <Stack alignItems="center"><CircularProgress color="primary" size={15} /></Stack>;
                            } else if (!!upload.errors) {
                                chipIcon = <Stack alignItems="center"><ErrorOutlineIcon color="error" fontSize="small" /></Stack>;
                            } else {
                                chipIcon = <div></div>;
                            }
                            return (
                                <ListItem key={upload.key}>
                                    <Chip
                                        icon={chipIcon}
                                        label={upload.name}
                                        onDelete={handleDelete(upload)}
                                        size="small"
                                    />
                                </ListItem>
                            );
                        })}
                    </Stack>
                </Stack>}
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button onClick={handleSubmit} disabled={!canAttach} variant="contained" color="success" size="small">Attach files {attachedFiles > 0 ? `(${attachedFiles})` : ""}</Button>
            </DialogActions>
        </Dialog >
    );
}
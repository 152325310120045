import { useState, useEffect } from "react";
import { GridRowsProp, GridColDef, GridSelectionModel, GridSortModel, GridSortDirection } from "@mui/x-data-grid-pro";
import { NoScrollZenDataGrid } from "theme/DataGrid";
import Stack from "@mui/material/Stack";
import { useQuery } from "@apollo/client";
import ORDERS, {
    OrdersInput,
    OrdersPayload,
    OrdersFilter,
    OrdersSortInput,
} from "graphql/queries/OrdersQuery";
import { useParams } from "react-router-dom";
import { renderDate } from "components/conversations/renderers/RenderDate";
import { renderOrderStatus } from "components/conversations/renderers/RenderOrderStatus";
import Alert from "@mui/material/Alert";
import { useHistory } from "react-router";

type Params = {
    id: string;
};

function OrdersPanel() {

    const { id } = useParams<Params>();

    const history = useHistory();

    const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [filter, setFilter] = useState<OrdersFilter>("ALL" as OrdersFilter)
    const [page, setPage] = useState<number>(0);
    const [after, setAfter] = useState<string | undefined>(undefined);
    const [before, setBefore] = useState<string | undefined>(undefined);
    const [sort, setSort] = useState<OrdersSortInput | undefined>({ date: "DESC" });

    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'date',
            sort: 'desc' as GridSortDirection,
        },
    ]);

    const { data, error, loading, refetch } = useQuery<
        OrdersPayload,
        OrdersInput
    >(ORDERS, {
        variables: {
            after: after,
            before: before,
            first: !after && !before ? 10 : !!after ? 10 : undefined,
            last: !!before ? 10 : undefined,
            filters: {
                searchQuery: searchQuery,
                customerId: id,
                filter: filter,
                sort: sort,
            },
        },
    });

    const rows: GridRowsProp = !!data
        ? data.orders.nodes.map((row) => {
            return {
                id: row.id,
                number: row.name,
                date: row.createdAt,
                value: row.currentSubtotalPriceSet.shopAmount,
                payment: [row.financialStatus, !row.unpaid],
                fulfillment: [row.fulfillmentStatus, row.fulfilled],
                items: row.lineItemsCount,
                line_items_formatted: row.lineItemsFormatted,
            };
        })
        : [];

    const columns: GridColDef[] = [
        {
            field: "number",
            headerName: "Order #",
            width: 100,
        },
        {
            field: "date",
            headerName: "Date",
            width: 150,
            renderCell: renderDate
        },
        {
            field: "value",
            headerName: "Value",
            width: 120,
        },
        {
            field: "line_items_formatted",
            headerName: "Items",
            width: 200,
        },
        {
            field: "items",
            headerName: "# Items",
            width: 120,
        },
        {
            field: "payment",
            headerName: "Payment",
            width: 150,
            renderCell: renderOrderStatus
        },
        {
            field: "fulfillment",
            headerName: "Fulfillment",
            width: 150,
            renderCell: renderOrderStatus
        },
    ];

    function navigateToFilter(filter: OrdersFilter) {
        setPage(0);
        setFilter(filter);
        setAfter(undefined);
        setBefore(undefined);
    };

    function navigateToAfter(after: string, page: number) {
        setPage(page);
        setAfter(after);
        setBefore(undefined);
    };

    function navigateToBefore(before: string, page: number) {
        setPage(page);
        setAfter(undefined);
        setBefore(before);
    };

    useEffect(() => {
        setPage(0);
        setBefore(undefined);
        setAfter(undefined);
        refetch();
    }, [sort, searchQuery, filter]);

    return (
        <>
            {!!error && <Alert severity="error" sx={{ maxWidth: 948 }}>{error.message}</Alert>}
            <Stack direction="column" style={{ minHeight: 635 }}>
                <NoScrollZenDataGrid
                    style={{ height: "100%" }}
                    disableColumnPinning
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    onSortModelChange={(model) => {
                        if (model.length > 0) {
                            const field = model[0];
                            const sort = field.sort;
                            if (!!sort) {
                                switch (field.field) {
                                    case "number": {
                                        setSort({ name: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                    case "date": {
                                        setSort({ date: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                    case "customer": {
                                        setSort({ customerName: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                    case "value": {
                                        setSort({ value: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                    case "line_items_formatted": {
                                        setSort({ lineItems: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                    case "payment": {
                                        setSort({ payment: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                    case "fulfillment": {
                                        setSort({ fulfillment: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                    case "items": {
                                        setSort({ itemCount: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                }
                            } else {
                                setSort(undefined);
                            }
                        } else {
                            setSort(undefined);
                        }
                        setSortModel(model);
                    }}
                    components={{
                        NoResultsOverlay: () => (<div />),
                    }}
                    disableColumnFilter
                    disableColumnReorder
                    disableColumnSelector
                    rows={rows}
                    columns={columns}
                    loading={!data}
                    page={page}
                    onPageChange={(newPage) => {
                        if (loading) {
                            return;
                        }
                        if (newPage > page) {
                            navigateToAfter(data!.orders.pageInfo.endCursor, newPage);
                        } else {
                            navigateToBefore(data!.orders.pageInfo.startCursor, newPage);
                        }
                        setPage(newPage);
                    }}
                    rowHeight={35}
                    pageSize={15}
                    rowsPerPageOptions={[15]}
                    rowCount={!!(data?.orders) ? data.orders.pageInfo.totalCount : 0}
                    paginationMode="server"
                    sortingMode="server"
                    pagination
                    disableSelectionOnClick
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    onRowClick={(row) => {
                        history.push(`/order/${row.id}`)
                    }}
                    selectionModel={selectionModel}
                />
            </Stack>
        </>
    );
}

export default OrdersPanel;
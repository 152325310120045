import { gql } from "@apollo/client";
import Customer from "types/Customer";
import CustomerFragment from "graphql/fragments/CustomerFragment";
import FieldFragment from "graphql/fragments/FieldFragment";
import MailingAddressFragment from "graphql/fragments/MailingAddressFragment";

export interface UpdateMailingAddressInput {
    input: {
        id: string;
        address1?: string;
        address2?: string;
        company?: string;
        city?: string;
        countryCode?: string;
        firstName?: string;
        lastName?: string;
        phone?: string;
        province?: string;
        zip?: string;
    };
}

export interface UpdateMailingAddressPayload {
    updateMailingAddress: {
        customer?: Customer;
    }
}

const UPDATE_MAILING_ADDRESS = gql`
  mutation UpdateMailingAddress($input: UpdateMailingAddressMutationInput!) {
    updateMailingAddress(input: $input) {
        customer {
            ...CustomerFields
            fields {
                nodes {
                ...FieldFields
                }
            }
            mailingAddresses {
                nodes {
                ...MailingAddressFields
                }
            }
        }
    }
  }
  ${MailingAddressFragment},
  ${CustomerFragment},
  ${FieldFragment}
`;

export default UPDATE_MAILING_ADDRESS;
import { useEffect, useState } from "react";
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import Button, { LoadingButton } from 'theme/Button';
import Dialog from '@mui/material/Dialog';
import { useMutation, useQuery } from "@apollo/client";
import RUN_WORKFLOW, { RunWorkflowInput, RunWorkflowPayload } from "graphql/mutations/RunWorkflowMutation";
import WORKFLOW, { WorkflowInput, WorkflowPayload } from "graphql/queries/WorkflowQuery";
import Typography from "@mui/material/Typography";
import { DialogActions } from "@mui/material";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useSnackbar } from 'notistack';

export interface NewWorkflowDialogProps {
    open: boolean;
    selectedWorkflowId?: string;
    conversationIds: string[];
    onClose: () => void;
}

export default function NewWorkflowDialogWrapper(props: NewWorkflowDialogProps) {

    const { selectedWorkflowId, open, onClose, conversationIds } = props;

    const { enqueueSnackbar } = useSnackbar();
    const [busy, setBusy] = useState<boolean>(false);
    const [alertError, setAlertError] = useState<string | undefined>(undefined);
    const [workflowId, setWorkflowId] = useState<string>(selectedWorkflowId || "");
    const [runWorkflow, { }] = useMutation<RunWorkflowPayload, RunWorkflowInput>(RUN_WORKFLOW, {});

    const { data, refetch } = useQuery<WorkflowPayload, WorkflowInput>(WORKFLOW, { variables: { id: workflowId } });

    const onSubmit = async () => {
        try {
            setBusy(true);

            const { data } = await runWorkflow({ variables: { input: { id: workflowId, conversationIds: conversationIds } } });

            if (!!data?.runWorkflow?.ok) {
                enqueueSnackbar(`Workflow is running`);
                onClose();
            }
        } catch (e: unknown) {
            if (e instanceof Error) {
                setAlertError(e.message);
            }
        } finally {
            setBusy(false);
        }
    }

    const handleClose = () => {
        if (busy) {
            return;
        }

        onClose();
    }

    useEffect(() => {
        if (!!selectedWorkflowId) {
            setWorkflowId(selectedWorkflowId);
            refetch({ id: selectedWorkflowId });
        }
    }, [selectedWorkflowId]);

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="xs">
            <DialogTitle>Run workflow</DialogTitle>
            <DialogContent dividers>
                <Stack direction="column" spacing={1}>
                    {!!alertError && <Alert severity="error" icon={false}>{alertError}</Alert>}
                    <Typography variant="body2">Are you sure you want to run the <strong>{data?.workflow?.name}</strong> workflow?</Typography>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button disabled={busy} size="small" variant="text" color="inherit" onClick={handleClose}>Cancel</Button>
                <LoadingButton loading={busy} size="small" variant="contained" color="success" onClick={onSubmit}>Run workflow</LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
import { gql } from "@apollo/client";
import Conversation from "types/Conversation";
import CompleteConversationFragment from "../fragments/CompleteConversationFragment";

export interface AddNoteInput {
  input: {
    id: string;
    noteHtml: string;
  };
}

export interface AddNotePayload {
  addNote: {
    errors: string[];
    conversation: Conversation;
  };
}

const ADD_NOTE = gql`
  mutation AddNote($input: AddNoteMutationInput!) {
    addNote(input: $input) {
      errors
      conversation {
        ...CompleteConversationFields
      }
    }
  }
  ${CompleteConversationFragment}
`;

export default ADD_NOTE;
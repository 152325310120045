import { gql } from "@apollo/client";
import Conversation from "types/Conversation";

export interface UpdateConversationConversationTypeInput {
  input: {
    id: string;
    typeId: string;
  };
}

export interface UpdateConversationConversationTypePayload {
  updateConversationConversationType: {
    errors: string[];
    conversation: Conversation;
  };
}

const UPDATE_CONVERSATION_CONVERSATION_TYPE = gql`
  mutation UpdateConversationConversationType($input: UpdateConversationConversationTypeMutationInput!) {
    updateConversationConversationType(input: $input) {
      errors
      conversation {
        id
        type {
          id
          title
          system
        }
      }
    }
  }
`;

export default UPDATE_CONVERSATION_CONVERSATION_TYPE;

import * as React from "react";
import { GridCellParams } from "@mui/x-data-grid-pro";
import Label from "types/Label";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

interface LabelProps {
    value?: Label;
}

const LabelCell = React.memo(function LabelFunction(
    props: LabelProps
) {
    const { value } = props;

    return (
        <Stack direction="row" spacing={1}>
            <Box sx={{ backgroundColor: value?.colorHex, width: 20, height: 20, borderRadius: "4px" }} />
            <Typography variant="subtitle2">{value?.title}</Typography>
        </Stack>);
});

export function renderLabel(params: GridCellParams) {
    return <LabelCell value={params.value as Label | undefined} />;
}

import { useState, useEffect } from "react";
import Stack from '@mui/material/Stack';
import DialogContent from 'theme/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
import Conversation from "types/Conversation";
import IconButton from '@mui/material/IconButton';
import CancelIcon from "@mui/icons-material/Cancel";
import Button, { LoadingButton } from "theme/Button";
import MergeConversationsGrid from "./MergeConversationsGrid";
import { useMutation, useQuery } from "@apollo/client";
import { GraphQLError } from "graphql";
import MERGE_CONVERSATIONS, {
    MergeConversationsInput,
    MergeConversationsPayload,
} from "graphql/mutations/MergeConversationsMutation";
import SIMILAR_CONVERSATIONS, {
    SimilarConversationsInput,
    SimilarConversationsPayload,
} from "graphql/queries/SimilarConversationsQuery";
import { useHistory } from "react-router-dom";
import AppBar from "theme/AppBar";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useTheme from "@mui/material/styles/useTheme";

export interface ChangeConversationTypeDialogProps {
    open: boolean;
    conversation: Conversation;
    onClose: (value: boolean) => void;
}

enum Step {
    select_conversations,
    select_parent
}

interface StepProps extends ChangeConversationTypeDialogProps {
    selection: Conversation[];
    setSelection: React.Dispatch<React.SetStateAction<Conversation[]>>;
    setStep: React.Dispatch<React.SetStateAction<Step>>;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

function SelectParent(props: StepProps) {

    const [parent, setParent] = useState<Conversation[]>([]);
    const { setStep, selection, setSelection, onClose, loading, setLoading } = props;
    const [payloadData, setPayloadData] = useState<MergeConversationsPayload | null | undefined>(null);
    const [payloadErrors, setPayloadErrors] = useState<readonly GraphQLError[] | undefined>(undefined);
    const [mergeConversations, { }] = useMutation<MergeConversationsPayload, MergeConversationsInput>(MERGE_CONVERSATIONS, { refetchQueries: ["Conversations", "Conversation", "SideBarQuery", "Customers"] });
    const history = useHistory();

    useEffect(() => {
        if (!!payloadData) {
            let payloadId = payloadData.mergeConversations.conversation.id;
            history.push(`/conversation/${payloadId}`);
            onClose(true);
        }
    }, [payloadData]);

    return (
        <>
            <DialogContent dividers>
                <Stack direction="column" spacing={2}>
                    {!!payloadErrors && <Alert severity="error">
                        {payloadErrors[0].message}
                    </Alert>}
                    <DialogContentText>You are merging <strong>{selection.map((node) => `!${node.number}`).join(", ")}</strong>.</DialogContentText>
                    <MergeConversationsGrid disableMultipleSelection={true} options={selection} setSelection={setParent} />
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button size="small" disabled={loading} variant="text" color="inherit" onClick={() => {
                    setStep(Step.select_conversations);
                    setSelection([]);
                }}>Go Back</Button><LoadingButton loading={loading} size="small" disabled={parent.length === 0 || loading} variant="contained" color="success" onClick={async () => {
                    try {
                        setLoading(true);

                        const { data, errors } = await mergeConversations({ variables: { input: { parentId: parent[0].id, duplicateIds: selection.map((o) => o.id) } } });

                        if (!!data) {
                            setPayloadData(data);
                        }

                        if (!!errors) {
                            setPayloadErrors(errors);
                        }
                    } catch {

                    } finally {
                        setLoading(false);
                    }
                }}>Confirm</LoadingButton>
            </DialogActions>
        </>
    );
}

function SelectConversations(props: StepProps) {

    const { conversation, setStep, selection, setSelection } = props;

    const { data } = useQuery<SimilarConversationsPayload, SimilarConversationsInput>(SIMILAR_CONVERSATIONS, {
        variables: { id: conversation.id },
    });

    return (
        <>
            <DialogContent dividers>
                <Stack direction="column" spacing={2}>
                    <DialogContentText>Select one or more conversations to merge with conversation <strong>!{conversation.number}</strong>.</DialogContentText>
                    <MergeConversationsGrid disableMultipleSelection={false} options={data?.conversation.similarConversations.nodes || []} setSelection={(o) => {
                        setSelection([...o as Conversation[], conversation]);
                    }} />
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button disabled={selection.length <= 1} variant="contained" color="success" size='small' onClick={() => setStep(Step.select_parent)}>Confirm</Button>
            </DialogActions>
        </>
    );
}

export default function MergeConversationsDialog(props: ChangeConversationTypeDialogProps) {

    const { onClose, open } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [selection, setSelection] = useState<Conversation[]>([]);
    const [step, setStep] = useState<Step>(Step.select_conversations);
    const theme = useTheme();

    const handleClose = () => {
        if (loading) {
            return;
        }
        onClose(false);
        setSelection([]);
        setStep(Step.select_conversations);
    };

    return (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth="lg">
            <AppBar position="relative" elevation={0} color="transparent">
                <Toolbar sx={{ color: theme.palette.text.secondary }}>
                    <Typography sx={{ ml: 0, flex: 1 }} variant="h5">
                        {step === Step.select_conversations && "Merge conversations"}
                        {step === Step.select_parent && "Choose parent conversation"}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CancelIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            {step === Step.select_conversations && <SelectConversations {...props} setStep={setStep} selection={selection} setSelection={setSelection} setLoading={setLoading} loading={loading} />}
            {step === Step.select_parent && <SelectParent {...props} setStep={setStep} selection={selection} setSelection={setSelection} setLoading={setLoading} loading={loading} />}
        </Dialog>
    );
}
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import Dialog from '@mui/material/Dialog';
import Conversation from "types/Conversation";
import ConversationType from "types/ConversationType";
import CONVERSATION_TYPES, {
    ConversationTypesInput,
    ConversationTypesPayload,
} from "graphql/queries/ConversationTypesQuery";
import { useQuery, useMutation } from "@apollo/client";
import IconButton from '@mui/material/IconButton';
import CancelIcon from "@mui/icons-material/Cancel";
import UPDATE_CONVERSATION_CONVERSATION_TYPE, {
    UpdateConversationConversationTypeInput,
    UpdateConversationConversationTypePayload
} from "graphql/mutations/UpdateConversationConversationTypeMutation";

export interface ChangeConversationTypeDialogProps {
    open: boolean;
    conversation: Conversation;
    selectedValue: ConversationType;
    onClose: (value: ConversationType) => void;
}

export default function ChangeConversationTypeDialog(props: ChangeConversationTypeDialogProps) {

    const { data } = useQuery<ConversationTypesPayload, ConversationTypesInput>(CONVERSATION_TYPES, {
        variables: { filters: { sort: { title: "ASC" } } }
    });

    const [updateConversationType, { }] = useMutation<
        UpdateConversationConversationTypePayload,
        UpdateConversationConversationTypeInput
    >(UPDATE_CONVERSATION_CONVERSATION_TYPE, { refetchQueries: ["Conversations", "Conversation", "SideBarQuery"] });

    const types = data?.conversationTypes?.nodes ?? [];

    const { onClose, selectedValue, open, conversation } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value: ConversationType) => {
        if (value.id != selectedValue.id) {
            try {
                updateConversationType({
                    variables: {
                        input: {
                            id: conversation.id,
                            typeId: value.id
                        }
                    }
                })
            } catch { }
        }
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle sx={{ m: 0, p: 2, pr: 8 }}>Change type
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 12,
                        color: "text.secondary",
                    }}
                >
                    <CancelIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <List sx={{ pt: 0 }}>
                    {types.map((type) => (
                        <ListItemButton selected={type.id === selectedValue.id} onClick={() => handleListItemClick(type)} key={type.id}>
                            <Typography variant={type.id === selectedValue.id ? "subtitle2" : "body2"}>{type.title}</Typography>
                        </ListItemButton>
                    ))}
                </List>
            </DialogContent>
        </Dialog>
    );
}
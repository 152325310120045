import { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import Dialog from '@mui/material/Dialog';
import { useMutation, useQuery } from "@apollo/client";
import { useForm, Controller } from "react-hook-form";
import TextField from "theme/TextField";
import FormControl from "@mui/material/FormControl";
import { LoadingButton } from "theme/Button";
import { v4 as uuidv4 } from 'uuid';
import Typography from "@mui/material/Typography";
import UPDATE_MAILING_ADDRESS, {
    UpdateMailingAddressInput,
    UpdateMailingAddressPayload
} from 'graphql/mutations/UpdateMailingAddressMutation';
import MAILING_ADDRESS, {
    MailingAddressInput,
    MailingAddressPayload
} from 'graphql/queries/MailingAddressQuery';
import { useSnackbar } from 'notistack';
import Alert from "@mui/material/Alert";
import InputLabel from "@mui/material/InputLabel";
import { getCountries, getPhoneCode, parsePhoneNumber, CountryCode } from "libphonenumber-js";
import countries from "i18n-iso-countries";
import Select from "theme/Select";
import DialogActions from '@mui/material/DialogActions';
import { FlagIcon, FlagIconCode } from "react-flag-kit";
import MenuItem from '@mui/material/MenuItem';

export interface EditMailingAddressDialogProps {
    open: boolean;
    mailingAddressId?: string;
    onClose: () => void;
}

interface MailingAddressForm {
    address1?: string;
    address2?: string;
    company?: string;
    city?: string;
    countryCode?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    province?: string;
    zip?: string;
}

export default function EditMailingAddressDialogWrapper(props: EditMailingAddressDialogProps) {
    const { onClose, open, mailingAddressId } = props;

    if (!!mailingAddressId) {
        return <EditMailingAddressDialog mailingAddressId={mailingAddressId} open={open} onClose={onClose} />
    }

    return null;
}


interface _EditMailingAddressDialogProps {
    open: boolean;
    mailingAddressId: string;
    onClose: () => void;
}


function EditMailingAddressDialog(props: _EditMailingAddressDialogProps) {

    const [busy, setBusy] = useState<boolean>(false);
    const [alertError, setAlertError] = useState<string | undefined>(undefined);

    const { onClose, open, mailingAddressId } = props;
    const { enqueueSnackbar } = useSnackbar();

    const { data, refetch } = useQuery<MailingAddressPayload, MailingAddressInput>(MAILING_ADDRESS, {
        variables: { id: mailingAddressId },
    });

    const [updateMailingAddress, { }] = useMutation<UpdateMailingAddressPayload, UpdateMailingAddressInput>(UPDATE_MAILING_ADDRESS, {
        refetchQueries: ["Customer", "Conversation", "Customers"]
    });

    const handleClose = () => {
        onClose();
    };

    const onSubmit = async (data: MailingAddressForm) => {
        try {
            setBusy(true);

            const { data: updateMailingAddressData, errors } = await updateMailingAddress({
                variables: {
                    input: {
                        id: mailingAddressId,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        address1: data.address1,
                        address2: data.address2,
                        city: data.city,
                        zip: data.zip,
                        company: data.company,
                        province: data.province,
                        countryCode: data.countryCode,
                        phone: data.phone,
                    }
                }
            });


            if (!!errors && errors.length > 0) {
                setAlertError(errors[0].message);
            } else if (!!updateMailingAddressData) {
                enqueueSnackbar(`Customer details updated`);
                handleClose();
            }
        } catch (e: unknown) {
            if (e instanceof Error) {
                setAlertError(e.message);
            }
        } finally {
            setBusy(false);
        }
    }

    const {
        control,
        getValues,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        refetch();
    }, [mailingAddressId])

    useEffect(() => {
        if (!!data && !!data.mailingAddress) {
            setValue("countryCode", data.mailingAddress.countryCode);
            setValue("firstName", data.mailingAddress.firstName);
            setValue("lastName", data.mailingAddress.lastName);
            setValue("address1", data.mailingAddress.address1);
            setValue("address2", data.mailingAddress.address2);
            setValue("city", data.mailingAddress.city);
            setValue("company", data.mailingAddress.company);
            setValue("zip", data.mailingAddress.zip);
            if (!!data.mailingAddress.phone && data.mailingAddress.phone.length > 0) {
                console.log("updating phone");
                const phone = parsePhoneNumber(data.mailingAddress.phone);
                const phoneCountryCode = (phone.country || data.mailingAddress.countryCode)?.toUpperCase();
                console.log(phoneCountryCode);
                setValue("phoneCountryCode", phoneCountryCode);
                setValue("phoneNumber", phone.nationalNumber);
            } else {
                setValue("phoneCountryCode", "US");
                setValue("phoneNumber", "");
            }
        }
    }, [data]);

    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

    function getName(code: string): string | undefined {
        return countries.getName(code, "en", { select: "official" });
    }

    let countryOptions = getCountries()
        .filter((o) => !!getName(o))
        .sort((a, b) => getName(a)! < getName(b)! ? -1 : 1)
        .map((o) => <MenuItem key={o} value={o}>{`${getName(o)}`}</MenuItem>);

    let phoneOptions = getCountries()
        .filter((o) => !!getName(o))
        .sort((a, b) => getName(a)! < getName(b)! ? -1 : 1)
        .map((o) => <MenuItem key={o} value={o}>{`${getName(o)} (+${getPhoneCode(o)})`}</MenuItem>);

    return (
        <Dialog onClose={handleClose} open={open} maxWidth="sm">
            <DialogTitle>Edit address</DialogTitle>
            {!!alertError && <Alert sx={{ ml: 2, mb: 2, mr: 2 }} severity="error"><Typography sx={{ wordBreak: "break-word" }} variant="body2">{alertError}</Typography></Alert>}
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent dividers>
                    <Stack direction="column" spacing={2}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="country-label">Country</InputLabel>
                            <Controller
                                name="countryCode"
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        labelId="country-label"
                                        id="country"
                                        value={value}
                                        label="Country"
                                        onChange={onChange}
                                        size="small"
                                    >
                                        {countryOptions}
                                    </Select>
                                )}
                                control={control}
                                defaultValue="US"
                            />
                        </FormControl>
                        <Stack direction="row" spacing={2}>
                            <FormControl variant="outlined">
                                <Controller
                                    name="firstName"
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            autoComplete={uuidv4()}
                                            helperText={errors.firstName ? errors.firstName.message : null}
                                            variant="outlined"
                                            size="small"
                                            placeholder="First name"
                                            error={!!errors.firstName}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                    control={control}
                                    defaultValue=""
                                />
                            </FormControl>
                            <FormControl variant="outlined">
                                <Controller
                                    name="lastName"
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            autoComplete={uuidv4()}
                                            helperText={errors.lastName ? errors.lastName.message : null}
                                            variant="outlined"
                                            size="small"
                                            placeholder="Last name"
                                            error={!!errors.lastName}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                    control={control}
                                    defaultValue=""
                                />
                            </FormControl>
                        </Stack>
                        <FormControl fullWidth variant="outlined">
                            <Controller
                                name="company"
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        autoComplete={uuidv4()}
                                        helperText={errors.company ? errors.company.message : null}
                                        variant="outlined"
                                        size="small"
                                        placeholder="Company"
                                        error={!!errors.company}
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                                control={control}
                                defaultValue=""
                            />
                        </FormControl>
                        <FormControl fullWidth variant="outlined">
                            <Controller
                                name="address1"
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        autoComplete={uuidv4()}
                                        helperText={errors.address1 ? errors.address1.message : null}
                                        variant="outlined"
                                        size="small"
                                        placeholder="Address"
                                        error={!!errors.address1}
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "Please enter an address",
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth variant="outlined">
                            <Controller
                                name="address2"
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        autoComplete={uuidv4()}
                                        helperText={errors.address2 ? errors.address2.message : null}
                                        variant="outlined"
                                        size="small"
                                        placeholder="Apartment, suite, etc"
                                        error={!!errors.address2}
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                                control={control}
                                defaultValue=""
                            />
                        </FormControl>
                        <Stack direction="row" spacing={2}>
                            <FormControl variant="outlined">
                                <Controller
                                    name="city"
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            autoComplete={uuidv4()}
                                            helperText={errors.city ? errors.city.message : null}
                                            variant="outlined"
                                            size="small"
                                            placeholder="City"
                                            error={!!errors.city}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: "Please enter a city",
                                    }}
                                />
                            </FormControl>
                            <FormControl variant="outlined">
                                <Controller
                                    name="province"
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            autoComplete={uuidv4()}
                                            helperText={errors.province ? errors.province.message : null}
                                            variant="outlined"
                                            size="small"
                                            placeholder="State"
                                            error={!!errors.province}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                    control={control}
                                    defaultValue=""
                                />
                            </FormControl>
                            <FormControl variant="outlined">
                                <Controller
                                    name="zip"
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            autoComplete={uuidv4()}
                                            helperText={errors.zip ? errors.zip.message : null}
                                            variant="outlined"
                                            size="small"
                                            placeholder="Zip code"
                                            error={!!errors.zip}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                    control={control}
                                    defaultValue=""
                                />
                            </FormControl>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <FormControl sx={{ width: 100 }} variant="outlined">
                                <Controller
                                    name="phoneCountryCode"
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            id="phoneCountryCode"
                                            value={value}
                                            onChange={onChange}
                                            size="small"
                                            renderValue={(v) => {
                                                return <FlagIcon style={{ margin: 0, marginLeft: 5, marginTop: 2, padding: 0, height: 15 }} code={v as FlagIconCode} />
                                            }}
                                        >
                                            {phoneOptions}
                                        </Select>
                                    )}
                                    control={control}
                                    defaultValue="US"
                                />
                            </FormControl>
                            <FormControl fullWidth variant="outlined">
                                <Controller
                                    name="phoneNumber"
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            id="phone-number"
                                            helperText={errors.phoneNumber ? errors.phoneNumber.message : null}
                                            variant="outlined"
                                            size="small"
                                            placeholder="Phone number"
                                            error={!!errors.phoneNumber}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        validate: (value) => {
                                            try {
                                                const phoneCountryCode = getValues()["phoneCountryCode"] as CountryCode;
                                                if (!!phoneCountryCode && !!value && value.length > 0) {
                                                    const formattedNumber = `+${getPhoneCode(phoneCountryCode)}${value}`;
                                                    return parsePhoneNumber(formattedNumber).isPossible() || "Invalid number"
                                                }
                                            } catch {
                                                return "Invalid number"
                                            }
                                        }
                                    }}
                                />
                            </FormControl>
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <LoadingButton loading={busy} size="small" type="submit" onClick={handleSubmit((data) => {
                        if (!!data.phoneNumber && data.phoneNumber.length > 0) {
                            const formattedNumber = `+${getPhoneCode(data.phoneCountryCode! as CountryCode)}${data.phoneNumber!}`.replace(/\s/g, '');
                            const e164 = parsePhoneNumber(formattedNumber).number;
                            onSubmit({
                                ...data,
                                phone: e164
                            });
                        } else {
                            onSubmit({
                                ...data,
                            });
                        }
                    })} disabled={busy} variant="contained" color="success">Save changes</LoadingButton>
                </DialogActions>
            </form>
        </Dialog >
    );
}
import { gql } from "@apollo/client";
import Customer from "types/Customer";
import CustomerFragment from "../fragments/CustomerFragment";
import FieldFragment from "../fragments/FieldFragment";
import CompleteShopifyOrderFragment from "../fragments/CompleteShopifyOrderFragment";

export interface AddCustomerFieldInput {
  input: {
    id: string;
    fieldType: string;
    fieldValue: string;
  };
}

export interface AddCustomerFieldPayload {
  addCustomerField: {
    errors: string[];
    customer?: Customer;
  };
}

const ADD_CUSTOMER_FIELD = gql`
  mutation AddCustomerField($input: AddCustomerFieldMutationInput!) {
    addCustomerField(input: $input) {
      errors
      customer {
        ...CustomerFields
        fields {
          nodes {
            ...FieldFields
          }
        }
        orders {
          nodes {
            ...CompleteShopifyOrderFields
          }
        }
      }
    }
  }
  ${CustomerFragment},
  ${FieldFragment},
  ${CompleteShopifyOrderFragment}
`;

export default ADD_CUSTOMER_FIELD;

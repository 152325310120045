import { gql } from "@apollo/client";

export interface BulkLabelConversationInput {
    input: {
        ids: string[];
        labelIds: string[];
    };
}

export interface BulkLabelConversationPayload {
    bulkLabelConversation: {
        errors: string[];
        ok: boolean;
    };
}

const BULK_LABEL_CONVERSATION = gql`
  mutation BulkLabelConversation($input: BulkLabelConversationMutationInput!) {
    bulkLabelConversation(input: $input) {
      errors
      ok
    }
  }
`;

export default BULK_LABEL_CONVERSATION;

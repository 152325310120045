import { gql } from "@apollo/client";
import Conversation from "types/Conversation";

export interface SimilarConversationsInput {
    id: string;
}

export interface SimilarConversationsPayload {
    conversation: Conversation;
}

const SIMILAR_CONVERSATIONS = gql`
  query SimilarConversations($id: ID!) {
    conversation(id: $id) {
        id
        createdAt
        timeLeft
        messageCount
        number
        latestMessage
        totalOrderValue
        type {
          id
          title
        }
        customer {
          id
          name
          email
        }
        assignees {
          nodes {
            id
            name
            avatarUrl
          }
        }
        labels {
          nodes {
            id
            title
            colorHex
          }
        }
        similarConversations {
            pageInfo {
                totalCount
                startCursor
                endCursor
            }
            nodes {
                id
                createdAt
                timeLeft
                messageCount
                number
                latestMessage
                totalOrderValue
                type {
                  id
                  title
                }
                customer {
                  id
                  name
                  email
                }
                assignees {
                  nodes {
                    id
                    name
                    avatarUrl
                  }
                }
                labels {
                  nodes {
                    id
                    title
                    colorHex
                  }
                }
            }
        }
    }
  }
`;

export default SIMILAR_CONVERSATIONS;

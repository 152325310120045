import { gql } from "@apollo/client";
import EmailDomain from "types/EmailDomain";

export interface EmailDomainsInput {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
}

export interface EmailDomainsPayload {
  emailDomains: {
    pageInfo: {
      totalCount: number;
      startCursor: string;
      endCursor: string;
    };
    nodes: EmailDomain[];
  };
}

const EMAIL_DOMAINS_QUERY = gql`
  query EmailDomains(
    $after: String
    $before: String
    $last: Int
    $first: Int
  ) {
    emailDomains(
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      pageInfo {
        totalCount
        startCursor
        endCursor
      }
      nodes {
        id
        createdAt
        subDomain
        address
        isCustomDomain
        forwardingAddress
        created
        mxRecordsCreated
        postmarkServerCreated
        postmarkDomainCreated
        dkimVerified
        bounceWebhookCreated
        forwardingEnabled
      }
    }
  }
`;

export default EMAIL_DOMAINS_QUERY;
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useQuery, useSubscription, useMutation } from "@apollo/client";
import CONVERSATION, {
  ConversationInput,
  ConversationPayload,
} from "graphql/queries/ConversationQuery";
import MY_NOTIFICATIONS_UPDATED, {
  MyNotificationsUpdatedInput,
  MyNotificationsUpdatedPayload
} from "graphql/subscriptions/MyNotificationsUpdatedSubscription";
import VISIT_CONVERSATION, {
  VisitConversationInput,
  VisitConversationPayload,
} from "graphql/mutations/VisitConversationMutation";
import Alert from "@mui/material/Alert";
import { useParams } from "react-router-dom";
import ConversationsLeftSideBar from "components/chrome/ConversationsLeftSideBar";
import Button from "theme/Button";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import useTheme from "@mui/material/styles/useTheme";
import Typography from "@mui/material/Typography";
import ConversationSidePanel from "./ConversationRightSidePanel";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "theme/Divider";
import Chip from "theme/Chip";
import Message, { instanceOfMessage } from "types/Message";
import Activity, { instanceOfActivity } from "types/Activity";
import Note, { instanceOfNote } from "types/Note";
import { parseDateString, formatDateStringToTimeAgo } from "helpers/DateFormatters";
import ConversationToolbar from "./ConversationToolbar";
import MessageCard from "./MessageCard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChangeConversationTypeDialog from "./ChangeConversationTypeDialog";
import ConversationType from "types/ConversationType";
import ActivityCard from "./Activities";
import MergeConversationsDialog from "./MergeConversationsDialog";
import MergeTypeIcon from '@mui/icons-material/MergeType';
import NoteCard from "./NoteCard";
import ReplyBox from "./ReplyBox";
import ConversationTabs from "./ConversationTabs";
import { ProgressBar } from "components/conversations/renderers/RenderTimeLeft";
import { stateOf, ConversationState } from "types/Conversation";
import { useStoreon } from 'storeon/react';
import State from "store/State";
import ME, {
  MeInput,
  MePayload,
} from "graphql/queries/MeQuery";

function Timeline(props: ConversationPayload) {

  const { conversation } = props;
  const [mergeDialogOpen, setMergeDialogOpen] = useState<boolean>(false);
  const [conversationType, setConversationType] = useState<ConversationType>(conversation.type);
  const [changeTypeOpen, setChangeTypeOpen] = useState<boolean>(false);
  const theme = useTheme();

  const [visitConversation, { }] = useMutation<VisitConversationPayload, VisitConversationInput>(VISIT_CONVERSATION, { refetchQueries: ["MyUnreadNotifications", "Notifications", "SideBarQuery"] });

  useEffect(() => {
    try {
      visitConversation({ variables: { input: { conversationId: conversation.id } } });
    } catch { }
  }, [conversation]);

  return (
    <Stack direction="column" spacing={2}>
      <Card elevation={0} variant="outlined">
        <Stack direction="row" sx={{ p: theme.spacing(2) }} spacing={2}>
          <Stack direction="column" width="100%">
            <Stack direction="row" alignItems="flex-start">
              <Stack direction="column">
                <Button sx={{ justifyContent: "space-between" }} disabled={stateOf(conversation) === ConversationState.duplicate} size="small" endIcon={<KeyboardArrowDownIcon />} onClick={() => setChangeTypeOpen(true)}>
                  <Typography variant="h5" color="textPrimary">
                    {conversationType.title}
                  </Typography>
                </Button>
                <Typography variant="subtitle2" color="text.secondary" pl={theme.spacing(1)}>
                  Conversation !{conversation.number}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{ alignItems: "flex-start", flexWrap: "wrap" }}
              >
                {conversation.labels.nodes.map((label) => (
                  <Chip
                    key={label.id}
                    size="small"
                    label={<Typography fontWeight={600} variant="caption">{label.title}</Typography>}
                    variant="filled"
                    sx={{
                      bgcolor: label.colorHex,
                      color: theme.palette.getContrastText(label.colorHex),
                      mb: theme.spacing(1),
                      mt: theme.spacing(0.5),
                      ml: theme.spacing(0.5),
                    }}
                  />
                ))}
              </Stack>
              <Box sx={{ flexGrow: 1 }}></Box>
              <Stack direction="column" spacing={1} alignItems="flex-end">
                {conversation.timeLeft && <ProgressBar value={parseDateString(conversation.timeLeft)} />}
                {!conversation.timeLeft && conversation.events.nodes.length > 0 && <Box sx={{ alignItems: "center", display: "flex" }}>
                  <Typography variant="caption" color="text.secondary">Updated {formatDateStringToTimeAgo(conversation.events.nodes[conversation.events.nodes.length - 1].createdAt)}</Typography>
                </Box>}
                <Typography variant="caption" color="text.secondary">
                  Started{" "}
                  {formatDateStringToTimeAgo(conversation.createdAt)}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Divider />
        <ConversationToolbar conversation={conversation} />
        <ChangeConversationTypeDialog conversation={conversation} selectedValue={conversation.type} open={changeTypeOpen} onClose={(value) => { setConversationType(value); setChangeTypeOpen(false); }} />
      </Card>
      {
        conversation.events.nodes.map((event) => {
          if (!!event) {
            if (instanceOfActivity(event)) {
              return <ActivityCard key={event.id} activity={event as Activity} />;
            } else if (instanceOfMessage(event)) {
              return <MessageCard key={event.id} message={event as Message} conversation={conversation} />;
            } else if (instanceOfNote(event)) {
              return <NoteCard key={(event as Note).id} note={event as Note} />;
            } else {
              return <></>;
            }
          }
        })
      }
      {conversation.messageCount === 1 && conversation.similarConversations.pageInfo.totalCount > 0 &&
        <Stack spacing={1} direction="column">
          <Typography textAlign="center" variant="caption" color="text.secondary">This customer has <strong>{conversation.similarConversations.pageInfo.totalCount}</strong> other recent conversations.</Typography>
          <Button size="small" startIcon={<MergeTypeIcon />} onClick={() => setMergeDialogOpen(true)}>Merge conversations</Button>
          <MergeConversationsDialog conversation={conversation} onClose={() => setMergeDialogOpen(false)} open={mergeDialogOpen} />
        </Stack>}
      {!conversation.done && <ReplyBox conversation={conversation} variant="conversation" />}
      <div style={{ height: 100 }} />
    </Stack >
  );
}

type Params = {
  id: string;
};

function ConversationTimeline() {

  const { id } = useParams<Params>();
  const { dispatch } = useStoreon<State>();

  const theme = useTheme();

  const { data, error, loading, refetch } = useQuery<ConversationPayload, ConversationInput>(CONVERSATION, {
    fetchPolicy: "no-cache",
    variables: { id: id },
  });

  const { data: meData } = useQuery<MePayload, MeInput>(ME, {
    variables: {},
  })

  const { data: notificationsUpdatedData } = useSubscription<MyNotificationsUpdatedPayload, MyNotificationsUpdatedInput>(MY_NOTIFICATIONS_UPDATED, {
    variables: { userId: meData?.me?.id || "" },
  });

  useEffect(() => {
    if (!!id) {
      dispatch("conversations/setSelectedConversationId", id);
      dispatch("conversations/add", id);
    }
  }, [id]);


  useEffect(() => {
    if (!!notificationsUpdatedData?.myNotificationsUpdated) {
      refetch({ id: id });
    }
  }, [notificationsUpdatedData]);

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          width: 300,
          p: theme.spacing(2),
          display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "block"
          }
        }}
      >
        <ConversationsLeftSideBar />
      </Box>
      <Stack direction="column" sx={{
        p: 2,
        pl: {
          "xs": 2,
          "sm": 2,
          "md": 2,
          "lg": 0,
        },
        width: {
          "xs": "100%",
          "sm": "100%",
          "md": "100%",
          "lg": "calc(100% - 300px)",
        },
      }}>
        <Box sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "block"
          }
        }}>
          <ConversationTabs showSelection />
        </Box>
        <Stack direction="row" sx={{
          pt: {
            "xs": 0,
            "sm": 0,
            "md": 0,
            "lg": 2,
          },
          width: "100%"
        }}>
          <Stack direction="column" spacing={2} sx={{
            width: "100%",
            maxWidth: "900px"
          }}>
            {loading && !data && (
              <Stack alignItems="center" direction="column" p={2}>
                <CircularProgress />
              </Stack>
            )}
            {!!error && <Alert severity="error">{error.message}</Alert>}
            {!!data && <Timeline conversation={data.conversation} />}
          </Stack>
          <Box sx={{
            width: {
              md: 350,
              lg: 400,
            },
            pl: 2,
            pr: 2,
            display: {
              xs: "none",
              sm: "none",
              md: "block",
              lg: "block"
            }
          }}>
            {!!data && <ConversationSidePanel conversation={data.conversation} />}
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}

export default ConversationTimeline;
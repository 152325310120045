import { gql } from "@apollo/client";
import Notification from "types/Notification";

export interface NotificationsInput {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
}

export interface NotificationsPayload {
  myNotifications: {
    pageInfo: {
      totalCount: number;
      startCursor: string;
      endCursor: string;
    };
    nodes: Notification[];
  };
}

const NOTIFICATIONS = gql`
  query Notifications(
    $after: String
    $before: String
    $last: Int
    $first: Int,
  ) {
    myNotifications(
      first: $first
      last: $last
      after: $after
      before: $before,
    ) {
      pageInfo {
        totalCount
        startCursor
        endCursor
      }
      nodes {
        id
        createdAt
        read
        title
        conversation {
          id
        }
      }
    }
  }
`;

export default NOTIFICATIONS;
import { gql } from "@apollo/client";
import Notification from "types/Notification";

export interface MyNotificationsUpdatedInput {
    userId: string;
}

export interface MyNotificationsUpdatedPayload {
    myNotificationsUpdated?: {
        notification?: Notification;
    };
}

const MY_NOTIFICATIONS_UPDATED = gql`
  subscription MyNotificationsUpdated($userId: ID!) {
    myNotificationsUpdated(userId: $userId) {
        notification {
            id
            createdAt
            read
            title
        }
    }
  }
`;

export default MY_NOTIFICATIONS_UPDATED;
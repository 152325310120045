import { gql } from "@apollo/client";
import Conversation from "types/Conversation";

export interface UpdateConversationLabelsInput {
  input: {
    id: string;
    labelsToAdd: string[];
    labelsToRemove: string[];
  };
}

export interface UpdateConversationLabelsPayload {
  updateConversationLabels: {
    errors: string[];
    conversation: Conversation;
  }
}

const UPDATE_CONVERSATION_LABELS = gql`
  mutation UpdateConversationLabels($input: UpdateConversationLabelsMutationInput!) {
    updateConversationLabels(input: $input) {
      errors
      conversation {
        id
        labels {
          nodes {
            id
            createdAt
            title
            colorHex
          }
        }
      }
    }
  }
`;

export default UPDATE_CONVERSATION_LABELS;
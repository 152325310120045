import { gql } from "@apollo/client";

export interface RunWorkflowInput {
    input: {
        id: string;
        conversationIds: string[];
    };
}

export interface RunWorkflowPayload {
    runWorkflow: {
        ok?: boolean;
    };
}

const RUN_WORKFLOW = gql`
  mutation RunWorkflow($input: RunWorkflowMutationInput!) {
    runWorkflow(input: $input) {
        ok
    }
  }
`;

export default RUN_WORKFLOW;

import { gql } from "@apollo/client";

export interface UpdateOrgPlanInput {
    input: {
        planName: string;
    };
}

export interface UpdateOrgPlanPayload {
    updateOrgPlan: {
        confirmationUrl?: string;
    }
}

const UPDATE_ORG_PLAN = gql`
  mutation UpdateOrgPlan(
    $input: UpdateOrgPlanMutationInput!
  ) {
    updateOrgPlan(input: $input) {
      confirmationUrl 
    }
  }
`;

export default UPDATE_ORG_PLAN;
import Box from "@mui/material/Box";
import CustomerDetail from "components/customer/CustomerDetail";

function Conversation() {
    return (
        <Box>
            <CustomerDetail />
        </Box>
    );
}

export default Conversation;

import { gql } from "@apollo/client";

export interface SideBarInput { }

export interface SideBarPayload {
  myConversationsCount: number;
  savedConversationsCount: number;
  waitingConversationsCount: number;
  inboxCount: number;
  mentioningMeCount: number;
}

const SIDE_BAR = gql`
  query SideBarQuery {
    myConversationsCount: conversationsCount(filters: { filter: MINE })
    savedConversationsCount: conversationsCount(filters: { filter: SAVED })
    waitingConversationsCount: conversationsCount(filters: { filter: WAITING })
    inboxCount:  conversationsCount(filters: { filter: INBOX })
    mentioningMeCount: conversationsCount(filters: { filter: MENTIONED })
  }
`;

export default SIDE_BAR;

import gql from "graphql-tag";
import CustomerFragment from "../fragments/CustomerFragment";
import FieldFragment from "../fragments/FieldFragment";
import LabelFragment from "../fragments/LabelFragment";
import MessageFragment from "../fragments/MessageFragment";
import ActivityFragment from "../fragments/ActivityFragment";
import NoteFragment from "../fragments/NoteFragment";
import ConversationTypeFragment from "../fragments/ConversationTypeFragment";
import CompleteShopifyOrderFragment from "./CompleteShopifyOrderFragment";

const CompleteConversationFragment = gql`
  fragment CompleteConversationFields on Conversation {
      id
      createdAt
      updatedAt
      number
      saved
      watching
      waiting
      done
      spam
      timeLeft
      resolvedAt
      messageCount
      duplicate
      parent {
        id
        createdAt
        updatedAt
        number
        saved
        waiting
        done
        spam
        timeLeft
        messageCount
        duplicate
      }
      similarConversations {
        pageInfo {
          totalCount
          startCursor
          endCursor
        }
      }
      mentionedOrders {
        pageInfo {
          totalCount
          startCursor
          endCursor
        }
        nodes {
          ... CompleteShopifyOrderFields
        }
      }
      type {
        ...ConversationTypeFields
      }
      customer {
        ...CustomerFields
        latestOrder {
          ... CompleteShopifyOrderFields
        }
        fields {
          pageInfo {
            totalCount
            startCursor
            endCursor
          }
          nodes {
            ...FieldFields
          }
        }
      }
      assignees {
        pageInfo {
          totalCount
          startCursor
          endCursor
        }
        nodes {
          id
          name
          email
          avatarUrl
          recentlyActive
          role
        }
      }
      labels {
        pageInfo {
          totalCount
          startCursor
          endCursor
        }
        nodes {
          ...LabelFields
        }
      }
      events {
        pageInfo {
          totalCount
          startCursor
          endCursor
        }
        nodes {
          ... on Note {
            ...NoteFields
            author {
              id
              name
              email
              avatarUrl
              recentlyActive
              role
            }
          }
          ... on Activity {
            ...ActivityFields
            parentConversation {
              id
              createdAt
              updatedAt
              number
              saved
              waiting
              done
              spam
              timeLeft
              messageCount
              duplicate
            }
            duplicatesMerged {
              nodes {
                id
                createdAt
                updatedAt
                number
                saved
                waiting
                done
                spam
                timeLeft
                messageCount
                duplicate
              }
            }
            helpdeskMessengerOption {
              id
              questionText
            }
            order {
              ...CompleteShopifyOrderFields
            }
            newType {
              ...ConversationTypeFields
            }
            oldType {
              ...ConversationTypeFields
            }
            user {
              id
              name
              email
              avatarUrl
              recentlyActive
              role
            }
            customer {
              id
              name
              email
            }
            labelsAdded {
              nodes {
                ...LabelFields
              }
            }
            labelsRemoved {
              nodes {
                ...LabelFields
              }
            }
            assigneesAdded {
              nodes {
                id
                name
                email
                avatarUrl
                recentlyActive
                role
              }
            }
            assigneesRemoved {
              nodes {
                id
                name
                email
                avatarUrl
                recentlyActive
                role
              }
            }
          }
          ... on Message {
            ...MessageFields
            fileAttachments {
              pageInfo {
                totalCount
                startCursor
                endCursor
              }
              nodes {
                id
                createdAt
                givenFilename
              }
            }
            sender {
              ... on User {
                id
                name
                email
                avatarUrl
                role
                recentlyActive
              }
              ... on Customer {
                id
                name
                avatarUrl
              }
            }
          }
        }
      }
  }
  ${CustomerFragment},
  ${FieldFragment},
  ${LabelFragment},
  ${MessageFragment},
  ${ActivityFragment},
  ${NoteFragment},
  ${ConversationTypeFragment},
  ${CompleteShopifyOrderFragment}
`;

export default CompleteConversationFragment;
import { gql } from "@apollo/client";

export interface AcknowledgeFeatureDropInput {
    input: {

    };
}

export interface AcknowledgeFeatureDropPayload {
    acknowledgeFeatureDrop: {
        ok: boolean;
    };
}

const ACKNOWLEDGE_FEATURE_DROP = gql`
  mutation AcknowledgeFeatureDrop($input: AcknowledgeFeatureDropMutationInput!) {
    acknowledgeFeatureDrop(input: $input) {
      ok
    }
  }
`;

export default ACKNOWLEDGE_FEATURE_DROP;

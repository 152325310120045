import { useState } from "react";
import Stack from "@mui/material/Stack";
import { useQuery } from "@apollo/client";
import SHOPIFY_ORDER, {
    ShopifyOrderInput,
    ShopifyOrderPayload,
} from "graphql/queries/ShopifyOrderQuery";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import { formatDateStringToDDMMYYYY } from "helpers/DateFormatters";
import useTheme from "@mui/material/styles/useTheme";
import Chip from "theme/Chip";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import StepperConnector from "theme/StepperConnector";
import { eventToDisplayStatus } from "types/ShopifyFulfillmentEvent";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Avatar from "theme/Avatar";
import Card, { InnerCard } from "theme/Card";
import Divider from "theme/Divider";
import Link from '@mui/material/Link';
import Inventory2Icon from '@mui/icons-material/Inventory2';

type Params = {
    id: string;
};

function OrderPanel() {

    const theme = useTheme();

    const { id } = useParams<Params>();

    const [alertError, setAlertError] = useState<string | undefined>(undefined);

    const { data, error } = useQuery<ShopifyOrderPayload, ShopifyOrderInput>(SHOPIFY_ORDER, {
        variables: {
            id: id
        },
    });

    const order = data?.shopifyOrder;

    let riskLevelColor;

    switch (order?.riskLevel) {
        case "high":
            riskLevelColor = theme.palette.error.main;
            break;
        case "medium":
            riskLevelColor = theme.palette.warning.main;
            break;
        case "low":
            riskLevelColor = undefined;
            break;
    }

    return (
        <>
            {!!alertError && <Alert severity="error" sx={{ maxWidth: 948 }}>{alertError}</Alert>}
            {!!error && <Alert severity="error" sx={{ maxWidth: 948 }}>{error.message}</Alert>}
            {!!order &&
                <Stack direction="column" sx={{ maxWidth: 948 }} spacing={1}>
                    <Card variant="outlined">
                        <Stack direction="column" spacing={2} padding={2}>
                            <Typography variant="h5">Order details</Typography>
                            <Stack spacing={1}>
                                <Typography variant="caption" color="text.secondary">{formatDateStringToDDMMYYYY(order.createdAt)}</Typography>
                                {!!order.closedAt && <Typography variant="caption" color="text.secondary">Closed at: {formatDateStringToDDMMYYYY(order.closedAt)}</Typography>}
                                {!!order.cancelledAt && <Typography variant="caption" color="text.secondary">Cancelled at: {formatDateStringToDDMMYYYY(order.cancelledAt)}</Typography>}
                                <Stack direction="row" spacing={0.5} alignItems="center">
                                    <Typography variant="caption" color="text.secondary">{order.name}</Typography>
                                    <Typography variant="caption" fontWeight={700} color="text.secondary">{order.currentTotalPriceSet.shopAmount}</Typography>
                                </Stack>
                                <Stack
                                    direction="row"
                                    flexWrap="wrap"
                                    sx={{
                                        justifyContent: "flex-start",
                                        alignContent: "space-evenly",
                                        height: "100%",
                                    }}
                                >
                                    <Chip
                                        size="small"
                                        label={`${order.financialStatus.replace(/_/g, " ")}`}
                                        sx={{
                                            bgcolor: `${order.fullyPaid ? undefined : theme.palette.warning.main}`,
                                            color: order.fullyPaid ? undefined : theme.palette.getContrastText(theme.palette.warning.main),
                                            mr: theme.spacing(0.5),
                                        }} />
                                    <Chip
                                        size="small"
                                        label={`${order.fulfillmentStatus.replace(/_/g, " ")}`}
                                        sx={{
                                            bgcolor: `${order.closed ? undefined : theme.palette.warning.main}`,
                                            color: order.closed ? undefined : theme.palette.getContrastText(theme.palette.warning.main),
                                            mr: theme.spacing(0.5),
                                        }} />
                                    <Chip
                                        size="small"
                                        label={`${order.riskLevel} risk`}
                                        sx={{
                                            bgcolor: riskLevelColor,
                                            color: !!riskLevelColor ? theme.palette.getContrastText(riskLevelColor) : undefined,
                                            mr: theme.spacing(0.5),
                                        }} />
                                </Stack>
                                <Stack spacing={0}>
                                    <Card elevation={0}>
                                        <AccordionSummary sx={{ p: 0 }}>
                                            <Typography variant="caption" color="text.secondary">
                                                <Stack direction="row" alignItems="center">
                                                    <ShoppingCartIcon color="inherit" />
                                                    <Typography fontWeight={700} variant="caption" color="text.secondary" sx={{ ml: 1 }}>
                                                        Items
                                                    </Typography>
                                                </Stack>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ pt: 0, pb: 0 }}>
                                            <Stack direction="column" spacing={1}>
                                                {order.lineItems.nodes.map((lineItem) =>
                                                    <Stack key={lineItem.id} direction="row" spacing={2} alignItems="center">
                                                        <Avatar
                                                            variant="rounded"
                                                            src={lineItem.imageSrc}
                                                        ></Avatar>
                                                        <Stack direction="column" spacing={0.5}>
                                                            <Typography variant="caption" color="text.secondary">{lineItem.name}</Typography>
                                                            <Typography variant="caption" color="text.secondary">{lineItem.currentQuantity} x {lineItem.originalUnitPriceSet.shopAmount} = {lineItem.originalTotalSet.shopAmount}</Typography>
                                                        </Stack>
                                                    </Stack>
                                                )}
                                            </Stack>
                                        </AccordionDetails>
                                    </Card>
                                    {order.fulfillments.nodes.length > 0 && <Card elevation={0}>
                                        <AccordionSummary sx={{ p: 0 }}>
                                            <Typography variant="caption" color="text.secondary">
                                                <Stack direction="row" alignItems="center">
                                                    <Inventory2Icon color="inherit" />
                                                    <Typography fontWeight={700} variant="caption" color="text.secondary" sx={{ ml: 1 }}>
                                                        Fulfillments
                                                    </Typography>
                                                </Stack>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ pt: 0, pb: 0 }}>
                                            <Stack direction="column" spacing={0.5}>
                                                {order.fulfillments.nodes.map((fulfillment, findex) => <>
                                                    <Stack direction="column" spacing={0.5} key={fulfillment.id}>
                                                        <Typography variant="caption" color="text.secondary">
                                                            {fulfillment.name} - {fulfillment.displayStatus}
                                                        </Typography>
                                                        {!!fulfillment.trackingInfoUrl && <Link href={fulfillment.trackingInfoUrl} target="_blank" rel="noopener" variant="caption" underline="hover">{fulfillment.trackingInfoUrl}</Link>}
                                                        {!!fulfillment.trackingInfoNumber && <Typography variant="caption" color="text.secondary" fontWeight={600}>
                                                            {fulfillment.trackingInfoNumber}
                                                        </Typography>}
                                                        {!!fulfillment.trackingInfoCompany && <Typography variant="caption" color="text.secondary">
                                                            {fulfillment.trackingInfoCompany}
                                                        </Typography>}
                                                    </Stack>
                                                    {findex < order.fulfillments.nodes.length - 1 && <Divider />}
                                                </>)}
                                            </Stack>
                                        </AccordionDetails>
                                    </Card>}
                                    <Card elevation={0}>
                                        <AccordionSummary sx={{ p: 0 }}>
                                            <Typography variant="caption" color="text.secondary">
                                                <Stack direction="row" alignItems="center">
                                                    <LocalShippingIcon color="inherit" />
                                                    <Typography fontWeight={700} variant="caption" color="text.secondary" sx={{ ml: 1 }}>
                                                        Shipping
                                                    </Typography>
                                                </Stack>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ pt: 0, pb: 0 }}>
                                            <Stack direction="column" spacing={0.5}>
                                                {order.shippingLines.nodes.map((shippingLine) => <Typography key={shippingLine.id} variant="caption" color="text.secondary">
                                                    {shippingLine.title} - {shippingLine.discountedPriceSet.shopAmount}
                                                </Typography>)}
                                                <Typography fontWeight={700} variant="caption" color="text.secondary">
                                                    Address:
                                                </Typography>
                                                <Typography variant="caption" color="text.secondary">
                                                    {order.shippingAddress.formatted}
                                                </Typography>
                                            </Stack>
                                        </AccordionDetails>
                                    </Card>
                                    <Card elevation={0}>
                                        <AccordionSummary sx={{ p: 0 }}>
                                            <Typography variant="caption" color="text.secondary">
                                                <Stack direction="row" alignItems="center">
                                                    <ReceiptIcon color="inherit" />
                                                    <Typography fontWeight={700} variant="caption" color="text.secondary" sx={{ ml: 1 }}>
                                                        Billing
                                                    </Typography>
                                                </Stack>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ pt: 0, pb: 0 }}>
                                            <Stack direction="column" spacing={0.5}>
                                                <Typography fontWeight={700} variant="caption" color="text.secondary">
                                                    Address:
                                                </Typography>
                                                <Typography variant="caption" color="text.secondary">
                                                    {order.billingAddress.formatted}
                                                </Typography>
                                                <Typography fontWeight={700} variant="caption" color="text.secondary">
                                                    Phone:
                                                </Typography>
                                                <Typography variant="caption" color="text.secondary">
                                                    {!!order.phone ? order.phone : "Not on file"}
                                                </Typography>
                                                <Typography fontWeight={700} variant="caption" color="text.secondary">
                                                    Email:
                                                </Typography>
                                                <Typography variant="caption" color="text.secondary">
                                                    {!!order.email ? order.email : "Not on file"}
                                                </Typography>
                                            </Stack>
                                        </AccordionDetails>
                                    </Card>
                                    <Card elevation={0}>
                                        <AccordionSummary sx={{ p: 0 }}>
                                            <Typography variant="caption" color="text.secondary">
                                                <Stack direction="row" alignItems="center">
                                                    <LocalOfferIcon color="inherit" />
                                                    <Typography fontWeight={700} variant="caption" color="text.secondary" sx={{ ml: 1 }}>
                                                        Discounts
                                                    </Typography>
                                                </Stack>
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ pt: 0, pb: 0 }}>
                                            <Stack direction="column" spacing={0.5}>
                                                {!!order.discountCode && <Typography variant="caption" color="text.secondary">Discount code applied: {order.discountCode}</Typography>}
                                                {!order.discountCode && <Typography variant="caption" color="text.secondary">No discount code applied</Typography>}
                                            </Stack>
                                        </AccordionDetails>
                                    </Card>
                                </Stack>
                                <Stepper orientation="vertical" connector={<StepperConnector />}>
                                    {order.fulfillmentEvents.nodes.map((event, index) =>
                                        <Step key={event.id} expanded active={order.fulfillmentEvents.nodes.length - 1 == index}>
                                            <StepLabel color="text.secondary">
                                                <Typography variant="caption">{formatDateStringToDDMMYYYY(event.happenedAt)}</Typography>
                                            </StepLabel>
                                            <StepContent>
                                                <InnerCard variant="outlined">
                                                    <Stack p={1}>
                                                        <Typography color="text.secondary" variant="caption">{eventToDisplayStatus(event)}</Typography>
                                                    </Stack>
                                                </InnerCard>
                                            </StepContent>
                                        </Step>
                                    )}
                                </Stepper>
                            </Stack>
                        </Stack>
                    </Card>
                </Stack >}
        </>
    );
}

export default OrderPanel;
import Box from "@mui/material/Box";
import ConversationsGrid from "components/conversations/ConversationsGrid";

function Conversations() {

  return (
    <Box>
      <ConversationsGrid />
    </Box>
  );
}

export default Conversations;

import Box from "@mui/material/Box";
import GettingStartedInviteTeamCard from "components/getting_started/GettingStartedInviteTeam";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useHistory } from "react-router-dom";

function GettingStartedEmail() {
    const history = useHistory();
    return (
        <Box sx={{ height: "100vh", backgroundImage: "linear-gradient(to right, rgb(0, 0, 0), rgb(17, 24, 28))" }}>
            <Stack gap={2} flexWrap="wrap" sx={{ p: 1, color: "white", backgroundImage: "linear-gradient(135deg, rgb(14, 115, 204) 1.93%, rgb(98, 75, 187) 14.86%, rgb(255, 69, 93) 48.09%, rgb(243, 88, 21) 77.82%, rgb(242, 182, 0) 97.3%)" }} direction="row" justifyContent="flext-start" alignItems="center">
                <Typography variant="h5" color="inherit" sx={{
                    fontSize: "20px",
                    pl: 2,
                    display: {
                        xs: "none",
                        sm: "none",
                        md: "inline-flex",
                        lg: "inline-flex"
                    },
                }}>Setup</Typography>
                <IconButton size="small" color="warning" sx={{
                    display: {
                        xs: "none",
                        sm: "none",
                        md: "inline-flex",
                        lg: "inline-flex"
                    },
                }}><ArrowForwardIcon /></IconButton>
                <Button onClick={() => history.push("/getting-started")} sx={{ textTransform: "none", fontWeight: 700 }} color="inherit" variant="text" size="small">Show the world your beautiful smile</Button>
                <IconButton size="small" color="warning"><ArrowForwardIcon /></IconButton>
                <Button onClick={() => history.push("/getting-started-email")} sx={{ textTransform: "none", fontWeight: 700 }} color="inherit" variant="text" size="small">Setup your Mailbox</Button>
                <IconButton size="small" color="warning"><ArrowForwardIcon /></IconButton>
                <Button onClick={() => history.push("/getting-started-team-members")} sx={{ textTransform: "none", fontWeight: 700 }} color="inherit" variant="text" size="small">Lets invite the team</Button>
                <IconButton size="small" color="warning"><ArrowForwardIcon /></IconButton>
            </Stack>
            <Box
                sx={{
                    pt: 15,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <GettingStartedInviteTeamCard />
            </Box>
        </Box>
    );
}

export default GettingStartedEmail;
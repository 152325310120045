import { useState, useEffect } from "react";
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button, { LoadingButton } from 'theme/Button';
import Dialog from '@mui/material/Dialog';
import TextField from 'theme/TextField';
import { useMutation } from "@apollo/client";
import UPDATE_CONVERSATION_TYPE, { UpdateConversationTypeInput, UpdateConversationTypePayload } from "graphql/mutations/UpdateConversationTypeMutation";
import DELETE_CONVERSATION_TYPE, { DeleteConversationTypeInput, DeleteConversationTypePayload } from "graphql/mutations/DeleteConversationTypeMutation";
import Typography from "@mui/material/Typography";
import { useForm, Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import useTheme from "@mui/material/styles/useTheme";
import ConversationType from "types/ConversationType";

interface Props {
    open: boolean;
    conversationType?: ConversationType;
    onClose: () => void;
}

export default function EditConversationTypeDialog(props: Props) {

    const { onClose, open, conversationType } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [alertError, setAlertError] = useState<string | undefined>(undefined);
    const theme = useTheme();

    const {
        control,
        handleSubmit,
        reset,
        trigger,
        setValue: setFormValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            "conversation_type_title": conversationType?.title,
        }
    });

    const [updateConversationType, { error: updateError }] = useMutation<UpdateConversationTypePayload, UpdateConversationTypeInput>(UPDATE_CONVERSATION_TYPE, { refetchQueries: ["ConversationTypes", "Conversations", "Conversation"] });
    const [deleteConversationType, { error: deleteError }] = useMutation<DeleteConversationTypePayload, DeleteConversationTypeInput>(DELETE_CONVERSATION_TYPE, { refetchQueries: ["ConversationTypes", "Conversations", "Conversation"] });

    useEffect(() => {
        if (!!updateError) {
            setAlertError(updateError.message);
        } else if (!!deleteError) {
            setAlertError(deleteError.message);
        } else {
            setAlertError(undefined);
        }
    }, [updateError, deleteError]);

    useEffect(() => {
        if (!!conversationType) {
            reset();
            setFormValue("conversation_type_title", conversationType.title);
            trigger("conversation_type_title");
        }
    }, [conversationType]);

    const handleClose = () => {
        if (loading) {
            return;
        }
        onClose();
        setTimeout(() => {
            reset();
            setAlertError(undefined);
        }, 200);
    };

    const onSubmitUpdateConversationType = async (formData: { [x: string]: any; }) => {
        try {
            setLoading(true);

            const { data } = await updateConversationType({
                variables: {
                    input: {
                        id: conversationType!.id,
                        title: formData.conversation_type_title,
                        descriptionHtml: "<p></p>"
                    }
                }
            });

            if (!!(data?.updateConversationType)) {
                if (data.updateConversationType.errors.length > 0) {
                    setAlertError(data.updateConversationType.errors[0]);
                } if (!!data.updateConversationType.conversationType) {
                    handleClose();
                }
            }
        } catch {
            setAlertError('There was a problem updating this type');
        } finally {
            setLoading(false);
        }
    };

    const onSubmitDeleteConversationType = async () => {
        try {
            setLoading(true);

            const { data } = await deleteConversationType({
                variables: {
                    input: {
                        id: conversationType!.id,
                    }
                }
            });

            if (!!(data?.deleteConversationType)) {
                if (data.deleteConversationType.errors.length > 0) {
                    setAlertError(data.deleteConversationType.errors[0]);
                } if (!!data.deleteConversationType.ok) {
                    handleClose();
                }
            }
        } catch {
            setAlertError('There was a problem deleting this type');
        } finally {
            setLoading(false);
        }
    };

    const busy = loading;

    return (
        <Dialog onClose={() => handleClose()} open={open} scroll="paper" maxWidth="xs" fullWidth>
            <DialogTitle>
                <Typography variant="h5">Update conversation type</Typography>
            </DialogTitle>
            <DialogContent dividers sx={{ backgroundColor: theme.palette.background.default }}>
                <Stack direction="column" spacing={1}>
                    {!!alertError && <Alert severity="error" icon={false}>{alertError}</Alert>}
                    <Stack direction="column" spacing={2}>
                        <form onSubmit={handleSubmit(onSubmitUpdateConversationType)}>
                            <Stack direction="row" spacing={2}>
                                <FormControl variant="outlined" fullWidth>
                                    <Controller
                                        name="conversation_type_title"
                                        defaultValue={conversationType?.title}
                                        rules={{
                                            required: true,
                                            maxLength: 150,
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                id="conversation_type_title"
                                                type="conversation_type_title"
                                                sx={{ backgroundColor: theme.palette.background.paper }}
                                                fullWidth
                                                defaultValue={conversationType?.title}
                                                size="small"
                                                helperText={errors.conversation_type_title ? errors.conversation_type_title.message : undefined}
                                                variant="outlined"
                                                placeholder="Type"
                                                error={!!errors.conversation_type_title}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                        control={control}
                                    />
                                </FormControl>
                            </Stack>
                        </form>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                {!conversationType?.system && <LoadingButton loading={busy} onClick={() => { onSubmitDeleteConversationType() }} size="small" disabled={busy || !conversationType} variant="contained" color="error">Delete</LoadingButton>}
                <div style={{ flexGrow: 1 }} />
                <Button size="small" onClick={() => handleClose()} color="inherit">Close</Button>
                <LoadingButton loading={busy} onClick={handleSubmit(onSubmitUpdateConversationType)} size="small" disabled={busy} variant="contained" color="success">Update</LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
import { gql } from "@apollo/client";

export interface BulkMarkAsResolvedInput {
    input: {
        ids: string[];
        resolved: boolean;
        reason: string;
    };
}

export interface BulkMarkAsResolvedPayload {
    bulkMarkAsResolved: {
        errors: string[];
        ok: boolean;
    };
}

const BULK_MARK_AS_RESOLVED = gql`
  mutation BulkMarkAsResolved($input: BulkMarkAsResolvedMutationInput!) {
    bulkMarkAsResolved(input: $input) {
      errors
      ok
    }
  }
`;

export default BULK_MARK_AS_RESOLVED;

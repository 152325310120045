import { useQuery } from "@apollo/client";
import ME, { MeInput, MePayload } from "graphql/queries/MeQuery";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Card from "theme/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import shopifyLogo from "logos/shopify-logo.png"
import { useMutation } from "@apollo/client";
import SELECT_ORG, { SelectOrgInput, SelectOrgPayload } from "graphql/mutations/SelectOrgMutation";
import { useHistory } from "react-router-dom";
import Button from "theme/Button";

function SelectOrgGrid() {
    const history = useHistory();

    const { data, error: loadError } = useQuery<MePayload, MeInput>(ME, {});

    const [selectOrg, { error: selectOrgError }] = useMutation<SelectOrgPayload, SelectOrgInput>(SELECT_ORG, {});

    const renderSelectOrg = () => {
        return <>
            <Typography variant="h5">Select your Organization</Typography>
            <Typography variant="subtitle2" color="text.secondary">You can only be logged into one Organization at a time.</Typography>
            <Typography variant="subtitle2" color="text.secondary">Selecting the Organization here will also update your other devices you are using.</Typography>
            <Stack direction="row" spacing={2} alignItems="center">
                {data!.me.memberships.nodes.map((org) =>
                    <Card variant="outlined" key={org.id}>
                        <CardActionArea>
                            <CardContent onClick={async () => {
                                try {
                                    const { data } = await selectOrg({ variables: { input: { id: org.id } } });
                                    if (!!data) {
                                        history.push("/conversations");
                                    }
                                } catch { }
                            }}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <img style={{ height: 25, width: 25 }} src={shopifyLogo} />
                                    <Typography variant="subtitle2">{org.name}</Typography>
                                </Stack>
                            </CardContent>
                        </CardActionArea>
                    </Card>)}
            </Stack>
        </>
    }

    const renderInstallApp = () => {
        return <>
            <Typography variant="h5">You need to install the Shopify App first</Typography>
            <Typography variant="subtitle2" color="text.secondary">You are not a member of any team using zenshop.</Typography>
            <Typography variant="subtitle2" color="text.secondary">Install the zenshop app first on your Shopify store to get started.</Typography>
            <Button size="medium" onClick={() => { window.location.href = process.env.REACT_APP_SHOPIFY_APP_URL! }} variant="contained" color="secondary">Install zenshop</Button>
        </>
    }

    return (
        <Stack direction="column" alignItems="center" justifyContent="center" pt={20}>
            <Stack direction="column" spacing={2} p={2}>
                {!!loadError && <Alert severity="error">{loadError.message}</Alert>}
                {!!selectOrgError && <Alert severity="error">{selectOrgError.message}</Alert>}
                {!!(data?.me?.memberships?.pageInfo) && data.me.memberships.pageInfo.totalCount > 0 && renderSelectOrg()}
                {!!(data?.me?.memberships?.pageInfo) && data.me.memberships.pageInfo.totalCount == 0 && renderInstallApp()}
            </Stack>
        </Stack>
    );
}

export default SelectOrgGrid;
import Stack from "@mui/material/Stack";
import useQueryParams from "hooks/useQueryParams";
import { useEffect } from "react";
import useApp from "hooks/useApp";
import { Redirect, useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { useStoreon } from 'storeon/react';
import State from "store/State";
import { motion } from "framer-motion/dist/framer-motion";

function ConnectToShopify() {

    const params = useQueryParams();
    const { updateToken } = useApp();
    const history = useHistory();
    const { dispatch } = useStoreon<State>();

    const auth_token = params.get("auth-token") ?? undefined;
    const new_org = Boolean(params.get("new-org"));
    const choose_org = Boolean(params.get("choose-org"));
    const requestToJoin = Boolean(params.get("request-to-join"));
    const shop = params.get("shop") ?? "";
    const connectionId = params.get("connection-id") ?? "";

    useEffect(() => {
        dispatch("conversations/removeAll");
        updateToken(auth_token);
    }, [auth_token, updateToken]);

    if (!!auth_token) {
        // Update the auth token
        updateToken(auth_token);
        dispatch("conversations/removeAll");

        setTimeout(() => {
            // Redirect to final destination
            if (new_org) {
                history.push("/getting-started");
            } else if (choose_org) {
                history.push(`/select-org-connect?connection-id=${encodeURIComponent(connectionId)}&shop=${encodeURIComponent(shop)}`);
            } else {
                history.push(`/conversations`);
            }
        }, 4000);
    }

    if (requestToJoin) {
        return <Redirect to={{ pathname: `/request-to-join?shop=${encodeURIComponent(shop)}` }} />
    }

    return (
        <Stack display="flex" flexDirection="column" alignItems="center" p={5} spacing={6} sx={{ overflow: "clip", color: "white", height: "100vh", backgroundImage: "linear-gradient(to right, rgb(0, 0, 0), rgb(17, 24, 28))" }}>
            <motion.div style={{ overflow: "clip", position: "absolute", top: "calc(100vw * -0.6)" }} initial={{ opacity: 0, top: "calc(100vw * -1)" }} viewport={{ once: true }} whileInView={{ opacity: 1, top: "calc(100vw * -0.6)" }} transition={{ delay: 0.7, duration: 9, type: 'spring', bounce: 0.5 }}>
                <motion.div initial={{ rotate: 0 }} viewport={{ once: true }} whileInView={{ rotate: 360 }} transition={{ duration: 15, repeat: 100 }}>
                    <img draggable="false" style={{ width: "100vw" }} alt="loading" src="/images/Circle.png" />
                </motion.div>
            </motion.div>
            <motion.div style={{ zIndex: 1 }} initial={{ opacity: 0, y: 30 }} viewport={{ once: true }} whileInView={{ opacity: 1, y: 0 }} transition={{ delay: 0.7, duration: 0.8, type: 'spring', bounce: 0.5 }}>
                <Typography sx={{ textShadow: "3px 3px 8px rgba(63,107,169, 0.75)" }} variant="h4" color="inherit">Connecting...</Typography>
            </motion.div>
            <motion.div style={{ zIndex: 1 }} initial={{ opacity: 0 }} viewport={{ once: true }} whileInView={{ opacity: 1 }} transition={{ delay: 0.9, duration: 1, type: 'spring', bounce: 0.5 }}>
                <CircularProgress color="inherit" />
            </motion.div>
        </Stack>
    );
}

export default ConnectToShopify;

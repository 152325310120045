import gql from "graphql-tag";

const EmailDomainFragment = gql`
  fragment EmailDomainFields on EmailDomain {
    id
    address
    isCustomDomain
    createdAt
    subDomain
    forwardingAddress
    created
    mxRecordsCreated
    postmarkServerCreated
    postmarkDomainCreated
    dkimVerified
    bounceWebhookCreated
    forwardingEnabled
    dkimHost
    dkimTextValue
    returnPathDomain
    returnPathDomainCnameValue
  }
`;

export default EmailDomainFragment;

import { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import User from "types/User";
import IconButton from '@mui/material/IconButton';
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "theme/Button";
import Avatar from "theme/Avatar";
import Typography from '@mui/material/Typography';
import ME, { MeInput, MePayload } from "graphql/queries/MeQuery";
import { useQuery, useMutation } from "@apollo/client";
import REMOVE_FROM_TEAM, {
    RemoveFromTeamInput,
    RemoveFromTeamPayload
} from 'graphql/mutations/RemoveFromTeamMutation';
import Alert from "@mui/material/Alert";

export interface MemberDialogProps {
    open: boolean;
    member?: User;
    onClose: () => void;
}


export default function MemberDialog(props: MemberDialogProps) {

    const { onClose, open, member } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [selection, setSelection] = useState<User | undefined>(member);
    const [alertError, setAlertError] = useState<string | undefined>(undefined);

    const { data } = useQuery<MePayload, MeInput>(ME, {
        variables: {},
    });

    const [removeFromTeam, { }] = useMutation<RemoveFromTeamPayload, RemoveFromTeamInput>(REMOVE_FROM_TEAM, {
        refetchQueries: ["Team"],
    });

    useEffect(() => {
        if (!!member) {
            setSelection(member);
        }
    }, [member]);

    const handleClose = () => {
        onClose();
    };

    const handleRemoveTeamMember = async () => {

        try {
            setLoading(true);

            const { data } = await removeFromTeam({
                variables: {
                    input: {
                        teamMemberId: member!.id,
                    },
                },
            });

            if (!!(data?.removeFromTeam)) {
                if (data.removeFromTeam.ok) {
                    handleClose();
                } else {

                }
            }
        }
        catch (error) {
            setAlertError((error as any).message as string);
        }
        finally {
            setLoading(false);
        }

    };

    return (
        <Dialog fullWidth maxWidth="sm" onClose={handleClose} open={open}>
            <DialogTitle>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    Member profile
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            color: "text.secondary",
                        }}
                    >
                        <CancelIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent dividers>
                <Stack direction="column" spacing={1}>
                    {!!alertError && <Alert severity='error'>{alertError}</Alert>}
                    <Stack alignItems="center" direction="row" spacing={1}><Avatar src={selection?.avatarUrl} variant="rounded" /><Typography>{selection?.name}</Typography></Stack>
                    <Stack direction="row" spacing={1}>
                        <Typography color="text.secondary" variant="body2" fontWeight={600}>{selection?.role}</Typography>
                        <Typography color="text.secondary" variant="body2">{selection?.email}</Typography>
                    </Stack>
                </Stack>
            </DialogContent>
            {data?.me.role === "admin" && data?.me.id != selection?.id && <DialogActions sx={{ p: 2 }}>
                <LoadingButton loading={loading} disabled={loading} size="small" variant="contained" color="error" onClick={handleRemoveTeamMember}>Remove from team</LoadingButton>
            </DialogActions>}
        </Dialog>
    );
}
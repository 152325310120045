import Box from "@mui/material/Box";
import TeamGrid from "components/team/TeamGrid";

function Team() {
  return (
    <Box>
      <TeamGrid />
    </Box>);
}

export default Team;

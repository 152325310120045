import Box from "@mui/material/Box";
import AddressBookGrid from "components/address_book/AddressBookGrid";

function AddressBook() {
  return (
    <Box>
      <AddressBookGrid />
    </Box>
  );
}

export default AddressBook;

import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import useTheme from "@mui/material/styles/useTheme";
import Button, { LoadingButton } from "theme/Button";
import Card from "theme/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import { useQuery, useMutation } from "@apollo/client";
import CUSTOMER, {
    CustomerInput,
    CustomerPayload,
} from "graphql/queries/CustomerQuery";
import UPDATE_CUSTOMER_DETAILS, {
    UpdateCustomerDetailsInput,
    UpdateCustomerDetailsPayload,
} from "graphql/mutations/UpdateCustomerDetailsMutation";
import UPDATE_FIELD_TO_DEFAULT, {
    UpdateFieldToDefaultInput,
    UpdateFieldToDefaultPayload,
} from "graphql/mutations/UpdateFieldToDefaultMutation";
import UPDATE_MAILING_ADDRESS_TO_DEFAULT, {
    UpdateMailingAddressToDefaultInput,
    UpdateMailingAddressToDefaultPayload,
} from "graphql/mutations/UpdateMailingAddressToDefaultMutation";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import TextField from 'theme/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AddCircleIcon from '@mui/icons-material/Add';
import CircularProgress from "@mui/material/CircularProgress";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AddFieldDialog, { FieldType } from "./AddFieldDialog";
import * as EmailValidator from "email-validator";
import { useForm, Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import IconButton from '@mui/material/IconButton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { parsePhoneNumber } from "libphonenumber-js";
import Field from "types/Field";
import MailingAddress from "types/MailingAddress";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import EditMailingAddressDialog from "./EditMailingAddressDialog";
import Chip from "theme/Chip";
import CardActions from "@mui/material/CardActions";

type Params = {
    id: string;
};

function CustomerPanel() {

    const theme = useTheme();

    const { id } = useParams<Params>();

    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const [selectedMailingAddressId, setSelectedMailingAddressId] = useState<string | undefined>(undefined);
    const [editing, setEditing] = useState<boolean>(false);
    const [alertError, setAlertError] = useState<string | undefined>(undefined);
    const [selectedFieldType, setSelectedFieldType] = useState<FieldType | undefined>(undefined);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const fieldDialogOpen = Boolean(selectedFieldType);
    const open = Boolean(anchorEl);

    const [savingChanges, setSavingChanges] = useState<boolean>(false);

    const {
        control,
        handleSubmit,
        getValues,
        setValue,
        reset,
        formState: { errors, isDirty },
    } = useForm();

    const { data, error, loading, refetch } = useQuery<CustomerPayload, CustomerInput>(CUSTOMER, {
        variables: {
            id: id
        },
    });

    const [updateCustomer, { }] = useMutation<UpdateCustomerDetailsPayload, UpdateCustomerDetailsInput>(UPDATE_CUSTOMER_DETAILS, { refetchQueries: ["Customer", "Customers", "Conversation", "Conversations"] });
    const [updateFieldToDefault, { }] = useMutation<UpdateFieldToDefaultPayload, UpdateFieldToDefaultInput>(UPDATE_FIELD_TO_DEFAULT, { refetchQueries: ["Customer", "Customers", "Conversation", "Conversations"] });
    const [updateMailingAddressToDefault, { }] = useMutation<UpdateMailingAddressToDefaultPayload, UpdateMailingAddressToDefaultInput>(UPDATE_MAILING_ADDRESS_TO_DEFAULT, { refetchQueries: ["Customer", "Customers", "Conversation", "Conversations"] });

    const customer = data?.customer;

    const [emails, setEmails] = useState<Field[]>(customer?.fields.nodes.filter((field) => field.type === "email") ?? [])
    const [phoneNumbers, setPhoneNumbers] = useState<Field[]>(customer?.fields.nodes.filter((field) => field.type === "phone_number") ?? [])
    const [addresses, setAddresses] = useState<MailingAddress[]>(customer?.mailingAddresses.nodes ?? [])

    const handleFieldTypeSelection = (type: FieldType) => {
        setAnchorEl(null);
        setSelectedFieldType(type);
    };

    const handleFieldTypeDialogClose = () => {
        setSelectedFieldType(undefined);
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSubmit = async (data: any) => {

        if (!!customer) {

            setSavingChanges(true);

            const firstName = data.firstName as string | undefined;
            const lastName = data.lastName as string | undefined;
            const customerNotes = data.notes as string | undefined;
            const oldFieldIDs = customer.fields.nodes.map(o => o.id);
            const currentFieldIDs = [...phoneNumbers.map(o => o.id), ...emails.map(o => o.id)];

            const fieldsRemoved = oldFieldIDs.filter(o => currentFieldIDs.indexOf(o) === -1);
            const fieldUpdates = currentFieldIDs.map(o => { return { id: o, value: data[o] as string } });

            try {
                const { data: updateData, errors } = await updateCustomer({
                    variables: {
                        input: {
                            id: customer.id,
                            firstName: firstName,
                            lastName: lastName,
                            customerNotes: customerNotes,
                            fieldUpdates: fieldUpdates,
                            fieldsRemoved: fieldsRemoved
                        }
                    }
                });

                if (!!errors && errors.length > 0) {
                    setAlertError(errors[0].message);
                }

                if (!!updateData && !!updateData.updateCustomerDetails.customer) {
                    setEditing(false);
                    reset(data);
                    enqueueSnackbar('Customer details updated');
                    refetch();
                }
            }
            catch { }
            finally {
                setSavingChanges(false);
            }
        }
    }

    const makeFieldDefault = async (field: Field) => {
        setSavingChanges(true);

        try {
            const { data: updateData, errors } = await updateFieldToDefault({
                variables: {
                    input: {
                        id: field.id
                    }
                }
            });

            if (!!errors && errors.length > 0) {
                setAlertError(errors[0].message);
            }

            if (!!updateData && !!updateData.updateFieldToDefault?.field) {
                setEditing(false);
                reset(data);
                enqueueSnackbar('Customer details updated');
                refetch();
            }
        }
        catch { }
        finally {
            setSavingChanges(false);
        }
    }

    const makeAddressDefault = async (address: MailingAddress) => {
        setSavingChanges(true);

        try {
            const { data: updateData, errors } = await updateMailingAddressToDefault({
                variables: {
                    input: {
                        id: address.id
                    }
                }
            });

            if (!!errors && errors.length > 0) {
                setAlertError(errors[0].message);
            }

            if (!!updateData && !!updateData.updateMailingAddressToDefault?.mailingAddress) {
                setEditing(false);
                reset(data);
                enqueueSnackbar('Customer details updated');
                refetch();
            }
        }
        catch { }
        finally {
            setSavingChanges(false);
        }
    }

    useEffect(() => {
        if (isDirty) {
            setEditing(true);
        }
    }, [isDirty]);

    useEffect(() => {
        if (!!customer) {
            setEmails(customer.fields.nodes.filter((field) => field.type === "email"));
            setPhoneNumbers(customer.fields.nodes.filter((field) => field.type === "phone_number"));
            setAddresses(customer.mailingAddresses.nodes);
        }
    }, [customer]);

    return (
        <>
            <AddFieldDialog open={fieldDialogOpen} onClose={() => handleFieldTypeDialogClose()} customer={customer} type={selectedFieldType} />
            <EditMailingAddressDialog mailingAddressId={selectedMailingAddressId} open={Boolean(selectedMailingAddressId)} onClose={() => setSelectedMailingAddressId(undefined)} />
            <Menu
                variant="menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        width: 200,
                        outline: "none",
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: theme.spacing(1),
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem
                    onClick={() => {
                        handleFieldTypeSelection(FieldType.email);
                    }}
                >
                    <Typography variant="body2">Email</Typography>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleFieldTypeSelection(FieldType.phone_number);
                    }}
                >
                    <Typography variant="body2">Phone number</Typography>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleFieldTypeSelection(FieldType.address);
                    }}
                >
                    <Typography variant="body2">Address</Typography>
                </MenuItem>
                {false && <MenuItem
                    onClick={() => {
                        setAnchorEl(null);
                        enqueueSnackbar('Upgrade to Pro Plan for custom fields feature');
                    }}
                >
                    <Typography variant="body2">Custom field</Typography>
                </MenuItem>}
            </Menu>
            {loading && (
                <Stack alignItems="center" direction="column" spacing={2} p={2} sx={{ maxWidth: 948 }}>
                    <CircularProgress />
                    <Typography
                        color="text.secondary"
                        align="center"
                        variant="subtitle2"
                    >
                        Loading customer
                    </Typography>
                </Stack>
            )}
            {!!alertError && <Alert severity="error" sx={{ maxWidth: 948 }}>{alertError}</Alert>}
            {!!error && <Alert severity="error" sx={{ maxWidth: 948 }}>{error.message}</Alert>}
            {!!customer &&
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack direction="column" sx={{ maxWidth: 948 }} spacing={1}>
                        <Stack direction="row" spacing={1} mb={1} sx={{
                            color: theme.palette.text.secondary, mr: {
                                xs: 0,
                                sm: 0,
                                md: 2,
                                lg: 2,
                            }
                        }}>
                            <div style={{ flexGrow: 1 }} />
                            {!editing && <Button onClick={handleClick} color="secondary" size="small" variant="contained" startIcon={<AddCircleIcon />}>Add new field</Button>}
                            {editing && <Button size="small" onClick={() => {
                                if (isDirty) {
                                    history.go(0);
                                } else {
                                    setEditing(false);
                                }
                            }}>Cancel</Button>}
                            {editing && <LoadingButton size="small" onClick={handleSubmit(onSubmit)} type="submit" disabled={!isDirty} loading={savingChanges} color="success" variant="contained">Save changes</LoadingButton>}
                        </Stack>
                        <Stack direction="row" flexWrap="wrap">
                            <Card variant="outlined" sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "300px",
                                    lg: "300px",
                                }, mr: {
                                    xs: 0,
                                    sm: 0,
                                    md: 2,
                                    lg: 2,
                                }, mb: 2
                            }}>
                                <Stack direction="column" spacing={2} padding={2}>
                                    {!!customer.name && <Typography variant="h5">{customer.name}</Typography>}
                                    {!!customer.email && <Typography variant="body2">{customer.email}</Typography>}
                                </Stack>
                            </Card>
                            <Card variant="outlined" sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "300px",
                                    lg: "300px",
                                }, mr: {
                                    xs: 0,
                                    sm: 0,
                                    md: 2,
                                    lg: 2,
                                }, mb: 2
                            }}>
                                <Stack direction="column" spacing={1} padding={2} alignItems="center">
                                    <Stack direction="row" spacing={2}>
                                        <Stack direction="column" spacing={1} alignItems="center">
                                            <Typography fontWeight={700} variant="caption" color="text.secondary">Active</Typography>
                                            <Typography variant="h5">{customer.numberOfActiveConversations}</Typography>
                                        </Stack>
                                        <Stack direction="column" spacing={1} alignItems="center">
                                            <Typography fontWeight={700} variant="caption" color="text.secondary">Resolved</Typography>
                                            <Typography variant="h5">{customer.numberOfResolvedConversations}</Typography>
                                        </Stack>
                                        <Stack direction="column" spacing={1} alignItems="center">
                                            <Typography fontWeight={700} variant="caption" color="text.secondary">Messages</Typography>
                                            <Typography variant="h5">{customer.numberOfMessages}</Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Card>
                            <Card variant="outlined" sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "300px",
                                    lg: "300px",
                                }, mr: {
                                    xs: 0,
                                    sm: 0,
                                    md: 2,
                                    lg: 2,
                                }, mb: 2
                            }}>
                                <Stack direction="column" spacing={1} padding={2} alignItems="center">
                                    <Stack direction="row" spacing={2}>
                                        <Stack direction="column" spacing={1} alignItems="center">
                                            <Typography fontWeight={700} variant="caption" color="text.secondary">Value</Typography>
                                            <Typography variant="h5">{customer.totalSpend}</Typography>
                                        </Stack>
                                        <Stack direction="column" spacing={1} alignItems="center">
                                            <Typography fontWeight={700} variant="caption" color="text.secondary">Orders</Typography>
                                            <Typography variant="h5">{customer.numberOfOrders}</Typography>
                                        </Stack>
                                        <Stack direction="column" spacing={1} alignItems="center">
                                            <Typography fontWeight={700} variant="caption" color="text.secondary">Items</Typography>
                                            <Typography variant="h5">{customer.numberOfOrderItems}</Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack direction="row" spacing={2}>
                                        <Stack direction="column" spacing={1} alignItems="center">
                                            <Typography fontWeight={700} variant="caption" color="text.secondary">Highest value order item</Typography>
                                            <Typography variant="body2" align="center">{customer.mostExpensiveItem || "n/a"}</Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack direction="row" spacing={2}>
                                        <Stack direction="column" spacing={1} alignItems="center">
                                            <Typography fontWeight={700} variant="caption" color="text.secondary">Most ordered item</Typography>
                                            <Typography variant="body2" align="center">{customer.mostOrderedItem || "n/a"}</Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Card>
                            <Card variant="outlined" sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "465px",
                                    lg: "465px",
                                }, mr: {
                                    xs: 0,
                                    sm: 0,
                                    md: 2,
                                    lg: 2,
                                }, mb: 2
                            }}>
                                <Stack direction="column" spacing={2} padding={2}>
                                    <Stack direction="row" alignItems="center">
                                        <Typography fontWeight={700} variant="caption" color="text.secondary">Contact details</Typography>
                                    </Stack>
                                    <Stack direction="row" spacing={1}>
                                        <FormControl variant="outlined">
                                            <Controller
                                                name="firstName"
                                                render={({ field: { onChange, value } }) => (
                                                    <TextField
                                                        size="small"
                                                        helperText={errors.firstName ? errors.firstName.message : null}
                                                        variant="outlined"
                                                        placeholder="First name"
                                                        error={!!errors.firstName}
                                                        onChange={onChange}
                                                        value={value}
                                                    />
                                                )}
                                                control={control}
                                                defaultValue={customer.firstName}
                                                rules={{
                                                    required: "Please enter a first name",
                                                    pattern: {
                                                        value: /^\S{3,}/i,
                                                        message: "Minimum three characters",
                                                    }
                                                }}
                                            />
                                        </FormControl>
                                        <FormControl variant="outlined">
                                            <Controller
                                                name="lastName"
                                                render={({ field: { onChange, value } }) => (
                                                    <TextField
                                                        size="small"
                                                        helperText={errors.lastName ? errors.lastName.message : null}
                                                        variant="outlined"
                                                        placeholder="Last name"
                                                        error={!!errors.lastName}
                                                        onChange={onChange}
                                                        value={value}
                                                    />
                                                )}
                                                control={control}
                                                defaultValue={customer.lastName}
                                            />
                                        </FormControl>
                                    </Stack>
                                    {emails.map((field) => (
                                        <div key={field.id}>
                                            <FormControl fullWidth variant="outlined">
                                                <Controller
                                                    name={field.id}
                                                    render={({ field: { onChange, value } }) => (
                                                        <TextField
                                                            InputProps={{
                                                                startAdornment: (
                                                                    !field.isDefault ? <InputAdornment position="start"><Chip size="small" disabled={savingChanges} color="default" label="make default" onClick={() => makeFieldDefault(field)} /></InputAdornment> : null
                                                                ),
                                                                endAdornment: (
                                                                    (emails.length > 1 && !field.isDefault) ? <InputAdornment position="end">
                                                                        <IconButton size="small" color="error" edge="end" onClick={() => {
                                                                            const idx = emails.indexOf(field);
                                                                            if (idx > -1) {
                                                                                setEmails([
                                                                                    ...emails.slice(0, idx),
                                                                                    ...emails.slice(idx + 1)
                                                                                ]);
                                                                                setValue(field.id, undefined, { shouldDirty: true });
                                                                            }
                                                                        }}>
                                                                            <RemoveCircleIcon />
                                                                        </IconButton>
                                                                    </InputAdornment> : null
                                                                )
                                                            }}
                                                            size="small"
                                                            placeholder="Email address"
                                                            helperText={errors[field.id] ? errors[field.id].message : null}
                                                            variant="outlined"
                                                            error={!!errors[field.id]}
                                                            onChange={onChange}
                                                            value={value}
                                                        />
                                                    )}
                                                    control={control}
                                                    defaultValue={field.value}
                                                    rules={{
                                                        required: "Please enter an email address",
                                                        validate: (value) => EmailValidator.validate(value) || "Invalid email address",
                                                    }}
                                                />
                                            </FormControl>
                                        </div>
                                    ))}
                                    {phoneNumbers.map((field) => (
                                        <div key={field.id}>
                                            <FormControl fullWidth variant="outlined">
                                                <Controller
                                                    name={field.id}
                                                    render={({ field: { onChange, value } }) => (
                                                        <TextField
                                                            InputProps={{
                                                                startAdornment: (
                                                                    !field.isDefault ? <InputAdornment position="start"><Chip size="small" disabled={savingChanges} color="default" label="make default" onClick={() => makeFieldDefault(field)} /></InputAdornment> : null
                                                                ),
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton size="small" color="error" edge="end" onClick={() => {
                                                                            const idx = phoneNumbers.indexOf(field);
                                                                            if (idx > -1) {
                                                                                setPhoneNumbers([
                                                                                    ...phoneNumbers.slice(0, idx),
                                                                                    ...phoneNumbers.slice(idx + 1)
                                                                                ]);
                                                                                setValue(field.id, undefined, { shouldDirty: true });
                                                                            }
                                                                        }}>
                                                                            <RemoveCircleIcon />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                            size="small"
                                                            placeholder="Phone number"
                                                            helperText={errors[field.id] ? errors[field.id].message : null}
                                                            variant="outlined"
                                                            error={!!errors[field.id]}
                                                            onChange={onChange}
                                                            value={value}
                                                        />
                                                    )}
                                                    control={control}
                                                    defaultValue={field.value}
                                                    rules={{
                                                        required: "Please enter a phone number",
                                                        validate: (value) => {
                                                            try {
                                                                const result = parsePhoneNumber(value).isPossible() || "Invalid phone number";
                                                                return result;
                                                            } catch {
                                                                return "Invalid phone number";
                                                            }
                                                        }
                                                    }}
                                                />
                                            </FormControl>
                                        </div>
                                    ))}
                                </Stack>
                            </Card>
                            <Card variant="outlined" sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "450px",
                                    lg: "450px",
                                }, mr: {
                                    xs: 0,
                                    sm: 0,
                                    md: 2,
                                    lg: 2,
                                }, mb: 2
                            }}>
                                <Stack direction="column" spacing={2} padding={2}>
                                    <Stack direction="row" alignItems="center">
                                        <Typography fontWeight={700} variant="caption" color="text.secondary">Addresses</Typography>
                                        <div style={{ flexGrow: 1 }} />
                                    </Stack>
                                    {addresses.map((field) => (
                                        <Card key={field.id} variant="outlined">
                                            <CardActionArea onClick={() => setSelectedMailingAddressId(field.id)}>
                                                <CardContent>
                                                    <Typography variant="body2" sx={{ textTransform: "capitalize" }}>{field.formatted}</Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    ))}
                                    {addresses.length === 0 && <Typography variant="body2" color="text.secondary">None</Typography>}
                                </Stack>
                            </Card>
                            {false && <Card variant="outlined" sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "300px",
                                    lg: "300px",
                                }, mr: {
                                    xs: 0,
                                    sm: 0,
                                    md: 2,
                                    lg: 2,
                                }, mb: 2
                            }}>
                                <Stack direction="column" spacing={2} padding={2}>
                                    <Stack direction="row" alignItems="center">
                                        <Typography fontWeight={700} variant="caption" color="text.secondary">Custom fields</Typography>
                                        <div style={{ flexGrow: 1 }} />
                                    </Stack>
                                    {customer?.fields.nodes
                                        .filter((field) => field.type.includes("c__"))
                                        .map((field) => (
                                            <div key={field.id}>
                                                <FormControl fullWidth variant="outlined">
                                                    <Controller
                                                        name={field.id}
                                                        render={({ field: { onChange, value } }) => (
                                                            <TextField
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        editing ? <InputAdornment position="end">
                                                                            <IconButton size="small" color="error" edge="end" onClick={() => {
                                                                                setValue(field.id, undefined, { shouldDirty: true });
                                                                            }}>
                                                                                <RemoveCircleIcon />
                                                                            </IconButton>
                                                                        </InputAdornment> : null
                                                                    )
                                                                }}
                                                                multiline
                                                                size="small"
                                                                placeholder="Address"
                                                                helperText={errors[field.id] ? errors[field.id].message : null}
                                                                variant="outlined"
                                                                error={!!errors[field.id]}
                                                                onChange={onChange}
                                                                value={value}
                                                            />
                                                        )}
                                                        control={control}
                                                        defaultValue={field.value}
                                                        rules={{
                                                            required: "Please enter an address",
                                                        }}
                                                    />
                                                </FormControl>
                                            </div>
                                        ))}
                                    {customer?.fields.nodes
                                        .filter((field) => field.type.includes("c__")).length === 0 && <Typography variant="body2" color="text.secondary">None</Typography>}
                                </Stack>
                            </Card>}
                            <Card variant="outlined" sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "615px",
                                    lg: "615px",
                                }, mr: {
                                    xs: 0,
                                    sm: 0,
                                    md: 2,
                                    lg: 2,
                                }, mb: 2
                            }}>
                                <Stack direction="column" spacing={2} padding={2}>
                                    <FormControl fullWidth variant="outlined">
                                        <Controller
                                            name="notes"
                                            render={({ field: { onChange, value } }) => (
                                                <TextField
                                                    multiline
                                                    minRows={5}
                                                    size="small"
                                                    helperText={errors.notes ? errors.notes.message : null}
                                                    variant="outlined"
                                                    placeholder="Notes"
                                                    error={!!errors.notes}
                                                    onChange={(e) => {
                                                        setValue('notes', getValues("notes"), { shouldDirty: true });
                                                        onChange(e);
                                                    }}
                                                    value={value}
                                                />
                                            )}
                                            control={control}
                                            defaultValue={customer.notes}
                                        />
                                    </FormControl>
                                </Stack>
                            </Card>
                        </Stack>
                    </Stack>
                </form>}
        </>
    );
}

export default CustomerPanel;
import { gql } from "@apollo/client";
import Workflow from "types/Workflow";
import WorkflowFragment from "../fragments/WorkflowFragment";

export interface UpdateWorkflowInput {
  input: {
    id: string;
    name: string;
    privateWorkflow: boolean;
    resolvesConversation: boolean;
    templateId: string;
  };
}

export interface UpdateWorkflowPayload {
  updateWorkflow: {
    workflow?: Workflow;
  };
}

const UPDATE_WORKFLOW = gql`
  mutation UpdateWorkflow($input: UpdateWorkflowMutationInput!) {
    updateWorkflow(input: $input) {
      workflow {
        ...WorkflowFields
        template {
            id
            createdAt
            name
        }
      }
    }
  }
  ${WorkflowFragment}
`;

export default UPDATE_WORKFLOW;
import { gql } from "@apollo/client";
import ResponderEmailAddress from "types/ResponderEmailAddress";

export interface CreateResponderEmailAddressInput {
  input: {
    address: string;
    domainId: string;
  };
}

export interface CreateResponderEmailAddressPayload {
  createResponderEmailAddress: {
    errors: string[];
    responderEmailAddress?: ResponderEmailAddress;
  };
}

const CREATE_RESPONDER_EMAIL_ADDRESS = gql`
  mutation CreateResponderEmailAddress($input: CreateResponderEmailAddressMutationInput!) {
    createResponderEmailAddress(input: $input) {
      errors
      responderEmailAddress {
        id
      }
    }
  }
`;

export default CREATE_RESPONDER_EMAIL_ADDRESS;
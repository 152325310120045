import { useState } from 'react';
import Stack from '@mui/material/Stack';
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import Dialog from '@mui/material/Dialog';
import Customer from "types/Customer";
import { useMutation } from "@apollo/client";
import Button, { LoadingButton } from "theme/Button";
import Typography from "@mui/material/Typography";
import { useSnackbar } from 'notistack';
import Alert from "@mui/material/Alert";
import DialogActions from '@mui/material/DialogActions';
import DELETE_CUSTOMER, {
    DeleteCustomerInput,
    DeleteCustomerPayload,
} from "graphql/mutations/DeleteCustomerMutation";
import { useHistory } from "react-router-dom";
import { useStoreon } from 'storeon/react';
import State from "store/State";

export interface Props {
    open: boolean;
    customer?: Customer;
    onClose: () => void;
}

export default function DeleteCustomerDialog(props: Props) {

    const { onClose, open, customer } = props;

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState<boolean>(false);
    const [alertError, setAlertError] = useState<string | undefined>(undefined);

    const [deleteCustomer, { }] = useMutation<DeleteCustomerPayload, DeleteCustomerInput>(DELETE_CUSTOMER, { refetchQueries: ["Customer", "Customers", "Conversation", "Conversations"] });
    const { dispatch } = useStoreon<State>();

    const handleClose = () => {
        if (loading) { return; }
        onClose();
    };

    const handleDeleteCustomer = async () => {

        if (!!customer) {

            setLoading(true);

            try {
                const { data: deleteData, errors } = await deleteCustomer({
                    variables: {
                        input: {
                            id: customer.id,
                        }
                    }
                });

                if (!!errors && errors.length > 0) {
                    setAlertError(errors[0].message);
                }

                if (!!deleteData && !!deleteData.deleteCustomer) {
                    enqueueSnackbar('Customer details are being removed');
                    handleClose();
                    dispatch("conversations/removeAll");
                    history.push("/conversations")
                }
            } catch { }

            setLoading(false);
        }
    }

    return (
        <Dialog onClose={handleClose} open={open} maxWidth="xs">
            <DialogTitle>Delete Customer details</DialogTitle>
            <DialogContent dividers>
                {!!alertError && <Alert sx={{ ml: 2, mb: 2, mr: 2 }} severity="error"><Typography sx={{ wordBreak: "break-word" }} variant="body2">{alertError}</Typography></Alert>}
                <Stack direction="column" spacing={2}>
                    <Typography variant='body2'>Deleting the Customer profile will mean all conversations, interactions and order data associated with the Customer will also be removed.</Typography>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button size="small" disabled={loading} variant="text" color="inherit" onClick={handleClose}>Close</Button>
                <LoadingButton size="small" loading={loading} variant="contained" color="error" onClick={handleDeleteCustomer}>Delete</LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
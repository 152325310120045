import { gql } from "@apollo/client";
import MailingAddress from "types/MailingAddress";
import MailingAddressFragment from "../fragments/MailingAddressFragment";

export interface MailingAddressInput {
    id: string;
}

export interface MailingAddressPayload {
    mailingAddress?: MailingAddress;
}

const MAILING_ADDRESS = gql`
  query MailingAddress($id: ID!) {
    mailingAddress(id: $id) {
        ...MailingAddressFields
    }
  }
  ${MailingAddressFragment}
`;

export default MAILING_ADDRESS;
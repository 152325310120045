import { useEffect, useState } from "react";
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import Stack from '@mui/material/Stack';
import Button from 'theme/Button';
import Dialog from '@mui/material/Dialog';
import { useMutation } from "@apollo/client";
import IconButton from '@mui/material/IconButton';
import CancelIcon from "@mui/icons-material/Cancel";
import { useSnackbar } from 'notistack';
import { useForm, Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import CREATE_LAYOUT, { CreateLayoutInput, CreateLayoutPayload } from "graphql/mutations/CreateLayoutMutation";
import { useHistory } from "react-router-dom";
import TextField from 'theme/TextField';
import Alert from '@mui/material/Alert';
import useTheme from "@mui/material/styles/useTheme";

export interface NewLayoutDialogProps {
    open: boolean;
    onClose: () => void;
}

export default function NewLayoutDialog(props: NewLayoutDialogProps) {

    const {
        control,
        trigger,
        handleSubmit,
        reset,
        formState: { errors, isValid },
    } = useForm();

    const [alertError, setAlertError] = useState<string | undefined>(undefined);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const theme = useTheme();

    const [createLayout, _] = useMutation<
        CreateLayoutPayload,
        CreateLayoutInput
    >(CREATE_LAYOUT, {});

    const { onClose, open } = props;

    const handleClose = () => {
        onClose();
    };

    const onSubmit = async (formData: { [x: string]: any; }) => {
        try {
            const { data } = await createLayout({
                variables: {
                    input: {
                        name: formData.layout_name,
                    }
                }
            });

            if (!!data?.createLayout.layout) {
                enqueueSnackbar(`Created Layout`);
                history.push(`/layout/${data.createLayout.layout.id}`)
            } else if (!!data?.createLayout && data.createLayout.errors.length > 0) {
                setAlertError(data.createLayout.errors[0]);
            }
        } catch (e: unknown) {
            if (e instanceof Error) {
                setAlertError(e.message);
            }
        }
    };

    useEffect(() => {
        if (!open) {
            reset();
        }
    }, [open]);

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="xs">
            <DialogTitle sx={{ m: 0, p: 2, pl: 3, pr: 12 }}>New email layout
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 12,
                        color: "text.secondary",
                    }}
                >
                    <CancelIcon />
                </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent dividers sx={{ backgroundColor: theme.palette.background.default }}>
                    <Stack direction="column" spacing={2}>
                        {!!alertError && <Alert severity="error">{alertError}</Alert>}
                        <FormControl variant="outlined" sx={{ pt: 1 }}>
                            <Controller
                                name="layout_name"
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        id="layout_name"
                                        type="layout_name"
                                        size="small"
                                        helperText={errors.layout_name ? errors.layout_name.message : null}
                                        variant="outlined"
                                        placeholder="Name of layout"
                                        error={!!errors.layout_name}
                                        onChange={(e) => { onChange(e); trigger("layout_name"); }}
                                        value={value}
                                    />
                                )}
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "Please enter a name for the layout",
                                }}
                            />
                        </FormControl>
                    </Stack>
                </DialogContent>
                <Stack direction="row" justifyContent="flex-end" sx={{ p: 2 }}>
                    <div>
                        <Button size="small" type="submit" disabled={!isValid} fullWidth color="success" variant="contained">Continue</Button>
                    </div>
                </Stack>
            </form>
        </Dialog>
    );
}
import { gql } from "@apollo/client";
import EmailDomain from "types/EmailDomain";

export interface EmailDomainUpdatedInput {
  emailDomainId: string;
}

export interface EmailDomainUpdatedPayload {
  emailDomainUpdated?: {
    emailDomain?: EmailDomain;
  };
}

const EMAIL_DOMAIN_UPDATED = gql`
  subscription EmailDomainUpdated($emailDomainId: ID!) {
    emailDomainUpdated(emailDomainId: $emailDomainId) {
      emailDomain {
        id
        createdAt
        created
        forwardingEnabled
      }
    }
  }
`;

export default EMAIL_DOMAIN_UPDATED;
import Card from "@mui/material/Card";
import Button from "theme/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useHistory } from "react-router-dom";
import DialogTitle from "theme/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "theme/DialogContent";
import Typography from "@mui/material/Typography";
import ME, {
    MeInput,
    MePayload,
} from "graphql/queries/MeQuery";
import { useQuery, useSubscription } from "@apollo/client";
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import USER_UPDATED, {
    UserUpdatedInput,
    UserUpdatedPayload,
} from "graphql/subscriptions/UserUpdatedSubscription";
import User from "types/User";

function WelcomeToPaidPlanWrapper() {

    const { data: meData, refetch } = useQuery<MePayload, MeInput>(ME, {
        variables: {}, pollInterval: 1000,
    });

    if (!!(meData?.me)) {
        return <WelcomeToPaidPlan me={meData.me} refetch={refetch} />
    }

    return <div />;
}

function WelcomeToPaidPlan(props: { me: User, refetch: any }) {

    const { me } = props;

    const [ready, setReady] = useState<boolean>(me.planRequired === false);

    let history = useHistory();

    const { data: userUpdatedData } = useSubscription<UserUpdatedPayload, UserUpdatedInput>(USER_UPDATED, {
        variables: { userId: me.id },
    });

    useEffect(() => {
        if (!!(userUpdatedData?.userUpdated?.user)) {
            if (!userUpdatedData.userUpdated.user.planRequired) {
                setReady(true);
            }
        }
    }, [userUpdatedData]);

    useEffect(() => {
        if (!me.planRequired) {
            setReady(true);
        }
    }, [me]);

    return (
        <Box sx={{ maxWidth: 400 }}>
            <Card elevation={5}>
                <DialogTitle>Thank you 🙏</DialogTitle>
                <DialogContent dividers>
                    <Stack spacing={2} direction="column">
                        <Typography variant="subtitle2" color="text.secondary">zenshop sincerely appreciates you joining a paid plan, we hope you enjoy your experience 🔥</Typography>
                        {!ready && <Typography variant="subtitle2" color="text.secondary">Hang tight one moment whilst we verify the transaction 🚀</Typography>}
                        {!ready && <Stack direction="column" alignItems="center" justifyContent="center"><CircularProgress /></Stack>}
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button disabled={!ready} size="small" variant="contained" color="success" type="submit" onClick={() => {
                        if (me.selectedOrg?.hasEmailDomain === true) {
                            history.push("/conversations");
                        } else {
                            history.push("/getting-started-email");
                        }
                    }}>
                        Continue
                    </Button>
                </DialogActions>
            </Card>
        </Box >
    );
}

export default WelcomeToPaidPlanWrapper;
import { gql } from "@apollo/client";

export interface DeleteOrgInput {
    input: {
        orgId: string;
    };
}

export interface DeleteOrgPayload {
    deleteOrg?: {
        errors: string[];
        ok?: boolean;
    };
}

const DELETE_ORG = gql`
  mutation DeleteOrg($input: DeleteOrgMutationInput!) {
    deleteOrg(input: $input) {
      errors
      ok
    }
  }
`;

export default DELETE_ORG;

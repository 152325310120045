import { useState, useEffect } from "react";
import { GridRowsProp, GridColDef, GridSelectionModel, GridSortModel, GridSortDirection } from "@mui/x-data-grid-pro";
import { NoScrollZenDataGrid } from "theme/DataGrid";
import Checkbox from "theme/Checkbox";
import { useQuery } from "@apollo/client";
import ORDERS, {
    OrdersInput,
    OrdersPayload,
    OrdersFilter,
    OrdersSortInput,
} from "graphql/queries/OrdersQuery";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { renderDate } from "components/conversations/renderers/RenderDate";
import { renderCustomer } from "components/conversations/renderers/RenderCustomer";
import { renderOrderStatus } from "components/conversations/renderers/RenderOrderStatus";
import TextField from "theme/TextField";
import useTheme from "@mui/material/styles/useTheme";
import { LeftAlignedButton } from "theme/Button";
import Button from "theme/Button";
import BackspaceIcon from '@mui/icons-material/Backspace';
import useQueryParams from "hooks/useQueryParams";
import InputAdornment from '@mui/material/InputAdornment';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { useHistory } from "react-router";

function OrdersGrid() {

    const theme = useTheme();
    const query = useQueryParams();
    const history = useHistory();

    const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [filter, setFilter] = useState<OrdersFilter>((query.get("filter") || "ALL") as OrdersFilter)
    const [page, setPage] = useState<number>(Number(query.get("page")) ?? 0);
    const [after, setAfter] = useState<string | undefined>(query.get("after") ?? undefined);
    const [before, setBefore] = useState<string | undefined>(query.get("before") ?? undefined);
    const [sort, setSort] = useState<OrdersSortInput | undefined>({ date: "DESC" });

    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'date',
            sort: 'desc' as GridSortDirection,
        },
    ]);

    const { data, error, loading, refetch } = useQuery<
        OrdersPayload,
        OrdersInput
    >(ORDERS, {
        variables: {
            after: after,
            before: before,
            first: !after && !before ? 15 : !!after ? 15 : undefined,
            last: !!before ? 15 : undefined,
            filters: {
                searchQuery: searchQuery,
                filter: filter,
                sort: sort,
            },
        },
    });

    const rows: GridRowsProp = !!data
        ? data.orders.nodes.map((row) => {
            return {
                id: row.id,
                number: row.name,
                date: row.createdAt,
                customer: row?.customer,
                value: row.currentSubtotalPriceSet.shopAmount,
                payment: [row.financialStatus, !row.unpaid],
                fulfillment: [row.fulfillmentStatus, row.fulfilled],
                items: row.lineItemsCount,
                line_items_formatted: row.lineItemsFormatted,
            };
        })
        : [];

    const columns: GridColDef[] = [
        {
            field: "number",
            headerName: "Order #",
            width: 100,
        },
        {
            field: "date",
            headerName: "Date",
            width: 150,
            renderCell: renderDate
        },
        {
            field: "customer",
            headerName: "Customer",
            width: 200,
            renderCell: renderCustomer
        },
        {
            field: "value",
            headerName: "Value",
            width: 120,
        },
        {
            field: "line_items_formatted",
            headerName: "Items",
            width: 200,
        },
        {
            field: "items",
            headerName: "# Items",
            width: 120,
        },
        {
            field: "payment",
            headerName: "Payment",
            width: 150,
            renderCell: renderOrderStatus
        },
        {
            field: "fulfillment",
            headerName: "Fulfillment",
            width: 150,
            renderCell: renderOrderStatus
        },
    ];

    function navigateToFilter(filter: OrdersFilter) {
        setPage(0);
        setFilter(filter);
        setAfter(undefined);
        setBefore(undefined);
    };

    function navigateToAfter(after: string, page: number) {
        setPage(page);
        setAfter(after);
        setBefore(undefined);
    };

    function navigateToBefore(before: string, page: number) {
        setPage(page);
        setAfter(undefined);
        setBefore(before);
    };

    useEffect(() => {
        setPage(0);
        setBefore(undefined);
        setAfter(undefined);
        refetch();
    }, [sort, searchQuery, filter]);

    return (
        <Box sx={{ display: "flex" }}>
            <Box sx={{ width: 300, p: theme.spacing(2) }}>
                <Stack spacing={1}>
                    <LeftAlignedButton
                        color={filter === OrdersFilter.all ? "primary" : "secondary"}
                        variant={filter === OrdersFilter.all ? "contained" : "text"}
                        onClick={() => navigateToFilter(OrdersFilter.all)}
                    >
                        <span style={{ width: "100%", textAlign: "left" }}>All</span>
                    </LeftAlignedButton>
                    <LeftAlignedButton
                        color={filter === OrdersFilter.unfulfilled ? "primary" : "secondary"}
                        variant={filter === OrdersFilter.unfulfilled ? "contained" : "text"}
                        onClick={() => navigateToFilter(OrdersFilter.unfulfilled)}
                    >
                        <span style={{ width: "100%", textAlign: "left" }}>Unfulfilled</span>
                    </LeftAlignedButton>
                    <LeftAlignedButton
                        color={filter === OrdersFilter.unpaid ? "primary" : "secondary"}
                        variant={filter === OrdersFilter.unpaid ? "contained" : "text"}
                        onClick={() => navigateToFilter(OrdersFilter.unpaid)}
                    >
                        <span style={{ width: "100%", textAlign: "left" }}>Unpaid</span>
                    </LeftAlignedButton>
                    <LeftAlignedButton
                        color={filter === OrdersFilter.open ? "primary" : "secondary"}
                        variant={filter === OrdersFilter.open ? "contained" : "text"}
                        onClick={() => navigateToFilter(OrdersFilter.open)}
                    >
                        <span style={{ width: "100%", textAlign: "left" }}>Open</span>
                    </LeftAlignedButton>
                    <LeftAlignedButton
                        color={filter === OrdersFilter.closed ? "primary" : "secondary"}
                        variant={filter === OrdersFilter.closed ? "contained" : "text"}
                        onClick={() => navigateToFilter(OrdersFilter.closed)}
                    >
                        <span style={{ width: "100%", textAlign: "left" }}>Closed</span>
                    </LeftAlignedButton>
                </Stack>
            </Box>
            <Stack direction="column" spacing={2} p={2} sx={{ p: theme.spacing(2), pl: 0, width: "calc(100% - 300px)", height: "min-content" }}>
                {!!error && <Alert severity="error">{error.message}</Alert>}
                <Stack direction="row" spacing={1} alignItems="center">
                    <TextField value={searchQuery} size="small" placeholder="Filter results"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FilterListOutlinedIcon />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => setSearchQuery(e.target.value)}></TextField>
                    {(!!searchQuery) &&
                        <div style={{ color: theme.palette.text.secondary }}>
                            <Button onClick={() => { setSearchQuery(""); }} variant="outlined" startIcon={<BackspaceIcon />} color="inherit">Clear applied search filters</Button>
                        </div>}
                </Stack>
                <Stack direction="column" style={{ minHeight: 635 }}>
                    <NoScrollZenDataGrid
                        style={{ height: "100%" }}
                        disableColumnPinning
                        sortingOrder={['asc', 'desc']}
                        sortModel={sortModel}
                        onSortModelChange={(model) => {
                            if (model.length > 0) {
                                const field = model[0];
                                const sort = field.sort;
                                if (!!sort) {
                                    switch (field.field) {
                                        case "number": {
                                            setSort({ name: sort.toUpperCase() as "ASC" | "DESC" });
                                            break;
                                        }
                                        case "date": {
                                            setSort({ date: sort.toUpperCase() as "ASC" | "DESC" });
                                            break;
                                        }
                                        case "customer": {
                                            setSort({ customerName: sort.toUpperCase() as "ASC" | "DESC" });
                                            break;
                                        }
                                        case "value": {
                                            setSort({ value: sort.toUpperCase() as "ASC" | "DESC" });
                                            break;
                                        }
                                        case "line_items_formatted": {
                                            setSort({ lineItems: sort.toUpperCase() as "ASC" | "DESC" });
                                            break;
                                        }
                                        case "payment": {
                                            setSort({ payment: sort.toUpperCase() as "ASC" | "DESC" });
                                            break;
                                        }
                                        case "fulfillment": {
                                            setSort({ fulfillment: sort.toUpperCase() as "ASC" | "DESC" });
                                            break;
                                        }
                                        case "items": {
                                            setSort({ itemCount: sort.toUpperCase() as "ASC" | "DESC" });
                                            break;
                                        }
                                    }
                                } else {
                                    setSort(undefined);
                                }
                            } else {
                                setSort(undefined);
                            }
                            setSortModel(model);
                        }}
                        components={{
                            BaseCheckbox: Checkbox,
                            NoResultsOverlay: () => (<div />),
                        }}
                        disableColumnFilter
                        disableColumnReorder
                        disableColumnSelector
                        rows={rows}
                        columns={columns}
                        loading={!data}
                        page={page}
                        onPageChange={(newPage) => {
                            if (loading) {
                                return;
                            }
                            if (newPage > page) {
                                navigateToAfter(data!.orders.pageInfo.endCursor, newPage);
                            } else {
                                navigateToBefore(data!.orders.pageInfo.startCursor, newPage);
                            }
                            setPage(newPage);
                        }}
                        rowHeight={35}
                        pageSize={15}
                        rowsPerPageOptions={[15]}
                        rowCount={!!(data?.orders) ? data.orders.pageInfo.totalCount : 0}
                        paginationMode="server"
                        sortingMode="server"
                        pagination
                        disableSelectionOnClick
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        onRowClick={(row) => {
                            history.push(`/order/${row.id}`)
                        }}
                        selectionModel={selectionModel}
                    />
                </Stack>
            </Stack>
        </Box>
    );
}

export default OrdersGrid;
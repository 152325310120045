import { gql } from "@apollo/client";

export interface StartEmailConversationInput {
    input: {
        email: string;
        subject?: string;
        messageHtml: string;
        attachedFileIds: string[];
        layoutId?: string;
    };
}

export interface StartEmailConversationPayload {
    startEmailConversation: {
        ok: Boolean;
    };
}

const START_EMAIL_CONVERSATION = gql`
  mutation StartEmailConversation($input: StartEmailConversationMutationInput!) {
    startEmailConversation(input: $input) {
        ok
    }
  }
`;

export default START_EMAIL_CONVERSATION;

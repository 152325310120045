import { useState, useEffect, useCallback } from "react";
import { useDropzone, FileRejection, DropEvent } from 'react-dropzone';
import Button from "theme/Button";
import Stack from "@mui/material/Stack";
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import { v4 as uuidv4 } from 'uuid';
import CircularProgress from "@mui/material/CircularProgress";
import { useMutation, useQuery } from "@apollo/client";
import SIGN_FILE_UPLOAD, {
    SignFileUploadInput,
    SignFileUploadPayload,
} from "graphql/mutations/SignFileUploadMutation";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FormData from "form-data";
import axios from "axios";
import SELECTED_ORG, {
    SelectedOrgInput,
    SelectedOrgPayload,
} from "graphql/queries/SelectedOrgQuery";

interface FileUpload {
    key: string;
    name: string;
    uploading: boolean;
    completed: boolean;
    file: File;
    errors?: string[];
    uploadUrl?: string;
    uploadFields?: [key: string];
}

const ListItem = styled('li')(({ theme }) => ({
    margin: 0,
}));

function Dropzone(props: { exclusive?: boolean, shortcut: string, privateFile: boolean, temporaryFile: boolean, didUploadFile?: (error?: Error) => void }) {

    const { exclusive, didUploadFile, shortcut, privateFile, temporaryFile } = props;
    const isExclusive = exclusive || false;
    const [deleted, setDeleted] = useState<string[]>([]);
    const [uploads, setUploads] = useState<FileUpload[]>([]);
    const [needsUpload, setNeedsUpload] = useState<string[]>([]);
    const [fileAlert, setFileAlert] = useState<string | undefined>(undefined);

    const { data } = useQuery<SelectedOrgPayload, SelectedOrgInput>(SELECTED_ORG, {
        variables: {},
    });

    const [signFile, { }] = useMutation<SignFileUploadPayload, SignFileUploadInput>(SIGN_FILE_UPLOAD, {});

    const doUpload = useCallback(async (uploadFile: FileUpload, file: File) => {
        try {
            if (!!uploadFile.uploadFields && !!uploadFile.uploadUrl && !uploadFile.uploading && !uploadFile.completed) {
                uploadFile.uploading = true;
                setUploads([...uploads.filter((o) => deleted.indexOf(o.key) === -1)]);
                const form = new FormData();
                Object.entries(uploadFile.uploadFields).forEach(([field, value]) => {
                    form.append(field, value);
                });
                form.append("file", file);
                const response = await axios
                    .post(uploadFile.uploadUrl, form, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                uploadFile.completed = true;
                if (response.status == 201) {
                    uploadFile.uploading = false;
                    if (!!didUploadFile) {
                        didUploadFile(undefined)
                    }
                    setUploads([...uploads.filter((o) => deleted.indexOf(o.key) === -1)]);
                } else {
                    uploadFile.uploading = false;
                    uploadFile.errors = ['There was a problem uploading this file'];
                    if (!!didUploadFile) {
                        didUploadFile(new Error('There was a problem uploading this file'))
                    }
                    setUploads([...uploads.filter((o) => deleted.indexOf(o.key) === -1)]);
                }
            }
        } catch (error) {
            uploadFile.completed = true;
            uploadFile.uploading = false;
            uploadFile.errors = ['There was a problem uploading this file'];
            if (!!didUploadFile) {
                didUploadFile(new Error('There was a problem uploading this file'))
            }
            setUploads([...uploads.filter((o) => deleted.indexOf(o.key) === -1)]);
        }
    }, [uploads, deleted]);

    const onDrop = useCallback(async (acceptedFiles: File[], rejectedFiles: FileRejection[], _: DropEvent) => {
        if (rejectedFiles.length > 0) {
            setFileAlert('Some files were rejected because they were too large (50MB limit)');
        } else {
            setFileAlert(undefined);
        }
        if (acceptedFiles.length == 0) {
            return;
        }
        let newUploads = acceptedFiles.map((file) => {
            let upload: FileUpload = { name: file.name, uploading: false, completed: false, key: uuidv4(), file: file };
            return upload;
        });
        for (let i = 0; i < newUploads.length; i++) {
            let upload = newUploads[i];

            try {
                const { data, errors } = await signFile({
                    variables: {
                        input: {
                            userAvatar: false,
                            fileName: upload.name,
                            privateFile: privateFile,
                            temporaryFile: temporaryFile,
                        }
                    }
                });

                if (!!errors) {
                    upload.errors = errors.map((error) => error.message);
                }

                if (!!data) {
                    if (data.signFileUpload.errors.length > 0) {
                        upload.errors = data.signFileUpload.errors;
                        upload.uploading = false;
                    } else {
                        upload.uploadUrl = data.signFileUpload.uploadUrl;
                        upload.uploadFields = data.signFileUpload.uploadFields;
                    }
                }
            } catch (error) {
                upload.errors = ['There was a problem uploading this file'];
                upload.uploading = false;
            }
        }
        let update = [...uploads, ...newUploads];
        let toBeUploaded = newUploads.map((o) => o.key);
        setUploads(update);
        setNeedsUpload(toBeUploaded);
    }, [uploads, deleted, shortcut, privateFile, temporaryFile]);

    const { getRootProps, getInputProps, open } = useDropzone({
        noClick: true,
        noKeyboard: true,
        onDrop: onDrop,
        maxSize: 52428800000,
        multiple: !isExclusive
    });

    const handleDelete = (uploadToDelete: FileUpload) => () => {
        setDeleted([uploadToDelete.key, ...deleted]);
        setUploads((uploads) => uploads.filter((upload) => upload.key !== uploadToDelete.key));
    };

    useEffect(() => {
        for (let upload of uploads) {
            if (!!upload.errors) {
                setFileAlert(upload.errors[0]);
            }
        }
    }, [uploads]);

    useEffect(() => {
        for (let key of needsUpload) {
            let toBeUploaded = uploads.find((o) => o.key === key);
            if (!!toBeUploaded && deleted.indexOf(key) === -1) {
                doUpload(toBeUploaded, toBeUploaded.file);
            }
        }
    }, [needsUpload, uploads, deleted]);

    return (
        <Stack direction="column" spacing={1}>
            {!!fileAlert && <Alert color="error">{fileAlert}</Alert>}
            <Stack direction={exclusive ? "column" : "row"} spacing={1} alignItems="center">
                {!!data && !!data.selectedOrg && data.selectedOrg.isPaidPlan && <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <Button disabled={uploads.filter((o) => !o.completed).length > 0} color={isExclusive ? "success" : "inherit"} variant={isExclusive ? "contained" : "outlined"} size="small" onClick={open}>{isExclusive ? "Upload file" : "Attach files"}</Button>
                </div>}
                {!!data && !!data.selectedOrg && !data.selectedOrg.isPaidPlan && <Tooltip title="Upgrade to Pro plan to attach files.">
                    <Button color="inherit" variant="outlined" size="small">Attach files</Button>
                </Tooltip>}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'left',
                        flexWrap: 'wrap',
                        listStyle: 'none',
                        m: 0,
                    }}
                >
                    {uploads.map((upload) => {
                        let chipIcon;
                        if (upload.uploading) {
                            chipIcon = <Stack alignItems="center"><CircularProgress color="primary" size={15} /></Stack>;
                        } else if (!!upload.errors) {
                            chipIcon = <Stack alignItems="center"><ErrorOutlineIcon color="error" fontSize={"small"} /></Stack>;
                        } else {
                            chipIcon = <div></div>;
                        }
                        return (
                            <ListItem key={upload.key}>
                                <Chip
                                    icon={chipIcon}
                                    label={upload.name}
                                    onDelete={handleDelete(upload)}
                                    size="small"
                                />
                            </ListItem>
                        );
                    })}
                </Box>
            </Stack>
        </Stack>
    );
}

export default Dropzone;
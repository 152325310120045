import gql from "graphql-tag";

const DiscordIntegrationFragment = gql`
  fragment DiscordIntegrationFields on DiscordIntegration {
    id
    createdAt
    accessToken
  }
`;

export default DiscordIntegrationFragment;

import { gql } from "@apollo/client";

export interface TemplateAutofillValuesInput {
  conversationId?: string;
}

export interface TemplateAutofillValuesPayload {
  templateAutofillValues?: [key: string];
}

const TEMPLATE_AUTOFILL_VALUES = gql`
  query TemplateAutofillValues($conversationId: ID) {
    templateAutofillValues(conversationId: $conversationId) 
  }
`;

export default TEMPLATE_AUTOFILL_VALUES;
import { useState, useEffect } from "react";
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from 'theme/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CancelIcon from "@mui/icons-material/Cancel";
import TextField from 'theme/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { useQuery, useMutation } from "@apollo/client";
import LABELS, { LabelsInput, LabelsPayload } from "graphql/queries/LabelsQuery";
import BULK_LABEL_CONVERSATION, {
    BulkLabelConversationInput,
    BulkLabelConversationPayload,
} from "graphql/mutations/BulkLabelConversationMutation";
import Label from "types/Label";
import { GridColDef, GridSelectionModel, GridCellParams } from '@mui/x-data-grid-pro';
import DataGrid from "theme/DataGrid";
import Checkbox from "theme/Checkbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export function renderLabel(params: GridCellParams) {
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Box
                component="span"
                sx={{
                    width: 14,
                    height: 14,
                    flexShrink: 0,
                    borderRadius: "3px",
                    mr: 1,
                    mt: "2px",
                }}
                style={{ backgroundColor: params.value["colorHex"] }}
            />
            <Typography variant="body2">{params.value["title"]}</Typography>
        </Stack>);
}

interface Props {
    open: boolean;
    conversationIds: string[];
    onClose: () => void;
}

const columns: GridColDef[] = [
    {
        field: 'label',
        headerName: 'Label',
        renderCell: renderLabel,
        flex: 1
    }
];

export default function LabelsDialog(props: Props) {

    const [searchInput, setSearchInput] = useState<string>("");
    const [alertError, setAlertError] = useState<string | undefined>(undefined);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

    const { onClose, open, conversationIds } = props;

    const { data, error } = useQuery<LabelsPayload, LabelsInput>(LABELS, {
        variables: { filters: { sort: { title: "ASC" } } },
    });

    const [bulkLabelConversation, { loading }] = useMutation<BulkLabelConversationPayload, BulkLabelConversationInput>(BULK_LABEL_CONVERSATION,
        { refetchQueries: ["Conversations", "Conversation", "SideBarQuery"] }
    );

    const handleClose = () => {
        onClose();
        setTimeout(() => {
            setAlertError(undefined);
            setSelectionModel([]);
            setSearchInput("");
        }, 200);
    };

    const handleSubmit = async () => {
        try {
            const { data } = await bulkLabelConversation({
                variables: {
                    input: {
                        ids: conversationIds,
                        labelIds: selectionModel as string[]
                    }
                }
            });

            if (!!data) {
                if (data.bulkLabelConversation.errors.length > 0) {
                    setAlertError('There was a problem performing this bulk action');
                } else {
                    handleClose();
                }
            }
        } catch {
            setAlertError('There was a problem performing this bulk action');
        }
    };

    const labels: Label[] = data?.labels?.nodes ?? [];

    let rows = labels.map((label) => {
        return {
            id: label.id,
            label: {
                title: label.title,
                colorHex: label.colorHex,
            }
        }
    });

    if (searchInput.length > 0) {
        rows = rows.filter((obj) => obj.label.title.toLowerCase().includes(searchInput.toLowerCase()))
    }

    useEffect(() => {
        if (!!error) {
            setAlertError("Unable to fetch team");
        } else {
            setAlertError(undefined);
        }
    }, [error]);

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm" scroll="paper">
            <DialogTitle sx={{ m: 0, p: 2, pr: 8, pl: 3 }}>
                <TextField
                    fullWidth
                    size="small"
                    placeholder="Filter labels"
                    onChange={(e) => setSearchInput(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FilterListOutlinedIcon />
                            </InputAdornment>
                        ),
                    }} />
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 20,
                        top: 16,
                        color: "text.secondary",
                    }}
                >
                    <CancelIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={1}>
                    {!!alertError && <Alert severity="error" title={alertError}></Alert>}
                    <div style={{ height: 300, width: '100%' }}>
                        <DataGrid
                            density="compact"
                            rows={rows}
                            selectionModel={selectionModel}
                            onSelectionModelChange={(newSelectionModel) => {
                                setSelectionModel(newSelectionModel);
                            }}
                            columns={columns}
                            checkboxSelection
                            hideFooter
                            disableColumnMenu
                            disableColumnReorder
                            disableColumnResize
                            disableChildrenSorting
                            components={{
                                BaseCheckbox: Checkbox,
                                NoResultsOverlay: () => (<div />),
                            }}
                        />
                    </div>
                </Stack>
            </DialogContent>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            </Box>
            <DialogActions sx={{ p: 2 }}>
                <Button disabled={selectionModel.length === 0 || loading} onClick={handleSubmit} variant="contained" color="success" size="small">Confirm</Button>
            </DialogActions>
        </Dialog>
    );
}
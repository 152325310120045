import { gql } from "@apollo/client";
import User from "types/User";
import UserFragment from "../fragments/UserFragment";
import OrgFragment from "../fragments/OrgFragment";

export interface SelectOrgInput {
    input: {
        id: string;
    };
}

export interface SelectOrgPayload {
    selectOrg: {
        errors: string[];
        user: User;
    };
}

const SELECT_ORG = gql`
  mutation SelectOrg($input: SelectOrgMutationInput!) {
    selectOrg(input: $input) {
      errors
      user {
        ...UserFields
        selectedOrg {
            ...OrgFields
        }
      }
    }
  }
  ${UserFragment},
  ${OrgFragment}
`;

export default SELECT_ORG;

import { gql } from "@apollo/client";

export interface BulkMarkAsSpamInput {
    input: {
        ids: string[];
        spam: boolean;
    };
}

export interface BulkMarkAsSpamPayload {
    bulkMarkAsSpam: {
        errors: string[];
        ok: boolean;
    };
}

const BULK_MARK_AS_SPAM = gql`
  mutation BulkMarkAsSpam($input: BulkMarkAsSpamMutationInput!) {
    bulkMarkAsSpam(input: $input) {
      errors
      ok
    }
  }
`;

export default BULK_MARK_AS_SPAM;

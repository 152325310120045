import Card from "@mui/material/Card";
import Button from "theme/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useHistory } from "react-router-dom";
import DialogTitle from "theme/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "theme/DialogContent";
import Typography from "@mui/material/Typography";
import ME, {
    MeInput,
    MePayload,
} from "graphql/queries/MeQuery";
import { useQuery } from "@apollo/client";
import { motion } from "framer-motion/dist/framer-motion";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function GettingStartedJoinCommunityCard() {

    let history = useHistory();

    const { data } = useQuery<MePayload, MeInput>(ME, {
        variables: {},
    });

    return (
        <Box sx={{ maxWidth: 400 }}>
            <motion.div initial={{ opacity: 0, scale: 0 }} viewport={{ once: true }} whileInView={{ opacity: 1, scale: 1 }} transition={{ delay: .5 }}>
                <Card elevation={5}>
                    <DialogTitle>We&apos;re here to help</DialogTitle>
                    <DialogContent dividers>
                        <Stack spacing={2} direction="column">
                            <Typography variant="subtitle2" color="text.secondary">Everyone at zenshop welcomes you, and we&apos;re here to help you get started.</Typography>
                            <Stack spacing={1} direction="column">
                                <Button onClick={() => {
                                    window.open(process.env.REACT_APP_DISCORD_URL, "_blank");
                                }} size="small" variant="text" color="inherit">Join our Discord community</Button>
                                <Button onClick={() => {
                                    window.open(process.env.REACT_APP_DOCS_URL, "_blank");
                                }} size="small" variant="text" color="inherit">Read our getting started guides</Button>

                            </Stack>
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ p: 2 }}>
                        <Button startIcon={<ArrowForwardIcon />} size="small" variant="contained" color="secondary" type="submit" onClick={() => {
                            if (data?.me?.role === "admin") {
                                history.push("/articles?selection=messenger");
                            } else {
                                history.push("/conversations");
                            }
                        }}>
                            Setup Helpdesk Messenger
                        </Button>
                    </DialogActions>
                </Card>
            </motion.div>
        </Box >
    );
}

export default GettingStartedJoinCommunityCard;
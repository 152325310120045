import gql from "graphql-tag";

const ShopifyMailingAddressFragment = gql`
  fragment ShopifyMailingAddressFields on ShopifyMailingAddress {
    id
    createdAt
    formatted
  }
`;

export default ShopifyMailingAddressFragment;

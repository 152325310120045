import { useState, useEffect } from "react";
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button, { LoadingButton } from 'theme/Button';
import Dialog from '@mui/material/Dialog';
import TextField from 'theme/TextField';
import { useMutation, useQuery } from "@apollo/client";
import EMAIL_DOMAINS, { EmailDomainsInput, EmailDomainsPayload } from "graphql/queries/EmailDomainsQuery";
import CREATE_RESPONDER_EMAIL_ADDRESS, { CreateResponderEmailAddressInput, CreateResponderEmailAddressPayload } from "graphql/mutations/CreateResponderEmailAddressMutation";
import Typography from "@mui/material/Typography";
import { useForm, Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import useTheme from "@mui/material/styles/useTheme";
import Select from "theme/Select";
import MenuItem from '@mui/material/MenuItem';

interface Props {
    open: boolean;
    onClose: () => void;
}

export default function NewEmailAddressDialog(props: Props) {

    const { onClose, open } = props;

    const [address, setAddress] = useState<string>("yourname");
    const [loading, setLoading] = useState<boolean>(false);
    const [alertError, setAlertError] = useState<string | undefined>(undefined);
    const theme = useTheme();

    const { error: domainsError, data: domainsData } = useQuery<EmailDomainsPayload, EmailDomainsInput>(EMAIL_DOMAINS, {});

    const uniqueDomains = !!domainsData ? Array.from(new Map(domainsData.emailDomains.nodes.map(item => [item.subDomain, item])).values()) : [];

    const {
        control,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm();

    const [createResponderEmailAddress, { error }] = useMutation<CreateResponderEmailAddressPayload, CreateResponderEmailAddressInput>(CREATE_RESPONDER_EMAIL_ADDRESS, { refetchQueries: ["ResponderEmailAddresses"] });

    useEffect(() => {
        if (!!error) {
            setAlertError(error.message);
        } else {
            setAlertError(undefined);
        }
    }, [error]);

    useEffect(() => {
        if (!!uniqueDomains && uniqueDomains.length > 0) {
            setValue("email_domain", uniqueDomains[0].id)
        }
    }, [uniqueDomains]);

    const handleClose = () => {
        if (loading) {
            return;
        }
        onClose();
        setTimeout(() => {
            reset();
            setAlertError(undefined);
        }, 200);
    };

    const onSubmit = async (formData: { [x: string]: any; }) => {
        try {
            setLoading(true);

            const { data } = await createResponderEmailAddress({
                variables: {
                    input: {
                        address: address,
                        domainId: formData.email_domain || domainsData?.emailDomains.nodes[0].id
                    }
                }
            });

            if (!!data) {
                if (data.createResponderEmailAddress.errors.length > 0) {
                    setAlertError(data.createResponderEmailAddress.errors[0]);
                } if (!!data.createResponderEmailAddress.responderEmailAddress) {
                    handleClose();
                }
            }
        } catch {
            setAlertError('There was a problem creating this conversation type');
        } finally {
            setLoading(false);
        }
    };

    let options = uniqueDomains.map((o) => <MenuItem key={o.id} value={o.id}>{o.subDomain}</MenuItem>);

    return (
        <Dialog onClose={() => handleClose()} open={open} scroll="paper" maxWidth="sm" fullWidth>
            <DialogTitle>
                <Typography variant="h5">Your new personal mailbox</Typography>
            </DialogTitle>
            <DialogContent dividers sx={{ backgroundColor: theme.palette.background.default }}>
                <Stack direction="column" spacing={2}>
                    <Typography color="text.secondary" variant="subtitle2">
                        Choose a unique address to receive email with zenshop.
                    </Typography>
                    {!!alertError && <Alert severity="error" icon={false}>{alertError}</Alert>}
                    {!!domainsError && <Alert severity="error" icon={false}>{domainsError}</Alert>}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <FormControl variant="outlined" fullWidth>
                                <Controller
                                    name="mailing"
                                    rules={{
                                        required: true,
                                        minLength: 3,
                                        maxLength: 150,
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            id="mailing"
                                            autoComplete="off"
                                            type='text'
                                            sx={{ backgroundColor: theme.palette.background.paper }}
                                            size="small"
                                            helperText={errors.mailing ? errors.mailing.message : undefined}
                                            variant="outlined"
                                            placeholder={`yourname`}
                                            error={!!errors.mailing}
                                            onChange={(e) => {
                                                const v = e.currentTarget.value.replace(/[^a-z]/g, '')
                                                setAddress(v);
                                                onChange(v);
                                            }}
                                            value={value}
                                        />
                                    )}

                                    control={control}
                                />
                            </FormControl>
                            <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 600 }}>
                                @
                            </Typography>
                            <FormControl variant="outlined" fullWidth>
                                <Controller
                                    name="email_domain"
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            labelId="email_domain"
                                            id="email_domain"
                                            value={value}
                                            onChange={onChange}
                                            size="small"
                                        >
                                            {options}
                                        </Select>
                                    )}
                                    control={control}
                                />
                            </FormControl>
                        </Stack>
                    </form>
                </Stack>
            </DialogContent >
            <DialogActions sx={{ p: 2 }}>
                <Button disabled={loading} size="small" onClick={() => handleClose()} color="inherit">Close</Button>
                <LoadingButton onClick={handleSubmit(onSubmit)} size="small" disabled={loading} loading={loading} variant="contained" color="success">Create</LoadingButton>
            </DialogActions>
        </Dialog >
    );
}
import { useState, useEffect } from "react";
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from 'theme/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CancelIcon from "@mui/icons-material/Cancel";
import OrgFile from 'types/OrgFile';
import { useApolloClient } from "@apollo/client";
import ORG_FILE_DOWNLOAD, { OrgFileInput, OrgFilePayload } from "graphql/queries/OrgFileDownloadQuery";

export interface Props {
    open: boolean;
    fileId?: string;
    onClose: () => void;
}

export default function FileDetailsDialog(props: Props) {

    const [orgFile, setOrgFile] = useState<OrgFile | undefined>(undefined);
    const [alertError, setAlertError] = useState<string | undefined>(undefined);
    const { onClose, open, fileId } = props;
    const client = useApolloClient();

    useEffect(() => {
        if (!!fileId) {
            try {
                client.query<OrgFilePayload, OrgFileInput>({ query: ORG_FILE_DOWNLOAD, fetchPolicy: "no-cache", variables: { id: fileId } })
                    .then(({ data }) => {
                        if (!!data && !!data.orgFile) {
                            setOrgFile(data.orgFile);
                        }
                    });
            } catch (error) {
                setAlertError('There was a problem loading this file.')
            }
        }
    }, [fileId]);

    const handleClose = () => {
        onClose();
        setTimeout(() => {
            setAlertError(undefined);
        }, 200);
    };

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    Information
                    <IconButton onClick={handleClose} sx={{ color: "text.secondary" }}>
                        <CancelIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent dividers>
                <Stack spacing={2}>
                    {!!alertError && <Alert severity="error" title={alertError} />}
                    <Button
                        color="inherit"
                        variant="outlined"
                        size="small"
                        onClick={() => {
                            if (!!orgFile && !!orgFile.signedUrl) {
                                window.open(orgFile.signedUrl, "_blank");
                            }
                        }}>Download</Button>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button disabled={!Boolean(orgFile)} variant="contained" color="error" size="small">Delete file</Button>
            </DialogActions>
        </Dialog>
    );
}
import gql from "graphql-tag";

const MoneyBagFragment = gql`
  fragment MoneyBagFields on MoneyBag {
    id
    createdAt
    presentmentAmount
    shopAmount
  }
`;

export default MoneyBagFragment;

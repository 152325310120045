import { gql } from "@apollo/client";
import User from "types/User";
import PageInfo from "types/PageInfo";

export interface RecentlyActiveTeamMembersInput {
    first: number;
}

export interface RecentlyActiveTeamMembersPayload {
    recentlyActiveTeamMembers: {
        pageInfo: PageInfo;
        nodes: User[];
    };
}

const RECENTLY_ACTIVE_TEAM_MEMBERS = gql`
  query RecentlyActiveTeamMembers($first: Int) {
    recentlyActiveTeamMembers(first: $first) {
      pageInfo {
          totalCount
          startCursor
          endCursor
      }
      nodes {
          id
          createdAt
          name
          avatarUrl
      }
    }
  }
`;

export default RECENTLY_ACTIVE_TEAM_MEMBERS;
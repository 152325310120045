import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";

function SettingsMain() {
    return (
        <Stack direction="column" spacing={2}>
            <Alert icon={false} variant="outlined" color="info">
                <Stack direction="column" spacing={1}>
                    <Typography variant="subtitle2">Welcome to your Organizations settings</Typography>
                    <Typography variant="body2">If you&apos;re unsure how to configure something, don&apos;t hesitate to reach out.</Typography>
                </Stack>
            </Alert>
        </Stack>
    );
}

export default SettingsMain;
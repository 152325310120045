import { styled } from "@mui/material/styles";
import StepConnector, { StepConnectorProps } from "@mui/material/StepConnector";

const ZenStepConnector = styled(StepConnector)<StepConnectorProps>(({ theme }) => ({
    "& .MuiStepConnector-line": {
        minHeight: 0,
    },
}));

export default ZenStepConnector;

import { gql } from "@apollo/client";
import User from "types/User";

export interface UserUpdatedInput {
  userId: string;
}

export interface UserUpdatedPayload {
  userUpdated?: {
    user?: User;
  }
}

const USER_UPDATED = gql`
  subscription UserUpdated($userId: ID!) {
    userUpdated(userId: $userId) {
      user {
        id 
        avatarUrl
        avatarProcessing
        planRequired
        selectedOrg {
          id
          name
          hasEmailDomain
        }
      }
    }
  }
`;

export default USER_UPDATED;
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import useTheme from "@mui/material/styles/useTheme";
import Typography from "@mui/material/Typography";
import AvatarGroup from "theme/AvatarGroup";
import Chip from "theme/Chip";
import Activity, { ActivityType } from "types/Activity";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Link } from "react-router-dom";
import { avatar } from "types/User";
import Avatar from "theme/Avatar";

export type ActivityCardProps = {
    activity: Activity;
};

export function selfUnassigned(activity: Activity) {
    if (!activity.user) {
        return false;
    }
    return (
        activity.assigneesRemoved.nodes.length === 1 &&
        activity.user.id === activity.assigneesRemoved.nodes[0].id
    );
}

export function selfAssigned(activity: Activity) {
    if (!activity.user) {
        return false;
    }
    return (
        activity.assigneesAdded.nodes.length === 1 &&
        activity.user.id === activity.assigneesAdded.nodes[0].id
    );
}

export function AssigneesUpdatedActivity(props: ActivityCardProps) {
    const { activity } = props;
    const theme = useTheme();

    let content: React.ReactNode;

    if (selfAssigned(activity)) {
        content = (
            <Typography variant="caption" color="text.secondary">
                self assigned to the conversation
            </Typography>
        );
    } else if (selfUnassigned(activity)) {
        content = (
            <Typography variant="caption" color="text.secondary">
                removed themself from the conversation
            </Typography>
        );
    } else {
        content = (
            <>
                {activity.assigneesAdded.nodes.length > 0 && (
                    <>
                        <Typography variant="caption" color="text.secondary">
                            added
                        </Typography>
                        <AvatarGroup max={4}>
                            {activity.assigneesAdded.nodes.map((user) => (
                                <div key={user.id}>
                                    {avatar(user)}
                                </div>
                            ))}
                        </AvatarGroup>

                        <Typography variant="caption" color="text.secondary">
                            to the conversation
                        </Typography>
                    </>
                )}
                {activity.assigneesRemoved.nodes.length > 0 && (
                    <>
                        <Typography variant="caption" color="text.secondary">
                            {activity.assigneesAdded.nodes.length > 0 ? "and " : ""}removed
                        </Typography>
                        <AvatarGroup max={4}>
                            {activity.assigneesRemoved.nodes.map((user) => (
                                <div key={user.id}>
                                    {avatar(user)}
                                </div>
                            ))}
                        </AvatarGroup>
                        <Typography variant="caption" color="text.secondary">
                            from the conversation
                        </Typography>
                    </>
                )}
            </>
        );
    }
    return (
        <Box pl={theme.spacing(2)} pr={theme.spacing(2)}>
            <Stack direction="row" spacing={0.5} sx={{ alignItems: "center" }}>
                {!!activity.user && avatar(activity.user)}
                <Typography variant="caption" fontWeight={600} color="text.secondary">
                    {activity.user?.name}
                </Typography>
                {content}
            </Stack>
        </Box>
    );
}

export function LabelsUpdatedActivity(props: ActivityCardProps) {
    const { activity } = props;
    const theme = useTheme();
    if (activity.labelsAdded.nodes.length === 0 && activity.labelsRemoved.nodes.length === 0) {
        return null;
    }
    return (
        <Box pl={theme.spacing(2)} pr={theme.spacing(2)}>
            <Stack direction="row" spacing={0.5} sx={{ alignItems: "center" }}>
                {!!activity.user && <Box sx={{ mr: 0.5 }}>
                    {avatar(activity.user)}
                </Box>}
                <Typography variant="caption" fontWeight={600} color="text.secondary">
                    {activity.user?.name}
                </Typography>
                {activity.labelsAdded.nodes.length > 0 && (
                    <>
                        <Typography variant="caption" color="text.secondary">
                            added
                        </Typography>
                        {activity.labelsAdded.nodes.map((label) => (
                            <Chip
                                key={label.id}
                                size="small"
                                label={<Typography fontWeight={600} variant="caption">{label.title}</Typography>}
                                variant="filled"
                                sx={{
                                    bgcolor: label.colorHex,
                                    color: theme.palette.getContrastText(label.colorHex),
                                    mb: theme.spacing(1),
                                    mt: theme.spacing(0.5),
                                    ml: theme.spacing(0.5),
                                }}
                            />
                        ))}
                    </>
                )}
                {activity.labelsRemoved.nodes.length > 0 && (
                    <>
                        <Typography variant="caption" color="text.secondary">
                            {activity.labelsAdded.nodes.length > 0 ? "and " : ""}removed
                        </Typography>
                        {activity.labelsRemoved.nodes.map((label) => (
                            <Chip
                                key={label.id}
                                size="small"
                                label={<Typography fontWeight={600} variant="caption">{label.title}</Typography>}
                                variant="filled"
                                sx={{
                                    bgcolor: label.colorHex,
                                    color: theme.palette.getContrastText(label.colorHex),
                                    mb: theme.spacing(1),
                                    mt: theme.spacing(0.5),
                                    ml: theme.spacing(0.5),
                                }}
                            />
                        ))}
                    </>
                )}
            </Stack>
        </Box>
    );
}

export function SavedActivity(props: ActivityCardProps) {
    const { activity } = props;
    const theme = useTheme();
    return (
        <Box pl={theme.spacing(2)} pr={theme.spacing(2)}>
            <Stack direction="row" spacing={0.5} sx={{ alignItems: "center" }}>
                {!!activity.user && <Box sx={{ mr: 0.5 }}>
                    {avatar(activity.user)}
                </Box>}
                <Typography variant="caption" fontWeight={600} color="text.secondary">
                    {activity.user?.name}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                    {activity.activityType === ActivityType.saved ? "saved" : "unsaved"}{" "}
                    the conversation
                </Typography>
            </Stack>
        </Box>
    );
}

export function WaitingActivity(props: ActivityCardProps) {
    const { activity } = props;
    const theme = useTheme();
    return (
        <Box pl={theme.spacing(2)} pr={theme.spacing(2)}>
            <Stack direction="row" spacing={0.5} sx={{ alignItems: "center" }}>
                {!!activity.user && <Box sx={{ mr: 0.5 }}>
                    {avatar(activity.user)}
                </Box>}
                <Typography variant="caption" fontWeight={600} color="text.secondary">
                    {activity.user?.name}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                    {activity.activityType === ActivityType.waiting
                        ? "set the conversation to waiting"
                        : "set the conversation to no longer waiting"}
                </Typography>
            </Stack>
        </Box>
    );
}

export function ResolvedActivity(props: ActivityCardProps) {
    const { activity } = props;
    const theme = useTheme();
    return (
        <Box pl={theme.spacing(2)} pr={theme.spacing(2)}>
            <Stack direction="row" spacing={0.5} sx={{ alignItems: "center" }}>
                {!!activity.user && <Box sx={{ mr: 0.5 }}>
                    {avatar(activity.user)}
                </Box>}
                <Typography variant="caption" fontWeight={600} color="text.secondary">
                    {activity.user?.name || activity?.customer?.email || activity.customer?.name}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                    {activity.activityType === ActivityType.resolved
                        ? "set the conversation to resolved"
                        : "set the conversation back to in-progress"}
                </Typography>
            </Stack>
        </Box>
    );
}

export function SpamActivity(props: ActivityCardProps) {
    const { activity } = props;
    const theme = useTheme();
    return (
        <Box pl={theme.spacing(2)} pr={theme.spacing(2)}>
            <Stack direction="row" spacing={0.5} sx={{ alignItems: "center" }}>
                {!!activity.user && <Box sx={{ mr: 0.5 }}>
                    {avatar(activity.user)}
                </Box>}
                <Typography variant="caption" fontWeight={600} color="text.secondary">
                    {activity.user?.name}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                    {activity.activityType === ActivityType.spam
                        ? "marked the conversation as spam"
                        : "unmarked the conversation as spam"}
                </Typography>
            </Stack>
        </Box>
    );
}

export function TypeActivity(props: ActivityCardProps) {
    const { activity } = props;
    const theme = useTheme();
    return (
        <Box pl={theme.spacing(2)} pr={theme.spacing(2)}>
            <Stack direction="row" spacing={0.5} sx={{ alignItems: "center" }}>
                {!!activity.user && <Box sx={{ mr: 0.5 }}>
                    {avatar(activity.user)}
                </Box>}
                <Typography variant="caption" fontWeight={600} color="text.secondary">
                    {activity.user?.name}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                    changed conversation type to <strong style={{ fontWeight: 600 }}>{activity.newType?.title}</strong> (from <strong style={{ fontWeight: 600 }}>{activity.oldType?.title}</strong>)
                </Typography>
            </Stack>
        </Box>
    );
}

export function OrderMentionedActivity(props: ActivityCardProps) {
    const { activity } = props;
    const theme = useTheme();
    return (
        <Box pl={theme.spacing(2)} pr={theme.spacing(2)}>
            <Stack direction="row" spacing={0.5} sx={{ alignItems: "center" }}>
                <Avatar sx={{ mr: 0.5 }} variant="rounded"><ShoppingCartIcon /></Avatar>
                <Typography variant="caption" fontWeight={600} color="text.secondary">
                    Order <Link to={`/order/${activity.order!.id}`}><strong style={{ fontWeight: 600 }}>{activity.order!.name}</strong></Link>
                </Typography>
                <Typography variant="caption" color="text.secondary">
                    was automatically associated with this conversation because {activity.activityType === ActivityType.mentionedOrderByOrderNumber ? "order number" : "tracking number"} <strong style={{ fontWeight: 600 }}>{activity.mentionedOrderBy}</strong> was detected in this message
                </Typography>
            </Stack>
        </Box>
    );
}

export function DuplicatesActivity(props: ActivityCardProps) {
    const { activity } = props;
    let duplicates = activity.duplicatesMerged.nodes;
    const theme = useTheme();
    return (
        <Box pl={theme.spacing(2)} pr={theme.spacing(2)}>
            <Stack direction="row" spacing={0.5} sx={{ alignItems: "center" }}>
                {!!activity.user && <Box sx={{ mr: 0.5 }}>
                    {avatar(activity.user)}
                </Box>}
                <Typography variant="caption" fontWeight={600} color="text.secondary">
                    {activity.user?.name}
                </Typography>
                {activity.activityType === ActivityType.mergedDuplicatesInto && <Typography variant="caption" color="text.secondary">
                    merged messages from ({duplicates.map((o, i) => <><Link key={o.id} to={`/conversation/${o.id}`}><strong style={{ fontWeight: 600 }}>!{o.number}</strong></Link>{duplicates.length > 0 && i < duplicates.length - 1 ? ", " : ""}</>)}) into this conversation
                </Typography>}
                {activity.activityType === ActivityType.markedAsDuplicate && <Typography variant="caption" color="text.secondary">
                    merged this conversation into <Link to={`/conversation/${activity.parentConversation?.id}`}><strong style={{ fontWeight: 600 }}>!{activity.parentConversation?.number}</strong></Link>
                </Typography>}
            </Stack>
        </Box>
    );
}

export function HelpdeskMessengerOptionSelectedActivity(props: ActivityCardProps) {
    const { activity } = props;
    const theme = useTheme();
    return (
        <Box pl={theme.spacing(2)} pr={theme.spacing(2)}>
            <Stack direction="row" spacing={0.5} sx={{ alignItems: "center" }}>
                <Typography variant="caption" fontWeight={600} color="text.secondary">
                    {activity.customer?.name || activity.customer?.email}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                    selected <strong style={{ fontWeight: 600 }}>{activity.helpdeskMessengerOption?.questionText}</strong>
                </Typography>
            </Stack>
        </Box>
    );
}

export function AssociatedOrderActivity(props: ActivityCardProps) {
    const { activity } = props;
    const theme = useTheme();
    return (
        <Box pl={theme.spacing(2)} pr={theme.spacing(2)}>
            <Stack direction="row" spacing={0.5} sx={{ alignItems: "center" }}>
                {!!activity.user && <Box sx={{ mr: 0.5 }}>
                    {avatar(activity.user)}
                </Box>}
                <Typography variant="caption" fontWeight={600} color="text.secondary">
                    {activity.user?.name}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                    associated order <Link to={`/order/${activity.order!.id}`}><strong style={{ fontWeight: 600 }}>{activity.order!.name}</strong></Link> with this conversation
                </Typography>
            </Stack>
        </Box>
    );
}

export default function ActivityCard(props: ActivityCardProps) {
    const { activity } = props;
    switch (activity.activityType) {
        case ActivityType.labelsUpdated: {
            return <LabelsUpdatedActivity {...props} />;
        }
        case ActivityType.assignessUpdated: {
            return <AssigneesUpdatedActivity {...props} />;
        }
        case ActivityType.saved:
        case ActivityType.unsaved: {
            return <SavedActivity {...props} />;
        }
        case ActivityType.waiting:
        case ActivityType.unwaiting: {
            return <WaitingActivity {...props} />;
        }
        case ActivityType.resolved:
        case ActivityType.unresolved: {
            return <ResolvedActivity {...props} />;
        }
        case ActivityType.spam:
        case ActivityType.unspam: {
            return <SpamActivity {...props} />;
        }
        case ActivityType.updatedType: {
            return <TypeActivity {...props} />;
        }
        case ActivityType.mentionedOrderByOrderNumber:
        case ActivityType.mentionedOrderByTrackingOrderNumber: {
            return <OrderMentionedActivity {...props} />
        }
        case ActivityType.mergedDuplicatesInto:
        case ActivityType.markedAsDuplicate: {
            return <DuplicatesActivity {...props} />
        }
        case ActivityType.associatedOrderUpdated: {
            return <AssociatedOrderActivity {...props} />
        }
        case ActivityType.helpdeskMessengerOptionSelected: {
            return <HelpdeskMessengerOptionSelectedActivity {...props} />
        }
        default: {
            return null;
        }
    }
}
import { useState } from "react";
import Stack from "@mui/material/Stack";
import SELECTED_ORG, {
    SelectedOrgInput,
    SelectedOrgPayload,
} from "graphql/queries/SelectedOrgQuery";
import ME, {
    MeInput,
    MePayload,
} from "graphql/queries/MeQuery";
import { useQuery } from "@apollo/client";
import Card from "theme/Card";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "theme/Button";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Alert from "@mui/material/Alert";
import DeleteOrgDialog from "./DeleteOrgDialog";

function DeleteOrganisation() {

    const [deleteOrgOpen, setDeleteOrgOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [alertError, setAlertError] = useState<string | undefined>(undefined);

    const { data: orgData } = useQuery<SelectedOrgPayload, SelectedOrgInput>(SELECTED_ORG, {
        variables: {},
    });

    const { data: meData } = useQuery<MePayload, MeInput>(ME, {
        variables: {},
    });

    const handleDeleteOrganization = async () => {
        setDeleteOrgOpen(true);
    }

    return (
        <Card variant="outlined" sx={{ maxWidth: 948 }}>
            <DeleteOrgDialog open={deleteOrgOpen} onClose={() => setDeleteOrgOpen(false)} />
            <Stack direction="column" spacing={2} padding={2}>
                <Typography variant="h5">Delete organization data</Typography>
                <Typography variant="body2">
                    You can permanently delete all Customer, order and conversation information associated with this Organization. For large stores with many orders, customers and conversations, data deletion may take some time.
                </Typography>
                <Typography variant="body2">
                    You <strong>must be an Admin of the Organisation</strong> to proceed. Otherwise please contact support for help.
                </Typography>
                {!!alertError && <Alert severity='error'>{alertError}</Alert>}
                <div>
                    <LoadingButton disabled={loading || meData?.me?.role !== "admin"} loading={loading} onClick={handleDeleteOrganization} variant="contained" startIcon={<DeleteForeverIcon />} size="small" color="error">Delete {orgData?.selectedOrg?.name}&apos;s information</LoadingButton>
                </div>
            </Stack>
        </Card>
    );
}

export default DeleteOrganisation;
import { gql } from "@apollo/client";

export interface VisitConversationInput {
    input: {
        conversationId: string;
    };
}

export interface VisitConversationPayload {
    visitConversation: {
        errors: string[];
        ok?: boolean;
    };
}

const VISIT_CONVERSATION = gql`
  mutation VisitConversation($input: VisitConversationMutationInput!) {
    visitConversation(input: $input) {
        errors
        ok
    }
  }
`;

export default VISIT_CONVERSATION;
import { useState, useEffect } from "react";
import { GridRowsProp, GridColDef, GridSortModel, GridSortDirection } from "@mui/x-data-grid-pro";
import { NoScrollZenDataGrid } from "theme/DataGrid";
import Stack from "@mui/material/Stack";
import { useQuery } from "@apollo/client";
import CONVERSATIONS, {
    ConversationsInput,
    ConversationsPayload,
    ConversationsFilter,
    ConversationsSortInput
} from "graphql/queries/ConversationsQuery";
import { useParams } from "react-router-dom";
import { renderTimeLeft } from "components/conversations/renderers/RenderTimeLeft";
import { renderLabel } from "components/conversations/renderers/RenderLabel";
import { renderAssignee } from "components/conversations/renderers/RenderAssignee";
import { renderMessage } from "components/conversations/renderers/RenderMessage";
import Alert from "@mui/material/Alert";
import { parseDateString } from "helpers/DateFormatters";
import { useStoreon } from 'storeon/react';
import State from "store/State";
import { useHistory } from "react-router-dom";

type Params = {
    id: string;
};

function OrdersPanel() {

    const { id } = useParams<Params>();

    const history = useHistory();
    const [page, setPage] = useState<number>(0);
    const [after, setAfter] = useState<string | undefined>(undefined);
    const [before, setBefore] = useState<string | undefined>(undefined);
    const [sort, setSort] = useState<ConversationsSortInput | undefined>({ timeLeft: "ASC" });

    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'date',
            sort: 'desc' as GridSortDirection,
        },
    ]);

    const { data, error, loading, refetch } = useQuery<
        ConversationsPayload,
        ConversationsInput
    >(CONVERSATIONS, {
        variables: {
            after: after,
            before: before,
            first: !after && !before ? 10 : !!after ? 10 : undefined,
            last: !!before ? 10 : undefined,
            filters: {
                filter: ConversationsFilter.customer_id,
                sort: sort,
                customerId: id,
            },
        },
    });

    const rows: GridRowsProp = !!(data?.conversations)
        ? data.conversations.nodes.map((row) => {
            return {
                id: row.id,
                type: row.type.title,
                message: row.latestMessage ?? "",
                labels: row.labels.nodes ?? [],
                time_left: !!row.timeLeft ? parseDateString(row.timeLeft) : undefined,
                assignee: row.assignees.nodes ?? [],
                message_count: row.messageCount,
                total_order_value: row.totalOrderValue
            };
        })
        : [];

    const columns: GridColDef[] = [
        {
            field: "type",
            headerName: "Type",
            width: 150,
        },
        {
            field: "labels",
            headerName: "Labels",
            width: 250,
            renderCell: renderLabel,
        },
        {
            field: "message",
            headerName: "Latest Message",
            width: 300,
            renderCell: renderMessage,
        },
        {
            field: "assignee",
            headerName: "Assignee",
            width: 150,
            renderCell: renderAssignee,
        },
        {
            field: "time_left",
            headerName: "Time Left",
            width: 150,
            renderCell: renderTimeLeft,
        },
        {
            field: "total_order_value",
            headerName: "Order value",
            width: 150,
        },
        {
            field: "message_count",
            headerName: "Messages",
            width: 100,
        }
    ];

    const navigateToAfter = async (after: string, page: number) => {
        setPage(page);
        setAfter(after);
        setBefore(undefined);
    };

    const navigateToBefore = async (before: string, page: number) => {
        setPage(page);
        setAfter(undefined);
        setBefore(before);
    };

    const navigateStart = async () => {
        setPage(0);
        setBefore(undefined);
        setAfter(undefined);
        refetch();
    }

    useEffect(() => {
        navigateStart();
    }, [sort]);

    const { dispatch } = useStoreon<State>();

    return (
        <>
            {!!error && <Alert severity="error" sx={{ maxWidth: 948 }}>{error.message}</Alert>}
            <Stack direction="column" style={{ minHeight: 659 }}>
                <NoScrollZenDataGrid
                    style={{ height: "100%" }}
                    disableColumnPinning
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    onSortModelChange={(model) => {
                        if (model.length > 0) {
                            const field = model[0];
                            const sort = field.sort;
                            if (!!sort) {
                                switch (field.field) {
                                    case "number": {
                                        setSort({ id: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                    case "customer_name": {
                                        setSort({ customerName: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                    case "type": {
                                        setSort({ conversationType: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                    case "labels": {
                                        setSort({ labels: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                    case "message": {
                                        setSort({ latestMessage: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                    case "assignee": {
                                        setSort({ assignee: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                    case "time_left": {
                                        setSort({ timeLeft: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                    case "message_count": {
                                        setSort({ numberOfMessages: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                    case "total_order_value": {
                                        setSort({ totalOrderValue: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                }
                            } else {
                                setSort(undefined);
                            }
                        } else {
                            setSort(undefined);
                        }
                        setSortModel(model);
                    }}
                    components={{
                        NoResultsOverlay: () => (<div />),
                    }}
                    disableColumnFilter
                    disableColumnReorder
                    disableColumnSelector
                    rows={rows}
                    columns={columns}
                    loading={!data}
                    page={page}
                    onPageChange={(newPage) => {
                        if (!loading && !!(data?.conversations)) {
                            if (newPage > page) {
                                navigateToAfter(data.conversations.pageInfo.endCursor, newPage);
                            } else {
                                navigateToBefore(data.conversations.pageInfo.startCursor, newPage);
                            }
                        }
                    }}
                    rowHeight={55}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    rowCount={!!(data?.conversations) ? data.conversations.pageInfo.totalCount : 0}
                    paginationMode="server"
                    sortingMode="server"
                    pagination
                    disableSelectionOnClick
                    onRowClick={(row) => {
                        dispatch("conversations/setSelectedConversationId", row.id);
                        dispatch("conversations/add", row.id);
                        history.push(`/conversation/${row.id}`)
                    }}
                />
            </Stack>
        </>
    );
}

export default OrdersPanel;
import { gql } from "@apollo/client";
import Conversation from "types/Conversation";
import CompleteConversationFragment from "../fragments/CompleteConversationFragment";

export interface UpdateConversationAssociatedOrderInput {
    input: {
        id: string;
        orderId: string;
    };
}

export interface UpdateConversationAssociatedOrderPayload {
    updateConversationAssociatedOrder: {
        errors: string[];
        conversation: Conversation;
    }
}

const UPDATE_CONVERSATION_ASSOCIATED_ORDER = gql`
  mutation UpdateConversationAssociatedOrder(
    $input: UpdateConversationAssociatedOrderMutationInput!
  ) {
    updateConversationAssociatedOrder(input: $input) {
      errors
      conversation {
        ...CompleteConversationFields
      }
    }
  }
  ${CompleteConversationFragment}
`;

export default UPDATE_CONVERSATION_ASSOCIATED_ORDER;

import { useState } from "react";
import Stack from "@mui/material/Stack";
import Button, { LoadingButton } from "theme/Button";
import Card from "theme/Card";
import { useQuery, useMutation } from "@apollo/client";
import CUSTOMER, {
    CustomerInput,
    CustomerPayload,
} from "graphql/queries/CustomerQuery";
import SELECTED_ORG, {
    SelectedOrgInput,
    SelectedOrgPayload,
} from "graphql/queries/SelectedOrgQuery";
import EXPORT_GDPR, {
    ExportCustomerGdprDataInput,
    ExportCustomerGdprDataPayload,
} from "graphql/mutations/ExportCustomerGdprDataMutation";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import Divider from "theme/Divider";
import { useSnackbar } from 'notistack';

type Params = {
    id: string;
};

function BillingPanel() {

    const { id } = useParams<Params>();

    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState<boolean>(false);

    const [alertError, setAlertError] = useState<string | undefined>(undefined);

    const { data, error } = useQuery<CustomerPayload, CustomerInput>(CUSTOMER, {
        variables: {
            id: id
        },
    });

    const { data: gdprData } = useQuery<SelectedOrgPayload, SelectedOrgInput>(SELECTED_ORG, {});

    const [exportGdpr, { }] = useMutation<ExportCustomerGdprDataPayload, ExportCustomerGdprDataInput>(EXPORT_GDPR, { refetchQueries: [] });

    const customer = data?.customer;

    const handleExport = async () => {
        if (!!customer) {

            setLoading(true);

            try {
                const { data: deleteData, errors } = await exportGdpr({
                    variables: {
                        input: {
                            customerId: customer.id,
                        }
                    }
                });

                if (!!errors && errors.length > 0) {
                    setAlertError(errors[0].message);
                }

                if (!!deleteData && !!deleteData.exportCustomerGdprData?.ok) {
                    enqueueSnackbar('Customer data will be emailed to you shortly');
                }
            } catch { }

            setLoading(false);
        }
    }

    return (
        <>
            {!!alertError && <Alert severity="error" sx={{ maxWidth: 948 }}>{alertError}</Alert>}
            {!!error && <Alert severity="error" sx={{ maxWidth: 948 }}>{error.message}</Alert>}
            {!!customer &&
                <Stack direction="column" sx={{ maxWidth: 948 }} spacing={1}>
                    <Card variant="outlined">
                        <Stack direction="column" spacing={2} padding={2}>
                            <Typography variant="h5">Data retention period</Typography>
                            <Typography variant="body2">
                                All Customer, order and conversation history will be automatically deleted after <strong>{gdprData?.selectedOrg?.numberOfDaysToClearData || ""} days</strong>.
                            </Typography>
                            <Typography variant="body2">
                                You can change the retention period in your Organization settings.
                            </Typography>
                            <Divider />
                            <Typography variant="h5">Download Customer information</Typography>
                            <Typography variant="body2">
                                You can request a copy of all the stored information.
                            </Typography>
                            <Typography variant="body2">
                                You will be emailed shortly later with the download.
                            </Typography>
                            <div>
                                <LoadingButton loading={loading} onClick={handleExport} size="small" variant="contained" color="secondary">Download {data?.customer?.name || data?.customer?.email}&apos;s information</LoadingButton>
                            </div>
                            <Divider />
                            <Typography variant="h5">Delete Customer information</Typography>
                            <Typography variant="body2">
                                You can permanently delete all Customer, order and conversation information by clicking here.
                            </Typography>
                        </Stack>
                    </Card>
                </Stack>}
        </>
    );
}

export default BillingPanel;
import gql from "graphql-tag";

const ShopifyProductFragment = gql`
  fragment ShopifyProductFields on ShopifyProduct {
    id
    createdAt
    imageSrc
    title
  }
`;

export default ShopifyProductFragment;

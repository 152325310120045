import { useState, useEffect } from "react";
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button, { LoadingButton } from 'theme/Button';
import Dialog from '@mui/material/Dialog';
import TextField from 'theme/TextField';
import { useMutation } from "@apollo/client";
import CREATE_EMAIL_DOMAIN, { CreateEmailDomainInput, CreateEmailDomainPayload } from "graphql/mutations/CreateEmailDomainMutation";
import EMAIL_DOMAIN, { EmailDomainInput, EmailDomainPayload } from "graphql/queries/EmailDomainQuery";
import Typography from "@mui/material/Typography";
import { useForm, Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import useTheme from "@mui/material/styles/useTheme";
import SELECTED_ORG, {
    SelectedOrgInput,
    SelectedOrgPayload,
} from "graphql/queries/SelectedOrgQuery";
import { useQuery } from "@apollo/client";
import Chip from '@mui/material/Chip';
import CircularProgress from "@mui/material/CircularProgress";

interface Props {
    open: boolean;
    onClose: () => void;
}

interface NewMailboxProps extends Props {
    setCustomEmailDomainId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

interface CustomEmailDomainProps extends Props {
    id: string;
    closeButtonTitle?: string;
}

function NewMailboxDialog(props: NewMailboxProps) {

    const { open, setCustomEmailDomainId, onClose } = props;

    const { data: selctedOrgData } = useQuery<SelectedOrgPayload, SelectedOrgInput>(SELECTED_ORG, {});
    const [createEmailDomain, { error }] = useMutation<CreateEmailDomainPayload, CreateEmailDomainInput>(CREATE_EMAIL_DOMAIN, { refetchQueries: ["EmailDomains"] });

    const [custom, setCustom] = useState<boolean>(false);
    const [address, setAddress] = useState<string>("support");
    const [subdomain, setSubdomain] = useState<string>("company-name");
    const [loading, setLoading] = useState<boolean>(false);
    const [alertError, setAlertError] = useState<string | undefined>(undefined);
    const theme = useTheme();

    const {
        control,
        handleSubmit,
        reset,
        setValue: setFormValue,
        formState: { errors },
    } = useForm();

    const onSubmit = async () => {
        try {
            setLoading(true);

            const { data } = await createEmailDomain({
                variables: {
                    input: {
                        address: address,
                        customDomain: custom ? subdomain : undefined
                    }
                }
            });

            if (!!data?.createEmailDomain?.emailDomain) {
                if (data.createEmailDomain.emailDomain.isCustomDomain) {
                    resetEverything();
                    setCustomEmailDomainId(data.createEmailDomain.emailDomain.id);
                } else {
                    resetEverything();
                    onClose();
                }
            }
        } catch {
            setAlertError('There was a problem creating this email domain');
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        if (loading) {
            return;
        }
        resetEverything();
        onClose();
    };

    const resetEverything = () => {
        setTimeout(() => {
            reset();
            setCustom(false);
            setAddress("");
            setSubdomain("");
            setAlertError(undefined);
        }, 200);
    };

    useEffect(() => {
        if (!!error) {
            setAlertError(error.message);
        }
    }, [error]);

    useEffect(() => {
        if (open && !!selctedOrgData?.selectedOrg?.zenMailCompanyName) {
            if (custom) {
                setSubdomain("");
                setFormValue("sub_domain", "");
            } else {
                setSubdomain(selctedOrgData.selectedOrg.zenMailCompanyName);
                setFormValue("sub_domain", selctedOrgData.selectedOrg.zenMailCompanyName);
            }
        }
    }, [selctedOrgData, custom, open]);

    useEffect(() => {
        if (!open) {
            resetEverything();
        }
    }, [open]);

    return (
        <>
            <DialogTitle>
                <Typography variant="h5">New mailbox</Typography>
            </DialogTitle>
            <DialogContent dividers>
                <Stack direction="column" spacing={2}>
                    <Typography color="text.secondary" variant="subtitle2">
                        Create a new mailbox to send & receive email with zenshop.
                    </Typography>
                    {!!alertError && <Alert severity="error" icon={false}>{alertError}</Alert>}
                    <Stack direction="column" spacing={2}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <FormControl variant="outlined">
                                    <Controller
                                        name="address"
                                        rules={{
                                            required: true,
                                            minLength: 3,
                                            maxLength: 150,
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                type="address"
                                                fullWidth
                                                sx={{ backgroundColor: theme.palette.background.paper }}
                                                size="small"
                                                helperText={errors.address ? errors.address.message : undefined}
                                                variant="outlined"
                                                placeholder="support"
                                                error={!!errors.address}
                                                onChange={(e) => {
                                                    const v = e.currentTarget.value.replace(/[^\da-z.-]/g, '')
                                                    setAddress(v);
                                                    onChange(v);
                                                }}
                                                value={value}
                                            />
                                        )}
                                        control={control}
                                    />
                                </FormControl>
                                {custom && <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 600 }}>
                                    @
                                </Typography>}
                                {custom && <FormControl variant="outlined">
                                    <Controller
                                        name="sub_domain"
                                        rules={{
                                            required: true,
                                            minLength: 3,
                                            maxLength: 150,
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                id="sub_domain"
                                                type="sub_domain"
                                                sx={{ backgroundColor: theme.palette.background.paper }}
                                                size="small"
                                                helperText={errors.sub_domain ? errors.sub_domain.message : undefined}
                                                variant="outlined"
                                                placeholder="company-name.com"
                                                error={!!errors.sub_domain}
                                                onChange={(e) => {
                                                    const v = e.currentTarget.value.replace(/[^\da-z.-]/g, '');
                                                    setSubdomain(v);
                                                    onChange(v);
                                                }}
                                                value={value}
                                            />
                                        )}
                                        control={control}
                                    />
                                </FormControl>}
                                {!custom && <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 600 }}>
                                    @{subdomain}.zen-mail.com
                                </Typography>}
                            </Stack>
                        </form>
                    </Stack>
                    <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" flexWrap="wrap" sx={{ gap: 1 }}>
                        {custom && <Typography textAlign="center" variant="subtitle2" color="text.secondary" fontWeight={600}>
                            {address || "support"}@{subdomain || (custom ? "company-name.com" : selctedOrgData?.selectedOrg?.zenMailCompanyName)}{custom ? "" : ".zen-mail.com"}
                        </Typography>}
                        <div>
                            <Button color="secondary" variant="outlined" size="small" onClick={() => {
                                if (custom) {
                                    setSubdomain("company-name");
                                } else {
                                    setSubdomain("company-name.com");
                                }
                                setCustom(!custom)
                            }}>{custom ? "Use zenshop domain" : "Use your own domain"} </Button>
                        </div>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button disabled={loading} size="small" onClick={() => handleClose()} color="inherit">Close</Button>
                <LoadingButton onClick={handleSubmit(onSubmit)} size="small" disabled={loading} loading={loading} variant="contained" color="success">Create</LoadingButton>
            </DialogActions>
        </>
    );
}

export function CustomEmailDomainDialog(props: CustomEmailDomainProps) {
    const { onClose, id, closeButtonTitle } = props;

    const { data: domainData } = useQuery<EmailDomainPayload, EmailDomainInput>(EMAIL_DOMAIN, { variables: { id: id }, pollInterval: 2500 });

    return (
        <>
            <DialogTitle>
                <Typography variant="h5">Update your DNS settings</Typography>
            </DialogTitle>
            <DialogContent dividers>
                <Stack direction="column" spacing={2}>
                    <Typography color="text.secondary" variant="subtitle2">
                        To send & receive email with zenshop using your own domain, you need to make the changes to your DNS.
                    </Typography>
                    <Typography color="text.secondary" variant="subtitle2">
                        If you need any help with this, please reach out to zenshop support.
                    </Typography>
                    <Stack direction="column" alignItems="center" spacing={2}>
                        <Stack direction="row" spacing={2}>
                            <Stack direction='column' spacing={1} sx={{ width: "100px" }}>
                                <Typography textAlign="center" variant="subtitle2">Type</Typography>
                                <TextField size="small" disabled value="CNAME"></TextField>
                            </Stack>
                            <Stack direction='column' spacing={1} >
                                <Typography textAlign="center" variant="subtitle2">Name</Typography>
                                <TextField size="small" value={`pm-bounces.${domainData?.emailDomain?.subDomain}`} ></TextField>
                            </Stack>
                            <Stack direction='column' spacing={1}>
                                <Typography textAlign="center" variant="subtitle2">Content</Typography>
                                <TextField size="small" value="pm.mtasv.net" ></TextField>
                            </Stack>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <TextField size="small" disabled value="MX" sx={{ width: "100px" }}></TextField>
                            <TextField size="small" value={`${domainData?.emailDomain?.subDomain}`}></TextField>
                            <TextField size="small" value="inbound.postmarkapp.com"></TextField>
                        </Stack>
                    </Stack>
                    {!(domainData?.emailDomain?.created) && <Stack direction="column" alignItems="center" spacing={2}>
                        <Typography textAlign="center" fontWeight={600} variant="subtitle2" color="secondary">We are waiting for your DNS records to be updated</Typography>
                        <Chip size="small" avatar={<CircularProgress size={15} color="inherit" />} color="default" label="Checking your DNS records"></Chip>
                    </Stack>}
                    {!!(domainData?.emailDomain?.created) && !(domainData?.emailDomain?.dkimVerified) && <Typography textAlign="center" fontWeight={600} variant="subtitle2" color="secondary">You also need to add DKIM records</Typography>}
                    {!!(domainData?.emailDomain?.created) && <Stack direction="column" alignItems="center" spacing={2}>
                        <Stack direction="row" spacing={2}>
                            <TextField size="small" disabled value="TXT" sx={{ width: "100px" }}></TextField>
                            <TextField size="small" value={`${domainData?.emailDomain?.dkimHost}`}></TextField>
                            <TextField size="small" value={`${domainData?.emailDomain?.dkimTextValue}`}></TextField>
                        </Stack>
                        {!(domainData?.emailDomain?.dkimVerified) && <Chip size="small" avatar={<CircularProgress size={15} color="inherit" />} color="default" label="Checking your DNS records"></Chip>}
                        {!!(domainData?.emailDomain?.dkimVerified) && <Chip size="small" color="success" label="Ready to use"></Chip>}
                    </Stack>}
                </Stack>
            </DialogContent >
            <DialogActions sx={{ p: 2 }}>
                <Button size="small" onClick={onClose} color={!!closeButtonTitle ? "secondary" : "inherit"} variant={!!closeButtonTitle ? "contained" : "text"}>{closeButtonTitle || "Close"}</Button>
            </DialogActions>
        </>
    )
}

export default function NewEmailDomainDialog(props: Props) {

    const { onClose, open } = props;

    const [customEmailDomainId, setCustomEmailDomainId] = useState<string | undefined>("");

    return (
        <Dialog onClose={onClose} open={open} maxWidth="lg">
            {!!customEmailDomainId ?
                <CustomEmailDomainDialog id={customEmailDomainId} {...props} /> :
                <NewMailboxDialog setCustomEmailDomainId={setCustomEmailDomainId} {...props} />}
        </Dialog>
    );
}
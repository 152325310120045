import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Card from "theme/Card";
import Chip from "theme/Chip";
import SELECTED_ORG, {
    SelectedOrgInput,
    SelectedOrgPayload,
} from "graphql/queries/SelectedOrgQuery";
import { useQuery } from "@apollo/client";

export default function ShopifySettings() {

    const { data } = useQuery<SelectedOrgPayload, SelectedOrgInput>(SELECTED_ORG, {});

    return (
        <Card variant="outlined" elevation={0} sx={{ maxWidth: 900 }}>
            <Stack spacing={2} direction="column" p={2}>
                <Typography variant="h5">Shopify integration</Typography>
                {!!data && !!data.selectedOrg?.hasActiveShopifyIntegration && <Stack alignItems="center" spacing={1} direction="row">
                    <Chip size="small" variant="filled" color="success" label="Active"></Chip>
                    <Typography variant="caption">Integration is active</Typography>
                </Stack>}
                {!!data && !data.selectedOrg?.hasActiveShopifyIntegration && <Stack alignItems="center" spacing={1} direction="row">
                    <Chip size="small" variant="filled" color="error" label="Not active"></Chip>
                    <Typography variant="caption">Integration is not active</Typography>
                </Stack>}
                <Typography variant="subtitle2">Please refer to your Shopify store apps page for the integration settings.</Typography>
                <Typography variant="subtitle2">If you wish to remove zenshop from your store, you can do so from your apps settings within Shopify.</Typography>
            </Stack>
        </Card>
    );
}
import { gql } from "@apollo/client";
import Conversation from "types/Conversation";
import CompleteConversationFragment from "../fragments/CompleteConversationFragment";

export interface AddCommentInput {
  input: {
    id: string;
    commentHtml: string;
    done: boolean;
    toAddressId: string;
    attachedFileIds: string[];
    subject?: string;
  };
}

export interface AddCommentPayload {
  addComment: {
    conversation: Conversation;
  };
}

const ADD_COMMENT = gql`
  mutation AddComment($input: AddCommentMutationInput!) {
    addComment(input: $input) {
      conversation {
        ...CompleteConversationFields
      }
    }
  }
  ${CompleteConversationFragment}
`;

export default ADD_COMMENT;

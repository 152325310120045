import { lazy, Suspense } from "react";

const AccountSettings = lazy(() => import("components/account_settings/AccountSettings"));

export default function AccountSettingsRoute() {
    return (
        <Suspense fallback={<div></div>}>
            <AccountSettings />
        </Suspense>
    );
}
import gql from "graphql-tag";

const NoteFragment = gql`
  fragment NoteFields on Note {
    id
    createdAt
    noteHtml
  }
`;

export default NoteFragment;

import { gql } from "@apollo/client";

export interface Input {
  input: {
    accessToken: string;
  };
}

export interface Payload {
  updateMessageBirdIntegration: {
    ok?: boolean;
  };
}

const UPDATE_MESSAGE_BIRD_INTEGRATION = gql`
  mutation UpdateMessageBirdIntegration($input: UpdateMessageBirdIntegrationMutationInput!) {
    updateMessageBirdIntegration(input: $input) {
      ok
    }
  }
`;

export default UPDATE_MESSAGE_BIRD_INTEGRATION;
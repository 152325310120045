import * as React from "react";
import { GridCellParams } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";

interface Props {
    value: boolean;
}

const Cell = React.memo(function Function(props: Props) {
    const { value } = props;
    if (value === false) {
        return <Typography variant="caption" fontWeight={700} color="error">Yes</Typography>;
    } else {
        return <></>
    }
});

export function renderDefaultLayout(params: GridCellParams) {
    return <Cell value={params.value as boolean} />;
}
import { gql } from "@apollo/client";

export interface DeleteConversationTypeInput {
    input: {
        id: string;
    };
}

export interface DeleteConversationTypePayload {
    deleteConversationType?: {
        errors: string[];
        ok?: boolean;
    };
}

const DELETE_CONVERSATION_TYPE = gql`
  mutation DeleteConversationType($input: DeleteConversationTypeMutationInput!) {
    deleteConversationType(input: $input) {
      errors
      ok
    }
  }
`;

export default DELETE_CONVERSATION_TYPE;

import BaseObject from "./Base";
import User from "./User";
import Customer from "./Customer";
import Message from "./Message";
import Activity from "./Message";
import Label from "./Label";
import ConversationType from "./ConversationType";
import ShopifyOrder from "./ShopifyOrder";
import PageInfo from "./PageInfo";

interface Conversation extends BaseObject {
  customer: Customer;
  type: ConversationType;
  latestMessage?: string;
  timeLeft?: string;
  resolvedAt?: string;
  number: number;
  saved: boolean;
  watching: boolean;
  waiting: boolean;
  done: boolean;
  spam: boolean;
  duplicate: boolean;
  messageCount: number;
  totalOrderValue: string;
  parent?: Conversation;
  similarConversations: {
    pageInfo: PageInfo;
    nodes: Conversation[];
  },
  mentionedOrders: {
    pageInfo: PageInfo;
    nodes: ShopifyOrder[];
  };
  assignees: {
    pageInfo: PageInfo;
    nodes: User[];
  };
  labels: {
    pageInfo: PageInfo;
    nodes: Label[];
  };
  events: {
    pageInfo: PageInfo;
    nodes: (Message | Activity)[];
  };
}

export enum ConversationState {
  spam,
  done,
  waiting,
  awaitingResponse,
  awaitingCustomerResponse,
  duplicate,
}

export function stateToString(state: ConversationState): string {
  switch (state) {
    case ConversationState.spam: {
      return "Marked as spam";
    }
    case ConversationState.done: {
      return "Resolved";
    }
    case ConversationState.waiting: {
      return "Waiting";
    }
    case ConversationState.awaitingResponse: {
      return "Awaiting response";
    }
    case ConversationState.awaitingCustomerResponse: {
      return "Awaiting customer response";
    }
    case ConversationState.duplicate: {
      return "Merged";
    }
  }
}

export function stateToColor(state: ConversationState): 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined {
  switch (state) {
    case ConversationState.spam: {
      return "error";
    }
    case ConversationState.done: {
      return "success";
    }
    case ConversationState.waiting: {
      return "secondary";
    }
    case ConversationState.awaitingResponse: {
      return "secondary";
    }
    case ConversationState.awaitingCustomerResponse: {
      return "secondary";
    }
    case ConversationState.duplicate: {
      return "info";
    }
  }
}

export function stateOf(conversation: Conversation): ConversationState {
  if (conversation.duplicate) {
    return ConversationState.duplicate;
  }
  if (conversation.spam) {
    return ConversationState.spam;
  }
  if (conversation.done) {
    return ConversationState.done;
  }
  if (conversation.waiting) {
    return ConversationState.waiting;
  }
  if (!!conversation.timeLeft) {
    return ConversationState.awaitingResponse;
  }
  return ConversationState.awaitingCustomerResponse;
}

export function getInitials(fullName: string): string {
  const allNames = fullName.trim().split(' ');
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, '');
  return initials;
}


export default Conversation;
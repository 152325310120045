import { useState } from "react";
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import Alert from "@mui/material/Alert";
import ReplyBox from "components/conversation/ReplyBox";

export interface Props {
    open: boolean;
    conversationIds: string[];
    onClose: (completed: boolean) => void;
}

export default function ConfirmBulkReplyDialog(props: Props) {

    const { onClose, open, conversationIds } = props;
    const [alertError, setAlertError] = useState<string | undefined>(undefined);

    const handleClose = (completed: boolean) => {
        onClose(completed);
        setAlertError(undefined);
    };

    return (
        <Dialog onClose={() => handleClose(false)} open={open} fullWidth maxWidth="md">
            <Stack direction="column" spacing={2}>
                {!!alertError && <Alert severity="error">{alertError}</Alert>}
                <ReplyBox variant="bulk_reply" conversationIds={conversationIds} onClose={() => handleClose(true)} />
            </Stack>
        </Dialog>
    );
}
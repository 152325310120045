import { gql } from "@apollo/client";
import OrgFile from "types/OrgFile";

export interface OrgFileInput {
  id: string;
}

export interface OrgFilePayload {
  orgFile?: OrgFile;
}

const ORG_FILE_DOWNLOAD = gql`
  query OrgFile($id: ID!) {
    orgFile(id: $id) {
      id
      createdAt
      givenFilename
      privateFile
      fileSize
      signedUrl
      shortcut
    }
  }
`;

export default ORG_FILE_DOWNLOAD;
import { gql } from "@apollo/client";

export interface RemoveFromTeamInput {
    input: {
        teamMemberId: string;
    };
}

export interface RemoveFromTeamPayload {
    removeFromTeam: {
        ok: boolean;
    };
}

const REMOVE_FROM_TEAM = gql`
  mutation RemoveFromTeam($input: RemoveFromTeamMutationInput!) {
    removeFromTeam(input: $input) {
      ok 
    }
  }
`;

export default REMOVE_FROM_TEAM;

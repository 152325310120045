import * as React from "react";
import { GridCellParams } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import Notification from "types/Notification";
import Box from "@mui/material/Box";
import useTheme from "@mui/material/styles/useTheme";
import Stack from "@mui/material/Stack";

interface Props {
    value?: Notification;
}

const NotificationCell = React.memo(function NotificationFunction(props: Props) {
    const { value } = props;
    const theme = useTheme();

    return (
        <Stack alignItems="center" spacing={1} direction="row">
            {!(value?.read) && <Box sx={{ borderRadius: "5px", height: "10px", width: "10px", backgroundColor: theme.palette.primary.main }}></Box>}
            <Typography variant="subtitle2">{value?.title}</Typography>
        </Stack >);
});

export function renderNotification(params: GridCellParams) {
    return <NotificationCell value={params.value as Notification} />;
}

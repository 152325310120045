import { gql } from "@apollo/client";

export interface MyUnreadNotificationsInput {

}

export interface MyUnreadNotificationsPayload {
    myUnreadNotifications: number;
}

const MY_UNREAD_NOTIFICATIONS = gql`
  query MyUnreadNotifications {
    myUnreadNotifications
  }
`;

export default MY_UNREAD_NOTIFICATIONS;
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import { lazy, Suspense } from "react";
import useTheme from "@mui/material/styles/useTheme";

const ForgotPasswordCard = lazy(() => import("components/forgot_password/ForgotPasswordCard"));

function ForgotPassword() {
    const theme = useTheme();
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                backgroundColor: theme.palette.prominent.main
            }}
        >
            <Backdrop open={true}>
                <Suspense fallback={<div></div>}>
                    <ForgotPasswordCard />
                </Suspense>
            </Backdrop>
        </Box>
    );
}

export default ForgotPassword;

import gql from "graphql-tag";

const ShopifyFulfillmentEventFragment = gql`
  fragment ShopifyFulfillmentEventFields on ShopifyFulfillmentEvent {
    id
    createdAt
    happenedAt
    status
  }
`;

export default ShopifyFulfillmentEventFragment;

import gql from "graphql-tag";
import ShopifyOrderFragment from "./ShopifyOrderFragment";
import ShopifyLineItemFragment from "./ShopifyLineItemFragment";
import ShopifyMailingAddressFragment from "./ShopifyMailingAddressFragment";
import ShopifyFulfillmentFragment from "./ShopifyFulfillmentFragment";
import ShopifyFulfillmentEventFragment from "./ShopifyFulfillmentEventFragment";
import MoneyBagFragment from "./MoneyBagFragment";
import ShopifyProductFragment from "./ShopifyProductFragment";
import CustomerFragment from "./CustomerFragment";
import ShopifyShippingLineFragment from "./ShopifyShippingLineFragment";

const CompleteShopifyOrderFragment = gql`
  fragment CompleteShopifyOrderFields on ShopifyOrder {
    ...ShopifyOrderFields
    customer {
      ...CustomerFields
    }
    lineItems {
      nodes {
        ...ShopifyLineItemFields
        product {
          ...ShopifyProductFields
        }
        originalTotalSet {
          ...MoneyBagFields
        }
        originalUnitPriceSet {
          ...MoneyBagFields
        }
        discountedUnitPriceSet {
          ...MoneyBagFields
        }
        discountedTotalSet {
          ...MoneyBagFields
        }
      }
    }
    shippingLines {
      nodes {
        ...ShopifyShippingLineFields
        discountedPriceSet {
          ...MoneyBagFields
        }
      }
    }
    billingAddress {
      ...ShopifyMailingAddressFields
    }
    shippingAddress {
      ...ShopifyMailingAddressFields
    }
    fulfillments {
      nodes {
        ...ShopifyFulfillmentFields
      }
    }
    fulfillmentEvents {
      nodes {
        ...ShopifyFulfillmentEventFields
      }
    }
    currentTotalPriceSet {
      ...MoneyBagFields
    }
  }
  ${ShopifyOrderFragment},
  ${ShopifyLineItemFragment},
  ${ShopifyMailingAddressFragment},
  ${ShopifyFulfillmentFragment},
  ${ShopifyFulfillmentEventFragment},
  ${MoneyBagFragment},
  ${ShopifyProductFragment},
  ${CustomerFragment},
  ${ShopifyShippingLineFragment}
`;

export default CompleteShopifyOrderFragment;
import { gql } from "@apollo/client";
import Conversation from "types/Conversation";
import CompleteConversationFragment from "../fragments/CompleteConversationFragment";

export interface UpdateConversationSavedInput {
  input: {
    id: string;
    saved: boolean;
  };
}

export interface UpdateConversationSavedPayload {
  updateConversationSaved: {
    errors: string[];
    conversation: Conversation;
  };
}

const UPDATE_CONVERSATION_SAVED = gql`
  mutation UpdateConversationSaved($input: UpdateConversationSavedMutationInput!) {
    updateConversationSaved(input: $input) {
      errors
      conversation {
        id
        saved
      }
    }
  }
`;

export default UPDATE_CONVERSATION_SAVED;

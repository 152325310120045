import { gql } from "@apollo/client";
import Conversation from "types/Conversation";

export interface UpdateConversationAssigneesInput {
  input: {
    id: string;
    assigneesToAdd: string[];
    assigneesToRemove: string[];
    reason?: string;
  };
}

export interface UpdateConversationAssigneesPayload {
  updateConversationAssignees: {
    errors: string[];
    conversation: Conversation;
  }
}

const UPDATE_CONVERSATION_ASSIGNEES = gql`
  mutation UpdateConversationAssignees($input: UpdateConversationAssigneesMutationInput!) {
    updateConversationAssignees(input: $input) {
      errors
      conversation {
        id
        assignees {
          nodes {
            id
            name
            avatarUrl
          }
        }
      }
    }
  }
`;

export default UPDATE_CONVERSATION_ASSIGNEES;

import { gql } from "@apollo/client";

export interface BulkReplyInput {
  input: {
    ids: string[];
    commentHtml: string;
    subject?: string;
    done: boolean;
    attachedFileIds: string[];
  };
}

export interface BulkReplyPayload {
  bulkReply: {
    ok: boolean;
  };
}

const BULK_REPLY = gql`
  mutation BulkReply($input: BulkReplyMutationInput!) {
    bulkReply(input: $input) {
      ok
    }
  }
`;

export default BULK_REPLY;

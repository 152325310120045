import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button, { LoadingButton } from "theme/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "theme/TextField";
import FormControl from "@mui/material/FormControl";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "@apollo/client";
import LOGIN, {
  LoginInput,
  LoginPayload,
} from "graphql/mutations/LoginMutation";
import { useHistory } from "react-router-dom";
import Alert from "@mui/material/Alert";
import * as EmailValidator from "email-validator";
import useApp from "hooks/useApp";
import Avatar from "theme/Avatar";
import shopifyLogo from "logos/shopify-logo.png"
import DialogTitle from "theme/DialogTitle";
import DialogContent from "theme/DialogContent";
import SHOPIFY_REDIRECT_URL, {
  ShopifyRedirectInput,
  ShopifyRedirectPayload
} from "graphql/queries/ShopifyRedirectQuery";
import { useApolloClient } from "@apollo/client";

interface EmailForm {
  email?: string;
  password?: string;
}

interface ShopifyForm {
  shopify?: string;
}

function LoginCard() {

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  let history = useHistory();
  let app = useApp();

  const [login, { error: loginError }] = useMutation<LoginPayload, LoginInput>(
    LOGIN,
    {}
  );

  const client = useApolloClient();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [showShopify, setShowShopify] = useState<boolean>(false);

  const onSubmitEmail = async (data: EmailForm) => {

    setError(undefined);

    try {

      setLoading(true);

      const { data: payload } = await login({
        variables: {
          input: {
            email: data.email!,
            password: data.password!,
          },
        },
      });

      setLoading(false);

      if (!!payload) {
        let response = payload.login;
        if (response.success) {
          if (!!response.token) {
            app.updateToken(response.token);
          }
          if (!!response.mobileEndingIn) {
            history.push(`/verify-login?email=${encodeURIComponent(data.email!)}`);
          } else {
            history.push("/verify-email");
          }
        } else if (response.errors.length > 0) {
          setError(response.errors[0]);
        }
      }

    } catch {
      setLoading(false);
    }

  };

  const onSubmitShopify = async (data: ShopifyForm) => {

    setError(undefined);
    setLoading(true);

    let cleanedInput = data.shopify!
      .replace(".myshopify.com/", "")
      .replace(".myshopify.com", "")
      .replace("http://", "")
      .replace("https://", "")
      .replace("www.", "");

    client.query<ShopifyRedirectPayload, ShopifyRedirectInput>({ query: SHOPIFY_REDIRECT_URL, fetchPolicy: "no-cache", variables: { shopDomain: `${cleanedInput}.myshopify.com` } })
      .then(({ data }) => {
        if (!!data && !!data.shopifyRedirectUrl) {
          window.location.href = data.shopifyRedirectUrl;
        } else {
          window.location.href = process.env.REACT_APP_SHOPIFY_APP_URL!;
        }
      })
      .catch(() => {
        window.location.href = process.env.REACT_APP_SHOPIFY_APP_URL!;
      }).finally(() => {
        setLoading(false);
      });

  };

  useEffect(() => {
    if (!!loginError) {
      if (loginError.message == "VERIFY_EMAIL") {
        history.push("/verify-email");
      }
      setError(loginError.message);
    }
  }, [loginError]);

  useEffect(() => {
    app.updateToken(undefined);
  }, []);

  if (showShopify) {
    return (
      <Box sx={{ maxWidth: 400, width: "100%" }}>
        <Stack spacing={4} direction="column">
          <Card elevation={5}>
            <form onSubmit={handleSubmit(onSubmitShopify)}>
              <DialogTitle>Sign in with Shopify</DialogTitle>
              <DialogContent dividers>
                <Stack spacing={2} >
                  <Typography variant="body2" color="text.secondary">Please enter your Shopify domain, e.g <strong>you-are-awesome.myshopify.com</strong></Typography>
                  <Typography variant="body2" color="text.secondary">You must already have zenshop installed to continue.</Typography>
                  {!!error && <Alert severity="error">{error}</Alert>}
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="shopify"
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          id="shopify"
                          size="small"
                          type="shopify"
                          helperText={errors.shopify ? errors.shopify.message : null}
                          variant="outlined"
                          placeholder="you-are-awesome.myshopify.com"
                          error={!!errors.shopify}
                          onChange={onChange}
                          value={value}
                        />
                      )}
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Please enter your myshopify domain",
                        validate: (value) => {
                          const validator = /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/
                          const valid = validator.test(value);
                          return (valid || "Please enter a valid store domain like you-are-awesome.myshopify.com");
                        }
                      }}
                    />
                  </FormControl>
                </Stack>
              </DialogContent>
              <CardActions >
                <Stack sx={{ p: 1, width: "100%" }} direction="row" spacing={1}>
                  <div style={{ flexGrow: 1 }} />
                  <Button size="small" disabled={loading} onClick={() => setShowShopify(false)}>Back</Button>
                  <LoadingButton size="small" disabled={loading} variant="contained" color="success" type="submit" onClick={handleSubmit(onSubmitShopify)}>
                    Continue
                  </LoadingButton>
                </Stack>
              </CardActions>
            </form>
          </Card>
        </Stack>
      </Box>
    );
  } else {
    return (
      <Box sx={{ maxWidth: 400, width: "100%" }}>
        <Stack spacing={4} direction="column">
          <Card elevation={5}>
            <form onSubmit={handleSubmit(onSubmitEmail)}>
              <DialogTitle>
                Sign in
              </DialogTitle>
              <DialogContent dividers>
                <Stack spacing={2} >
                  <Button size="medium" onClick={() => { setShowShopify(true); setError(undefined); }} variant="contained" color="success" startIcon={<Avatar sx={{ width: 20, height: 20 }} src={shopifyLogo} />}>Sign in with Shopify</Button>
                  <Typography color="text.secondary" variant="caption" textAlign="center">or use your zenshop account</Typography>
                  {!!error && <Alert severity="error">{error}</Alert>}
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="email"
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          id="email"
                          size="small"
                          type="email"
                          helperText={errors.email ? errors.email.message : null}
                          variant="outlined"
                          label=""
                          placeholder="Email"
                          error={!!errors.email}
                          onChange={onChange}
                          value={value}
                        />
                      )}
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Please enter your email address",
                        validate: (value) => EmailValidator.validate(value) || "Invalid email address",
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth variant="outlined">
                    <Controller
                      name="password"
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          id="password"
                          size="small"
                          type="password"
                          autoComplete="current-password"
                          placeholder="Password"
                          helperText={
                            errors.password ? errors.password.message : null
                          }
                          variant="outlined"
                          label=""
                          error={!!errors.password}
                          onChange={onChange}
                          value={value}
                        />
                      )}
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Please enter your password",
                        pattern: {
                          value: /^\S{8,}/i,
                          message: "Minimum eight characters",
                        },
                      }}
                    />
                  </FormControl>
                  <Button size="small" onClick={() => history.push("/forgot-password")}>Forgotten password?</Button>
                </Stack>
              </DialogContent>
              <CardActions>
                <Stack sx={{ p: 1, width: "100%" }} direction="row" spacing={1}>
                  <div style={{ flexGrow: 1 }} />
                  <Button size="small" disabled={loading} onClick={() => { window.location.href = 'https://www.zenshop.app'; }}>Cancel</Button>
                  <LoadingButton size="small" disabled={loading} loading={loading} variant="contained" color="success" type="submit" onClick={handleSubmit(onSubmitEmail)}>
                    Continue
                  </LoadingButton>
                </Stack>
              </CardActions>
            </form>
          </Card>
        </Stack>
      </Box>
    );
  }
}

export default LoginCard;
import BaseObject from "./Base";

interface ShopifyFulfillmentEvent extends BaseObject {
    happenedAt: string;
    status: string;
}

export function eventToDisplayStatus(event: ShopifyFulfillmentEvent): string {
    switch (event.status.toUpperCase()) {
        case "ATTEMPTED_DELIVERY":
            return "A delivery was attempted.";
        case "CONFIRMED":
            return "The fulfillment is confirmed.";
        case "DELIVERED":
            return "The fulfillment was successfully delivered.";
        case "FAILURE":
            return "The fulfillment request failed.";
        case "IN_TRANSIT":
            return "The fulfillment is in transit.";
        case "LABEL_PRINTED":
            return "A purchased shipping label has been printed.";
        case "LABEL_PURCHASED":
            return "A shipping label has been purchased.";
        case "OUT_FOR_DELIVERY":
            return "The fulfillment is out for delivery.";
        case "READY_FOR_PICKUP":
            return "The fulfillment is ready to be picked up.";

    }
    return "";
}

export default ShopifyFulfillmentEvent;

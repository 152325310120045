import { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import Dialog from '@mui/material/Dialog';
import Customer from "types/Customer";
import IconButton from '@mui/material/IconButton';
import CancelIcon from "@mui/icons-material/Cancel";
import { useMutation } from "@apollo/client";
import { useForm, Controller } from "react-hook-form";
import TextField from "theme/TextField";
import FormControl from "@mui/material/FormControl";
import { LoadingButton } from "theme/Button";
import * as EmailValidator from "email-validator";
import { v4 as uuidv4 } from 'uuid';
import Typography from "@mui/material/Typography";
import ADD_CUSTOMER_FIELD, {
    AddCustomerFieldInput,
    AddCustomerFieldPayload
} from "graphql/mutations/AddCustomerFieldMutation";
import CREATE_MAILING_ADDRESS, {
    CreateMailingAddressInput,
    CreateMailingAddressPayload
} from 'graphql/mutations/CreateMailingAddressMutation';
import { useSnackbar } from 'notistack';
import Alert from "@mui/material/Alert";
import InputLabel from "@mui/material/InputLabel";
import { getCountries, getPhoneCode, parsePhoneNumber, CountryCode } from "libphonenumber-js";
import countries from "i18n-iso-countries";
import Select from "theme/Select";
import DialogActions from '@mui/material/DialogActions';
import { FlagIcon, FlagIconCode } from "react-flag-kit";
import MenuItem from '@mui/material/MenuItem';

export enum FieldType {
    email = "email",
    phone_number = "phone_number",
    address = "address",
    custom = "custom"
}

export interface AddFieldDialogProps {
    open: boolean;
    type?: FieldType;
    customer?: Customer;
    onClose: () => void;
}

interface FormProps extends AddFieldDialogProps {
    loading: boolean;
    onSubmit(data: FieldForm): void;
}

interface MailingAddressFormProps extends AddFieldDialogProps {
    loading: boolean;
    onSubmit(data: MailingAddressForm): void;
}

interface FieldForm {
    fieldValue?: string;
}

interface MailingAddressForm {
    address1?: string;
    address2?: string;
    company?: string;
    city?: string;
    countryCode?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    province?: string;
    zip?: string;
}

function AddMailingAddressForm(props: MailingAddressFormProps) {

    const { loading, onSubmit } = props;

    const {
        control,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm();

    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

    function getName(code: string): string | undefined {
        return countries.getName(code, "en", { select: "official" });
    }

    let countryOptions = getCountries()
        .filter((o) => !!getName(o))
        .sort((a, b) => getName(a)! < getName(b)! ? -1 : 1)
        .map((o) => <MenuItem key={o} value={o}>{`${getName(o)}`}</MenuItem>);

    let phoneOptions = getCountries()
        .filter((o) => !!getName(o))
        .sort((a, b) => getName(a)! < getName(b)! ? -1 : 1)
        .map((o) => <MenuItem key={o} value={o}>{`${getName(o)} (+${getPhoneCode(o)})`}</MenuItem>);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent dividers>
                <Stack direction="column" spacing={2}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="country-label">Country</InputLabel>
                        <Controller
                            name="countryCode"
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    labelId="country-label"
                                    id="country"
                                    value={value}
                                    label="Country"
                                    onChange={onChange}
                                    size="small"
                                >
                                    {countryOptions}
                                </Select>
                            )}
                            control={control}
                            defaultValue="US"
                        />
                    </FormControl>
                    <Stack direction="row" spacing={2}>
                        <FormControl variant="outlined">
                            <Controller
                                name="firstName"
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        autoComplete={uuidv4()}
                                        helperText={errors.firstName ? errors.firstName.message : null}
                                        variant="outlined"
                                        size="small"
                                        placeholder="First name"
                                        error={!!errors.firstName}
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                                control={control}
                                defaultValue=""
                            />
                        </FormControl>
                        <FormControl variant="outlined">
                            <Controller
                                name="lastName"
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        autoComplete={uuidv4()}
                                        helperText={errors.lastName ? errors.lastName.message : null}
                                        variant="outlined"
                                        size="small"
                                        placeholder="Last name"
                                        error={!!errors.lastName}
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                                control={control}
                                defaultValue=""
                            />
                        </FormControl>
                    </Stack>
                    <FormControl fullWidth variant="outlined">
                        <Controller
                            name="company"
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    autoComplete={uuidv4()}
                                    helperText={errors.company ? errors.company.message : null}
                                    variant="outlined"
                                    size="small"
                                    placeholder="Company"
                                    error={!!errors.company}
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                            control={control}
                            defaultValue=""
                        />
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                        <Controller
                            name="address1"
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    autoComplete={uuidv4()}
                                    helperText={errors.address1 ? errors.address1.message : null}
                                    variant="outlined"
                                    size="small"
                                    placeholder="Address"
                                    error={!!errors.address1}
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "Please enter an address",
                            }}
                        />
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                        <Controller
                            name="address2"
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    autoComplete={uuidv4()}
                                    helperText={errors.address2 ? errors.address2.message : null}
                                    variant="outlined"
                                    size="small"
                                    placeholder="Apartment, suite, etc"
                                    error={!!errors.address2}
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                            control={control}
                            defaultValue=""
                        />
                    </FormControl>
                    <Stack direction="row" spacing={2}>
                        <FormControl variant="outlined">
                            <Controller
                                name="city"
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        autoComplete={uuidv4()}
                                        helperText={errors.city ? errors.city.message : null}
                                        variant="outlined"
                                        size="small"
                                        placeholder="City"
                                        error={!!errors.city}
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "Please enter a city",
                                }}
                            />
                        </FormControl>
                        <FormControl variant="outlined">
                            <Controller
                                name="province"
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        autoComplete={uuidv4()}
                                        helperText={errors.province ? errors.province.message : null}
                                        variant="outlined"
                                        size="small"
                                        placeholder="State"
                                        error={!!errors.province}
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                                control={control}
                                defaultValue=""
                            />
                        </FormControl>
                        <FormControl variant="outlined">
                            <Controller
                                name="zip"
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        autoComplete={uuidv4()}
                                        helperText={errors.zip ? errors.zip.message : null}
                                        variant="outlined"
                                        size="small"
                                        placeholder="Zip code"
                                        error={!!errors.zip}
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                                control={control}
                                defaultValue=""
                            />
                        </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <FormControl sx={{ width: 100 }} variant="outlined">
                            <Controller
                                name="phoneCountryCode"
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        id="phoneCountryCode"
                                        value={value}
                                        onChange={onChange}
                                        size="small"
                                        renderValue={(v) => {
                                            return <FlagIcon style={{ margin: 0, marginLeft: 5, marginTop: 2, padding: 0, height: 15 }} code={v as FlagIconCode} />
                                        }}
                                    >
                                        {phoneOptions}
                                    </Select>
                                )}
                                control={control}
                                defaultValue="US"
                            />
                        </FormControl>
                        <FormControl fullWidth variant="outlined">
                            <Controller
                                name="phoneNumber"
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        id="phone-number"
                                        helperText={errors.phoneNumber ? errors.phoneNumber.message : null}
                                        variant="outlined"
                                        size="small"
                                        placeholder="Phone number"
                                        error={!!errors.phoneNumber}
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                                control={control}
                                defaultValue=""
                                rules={{
                                    validate: (value) => {
                                        try {
                                            const phoneCountryCode = getValues()["phoneCountryCode"] as CountryCode;
                                            if (!!phoneCountryCode && !!value) {
                                                const formattedNumber = `+${getPhoneCode(phoneCountryCode)}${value}`;
                                                return parsePhoneNumber(formattedNumber).isPossible() || "Invalid number"
                                            }
                                        } catch {
                                            return "Invalid number"
                                        }
                                    }
                                }}
                            />
                        </FormControl>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <LoadingButton loading={loading} size="small" type="submit" onClick={handleSubmit((data) => {
                    if (!!data.phoneNumber && data.phoneNumber.length > 0) {
                        const formattedNumber = `+${getPhoneCode(data.phoneCountryCode! as CountryCode)}${data.phoneNumber!}`.replace(/\s/g, '');
                        const e164 = parsePhoneNumber(formattedNumber).number;
                        onSubmit({
                            ...data,
                            phone: e164
                        });
                    } else {
                        onSubmit({
                            ...data
                        });
                    }
                })} disabled={loading} variant="contained" color="success">Submit</LoadingButton>
            </DialogActions>
        </form>
    );
}

function AddPhoneFieldForm(props: FormProps) {

    const { loading, onSubmit } = props;

    const {
        control,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm();

    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

    function getName(code: string): string | undefined {
        return countries.getName(code, "en", { select: "official" });
    }

    let phoneOptions = getCountries()
        .filter((o) => !!getName(o))
        .sort((a, b) => getName(a)! < getName(b)! ? -1 : 1)
        .map((o) => <MenuItem key={o} value={o}>{`${getName(o)} (+${getPhoneCode(o)})`}</MenuItem>);

    return (
        <form onSubmit={handleSubmit((data) => {
            const formattedNumber = `+${getPhoneCode(data.countryCode! as CountryCode)}${data.phoneNumber!}`.replace(/\s/g, '');
            const e164 = parsePhoneNumber(formattedNumber).number;
            onSubmit({ fieldValue: e164 });
        })}>
            <DialogContent dividers>
                <Stack direction="row" spacing={2} alignItems="flex-start" justifyContent="flex-start">
                    <Typography color="text.secondary" variant="caption"></Typography>
                    <FormControl sx={{ width: 100 }} variant="outlined">
                        <Controller
                            name="countryCode"
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    id="country"
                                    value={value}
                                    onChange={onChange}
                                    size="small"
                                    renderValue={(v) => {
                                        return <FlagIcon style={{ margin: 0, marginLeft: 5, marginTop: 2, padding: 0, height: 15 }} code={v as FlagIconCode} />
                                    }}
                                >
                                    {phoneOptions}
                                </Select>
                            )}
                            control={control}
                            defaultValue="US"
                        />
                    </FormControl>
                    <FormControl fullWidth variant="outlined">
                        <Controller
                            name="phoneNumber"
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    id="phone-number"
                                    helperText={errors.phoneNumber ? errors.phoneNumber.message : null}
                                    variant="outlined"
                                    size="small"
                                    placeholder="Phone number"
                                    error={!!errors.phoneNumber}
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "Please enter a phone number",
                                validate: (value) => {
                                    try {
                                        const countryCode = getValues()["countryCode"] as CountryCode;
                                        const formattedNumber = `+${getPhoneCode(countryCode)}${value}`;
                                        return parsePhoneNumber(formattedNumber).isPossible() || "Invalid number"
                                    } catch {
                                        return "Invalid number"
                                    }
                                }
                            }}
                        />
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <LoadingButton loading={loading} size="small" type="submit" onClick={handleSubmit((data) => {
                    if (!!data.phoneNumber && data.phoneNumber.length > 0) {
                        const formattedNumber = `+${getPhoneCode(data.countryCode! as CountryCode)}${data.phoneNumber!}`.replace(/\s/g, '');
                        const e164 = parsePhoneNumber(formattedNumber).number;
                        onSubmit({ fieldValue: e164 });
                    }
                })} disabled={loading} variant="contained" color="success">Submit</LoadingButton>
            </DialogActions>
        </form>
    );
}

function AddEmailFieldFieldForm(props: FormProps) {

    const { loading, onSubmit } = props;

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent dividers>
                <Stack direction="column" spacing={2}>
                    <FormControl fullWidth variant="outlined">
                        <Controller
                            name="fieldValue"
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    autoComplete={uuidv4()}
                                    helperText={errors.fieldValue ? errors.fieldValue.message : null}
                                    variant="outlined"
                                    size="small"
                                    placeholder="Email"
                                    error={!!errors.fieldValue}
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                            control={control}
                            defaultValue=""
                            rules={{
                                required: "Please enter an email address",
                                validate: (value) => EmailValidator.validate(value) || "Invalid email address",
                            }}
                        />
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <LoadingButton loading={loading} size="small" type="submit" disabled={loading} variant="contained" color="success">Submit</LoadingButton>
            </DialogActions>
        </form>
    );
}


export default function AddFieldDialog(props: AddFieldDialogProps) {

    const [busy, setBusy] = useState<boolean>(false);

    const { onClose, open, customer, type } = props;

    const { enqueueSnackbar } = useSnackbar();

    const [alertError, setAlertError] = useState<string | undefined>(undefined);

    const [selection, setSelection] = useState<Customer | undefined>(customer);

    const [addField, { }] = useMutation<AddCustomerFieldPayload, AddCustomerFieldInput>(ADD_CUSTOMER_FIELD, {
        refetchQueries: ["Customer", "Conversation", "Customers"]
    });

    const [createMailingAddress, { }] = useMutation<CreateMailingAddressPayload, CreateMailingAddressInput>(CREATE_MAILING_ADDRESS, {
        refetchQueries: ["Customer", "Conversation", "Customers"]
    });

    useEffect(() => {
        if (!!customer) {
            setSelection(customer);
        }
    }, [customer]);

    const handleClose = () => {
        onClose();
    };

    function dialogTitle() {
        switch (type) {
            case FieldType.email: return "Add email";
            case FieldType.phone_number: return "Add phone number";
            case FieldType.address: return "Add address";
            case FieldType.custom: return "Add custom field";
            default: return "";
        }
    }

    const handleAddressSubmit = async (data: MailingAddressForm) => {
        if (!!selection && !!customer) {
            try {
                setBusy(true);

                const { data: createMailingAddressData, errors } = await createMailingAddress({
                    variables: {
                        input: {
                            customerId: customer.id,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            address1: data.address1,
                            address2: data.address2,
                            city: data.city,
                            zip: data.zip,
                            company: data.company,
                            province: data.province,
                            countryCode: data.countryCode,
                            phone: data.phone,
                        }
                    }
                });


                if (!!errors && errors.length > 0) {
                    setAlertError(errors[0].message);
                } else if (!!createMailingAddressData) {
                    enqueueSnackbar(`Customer details updated`);
                    handleClose();
                }
            } catch (e: unknown) {
                if (e instanceof Error) {
                    setAlertError(e.message);
                }
            } finally {
                setBusy(false);
            }
        }
    }

    const handleFieldSubmit = async (data: FieldForm) => {
        if (!!selection && data.fieldValue) {
            try {
                setBusy(true);

                const { data: addFieldData, errors: addFieldError } = await addField({
                    variables: {
                        input: {
                            id: selection.id,
                            fieldType: type as string,
                            fieldValue: data.fieldValue
                        }
                    }
                });


                if (!!addFieldError && addFieldError.length > 0) {
                    setAlertError(addFieldError[0].message);
                } else if (!!addFieldData) {
                    enqueueSnackbar(`Customer details updated`);
                    handleClose();
                }
            } catch (e: unknown) {
                if (e instanceof Error) {
                    setAlertError(e.message);
                }
            } finally {
                setBusy(false);
            }
        }
    }

    return (
        <Dialog onClose={handleClose} open={open} maxWidth={type === FieldType.address ? "sm" : "xs"}>
            <DialogTitle>{dialogTitle()}
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 12,
                        color: "text.secondary",
                    }}
                >
                    <CancelIcon />
                </IconButton>
            </DialogTitle>
            {!!alertError && <Alert sx={{ ml: 2, mb: 2, mr: 2 }} severity="error"><Typography sx={{ wordBreak: "break-word" }} variant="body2">{alertError}</Typography></Alert>}
            {type === FieldType.email && <AddEmailFieldFieldForm {...props} onSubmit={handleFieldSubmit} loading={busy} />}
            {type === FieldType.phone_number && <AddPhoneFieldForm {...props} onSubmit={handleFieldSubmit} loading={busy} />}
            {type === FieldType.address && <AddMailingAddressForm {...props} onSubmit={handleAddressSubmit} loading={busy} />}
        </Dialog>
    );
}
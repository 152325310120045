import { gql } from "@apollo/client";
import Org from "types/Org";
import OrgFragment from "../fragments/OrgFragment";

export interface SelectedOrgInput { }

export interface SelectedOrgPayload {
  selectedOrg: Org;
}

const SELECTED_ORG = gql`
  query SelectedOrg {
    selectedOrg {
        ...OrgFields
        hasActiveShopifyIntegration
        hasActiveMessageBirdIntegration
    }
  }
  ${OrgFragment},
`;

export default SELECTED_ORG;

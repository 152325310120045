import { gql } from "@apollo/client";
import ConversationType from "types/ConversationType";
import ConversationTypeFragment from "../fragments/ConversationTypeFragment";

export interface UpdateConversationTypeInput {
  input: {
    id: string;
    title: string;
    descriptionHtml: string;
  };
}

export interface UpdateConversationTypePayload {
  updateConversationType: {
    errors: string[];
    conversationType: ConversationType;
  };
}

const UPDATE_CONVERSATION_TYPE = gql`
  mutation UpdateConversationType($input: UpdateConversationTypeMutationInput!) {
    updateConversationType(input: $input) {
      errors
      conversationType {
        ...ConversationTypeFields
      }
    }
  }
  ${ConversationTypeFragment}
`;

export default UPDATE_CONVERSATION_TYPE;

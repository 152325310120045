import gql from "graphql-tag";

const FieldFragment = gql`
  fragment FieldFields on Field {
    id
    value
    type
    isDefault
  }
`;

export default FieldFragment;

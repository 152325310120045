import { gql } from "@apollo/client";
import Org from "types/Org";
import OrgFragment from "../fragments/OrgFragment";

export interface InviteMemberInput {
  input: {
    email: string;
    name?: string;
  };
}

export interface InviteMemberPayload {
  inviteMember: {
    errors: string[];
    org: Org;
  };
}

const INVITE_MEMBER = gql`
  mutation InviteMember($input: InviteMemberMutationInput!) {
    inviteMember(input: $input) {
      errors
      org {
        ...OrgFields
      }
    }
  }
  ${OrgFragment}
`;

export default INVITE_MEMBER;

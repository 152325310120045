import { useState } from "react";
import Stack from "@mui/material/Stack";
import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import { LeftAlignedButton } from "theme/Button";
import CustomerPanel from "./CustomerPanel";
import OrdersPanel from "./OrdersPanel";
import ConversationsPanel from "./ConversationsPanel";
import GDPRPanel from "./GDPRPanel";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from "theme/Button";
import Divider from "theme/Divider";
import CUSTOMER, {
    CustomerInput,
    CustomerPayload,
} from "graphql/queries/CustomerQuery";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

enum Filter {
    details,
    orders,
    conversations,
    gdpr
}

type Params = {
    id: string;
};

function CustomerDetail() {

    const theme = useTheme();

    const { id } = useParams<Params>();
    const [filter, setFilter] = useState<Filter>(Filter.details);

    const { data } = useQuery<CustomerPayload, CustomerInput>(CUSTOMER, {
        variables: {
            id: id
        },
    });

    const customer = data?.customer;

    return (
        <Stack direction="column">
            <Breadcrumbs sx={{ p: 1, color: theme.palette.text.secondary }}>
                <Button color="inherit">
                    {customer?.name || customer?.email} customer profile
                </Button>
            </Breadcrumbs>
            <Divider />
            <Stack direction="row">
                <Box sx={{
                    width: 300, p: theme.spacing(2), display: {
                        xs: "none",
                        sm: "none",
                        md: "none",
                        lg: "block",
                    },
                }}>
                    <Stack spacing={1}>
                        <LeftAlignedButton
                            onClick={() => setFilter(Filter.details)}
                            color={
                                filter === Filter.details ? "primary" : "secondary"
                            }
                            variant={
                                filter === Filter.details ? "contained" : "text"
                            }
                        >
                            <span style={{ width: "100%", textAlign: "left" }}>Details</span>
                        </LeftAlignedButton>
                        <LeftAlignedButton
                            onClick={() => setFilter(Filter.orders)}
                            color={
                                filter === Filter.orders ? "primary" : "secondary"
                            }
                            variant={
                                filter === Filter.orders ? "contained" : "text"
                            }
                        >
                            <span style={{ width: "100%", textAlign: "left" }}>Orders</span>
                        </LeftAlignedButton>
                        <LeftAlignedButton
                            onClick={() => setFilter(Filter.conversations)}
                            color={
                                filter === Filter.conversations ? "primary" : "secondary"
                            }
                            variant={
                                filter === Filter.conversations ? "contained" : "text"
                            }
                        >
                            <span style={{ width: "100%", textAlign: "left" }}>Conversations</span>
                        </LeftAlignedButton>
                        <LeftAlignedButton
                            onClick={() => setFilter(Filter.gdpr)}
                            color={
                                filter === Filter.gdpr ? "primary" : "secondary"
                            }
                            variant={
                                filter === Filter.gdpr ? "contained" : "text"
                            }
                        >
                            <span style={{ width: "100%", textAlign: "left" }}>GDPR</span>
                        </LeftAlignedButton>
                    </Stack>
                </Box>
                <Stack direction="column" spacing={2} sx={{
                    p: theme.spacing(2),
                    pl: {
                        xs: 2,
                        sm: 2,
                        md: 2,
                        lg: 0,
                    },
                    width: {
                        xs: "100%",
                        sm: "100%",
                        md: "100%",
                        lg: "calc(100% - 300px)",
                    },
                }}>
                    {filter === Filter.details && <CustomerPanel />}
                    {filter === Filter.orders && <OrdersPanel />}
                    {filter === Filter.conversations && <ConversationsPanel />}
                    {filter === Filter.gdpr && <GDPRPanel />}
                </Stack>
            </Stack>
        </Stack>
    );
}

export default CustomerDetail;
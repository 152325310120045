import { useStoreon } from 'storeon/react';
import State from "store/State";
import Stack from "@mui/material/Stack";
import ToggleButton from 'theme/ToggleButton';
import { gql } from "@apollo/client";
import Conversation from "types/Conversation";
import { useQuery } from "@apollo/client";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import RECENTLY_ACTIVE_TEAM_MEMBERS, {
    RecentlyActiveTeamMembersInput,
    RecentlyActiveTeamMembersPayload
} from 'graphql/queries/RecentlyActiveTeamMembersQuery';
import AvatarGroup from "theme/AvatarGroup";
import Avatar from "theme/Avatar";
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Tooltip from "@mui/material/Tooltip";

export interface ConversationInput {
    id: string;
}

export interface ConversationPayload {
    conversation: Conversation;
}

const CONVERSATION_CUSTOMER_NAME = gql`
  query ConversationCustomerName($id: ID!) {
    conversation(id: $id) {
      customer {
        name
        email
      }
    }
  }
`;

function ConversationToggleButton(props: { id: string, key: string, showSelection?: boolean, index: number, length: number }) {

    const history = useHistory();

    const { dispatch, selectedConversationId } = useStoreon<State>('conversationIds', 'selectedConversationId');

    const { id, key, showSelection } = props;

    const { data } = useQuery<ConversationPayload, ConversationInput>(CONVERSATION_CUSTOMER_NAME, {
        variables: { id: id },
    });

    if (!!(data?.conversation)) {
        return (
            <ToggleButton key={key} selected={!!showSelection && id === selectedConversationId} style={{ margin: 4, whiteSpace: "nowrap" }} size='small' value={id} onClick={() => {
                dispatch("conversations/setSelectedConversationId", id);
                history.push(`/conversation/${id}`);
            }} >
                <Typography variant="caption" fontWeight={600} sx={{ textTransform: "none", }}>
                    {data?.conversation?.customer?.name || data?.conversation?.customer?.email}
                </Typography>
            </ToggleButton>
        );
    }

    return null;
}

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            border: '1px solid currentColor',
            content: '""',
        },
    },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 30,
    height: 30,
}));

function ConversationTabs(props: { showSelection?: boolean }) {
    const { showSelection } = props;
    const { conversationIds } = useStoreon<State>('conversationIds');

    const { data: recentlyActiveData } = useQuery<RecentlyActiveTeamMembersPayload, RecentlyActiveTeamMembersInput>(RECENTLY_ACTIVE_TEAM_MEMBERS, {
        variables: { first: 10 },
        fetchPolicy: "network-only",
    });

    const hasActiveMembers = (!!(recentlyActiveData?.recentlyActiveTeamMembers?.pageInfo) && recentlyActiveData.recentlyActiveTeamMembers.pageInfo.totalCount > 0) || false;
    const hasActiveConversations = conversationIds.length > 0;

    return (
        <>
            {(hasActiveMembers || hasActiveConversations) && <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                <Stack direction="row" spacing={1} sx={{ backgroundColor: "rgba(235, 235, 235, 1)", borderRadius: "4px" }}>
                    {conversationIds.filter((x): x is string => x !== null).map((conversationId, idx) =>
                        <div key={conversationId}>
                            <ConversationToggleButton index={idx} length={conversationIds.length} showSelection={showSelection} id={conversationId} key={conversationId} />
                        </div>
                    )}
                </Stack>
                {!!recentlyActiveData?.recentlyActiveTeamMembers && <AvatarGroup max={4}>
                    {recentlyActiveData.recentlyActiveTeamMembers.nodes.map((user) => (
                        <Tooltip key={user.id} title={user.name} arrow>
                            <StyledBadge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                variant="dot"
                            >
                                <SmallAvatar
                                    variant="rounded"
                                    src={user.avatarUrl}
                                />
                            </StyledBadge>
                        </Tooltip>
                    ))}
                </AvatarGroup>}
            </Stack>}
        </>
    );
}

export default ConversationTabs;

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Card from "theme/Card";
import DISCORD_INTEGRATIONS, { DiscordIntegrationsInput, DiscordIntegrationsPayload } from "graphql/queries/DiscordIntegrationsQuery";
import ME, { MeInput, MePayload, } from "graphql/queries/MeQuery";
import { useQuery } from "@apollo/client";
import Link from '@mui/material/Link';
import Button from "theme/Button";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useSnackbar } from 'notistack';

export default function DiscordSettings() {

    const { enqueueSnackbar } = useSnackbar();

    const { data: meData } = useQuery<MePayload, MeInput>(ME, {
        variables: {},
    });

    const { data } = useQuery<DiscordIntegrationsPayload, DiscordIntegrationsInput>(DISCORD_INTEGRATIONS, {
        variables: {},
    });

    return (
        <>
            <Card variant="outlined" elevation={0} sx={{ maxWidth: 900 }}>
                <Stack spacing={2} direction="column" p={2}>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                        <Typography variant="h5">Install zenbot for Discord</Typography>
                    </Stack>
                    <Typography variant="subtitle2">Add zenbot to your Discord server and get notified of new Customer messages and even respond to conversations directly from the Discord app.</Typography>
                    <Link href="https://docs.zenshop.app/role-specific-guides/for-admins/discord-integration" variant="body2">More information on zenshop for Discord</Link>
                    <div>
                        <Button startIcon={<ArrowForwardIcon />} size="small" color="secondary" variant="contained" href="https://discord.com/oauth2/authorize?&client_id=1040215586254360616&scope=bot">Click to add zenbot to your Discord</Button>
                    </div>
                </Stack>
            </Card>
            <Card variant="outlined" elevation={0} sx={{ maxWidth: 900 }}>
                <Stack spacing={2} direction="column" p={2}>
                    <Typography variant="h5">Setup zenbot</Typography>
                    <Typography variant="subtitle2">Every team member needs to run the setup command if they wish to reply to conversations in Discord.</Typography>
                    <Typography variant="subtitle2">Copy and paste the setup command to Discord.</Typography>
                    <div>
                        <Button size="small" color="inherit" variant="outlined" onClick={() => {
                            try {
                                var copiedData = [new ClipboardItem({ "text/plain": new Blob([`/setup access_token: ${data?.discordIntegrations?.nodes[0].accessToken} user_token: ${meData?.me?.id}`], { type: "text/plain" }) })];
                                navigator.clipboard.write(copiedData);
                            }
                            catch {
                                enqueueSnackbar("Something went wrong", { variant: "error", anchorOrigin: { horizontal: "center", vertical: "bottom" } })
                            }
                            finally {
                                enqueueSnackbar("Copied setup command", { variant: "success", anchorOrigin: { horizontal: "center", vertical: "bottom" } })
                            }
                        }}>Click to copy the setup command</Button>
                    </div>
                </Stack>
            </Card>
        </>
    );
}
import * as React from "react";
import { GridCellParams } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import { formatDateStringToTimeAgo } from "helpers/DateFormatters";

interface Props {
    value?: string;
}

const TimeAgoCell = React.memo(function TimeAgoFunction(props: Props) {
    const { value } = props;

    return (<Typography variant="subtitle2">{!!value ? formatDateStringToTimeAgo(value, true) : ""}</Typography>);
});

export function renderTimeAgo(params: GridCellParams) {
    return <TimeAgoCell value={params.value as string} />;
}
import { gql } from "@apollo/client";

export interface OrgFilesUpdatedInput {

}

export interface OrgFilesUpdatedPayload {
    orgFilesUpdated: string;
}

const ORG_FILES_UPDATED = gql`
  subscription OrgFilesUpdated {
    orgFilesUpdated
  }
`;

export default ORG_FILES_UPDATED;
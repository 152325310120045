import { gql } from "@apollo/client";
import OrgFile from "types/OrgFile";
import OrgFileFragment from "graphql/fragments/OrgFileFragment";

export interface SignFileUploadInput {
  input: {
    fileName: string;
    privateFile: boolean;
    temporaryFile: boolean;
    userAvatar: boolean;
  };
}

export interface SignFileUploadPayload {
  signFileUpload: {
    errors: string[];
    provisionalOrgFile?: OrgFile;
    uploadUrl?: string;
    uploadFields?: [key: string];
  };
}

const SIGN_FILE_UPLOAD = gql`
  mutation SignFileUpload($input: SignFileUploadUrlMutationInput!) {
    signFileUpload(input: $input) {
        provisionalOrgFile {
          ...OrgFileFields
        }
        uploadUrl
        uploadFields
        errors
    }
  } 
  ${OrgFileFragment}
`;

export default SIGN_FILE_UPLOAD;

import { gql } from "@apollo/client";
import Conversation from "types/Conversation";
import CompleteConversationFragment from "graphql/fragments/CompleteConversationFragment";

export interface SubscribeToConversationNotificationsInput {
    input: {
        conversationId: string;
    };
}

export interface SubscribeToConversationNotificationsPayload {
    subscribeToConversationNotifications: {
        conversation?: Conversation;
    };
}

const SUBSCRIBE_TO_CONVERSATION_NOTIFICATIONS = gql`
  mutation SubscribeToConversationNotifications($input: SubscribeToConversationNotificationsMutationInput!) {
    subscribeToConversationNotifications(input: $input) {
        conversation {
            ...CompleteConversationFields
        }
    }
  } 
  ${CompleteConversationFragment}
`;

export default SUBSCRIBE_TO_CONVERSATION_NOTIFICATIONS;

import gql from "graphql-tag";

const ShopifyLineItemFragment = gql`
  fragment ShopifyLineItemFields on ShopifyLineItem {
    id
    createdAt
    name
    sku
    variantTitle
    vendor
    taxable
    requiresShipping
    quantity
    currentQuantity
    unfulfilledQuantity
    imageSrc
  }
`;

export default ShopifyLineItemFragment;

import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Button, { LoadingButton } from "theme/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "theme/TextField";
import FormControl from "@mui/material/FormControl";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Alert from "@mui/material/Alert";
import DialogTitle from "theme/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "theme/DialogContent";
import INVITE_MEMBER, {
    InviteMemberInput,
    InviteMemberPayload,
} from "graphql/mutations/InviteMemberMutation";
import { useMutation, useQuery } from "@apollo/client";
import EmailIcon from '@mui/icons-material/Email';
import * as EmailValidator from "email-validator";
import { Typography } from "@mui/material";
import { motion } from "framer-motion/dist/framer-motion";
import EMAIL_DOMAINS, { EmailDomainsInput, EmailDomainsPayload } from "graphql/queries/EmailDomainsQuery";

interface Form {
    name?: string;
    email?: string;
}

function GettingStartedInviteTeamCard() {

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    let history = useHistory();

    const { data: domainsData } = useQuery<EmailDomainsPayload, EmailDomainsInput>(EMAIL_DOMAINS, {});
    const [inviteMember, { error: inviteError }] = useMutation<InviteMemberPayload, InviteMemberInput>(INVITE_MEMBER, {});

    const usesCustomDomain = domainsData?.emailDomains.nodes.map((node) => node.isCustomDomain).reduce((res, cur) => res || cur, false) || false;
    const [error, setError] = useState<string | undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const [showInviteAnother, setShowInviteAnother] = useState<boolean>(false);
    const [invitedUser, setInvitedUser] = useState<string | undefined>(undefined);

    const onSubmit = async (data: Form) => {

        let payload: InviteMemberPayload | null | undefined;

        try {
            setLoading(true);

            const { data: r } = await inviteMember({
                variables: {
                    input: {
                        email: data.email!,
                        name: data.name!,
                    },
                },
            });

            payload = r;
        } catch (e: unknown) {
            if (e instanceof Error) {
                setError(e.message);
            }
        } finally {
            setLoading(false);

            if (!!payload) {

                let response = payload.inviteMember;

                if (!!response.org) {
                    setShowInviteAnother(true);
                    setInvitedUser(data.name);
                    setError(undefined);
                    reset();
                } else if (response.errors.length > 0) {
                    setError(response.errors[0]);
                }
            }
        }
    };

    useEffect(() => {
        if (!!inviteError) {
            setError(inviteError.message);
        }
    }, [inviteError]);

    return (
        <Box sx={{ maxWidth: 400, width: "100%" }}>
            <motion.div initial={{ opacity: 0, scale: 0 }} viewport={{ once: true }} whileInView={{ opacity: 1, scale: 1 }} transition={{ delay: .5 }}>
                <Card elevation={5}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <DialogTitle>{!!showInviteAnother ? "Shall we invite another member?" : "Lets invite the team"}</DialogTitle>
                        <DialogContent dividers>
                            <Stack spacing={2}>
                                <Stack spacing={1} alignItems="flex-start">
                                    {!!error && <Alert severity="error">{error}</Alert>}
                                    {!showInviteAnother && <Typography variant="subtitle2" color="text.secondary">Bring your friends to the party.</Typography>}
                                    {!showInviteAnother && <Typography variant="subtitle2" color="text.secondary">There&apos;s <strong>no extra cost</strong>. ❤️</Typography>}
                                    {!!showInviteAnother && <Typography variant="subtitle2" color="text.secondary">Great! {invitedUser} has been invited to the team 🎉</Typography>}
                                </Stack>
                                <FormControl fullWidth variant="outlined">
                                    <Controller
                                        name="name"
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                id="name"
                                                size="small"
                                                helperText={errors.name ? errors.name.message : null}
                                                variant="outlined"
                                                placeholder="Members name"
                                                error={!!errors.name}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: "Please enter a name",
                                            minLength: 3,
                                            maxLength: 500,
                                        }}
                                    />
                                </FormControl>
                                <FormControl fullWidth variant="outlined">
                                    <Controller
                                        name="email"
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                id="name"
                                                size="small"
                                                helperText={errors.email ? errors.email.message : null}
                                                variant="outlined"
                                                placeholder="Email"
                                                error={!!errors.email}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: "Please enter an email address",
                                            validate: (value) =>
                                                EmailValidator.validate(value) || "Invalid email address",
                                        }}
                                    />
                                </FormControl>

                            </Stack>
                        </DialogContent>
                        <DialogActions sx={{ p: 2 }}>
                            <Button size="small" variant="text" color="inherit" type="submit" onClick={() => {

                                if (usesCustomDomain) {
                                    history.push("/getting-started-join-community");
                                } else {
                                    history.push("/getting-started-email-forwarding");
                                }
                            }}>
                                Skip
                            </Button>
                            <LoadingButton loading={loading} size="small" variant="contained" color="success" type="submit" onClick={handleSubmit(onSubmit)} startIcon={<EmailIcon />}>
                                Send invitation
                            </LoadingButton>
                        </DialogActions>
                    </form>
                </Card>
            </motion.div>
        </Box >
    );
}

export default GettingStartedInviteTeamCard;
import { gql } from "@apollo/client";

export interface MarkAllNotificationsAsReadInput {
  input: {

  };
}

export interface MarkAllNotificationsAsReadPayload {
  markAllNotificationsAsRead: {
    ok: Boolean;
  };
}

const MARK_ALL_NOTIFICATIONS_AS_READ = gql`
  mutation MarkAllNotificationsAsRead($input: MarkAllNotificationsAsReadMutationInput!) {
    markAllNotificationsAsRead(input: $input) {
      ok
    }
  }
`;

export default MARK_ALL_NOTIFICATIONS_AS_READ;

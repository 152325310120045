import { gql } from "@apollo/client";
import Customer from "types/Customer";

export interface CustomersSortInput {
  name?: "ASC" | "DESC",
  email?: "ASC" | "DESC",
  totalSpend?: "ASC" | "DESC",
  orders?: "ASC" | "DESC",
  lastOrder?: "ASC" | "DESC",
  conversations?: "ASC" | "DESC",
}

export interface CustomersFilterInput {
  filter: CustomersFilter;
  searchQuery?: string;
  sort?: CustomersSortInput;
}

export enum CustomersFilter {
  all = "ALL",
  active = "ACTIVE",
  recentlyResolved = "RECENTLY_RESOLVED",
  recentlyOrdered = "RECENTLY_ORDERED"
}

export interface CustomersInput {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
  filters: CustomersFilterInput;
}

export interface CustomersPayload {
  customers: {
    pageInfo: {
      totalCount: number;
      startCursor: string;
      endCursor: string;
    };
    nodes: Customer[];
  };
}

const CUSTOMERS = gql`
  query Customers(
    $after: String
    $before: String
    $last: Int
    $first: Int,
    $filters: CustomerFilterInput!
  ) {
    customers(
      first: $first
      last: $last
      after: $after
      before: $before,
      filters: $filters,
    ) {
      pageInfo {
        totalCount
        startCursor
        endCursor
      }
      nodes {
        id
        name
        numberOfConversations
        numberOfOrders
        totalSpend
        email
        latestOrderDate
      }
    }
  }
`;

export default CUSTOMERS;
import { useState, useEffect } from "react";
import { GridRowsProp, GridColDef, GridSelectionModel, GridSortModel, GridSortDirection } from "@mui/x-data-grid-pro";
import DataGridPro from "theme/DataGrid";
import Checkbox from "theme/Checkbox";
import { useQuery, useMutation } from "@apollo/client";
import HELPDESK_MESSENGERS, {
    HelpdeskMessengersInput,
    HelpdeskMessengersPayload,
    HelpdeskMessengersSortInput,
} from "graphql/queries/HelpdeskMessengersQuery";
import HELPDESK_MESSENGERS_COUNT, {
    HelpdeskMessengersCountInput,
    HelpdeskMessengersCountPayload,
} from "graphql/queries/HelpdeskMessengersCountQuery";
import CREATE_DRAFT_HELPDESK_MESSENGER, {
    CreateDraftHelpdeskMessengerInput,
    CreateDraftHelpdeskMessengerPayload,
} from "graphql/mutations/CreateDraftHelpdeskMessengerMutation";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import { useHistory } from "react-router-dom";
import TextField from "theme/TextField";
import useTheme from "@mui/material/styles/useTheme";
import Button from "theme/Button";
import BackspaceIcon from '@mui/icons-material/Backspace';
import useQueryParams from "hooks/useQueryParams";
import qs from 'query-string';
import AddIcon from '@mui/icons-material/Add';
import NewHelpdeskMessengerDialog from "./helpdesk_messengers/NewHelpdeskMessengerDialog";
import { renderLiveness } from "./renderers/RenderHelpdeskLive";

function HelpdeskMessengersGrid() {

    const theme = useTheme();
    const history = useHistory();
    const query = useQueryParams();

    const [newHelpdeskMessengerOpen, setNewHelpdeskMessengerOpen] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [page, setPage] = useState(Number(query.get("page")) ?? 0);
    const after = query.get("after") ?? undefined;
    const before = query.get("before") ?? undefined;
    const [sort, setSort] = useState<HelpdeskMessengersSortInput | undefined>({ name: "DESC" });

    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'date',
            sort: 'desc' as GridSortDirection,
        },
    ]);

    const [createDraft, { }] = useMutation<
        CreateDraftHelpdeskMessengerPayload,
        CreateDraftHelpdeskMessengerInput
    >(CREATE_DRAFT_HELPDESK_MESSENGER, { refetchQueries: [] });

    const { data: countData, loading: countLoading } = useQuery<
        HelpdeskMessengersCountPayload,
        HelpdeskMessengersCountInput
    >(HELPDESK_MESSENGERS_COUNT, {
        fetchPolicy: "no-cache",
        variables: {
            filters: {

            },
        },
    });

    const { data, error, loading } = useQuery<
        HelpdeskMessengersPayload,
        HelpdeskMessengersInput
    >(HELPDESK_MESSENGERS, {
        fetchPolicy: "no-cache",
        variables: {
            after: after,
            before: before,
            first: !after && !before ? 10 : !!after ? 10 : undefined,
            last: !!before ? 10 : undefined,
            filters: {
                sort: sort,
            },
        },
    });

    const rows: GridRowsProp = !!data
        ? data.helpdeskMessengers.nodes.map((row) => {
            return {
                id: row.id,
                name: row.name,
                location: row.shopifyIntegration?.shopDomain,
                live: row.live
            };
        })
        : [];

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Name",
            width: 300,
        },
        {
            field: "location",
            headerName: "Location",
            width: 300,
        },
        {
            field: "live",
            headerName: "Live",
            width: 300,
            renderCell: renderLiveness
        }
    ];

    function navigateToAfter(after: string, page: number) {
        const query = { page: page, after: after };
        history.push({ pathname: "/org-settings/templates", search: qs.stringify(query) });
    };

    function navigateToBefore(before: string, page: number) {
        const query = { page: page, before: before };
        history.push({ pathname: "/org-settings/templates", search: qs.stringify(query) });
    };

    function navigateStart() {
        const query = { page: 0 };
        history.push({ pathname: "/org-settings/helpdesk-messengers", search: qs.stringify(query) });
    }

    useEffect(() => {
        navigateStart();
        setPage(0);
    }, [sort, searchQuery]);

    return (
        <Stack direction="column" spacing={2} sx={{ pr: theme.spacing(2), pb: theme.spacing(2), pl: 0, width: "100%", height: "min-content" }}>
            <NewHelpdeskMessengerDialog open={newHelpdeskMessengerOpen} onClose={() => {
                setNewHelpdeskMessengerOpen(false);
            }} />
            {!!error && <Alert severity="error">{error.message}</Alert>}
            <Stack direction="row" spacing={1} alignItems="center">
                <TextField value={searchQuery} size="small" placeholder="Filter results" onChange={(e) => setSearchQuery(e.target.value)}></TextField>
                {(!!searchQuery) &&
                    <div style={{ color: theme.palette.text.secondary }}>
                        <Button onClick={() => { setSearchQuery(""); }} variant="outlined" startIcon={<BackspaceIcon />} color="inherit">Clear applied search filters</Button>
                    </div>}
                <div style={{ flexGrow: 1 }} />
                <Button size="small" startIcon={<AddIcon />} color="secondary" variant="contained" onClick={() => {
                    setNewHelpdeskMessengerOpen(true);
                }}>New messenger</Button>
            </Stack>
            <Stack direction="column" style={{ minHeight: 700 }}>
                <DataGridPro
                    style={{ height: "100%" }}
                    disableColumnPinning
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    onSortModelChange={(model) => {
                        if (model.length > 0) {
                            const field = model[0];
                            const sort = field.sort;
                            if (!!sort) {
                                switch (field.field) {
                                    case "name": {
                                        setSort({ name: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                }
                            } else {
                                setSort(undefined);
                            }
                        } else {
                            setSort(undefined);
                        }
                        setSortModel(model);
                    }}
                    components={{
                        BaseCheckbox: Checkbox,
                        NoResultsOverlay: () => (<div />),
                    }}
                    disableColumnFilter
                    disableColumnReorder
                    disableColumnSelector
                    rows={rows}
                    columns={columns}
                    loading={loading || countLoading}
                    page={page}
                    onPageChange={(newPage) => {
                        if (loading || countLoading) {
                            return;
                        }
                        if (newPage > page) {
                            navigateToAfter(data!.helpdeskMessengers.pageInfo.endCursor, newPage);
                        } else {
                            navigateToBefore(data!.helpdeskMessengers.pageInfo.startCursor, newPage);
                        }
                        setPage(newPage);
                    }}
                    rowHeight={55}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    rowCount={!!countData ? countData.helpdeskMessengersCount : 0}
                    paginationMode="server"
                    sortingMode="server"
                    pagination
                    disableSelectionOnClick
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                    onRowClick={async (row) => {
                        const { data } = await createDraft({ variables: { input: { helpdeskMessengerId: row.id as string } } });
                        if (!!data?.createDraftHelpdeskMessenger.draftHelpdeskMessenger) {
                            history.push(`/helpdesk-messenger/${data.createDraftHelpdeskMessenger.draftHelpdeskMessenger.id}`);
                        }
                    }}
                />
            </Stack>
        </Stack>
    );
}

export default HelpdeskMessengersGrid;
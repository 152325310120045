import { useState } from "react";
import Typography from "@mui/material/Typography";
import Button, { UpgradeButton, MaxButton } from "theme/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useMutation } from "@apollo/client";
import Alert from "@mui/material/Alert";
import DialogTitle from "theme/DialogTitle";
import DialogContent from "theme/DialogContent";
import UPDATE_ORG_PLAN, {
    UpdateOrgPlanInput,
    UpdateOrgPlanPayload,
} from "graphql/mutations/UpdateOrgPlanMutation";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FavoriteIcon from '@mui/icons-material/FavoriteBorderOutlined';
import useTheme from "@mui/material/styles/useTheme";
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

interface Form {
    name?: string;
}

function GettingStartedOrgPlanCard() {

    const theme = useTheme();

    const [updateOrgPlan, { }] = useMutation<
        UpdateOrgPlanPayload,
        UpdateOrgPlanInput
    >(UPDATE_ORG_PLAN, {});

    const [error, setError] = useState<string | undefined>();
    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit = async (planName: string) => {

        let payload: UpdateOrgPlanPayload | null | undefined;

        try {
            setLoading(true);

            const { data: r } = await updateOrgPlan({
                variables: {
                    input: {
                        planName: planName,
                    },
                },
            });

            payload = r;
        } catch (e: unknown) {
            if (e instanceof Error) {
                setError(e.message);
            }
        } finally {
            setLoading(false);

            if (!!payload) {

                let response = payload.updateOrgPlan;

                if (!!response.confirmationUrl) {
                    window.location.href = response.confirmationUrl;
                }
            }
        }
    };

    return (
        <Box>
            <Card elevation={5}>
                <DialogTitle style={{ textAlign: "center" }}>Choose your plan 🤩</DialogTitle>
                <DialogContent dividers>
                    <Stack spacing={2} alignItems="center">
                        {!!error && <Alert severity="error">{error}</Alert>}
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Card variant="outlined" sx={{ width: 350 }}>
                                <CardContent>
                                    <Stack spacing={1}>
                                        <Typography variant="h5" textAlign="center">Trial</Typography>
                                        <Typography variant="h5" textAlign="center"><strong>free</strong></Typography>
                                        <Typography variant="body2" color="text.secondary">Explore zenshop free of charge for 30 days</Typography>
                                        <Typography variant="body2" color="text.secondary">Up to 50 messages/mo *</Typography>
                                        <Stack direction="row" justifyContent="center" sx={{ pt: 5.5 }}>
                                            <Button disabled={loading} onClick={() => {
                                                onSubmit("trial");
                                            }} variant="outlined" color="inherit" size="large">Start Trial</Button>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            </Card>
                            <Typography color="text.secondary" fontWeight={700}> {"<"}</Typography>
                            <Card variant="outlined" sx={{ width: 350 }}>
                                <CardContent>
                                    <Stack spacing={1}>
                                        <Typography variant="h5" textAlign="center">Pro</Typography>
                                        <Typography variant="h5" textAlign="center">$<strong>9</strong>/mo</Typography>
                                        <Typography variant="body2" color="text.secondary">Up to 1000 messages/mo *</Typography>
                                        <Typography variant="body2" color="text.secondary">Files and attachments upload</Typography>
                                        <Typography variant="body2" color="text.secondary">2GB of file storage for attachments</Typography>
                                        <Stack direction="row" justifyContent="center" sx={{ pt: 2 }}>
                                            <UpgradeButton disabled={loading} onClick={() => {
                                                onSubmit("pro");
                                            }} variant="contained" color="secondary" size="large" startIcon={<FavoriteIcon />}>Choose Pro</UpgradeButton>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            </Card>
                            <Typography color="text.secondary" fontWeight={700}> {"<"}</Typography>
                            <Card variant="outlined" sx={{ width: 350 }}>
                                <CardContent>
                                    <Stack spacing={1}>
                                        <Typography variant="h5" textAlign="center">Max</Typography>
                                        <Typography variant="h5" textAlign="center">$<strong>40</strong>/mo</Typography>
                                        <Typography variant="body2" color="text.secondary">Unlimited messages/mo *</Typography>
                                        <Typography variant="body2" color="text.secondary">Files and attachments upload</Typography>
                                        <Typography variant="body2" color="text.secondary">2GB of file storage for attachments</Typography>
                                        <Stack direction="row" justifyContent="center" sx={{ pt: 2 }}>
                                            <MaxButton disabled={loading} onClick={() => {
                                                onSubmit("max");
                                            }} variant="contained" color="secondary" size="large" startIcon={<RocketLaunchIcon />}>Go Max</MaxButton>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Stack>
                        <Stack alignItems="baseline" direction="row" spacing={1} pb={2} pt={2}>
                            <Typography variant="h5" color={theme.palette.secondary.main}>Unlimited agents </Typography><Typography variant="h5">  at no extra cost</Typography>
                        </Stack>
                        <Stack sx={{ maxWidth: 500 }} spacing={2} pt={2}>
                            <Typography variant="caption" color="text.secondary" textAlign="center">Payment will use Shopify billing.</Typography>
                            <Typography variant="caption" color="text.secondary" textAlign="center">(*) All plans are subject to terms & conditions. Accounts may be terminated if deemed used for illegal activity, spamming or detrimental to other users.</Typography>
                        </Stack>
                    </Stack>
                </DialogContent>
            </Card>
        </Box >
    );
}

export default GettingStartedOrgPlanCard;
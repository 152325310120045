import Dialog from '@mui/material/Dialog';
import { CustomEmailDomainDialog } from "./NewEmailDomainDialog";

interface Props {
    open: boolean;
    customEmailDomainId?: string;
    onClose: () => void;
}

export default function EditCustomEmailDomainDialog(props: Props) {

    const { onClose, open, customEmailDomainId } = props;

    return (
        <Dialog onClose={onClose} open={open} maxWidth="md">
            {!!customEmailDomainId && <CustomEmailDomainDialog id={customEmailDomainId} {...props} />}
        </Dialog>
    );
}
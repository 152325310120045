import BaseObject from "./Base";
import Label from "types/Label";
import User from "./User";
import Customer from "./Customer";
import ConversationType from "./ConversationType";
import Order from "./Order";
import Conversation from "./Conversation";
import HelpdeskMessengerOption from "./HelpdeskMessengerOption";

export enum ActivityType {
  labelsUpdated = "labels_updated",
  assignessUpdated = "assignees_updated",
  saved = "saved",
  unsaved = "unsaved",
  waiting = "waiting",
  unwaiting = "unwaiting",
  resolved = "resolved",
  unresolved = "unresolved",
  spam = "spam",
  unspam = "unspam",
  updatedType = "updated_type",
  mentionedOrderByTrackingOrderNumber = "mentioned_order_by_tracking_order_number",
  mentionedOrderByOrderNumber = "mentioned_order_by_order_number",
  markedAsDuplicate = "marked_as_duplicate",
  mergedDuplicatesInto = "merged_duplicates_into",
  associatedOrderUpdated = "associated_order_updated",
  helpdeskMessengerOptionSelected = "helpdesk_messenger_option_selected"
}

interface Activity extends BaseObject {
  user?: User;
  customer?: Customer;
  order?: Order;
  mentionedOrderBy?: string;
  activityType: ActivityType;
  newType?: ConversationType;
  oldType?: ConversationType;
  parentConversation?: Conversation;
  duplicatesMerged: {
    nodes: Conversation[];
  }
  labelsAdded: {
    nodes: Label[];
  };
  labelsRemoved: {
    nodes: Label[];
  };
  assigneesAdded: {
    nodes: User[];
  };
  assigneesRemoved: {
    nodes: User[];
  };
  helpdeskMessengerOption?: HelpdeskMessengerOption;
}

export function instanceOfActivity(object: any): object is Activity {
  return "activityType" in object;
}

export default Activity;
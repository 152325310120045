import { gql } from "@apollo/client";

export interface VerifyLoginInput {
  input: {
    email: string;
    code: string;
  };
}

export interface VerifyLoginPayload {
  verifyLogin: {
    token?: string;
    cubeToken?: string;
    errors: string[];
  };
}

const LOGIN = gql`
  mutation VerifyLogin($input: VerifyLoginMutationInput!) {
    verifyLogin(input: $input) {
      token
      cubeToken
      errors
    }
  }
`;

export default LOGIN;

import { gql } from "@apollo/client";
import Workflow from "types/Workflow";
import WorkflowFragment from "../fragments/WorkflowFragment";

export interface WorkflowInput {
  id: string;
}

export interface WorkflowPayload {
  workflow?: Workflow;
}

const WORKFLOW = gql`
  query Workflow($id: ID!) {
    workflow(id: $id) {
        ...WorkflowFields
        template {
            id
            createdAt
            name
        }
    }
  }
  ${WorkflowFragment},
`;

export default WORKFLOW;
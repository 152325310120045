import { useState, useEffect } from "react";
import Conversation, { stateOf, stateToString, ConversationState } from "types/Conversation";
import Stack from "@mui/material/Stack";
import Button from "theme/Button";
import ChooseLabelsDialog from "components/labels/ChooseLabelsDialog";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import Alert from "@mui/material/Alert";
import Fade from "@mui/material/Fade";
import TimerIcon from "@mui/icons-material/Timer";
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useMutation, useQuery } from "@apollo/client";
import UPDATE_CONVERSATION_SAVED, {
  UpdateConversationSavedInput,
  UpdateConversationSavedPayload,
} from "graphql/mutations/UpdateConversationSavedMutation";
import UPDATE_CONVERSATION_WAITING, {
  UpdateConversationWaitingInput,
  UpdateConversationWaitingPayload,
} from "graphql/mutations/UpdateConversationWaitingMutation";
import UPDATE_CONVERSATION_SPAM, {
  UpdateConversationSpamInput,
  UpdateConversationSpamPayload,
} from "graphql/mutations/UpdateConversationSpamMutation";
import RESOLVE_CONVERSATION, {
  ResolveConversationInput,
  ResolveConversationPayload,
} from "graphql/mutations/ResolveConversationMutation";
import MergeTypeIcon from '@mui/icons-material/MergeType';
import MergeConversationsDialog from "./MergeConversationsDialog";
import LabelIcon from "@mui/icons-material/Label";
import UPDATE_CONVERSATION_LABELS, {
  UpdateConversationLabelsInput,
  UpdateConversationLabelsPayload,
} from "graphql/mutations/UpdateConversationLabelsMutation";
import ChooseAssigneesDialog from "components/assignees/ChooseAssigneesDialog";
import UPDATE_CONVERSATION_ASSIGNEES, {
  UpdateConversationAssigneesInput,
  UpdateConversationAssigneesPayload,
} from "graphql/mutations/UpdateConversationAssigneesMutation";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import Avatar from "theme/Avatar";
import AvatarGroup from "theme/AvatarGroup";
import USER, {
  UserInput,
  UserPayload
} from "graphql/queries/UserQuery";
import { avatar } from "types/User";
import ConfirmMarkAsSpamDialog from "./ConfirmMarkAsSpamDialog";
import { _ } from "numeral";

const UserAvatar = (props: { id: string, setAssigneesOpen: (open: boolean) => void }) => {
  const { id, setAssigneesOpen } = props;

  const { data: userData, refetch } = useQuery<UserPayload, UserInput>(USER, { variables: { id: id } });

  useEffect(() => {
    refetch({ id: id });
  }, [id]);

  if (!!(userData?.user)) {
    return (
      <Tooltip key={id} title={userData.user.name} arrow>
        <div onClick={() => setAssigneesOpen(true)}>
          {avatar(userData.user)}
        </div>
      </Tooltip>
    )
  }

  return <Avatar
    onClick={() => setAssigneesOpen(true)}
    variant="rounded"
    key={id}
    src=""
  />
}

type Props = {
  conversation: Conversation;
};

export default function ConversationToolbar(props: Props) {
  const { conversation } = props;
  const theme = useTheme();

  const [labelsOpen, setLabelsOpen] = useState<boolean>(false);
  const [assigneesOpen, setAssigneesOpen] = useState<boolean>(false);

  const [confirmSpamDialogOpen, setConfirmSpamDialogOpen] = useState<boolean>(false);
  const [mergeConversationDialogOpen, setMergeConversationDialogOpen] = useState<boolean>(false);

  const [labelIds, setLabelIds] = useState<string[]>(conversation.labels.nodes.map((label) => label.id));
  const [assigneeIds, setAssigneeIds] = useState<string[]>(conversation.assignees.nodes.map((assignee) => assignee.id));

  const [done, setDone] = useState<boolean>(conversation.done);
  const [spam, setSpam] = useState<boolean>(conversation.spam);
  const [waiting, setWaiting] = useState<boolean>(conversation.waiting);
  const [saved, setSaved] = useState<boolean>(conversation.saved);
  const [alertResolved, setAlertResolved] = useState<boolean>(false);
  const [alertSpam, setAlertSpam] = useState<boolean>(false);
  const [alertWaiting, setAlertWaiting] = useState<boolean>(false);
  const [alertSaved, setAlertSaved] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [updateConversationSpam, { }] = useMutation<
    UpdateConversationSpamPayload,
    UpdateConversationSpamInput
  >(UPDATE_CONVERSATION_SPAM, { refetchQueries: ["Conversation", "Conversations", "SideBarQuery", "Customers"] });

  const [updateConversationSaved, { }] = useMutation<
    UpdateConversationSavedPayload,
    UpdateConversationSavedInput
  >(UPDATE_CONVERSATION_SAVED, { refetchQueries: ["Conversation", "Conversations", "SideBarQuery", "Customers"] });

  const [updateConversationWaiting, { }] = useMutation<
    UpdateConversationWaitingPayload,
    UpdateConversationWaitingInput
  >(UPDATE_CONVERSATION_WAITING, { refetchQueries: ["Conversation", "Conversations", "SideBarQuery", "Customers"] });

  const [resolveConversation, { }] = useMutation<
    ResolveConversationPayload,
    ResolveConversationInput
  >(RESOLVE_CONVERSATION, { refetchQueries: ["Conversation", "Conversations", "SideBarQuery", "Customers"] });

  const [updateConversationLabels, { }] = useMutation<
    UpdateConversationLabelsPayload,
    UpdateConversationLabelsInput
  >(UPDATE_CONVERSATION_LABELS, { refetchQueries: ["Conversation", "Conversations", "SideBarQuery", "Customers"] });

  const [updateConversationAssignees, { }] = useMutation<
    UpdateConversationAssigneesPayload,
    UpdateConversationAssigneesInput
  >(UPDATE_CONVERSATION_ASSIGNEES, { refetchQueries: ["Conversation", "Conversations", "SideBarQuery", "Customers"] });

  useEffect(() => {
    setLabelIds(conversation.labels.nodes.map((label) => label.id));
    setAssigneeIds(conversation.assignees.nodes.map((assignee) => assignee.id));
    setSpam(conversation.spam);
    setWaiting(conversation.waiting);
    setSaved(conversation.saved);
    setDone(conversation.done);
  }, [conversation])

  useEffect(() => {
    if (alertResolved) {
      setTimeout(() => {
        setAlertResolved(false);
      }, 2000);
    }
  }, [alertResolved]);

  useEffect(() => {
    if (alertSpam) {
      setTimeout(() => {
        setAlertSpam(false);
      }, 2000);
    }
  }, [alertSpam]);

  useEffect(() => {
    if (alertSaved) {
      setTimeout(() => {
        setAlertSaved(false);
      }, 2000);
    }
  }, [alertSaved]);

  useEffect(() => {
    if (alertWaiting) {
      setTimeout(() => {
        setAlertWaiting(false);
      }, 2000);
    }
  }, [alertWaiting]);


  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function color(): 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined {
    switch (stateOf(conversation)) {
      case ConversationState.spam: {
        return "error";
      }
      case ConversationState.done: {
        return "success";
      }
      case ConversationState.waiting: {
        return "secondary";
      }
      case ConversationState.awaitingResponse: {
        return "secondary";
      }
      case ConversationState.awaitingCustomerResponse: {
        return "secondary";
      }
      case ConversationState.duplicate: {
        return "warning";
      }
    }
  }

  function variant(): 'text' | 'outlined' | 'contained' | undefined {
    switch (stateOf(conversation)) {
      case ConversationState.spam: {
        return "contained";
      }
      case ConversationState.done: {
        return "contained";
      }
      case ConversationState.waiting: {
        return "contained";
      }
      case ConversationState.awaitingResponse: {
        return "contained";
      }
      case ConversationState.awaitingCustomerResponse: {
        return "contained";
      }
      case ConversationState.duplicate: {
        return "contained";
      }
    }
  }

  return (
    <>
      <ConfirmMarkAsSpamDialog id={conversation.id} open={confirmSpamDialogOpen} onClose={() => setConfirmSpamDialogOpen(false)} />
      <MergeConversationsDialog open={mergeConversationDialogOpen} onClose={() => setMergeConversationDialogOpen(false)} conversation={conversation} />
      <Menu
        variant="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: 200,
            outline: "none",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: theme.spacing(1),
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => {
          setSpam(!conversation.spam);
          setAlertSpam(true);
          setAnchorEl(null);
          try {
            updateConversationSpam({
              variables: { input: { id: conversation.id, spam: !conversation.spam, blockSender: true } },
            })
          } catch { }
        }}>
          <Typography variant="body2">{conversation.spam ? "Unmark as spam" : "Mark as spam"}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDone(!conversation.done);
            setAlertResolved(true);
            setAnchorEl(null);
            try {
              resolveConversation({
                variables: { input: { id: conversation.id, resolved: !conversation.done } },
              })
            } catch { }
          }}
        >
          <Typography variant="body2">
            {conversation.done ? "Move back to in-progress" : "Resolve"}
          </Typography>
        </MenuItem>
      </Menu>
      <Fade appear={false} in={alertResolved} unmountOnExit={true}>
        <Alert severity={done ? "success" : "info"}>
          Conversation {done ? "marked as resolved" : "returned to in-progress"}
        </Alert>
      </Fade>
      <Fade appear={false} in={alertSaved} unmountOnExit={true}>
        <Alert severity="info">
          Conversation {saved ? "saved" : "unsaved"}
        </Alert>
      </Fade>
      <Fade appear={false} in={alertWaiting} unmountOnExit={true}>
        <Alert severity="info">
          Conversation {waiting ? "set to waiting" : "is no longer waiting"}
        </Alert>
      </Fade>
      <Fade appear={false} in={alertSpam} unmountOnExit={true}>
        <Alert severity={spam ? "error" : "info"}>
          Conversation {spam ? "marked" : "unmarked"} as spam
        </Alert>
      </Fade>
      <Stack
        alignItems="center"
        direction="row"
        spacing={1}
        p={1}
        color={theme.palette.text.secondary}
      >
        <Button disabled={stateOf(conversation) === ConversationState.duplicate} size="small" color={color()} variant={variant()} endIcon={<KeyboardArrowDownIcon />} onClick={handleClick}>
          {stateToString(stateOf(conversation))}
        </Button>

        <Box sx={{ flexGrow: 1 }}></Box>
        {!conversation.done && <Tooltip title="Merge conversations" arrow>
          <IconButton
            disabled={stateOf(conversation) === ConversationState.duplicate}
            size="medium"
            color={"inherit"}
            onClick={() => {
              setMergeConversationDialogOpen(true);
            }}
          >
            <MergeTypeIcon />
          </IconButton>
        </Tooltip>}
        <Tooltip title={spam ? "Unmark as spam" : "Mark as spam"} arrow>
          <IconButton
            disabled={stateOf(conversation) === ConversationState.duplicate}
            size="medium"
            color={spam ? "error" : "inherit"}
            onClick={async () => {

              let toggledSpam = !spam;

              try {
                if (toggledSpam) {
                  setConfirmSpamDialogOpen(true);
                } else {
                  await updateConversationSpam({
                    variables: {
                      input: {
                        id: conversation.id,
                        spam: false,
                        blockSender: false
                      },
                    },
                  });

                  setSpam(toggledSpam);
                  setAlertSpam(true);
                }
              } catch { }
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={waiting ? "Set to no longer waiting" : "Set to waiting"} arrow>
          <IconButton
            disabled={stateOf(conversation) === ConversationState.duplicate}
            size="medium"
            color={waiting ? "primary" : "inherit"}
            onClick={async () => {
              try {
                await updateConversationWaiting({
                  variables: {
                    input: { id: conversation.id, waiting: !waiting },
                  },
                });
                setWaiting(!waiting);
                setAlertWaiting(true);
              } catch { }
            }}
          >
            <TimerIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={saved ? "Remove from saved conversations" : "Save conversation"} arrow>
          <IconButton
            disabled={stateOf(conversation) === ConversationState.duplicate}
            size="medium"
            color={saved ? "primary" : "inherit"}
            onClick={async () => {
              try {
                await updateConversationSaved({
                  variables: {
                    input: { id: conversation.id, saved: !saved },
                  },
                });
                setSaved(!saved);
                setAlertSaved(true);
              } catch { }
            }}
          >
            <BookmarkIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Apply labels" arrow>
          <IconButton
            disabled={stateOf(conversation) === ConversationState.duplicate}
            size="medium"
            color={conversation.labels.nodes.length > 0 ? "primary" : "inherit"}
            onClick={() => {
              setLabelsOpen(true);
            }}>
            <LabelIcon />
          </IconButton>
        </Tooltip>
        <ChooseLabelsDialog
          selectedLabelIds={labelIds}
          open={labelsOpen}
          onClose={async (pendingValue) => {
            if (pendingValue != labelIds) {

              setLabelIds(pendingValue);

              try {
                const value = conversation.labels.nodes.map((label) => label.id);

                const id = conversation.id;

                const labelsToAdd = pendingValue.filter(
                  (obj) => !value.includes(obj)
                );

                const labelsToRemove = value.filter((obj) => !pendingValue.includes(obj));

                updateConversationLabels({
                  variables: { input: { id, labelsToAdd, labelsToRemove } },
                });

              } catch { }
            }

            setLabelsOpen(false);
          }} />
        {assigneeIds.length === 0 && (
          <Tooltip title="Select assignee" arrow>
            <IconButton
              disabled={stateOf(conversation) === ConversationState.duplicate}
              size="medium"
              color="inherit"
              onClick={() => setAssigneesOpen(true)}>
              <AssignmentIndIcon />
            </IconButton>
          </Tooltip>
        )}
        {assigneeIds.length > 0 &&
          <AvatarGroup max={4}>
            {assigneeIds.map((id) => <UserAvatar key={id} id={id} setAssigneesOpen={setAssigneesOpen} />)}
          </AvatarGroup>}
        <ChooseAssigneesDialog
          selectedUserIds={assigneeIds}
          open={assigneesOpen}
          onClose={async (pendingValue) => {
            if (pendingValue != assigneeIds) {

              setAssigneeIds(pendingValue);

              try {
                const value = conversation.assignees.nodes.map((assignee) => assignee.id);

                const id = conversation.id;

                const assigneesToAdd = pendingValue.filter(
                  (obj) => !value.includes(obj)
                );

                const assigneesToRemove = value.filter((obj) => !pendingValue.includes(obj));

                updateConversationAssignees({
                  variables: { input: { id, assigneesToAdd, assigneesToRemove } },
                });

              } catch { }
            }

            setAssigneesOpen(false);
          }} />
      </Stack>
    </>
  );
}
import { gql } from "@apollo/client";
import User from "types/User";
import UserFragment from "../fragments/UserFragment";

export interface TeamInput { }

export interface TeamPayload {
    team: {
        nodes: User[];
    };
}

const TEAM = gql`
  query Team {
    team {
      nodes {
          ...UserFields
      }
    }
  }
  ${UserFragment}
`;

export default TEAM;
import { useState, useEffect } from "react";
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from 'theme/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CancelIcon from "@mui/icons-material/Cancel";
import Box from '@mui/material/Box';
import TextField from 'theme/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { useQuery, useMutation } from "@apollo/client";
import TEAM, {
    TeamInput,
    TeamPayload
} from "graphql/queries/TeamQuery";
import BULK_ASSIGN_TO_CONVERSATION, {
    BulkAssignToConversationInput,
    BulkAssignToConversationPayload,
} from "graphql/mutations/BulkAssignToConversationMutation";
import User, { avatar } from "types/User";
import { GridColDef, GridSelectionModel, GridCellParams } from '@mui/x-data-grid-pro';
import DataGrid from "theme/DataGrid";
import Checkbox from "theme/Checkbox";
import Typography from "@mui/material/Typography";

export function renderAvatar(params: GridCellParams) {
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            {avatar(params.value as User)}
            <Typography variant="body2">{params.value["name"]}</Typography>
        </Stack>);
}

interface Props {
    open: boolean;
    conversationIds: string[];
    onClose: () => void;
}

const columns: GridColDef[] = [
    {
        field: 'member',
        headerName: 'Team Member',
        renderCell: renderAvatar,
        flex: 1
    }
];

export default function AssigneesDialog(props: Props) {

    const [searchInput, setSearchInput] = useState<string>("");
    const [alertError, setAlertError] = useState<string | undefined>(undefined);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

    const { onClose, open, conversationIds } = props;

    const { data, error } = useQuery<TeamPayload, TeamInput>(TEAM, {
        variables: {},
    });

    const [bulkAssignToConversation, { loading }] = useMutation<BulkAssignToConversationPayload, BulkAssignToConversationInput>(BULK_ASSIGN_TO_CONVERSATION,
        { refetchQueries: ["Conversations", "Conversation", "SideBarQuery", "Customers"] }
    );

    const handleClose = () => {
        onClose();
        setTimeout(() => {
            setAlertError(undefined);
            setSelectionModel([]);
            setSearchInput("");
        }, 200);
    };

    const handleSubmit = async () => {
        try {
            const { data } = await bulkAssignToConversation({
                variables: {
                    input: {
                        ids: conversationIds,
                        assigneeIds: selectionModel as string[]
                    }
                }
            });

            if (!!data) {
                if (data.bulkAssignToConversation.errors.length > 0) {
                    setAlertError('There was a problem performing this bulk action');
                } else {
                    handleClose();
                }
            }
        } catch {
            setAlertError('There was a problem performing this bulk action');
        }
    };

    const team: User[] = data?.team?.nodes ?? [];

    let rows = team.map((member) => {
        return {
            id: member.id,
            member: member
        }
    });

    if (searchInput.length > 0) {
        rows = rows.filter((obj) => obj.member.name.toLowerCase().includes(searchInput.toLowerCase()))
    }

    useEffect(() => {
        if (!!error) {
            setAlertError("Unable to fetch team");
        } else {
            setAlertError(undefined);
        }
    }, [error]);

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm" scroll="paper">
            <DialogTitle sx={{ m: 0, p: 2, pr: 8, pl: 3 }}>
                <TextField
                    fullWidth
                    size="small"
                    placeholder="Filter team"
                    onChange={(e) => setSearchInput(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FilterListOutlinedIcon />
                            </InputAdornment>
                        ),
                    }} />
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 20,
                        top: 16,
                        color: "text.secondary",
                    }}
                >
                    <CancelIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={1}>
                    {!!alertError && <Alert severity="error" title={alertError}></Alert>}
                    <div style={{ height: 300, width: '100%' }}>
                        <DataGrid
                            density="compact"
                            rows={rows}
                            selectionModel={selectionModel}
                            onSelectionModelChange={(newSelectionModel) => {
                                setSelectionModel(newSelectionModel);
                            }}
                            columns={columns}
                            checkboxSelection
                            hideFooter
                            disableColumnMenu
                            disableColumnReorder
                            disableColumnResize
                            disableChildrenSorting
                            components={{
                                BaseCheckbox: Checkbox,
                                NoResultsOverlay: () => (<div />),
                            }}
                        />
                    </div>
                </Stack>
            </DialogContent>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            </Box>
            <DialogActions sx={{ p: 2 }}>
                <Button size="small" disabled={selectionModel.length === 0 || loading} onClick={handleSubmit} variant="contained" color="success">Confirm</Button>
            </DialogActions>
        </Dialog>
    );
}
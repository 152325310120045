import { useState, useEffect } from "react";
import { GridRowsProp, GridColDef, GridSortModel, GridSortDirection } from "@mui/x-data-grid-pro";
import Checkbox from "theme/Checkbox";
import Button from "theme/Button";
import TextField from "theme/TextField";
import { useQuery } from "@apollo/client";
import ORDERS, {
    OrdersInput,
    OrdersPayload,
    OrdersFilter,
    OrdersSortInput,
} from "graphql/queries/OrdersQuery";
import ORDERS_COUNT, {
    OrdersCountInput,
    OrdersCountPayload,
} from "graphql/queries/OrdersCountQuery";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import Customer from "types/Customer";
import { renderDate } from "components/conversations/renderers/RenderDate";
import { renderCustomer } from "components/conversations/renderers/RenderCustomer";
import { renderOrderStatus } from "components/conversations/renderers/RenderOrderStatus";
import { NoScrollZenDataGrid } from "theme/DataGrid";

interface Props {
    customer?: Customer;
    selection: string | undefined;
    setSelection: React.Dispatch<React.SetStateAction<string | undefined>>;
}

function AssociateOrdersGrid(props: Props) {

    const { setSelection, customer } = props;
    const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
    const filter = "ALL" as OrdersFilter;
    const [page, setPage] = useState(0);
    const [after, setAfter] = useState<string | undefined>(undefined);
    const [before, setBefore] = useState<string | undefined>(undefined);
    const [sort, setSort] = useState<OrdersSortInput | undefined>({ date: "DESC" });

    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'date',
            sort: 'desc' as GridSortDirection,
        },
    ]);

    const [filteredByCustomer, setFilteredByCustomer] = useState<string | undefined>(customer?.id);

    const { data: customerOrdersCountData } = useQuery<
        OrdersCountPayload,
        OrdersCountInput
    >(ORDERS_COUNT, {
        fetchPolicy: "no-cache",
        variables: {
            filters: {
                searchQuery: searchQuery,
                customerId: customer?.id,
                filter: filter,
            },
        },
    });

    const { data: allOrdersCountData } = useQuery<
        OrdersCountPayload,
        OrdersCountInput
    >(ORDERS_COUNT, {
        fetchPolicy: "no-cache",
        variables: {
            filters: {
                searchQuery: searchQuery,
                filter: filter,
            },
        },
    });

    const { data: countData, loading: countLoading } = useQuery<
        OrdersCountPayload,
        OrdersCountInput
    >(ORDERS_COUNT, {
        fetchPolicy: "no-cache",
        variables: {
            filters: {
                searchQuery: searchQuery,
                customerId: filteredByCustomer,
                filter: filter,
            },
        },
    });

    const { data, error, loading } = useQuery<
        OrdersPayload,
        OrdersInput
    >(ORDERS, {
        fetchPolicy: "no-cache",
        variables: {
            after: after,
            before: before,
            first: !after && !before ? 10 : !!after ? 10 : undefined,
            last: !!before ? 10 : undefined,
            filters: {
                searchQuery: searchQuery,
                customerId: filteredByCustomer,
                filter: filter,
                sort: sort,
            },
        },
    });

    useEffect(() => {
        if (!!filteredByCustomer && !!customer) {
            setFilteredByCustomer(customer.id);
        }
    }, [customer]);

    useEffect(() => {
        setPage(0);
        setBefore(undefined);
        setAfter(undefined);
    }, [filteredByCustomer, sort, searchQuery]);

    const rows: GridRowsProp = !!data
        ? data.orders.nodes.map((row) => {
            return {
                id: row.id,
                number: row.name,
                date: row.createdAt,
                customer: row.customer,
                value: row.currentSubtotalPriceSet.shopAmount,
                payment: [row.financialStatus, !row.unpaid],
                fulfillment: [row.fulfillmentStatus, row.fulfilled],
                items: row.lineItemsCount,
                line_items_formatted: row.lineItemsFormatted,
            };
        })
        : [];

    const columns: GridColDef[] = [
        {
            field: "number",
            headerName: "Order #",
            width: 100,
        },
        {
            field: "date",
            headerName: "Date",
            width: 150,
            renderCell: renderDate
        },
        {
            field: "customer",
            headerName: "Customer",
            width: 200,
            renderCell: renderCustomer
        },
        {
            field: "value",
            headerName: "Value",
            width: 120,
        },
        {
            field: "line_items_formatted",
            headerName: "Items",
            width: 200,
        },
        {
            field: "items",
            headerName: "# Items",
            width: 120,
        },
        {
            field: "payment",
            headerName: "Payment",
            width: 150,
            renderCell: renderOrderStatus
        },
        {
            field: "fulfillment",
            headerName: "Fulfillment",
            width: 150,
            renderCell: renderOrderStatus
        },
    ];

    return (
        <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
                <div>
                    <TextField value={searchQuery} size="small" placeholder="Filter results" onChange={(e) => setSearchQuery(e.target.value)}></TextField>
                </div>
                <div>
                    <Button
                        size="small"
                        variant={!!filteredByCustomer ? "contained" : "text"}
                        color={!!filteredByCustomer ? "primary" : "inherit"}
                        onClick={() => setFilteredByCustomer(customer?.id)}>Customer Orders ({customerOrdersCountData?.ordersCount})</Button>
                </div>
                <div>
                    <Button
                        size="small"
                        variant={!!filteredByCustomer ? "text" : "contained"}
                        color={!!filteredByCustomer ? "inherit" : "primary"}
                        onClick={() => setFilteredByCustomer(undefined)}>All Orders ({allOrdersCountData?.ordersCount})</Button>

                </div>
            </Stack>

            {!!error && <Alert severity="error">{error.message}</Alert>}
            <Stack direction="column" style={{ minHeight: 480 }}>
                <NoScrollZenDataGrid
                    style={{ height: "100%" }}
                    disableColumnPinning
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    onSortModelChange={(model) => {
                        if (model.length > 0) {
                            const field = model[0];
                            const sort = field.sort;
                            if (!!sort) {
                                switch (field.field) {
                                    case "number": {
                                        setSort({ name: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                    case "date": {
                                        setSort({ date: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                    case "customer": {
                                        setSort({ customerName: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                    case "value": {
                                        setSort({ value: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                    case "line_items_formatted": {
                                        setSort({ lineItems: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                    case "payment": {
                                        setSort({ payment: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                    case "fulfillment": {
                                        setSort({ fulfillment: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                    case "items": {
                                        setSort({ itemCount: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                }
                            } else {
                                setSort(undefined);
                            }
                        } else {
                            setSort(undefined);
                        }
                        setSortModel(model);
                    }}
                    components={{
                        BaseCheckbox: Checkbox,
                        NoResultsOverlay: () => (<div />),
                    }}
                    disableColumnFilter
                    disableColumnReorder
                    disableColumnSelector
                    disableMultipleSelection
                    rows={rows}
                    columns={columns}
                    loading={loading || countLoading}
                    page={page}
                    onPageChange={(newPage) => {
                        if (loading || countLoading) {
                            return;
                        }
                        if (newPage > page) {
                            setAfter(data!.orders.pageInfo.endCursor);
                            setBefore(undefined);
                        } else {
                            setAfter(undefined);
                            setBefore(data!.orders.pageInfo.startCursor);
                        }
                        setPage(newPage);
                    }}
                    rowHeight={35}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    rowCount={!!countData ? countData.ordersCount : 0}
                    paginationMode="server"
                    sortingMode="server"
                    pagination
                    onCellClick={(row) => {
                        setSelection(row.id as string);
                    }}
                />
            </Stack>
        </Stack>
    );
}

export default AssociateOrdersGrid;
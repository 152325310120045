import { useState, useEffect } from "react";
import useDebounce from "hooks/useDebounce";
import Box from "@mui/material/Box";
import { useQuery, useSubscription } from "@apollo/client";
import CONVERSATIONS, {
  ConversationsInput,
  ConversationsPayload,
  ConversationsFilter,
} from "graphql/queries/ConversationsQuery";
import WORKFLOWS, {
  WorkflowsInput,
  WorkflowsPayload,
} from "graphql/queries/WorkflowsQuery";
import MY_NOTIFICATIONS_UPDATED, {
  MyNotificationsUpdatedInput,
  MyNotificationsUpdatedPayload
} from "graphql/subscriptions/MyNotificationsUpdatedSubscription";
import ME, {
  MeInput,
  MePayload,
} from "graphql/queries/MeQuery";
import Alert from "@mui/material/Alert";
import { GridRowsProp, GridColDef, GridSortDirection, GridSortModel, GridSelectionModel } from "@mui/x-data-grid-pro";
import { NoScrollZenDataGrid } from "theme/DataGrid";
import { useHistory } from "react-router-dom";
import useTheme from "@mui/material/styles/useTheme";
import Stack from "@mui/material/Stack";
import TextField from "theme/TextField";
import { renderTimeLeft } from "./renderers/RenderTimeLeft";
import { renderLabel } from "./renderers/RenderLabel";
import { renderAssignee } from "./renderers/RenderAssignee";
import { renderCustomer } from "./renderers/RenderCustomer";
import { renderMessage } from "./renderers/RenderMessage";
import ConversationsLeftSideBar from "components/chrome/ConversationsLeftSideBar";
import Checkbox from "theme/Checkbox";
import useQueryParams from "hooks/useQueryParams";
import { parseDateString } from "helpers/DateFormatters";
import Button from "theme/Button";
import BackspaceIcon from '@mui/icons-material/Backspace';
import { useSnackbar } from 'notistack';
import { ConversationsSortInput } from "graphql/queries/ConversationsQuery";
import AddIcon from '@mui/icons-material/Add';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import ConfirmBulkMarkAsResolvedDialog from "./bulk_actions/ConfirmBulkMarkAsResolvedDialog";
import ConfirmBulkMarkAsSpamDialog from "./bulk_actions/ConfirmBulkMarkAsSpamDialog";
import AssigneesDialog from "components/assignees/AssigneesDialog";
import LabelsDialog from "components/labels/LabelsDialog";
import ConfirmBulkReplyDialog from "./bulk_actions/ConfirmBulkReplyDialog";
import InputAdornment from '@mui/material/InputAdornment';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { useStoreon } from 'storeon/react';
import State from "store/State";
import ConversationsTabs from "components/conversation/ConversationTabs";
import NewConversationDialog from "./NewConversationDialog";
import Divider from "theme/Divider";
import ConfirmRunWorkflow from "./ConfirmRunWorkflow";

export default function ConversationsGrid() {

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const history = useHistory();
  const query = useQueryParams();
  const [selectedWorkflowId, setSelectedWorkflowId] = useState<string | undefined>(undefined);
  const [newConversationOpen, setNewConversationOpen] = useState<boolean>(false);
  const [confirmMarkResolved, setConfirmMarkResolved] = useState<boolean>(false);
  const [confirmMarkAsSpam, setConfirmMarkAsSpam] = useState<boolean>(false);
  const [bulkReplyOpen, setBulkReplyOpen] = useState<boolean>(false);
  const [assigneesDialogOpen, setAssigneesDialogOpen] = useState<boolean>(false);
  const [labelsDialogOpen, setLabelsDialogOpen] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const debouncedSearchQuery = useDebounce<string | undefined>(searchQuery, 500);

  const [page, setPage] = useState(Number(query.get("page")) ?? 0);
  const [after, setAfter] = useState<string | undefined>(query.get("after") ?? undefined);
  const [before, setBefore] = useState<string | undefined>(query.get("before") ?? undefined);

  const { dispatch, conversationsGridFilter: filter, conversationsGridCoversationTypeId: conversationTypeId, conversationsGridLabelIds: labelIds, conversationsGridAssigneeIds: assigneeIds } = useStoreon<State>('conversationsGridFilter', 'conversationsGridCoversationTypeId', 'conversationsGridLabelIds', 'conversationsGridAssigneeIds');

  const [sort, setSort] = useState<ConversationsSortInput | undefined>(filter === "DONE" ? { resolvedAt: "DESC" } : { timeLeft: "ASC" });
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const [bulkActionAnchorEl, setBulkActionAnchorEl] = useState<undefined | HTMLElement>(undefined);
  const bulkActionOpen = Boolean(bulkActionAnchorEl);

  const [sortModel, setSortModel] = useState<GridSortModel>(filter === "DONE" ? [] : [
    {
      field: 'time_left',
      sort: 'asc' as GridSortDirection,
    },
  ]);


  const { data: meData } = useQuery<MePayload, MeInput>(ME, {
    variables: {},
  });

  const { data: notificationsUpdatedData } = useSubscription<MyNotificationsUpdatedPayload, MyNotificationsUpdatedInput>(MY_NOTIFICATIONS_UPDATED, {
    variables: { userId: meData?.me?.id || "" },
  });

  const { data: workFlowsData } = useQuery<WorkflowsPayload, WorkflowsInput>(WORKFLOWS, { variables: { filters: {} } });

  const { data, error, loading, refetch } = useQuery<ConversationsPayload, ConversationsInput>(CONVERSATIONS, {
    fetchPolicy: "no-cache",
    variables: {
      after: after,
      before: before,
      first: !after && !before ? 10 : !!after ? 10 : undefined,
      last: !!before ? 10 : undefined,
      filters: {
        filter: filter as ConversationsFilter,
        searchQuery: debouncedSearchQuery,
        conversationTypeId: conversationTypeId,
        labelIds: labelIds,
        assigneeIds: assigneeIds,
        sort: sort
      },
    },
  });

  const rows: GridRowsProp = !!(data?.conversations)
    ? data.conversations.nodes.map((row) => {
      return {
        id: row.id,
        type: row.type.title,
        message: row.latestMessage ?? "",
        labels: row.labels.nodes ?? [],
        time_left: !!row.timeLeft ? parseDateString(row.timeLeft) : undefined,
        customer_name: row.customer,
        assignee: row.assignees.nodes ?? [],
        message_count: row.messageCount,
        total_order_value: row.totalOrderValue
      };
    })
    : [];

  const columns: GridColDef[] = [
    {
      field: "customer_name",
      headerName: "Customer",
      width: 150,
      renderCell: renderCustomer,
    },
    {
      field: "type",
      headerName: "Type",
      width: 150,
    },
    {
      field: "labels",
      headerName: "Labels",
      width: 250,
      renderCell: renderLabel,
    },
    {
      field: "message",
      headerName: "Latest Message",
      width: 300,
      renderCell: renderMessage,
    },
    {
      field: "assignee",
      headerName: "Assignee",
      width: 150,
      renderCell: renderAssignee,
    },
    {
      field: "time_left",
      headerName: "Time Left",
      width: 150,
      renderCell: renderTimeLeft,
    },
    {
      field: "total_order_value",
      headerName: "Order value",
      width: 150,
    },
    {
      field: "message_count",
      headerName: "Messages",
      width: 100,
    }
  ];

  const navigateToAfter = async (after: string, page: number) => {
    setPage(page);
    setAfter(after);
    setBefore(undefined);
  };

  const navigateToBefore = async (before: string, page: number) => {
    setPage(page);
    setAfter(undefined);
    setBefore(before);
  };

  const navigateStart = async () => {
    setPage(0);
    setSelectionModel([]);
    setBefore(undefined);
    setAfter(undefined);
    refetch();
  }

  const performBulkAction = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (selectionModel.length > 0) {
      setBulkActionAnchorEl(e.currentTarget);
    } else {
      enqueueSnackbar('Select some conversations before performing a bulk action');
    }
  }

  const bulkActionHandleClose = () => {
    setBulkActionAnchorEl(undefined);
  }

  useEffect(() => {
    navigateStart();
  }, [debouncedSearchQuery, filter, sort, conversationTypeId, labelIds, assigneeIds]);

  useEffect(() => {
    if (!!notificationsUpdatedData?.myNotificationsUpdated) {
      refetch();
    }
  }, [notificationsUpdatedData]);

  return (
    <Box sx={{ display: "flex" }}>
      <NewConversationDialog open={newConversationOpen} onClose={() => setNewConversationOpen(false)} />
      <ConfirmRunWorkflow
        open={Boolean(selectedWorkflowId)}
        selectedWorkflowId={selectedWorkflowId}
        conversationIds={selectionModel as string[]}
        onClose={() => {
          setSelectedWorkflowId(undefined);
          setSelectionModel([]);
        }} />
      <ConfirmBulkReplyDialog
        open={bulkReplyOpen}
        onClose={() => {
          setBulkReplyOpen(false);
          setSelectionModel([]);
        }}
        conversationIds={selectionModel as string[]} />
      <LabelsDialog
        open={labelsDialogOpen}
        onClose={() => {
          setLabelsDialogOpen(false);
          setSelectionModel([]);
        }}
        conversationIds={selectionModel as string[]} />
      <AssigneesDialog
        open={assigneesDialogOpen}
        onClose={() => {
          setAssigneesDialogOpen(false);
          setSelectionModel([]);
        }}
        conversationIds={selectionModel as string[]} />
      <ConfirmBulkMarkAsResolvedDialog
        open={confirmMarkResolved}
        resolve={filter !== "DONE"}
        onClose={(completed) => {
          setConfirmMarkResolved(false);
          setSelectionModel([]);
          if (completed) {
            navigateStart();
          }
        }}
        conversationIds={selectionModel as string[]} />
      <ConfirmBulkMarkAsSpamDialog
        open={confirmMarkAsSpam}
        spam={filter !== "SPAM"}
        onClose={(completed) => {
          setConfirmMarkAsSpam(false);
          setSelectionModel([]);
          if (completed) {
            navigateStart();
          }
        }}
        conversationIds={selectionModel as string[]} />
      <Menu
        variant="selectedMenu"
        anchorEl={bulkActionAnchorEl}
        open={bulkActionOpen}
        onClose={bulkActionHandleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            minWidth: 200,
            outline: "none",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 42,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Stack direction="column" spacing={0}>
          {filter !== "SPAM" && <MenuItem dense onClick={() => {
            setBulkActionAnchorEl(undefined);
            setConfirmMarkResolved(true);
          }}>
            <Typography variant="body2">{filter === "DONE" ? "Mark as in progress" : "Mark as resolved"}</Typography>
          </MenuItem>}
          <MenuItem onClick={() => {
            setBulkActionAnchorEl(undefined);
            setConfirmMarkAsSpam(true);
          }}>
            <Typography variant="body2">{filter === "SPAM" ? "Mark as not spam" : "Mark as spam"} </Typography>
          </MenuItem>
          {filter !== "SPAM" && <MenuItem onClick={() => {
            setBulkActionAnchorEl(undefined);
            setAssigneesDialogOpen(true);
          }}>
            <Typography variant="body2">Assign to</Typography>
          </MenuItem>}
          {filter !== "SPAM" && <MenuItem onClick={() => {
            setBulkActionAnchorEl(undefined);
            setLabelsDialogOpen(true);
          }}>
            <Typography variant="body2">Apply labels</Typography>
          </MenuItem>}
          {filter !== "SPAM" && <MenuItem onClick={() => {
            setBulkActionAnchorEl(undefined);
            setBulkReplyOpen(true);
          }}>
            <Typography variant="body2">Reply with message</Typography>
          </MenuItem>}
          {!!workFlowsData?.workflows?.nodes && <><Divider sx={{ margin: "0px !important" }} /><Typography sx={{ p: 1, pl: 2, pb: 0, fontWeight: 600 }} variant="caption">Workflows</Typography></>}
          {workFlowsData?.workflows?.nodes?.map((workflow) => {
            return <MenuItem key={workflow.id} onClick={() => {
              setSelectedWorkflowId(workflow.id);
              setBulkActionAnchorEl(undefined);
            }}>
              <Typography variant="body2">{workflow.name}</Typography>
            </MenuItem>
          })}
        </Stack>
      </Menu>
      <Box sx={{ width: 300, p: theme.spacing(2) }}>
        <ConversationsLeftSideBar />
      </Box>
      <Stack
        direction="column"
        spacing={2}
        sx={{ p: theme.spacing(2), pl: 0, width: "calc(100% - 300px)", height: "min-content" }}
      >
        {!!error && <Alert severity="error">{error.message}</Alert>}
        <ConversationsTabs />
        <Stack direction="row" spacing={1} alignItems="center">
          <TextField value={searchQuery} size="small" placeholder="Filter results"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FilterListOutlinedIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setSearchQuery(e.target.value);
            }}></TextField>
          {(labelIds.length > 0 || assigneeIds.length > 0 || !!conversationTypeId || !!searchQuery) &&
            <div style={{ color: theme.palette.text.secondary }}>
              <Button size="small" onClick={() => {
                setSearchQuery("");
                dispatch("conversations/setLabels", []);
                dispatch("conversations/setType", undefined);
                dispatch("conversations/setAssignees", []);
              }} variant="outlined" startIcon={<BackspaceIcon />} color="inherit">Clear applied search filters</Button>
            </div>}
          <div style={{ flexGrow: 1 }} />
          <Button disabled={selectionModel.length == 0} size="small" startIcon={<ElectricBoltIcon />} variant="contained" color="info" onClick={performBulkAction}>
            Bulk action
          </Button>
          <Button size="small" startIcon={<AddIcon />} color="secondary" variant="contained" onClick={() => {
            setNewConversationOpen(true);
          }}>Start conversation</Button>
        </Stack>
        <Stack direction="column" style={{ minHeight: 659 }} sx={{
          '& .notifications--Unread': {

          },
          '& .notifications--Read': {
            bgcolor: () => "rgba(234,238,242,0.5)",
            '&:hover': {
              bgcolor: () => "rgba(234,238,242,0.9)",
            }
          },
        }}>
          <NoScrollZenDataGrid
            getRowClassName={(params) => {
              return `notifications--${!!params.row.time_left ? "Unread" : "Read"}`
            }}
            style={{ height: "100%" }}
            autoHeight={false}
            disableColumnPinning
            sortingOrder={['asc', 'desc']}
            sortModel={sortModel}
            selectionModel={selectionModel}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            onSortModelChange={(model) => {
              if (model.length > 0) {
                const field = model[0];
                const sort = field.sort;
                if (!!sort) {
                  switch (field.field) {
                    case "number": {
                      setSort({ id: sort.toUpperCase() as "ASC" | "DESC" });
                      break;
                    }
                    case "customer_name": {
                      setSort({ customerName: sort.toUpperCase() as "ASC" | "DESC" });
                      break;
                    }
                    case "type": {
                      setSort({ conversationType: sort.toUpperCase() as "ASC" | "DESC" });
                      break;
                    }
                    case "labels": {
                      setSort({ labels: sort.toUpperCase() as "ASC" | "DESC" });
                      break;
                    }
                    case "message": {
                      setSort({ latestMessage: sort.toUpperCase() as "ASC" | "DESC" });
                      break;
                    }
                    case "assignee": {
                      setSort({ assignee: sort.toUpperCase() as "ASC" | "DESC" });
                      break;
                    }
                    case "time_left": {
                      setSort({ timeLeft: sort.toUpperCase() as "ASC" | "DESC" });
                      break;
                    }
                    case "message_count": {
                      setSort({ numberOfMessages: sort.toUpperCase() as "ASC" | "DESC" });
                      break;
                    }
                    case "total_order_value": {
                      setSort({ totalOrderValue: sort.toUpperCase() as "ASC" | "DESC" });
                      break;
                    }
                  }
                } else {
                  setSort(undefined);
                }
              } else {
                setSort(undefined);
              }
              setSortModel(model);
            }}
            components={{
              BaseCheckbox: Checkbox,
              NoRowsOverlay: () => {

                if (filter === "INBOX") {
                  return (
                    <Stack zIndex={100} direction="row" justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
                      <Stack direction="column" alignItems="center" spacing={2}>
                        <Typography variant="h5">You inbox is empty 🦄</Typography>
                      </Stack>
                    </Stack>);
                }

                return (
                  <Stack zIndex={100} direction="row" justifyContent="center" alignItems="center" sx={{ height: "100%", }}>
                    <Stack direction="column" alignItems="center" spacing={2}>
                      <Typography variant="h5">No results</Typography>
                    </Stack>
                  </Stack>);
              },
            }}
            disableColumnFilter
            disableColumnReorder
            disableColumnSelector
            rows={rows}
            columns={columns}
            loading={!data}
            page={page}
            onPageChange={(newPage) => {
              if (!loading && !!(data?.conversations)) {
                if (newPage > page) {
                  navigateToAfter(data.conversations.pageInfo.endCursor, newPage);
                } else {
                  navigateToBefore(data.conversations.pageInfo.startCursor, newPage);
                }
              }
            }}
            rowHeight={55}
            pageSize={10}
            rowsPerPageOptions={[10]}
            rowCount={!!(data?.conversations) ? data.conversations.pageInfo.totalCount : 0}
            sortingMode="server"
            paginationMode="server"
            pagination
            checkboxSelection
            disableSelectionOnClick
            onRowClick={(row) => {
              history.push(`/conversation/${row.id}`)
            }}
          />
        </Stack>
      </Stack>
    </Box >
  );
}
import { gql } from "@apollo/client";
import Conversation from "types/Conversation";

export interface ConversationsSortInput {
  id?: "ASC" | "DESC",
  customerName?: "ASC" | "DESC",
  conversationType?: "ASC" | "DESC",
  labels?: "ASC" | "DESC",
  latestMessage?: "ASC" | "DESC",
  assignee?: "ASC" | "DESC",
  timeLeft?: "ASC" | "DESC",
  numberOfMessages?: "ASC" | "DESC",
  totalOrderValue?: "ASC" | "DESC",
  resolvedAt?: "ASC" | "DESC",
}

export interface ConversationsFilterInput {
  filter: ConversationsFilter;
  searchQuery?: string;
  conversationTypeId?: string;
  labelIds?: string[];
  assigneeIds?: string[];
  customerId?: string;
  sort?: ConversationsSortInput;
}

export enum ConversationsFilter {
  inbox = "INBOX",
  mine = "MINE",
  mentioned = "MENTIONED",
  todo = "TODO",
  waiting = "WAITING",
  saved = "SAVED",
  done = "DONE",
  spam = "SPAM",
  customer_id = "CUSTOMER_ID"
}

export interface ConversationsInput {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
  filters: ConversationsFilterInput;
}

export interface ConversationsPayload {
  conversations?: {
    pageInfo: {
      totalCount: number;
      startCursor: string;
      endCursor: string;
    };
    nodes: Conversation[];
  };
  errors: string[];
}

const CONVERSATIONS = gql`
  query Conversations(
    $after: String
    $before: String
    $last: Int
    $first: Int,
    $filters: ConversationFilterInput!
  ) {
    conversations(
      first: $first
      last: $last
      after: $after
      before: $before,
      filters: $filters,
    ) {
      pageInfo {
        totalCount
        startCursor
        endCursor
      }
      nodes {
        id
        createdAt
        timeLeft
        resolvedAt
        messageCount
        number
        latestMessage
        totalOrderValue
        type {
          id
          title
        }
        customer {
          id
          name
          email
        }
        assignees {
          nodes {
            id
            name
            avatarUrl
          }
        }
        labels {
          nodes {
            id
            title
            colorHex
          }
        }
      }
    }
  }
`;

export default CONVERSATIONS;

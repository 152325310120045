import { useState, useEffect } from "react";
import { GridRowsProp, GridColDef, GridSelectionModel, GridSortModel, GridSortDirection } from "@mui/x-data-grid-pro";
import { NoScrollZenDataGrid } from "theme/DataGrid";
import Checkbox from "theme/Checkbox";
import { useQuery } from "@apollo/client";
import WORKFLOWS, {
    WorkflowsInput,
    WorkflowsPayload,
    WorkflowsSortInput,
} from "graphql/queries/WorkflowsQuery";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import { useHistory } from "react-router-dom";
import TextField from "theme/TextField";
import useTheme from "@mui/material/styles/useTheme";
import Button from "theme/Button";
import BackspaceIcon from '@mui/icons-material/Backspace';
import useQueryParams from "hooks/useQueryParams";
import AddIcon from '@mui/icons-material/Add';
import NewWorkflowDialog from "./workflows/NewWorkflowDialog";
import useDebounce from "hooks/useDebounce";
import InputAdornment from '@mui/material/InputAdornment';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import EditWorkflowDialog from "./workflows/EditWorkflowDialog";

function WorkflowsGrid() {

    const theme = useTheme();
    const history = useHistory();
    const query = useQueryParams();

    const editWorkflowOpen = Boolean(query.get("edit"));
    const workflowId = query.get("edit") ?? undefined;

    const [newWorkflowOpen, setNewWorkflowOpen] = useState<boolean>(false);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [page, setPage] = useState<number>(Number(query.get("page")) ?? 0);
    const [after, setAfter] = useState<string | undefined>(query.get("after") ?? undefined);
    const [before, setBefore] = useState<string | undefined>(query.get("before") ?? undefined);
    const [sort, setSort] = useState<WorkflowsSortInput | undefined>({ name: "ASC" });

    const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
    const debouncedSearchQuery = useDebounce<string | undefined>(searchQuery, 500);

    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'name',
            sort: 'asc' as GridSortDirection,
        },
    ]);

    const { data, error, loading, refetch } = useQuery<WorkflowsPayload, WorkflowsInput>(WORKFLOWS, {
        variables: {
            after: after,
            before: before,
            first: !after && !before ? 10 : !!after ? 10 : undefined,
            last: !!before ? 10 : undefined,
            filters: {
                sort: sort,
                searchQuery: debouncedSearchQuery
            },
        },
    });

    const rows: GridRowsProp = !!data
        ? data.workflows.nodes.map((row) => {
            return {
                id: row.id,
                name: row.name,
                privateWorkflow: !!row.privateWorkflow ? "Private" : "Team",
                template: row.template.name,
            };
        })
        : [];

    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: "Name",
            width: 300,
        },
        {
            field: "privateWorkflow",
            headerName: "Permissions",
            width: 300,
        },
        {
            field: "template",
            headerName: "Template",
            width: 300,
        },
    ];

    function navigateToAfter(after: string, page: number) {
        setPage(page);
        setBefore(undefined);
        setAfter(after);
    };

    function navigateToBefore(before: string, page: number) {
        setPage(page);
        setBefore(before);
        setAfter(undefined);
    };

    function navigateStart() {
        setPage(0);
        setBefore(undefined);
        setAfter(undefined);
    }

    useEffect(() => {
        navigateStart();
        refetch();
    }, [sort, searchQuery]);

    return (
        <Stack direction="column" spacing={2} sx={{ pb: theme.spacing(2), pl: 0, width: "100%", height: "min-content" }}>
            <NewWorkflowDialog open={newWorkflowOpen} onClose={() => {
                setNewWorkflowOpen(false);
            }} />
            <EditWorkflowDialog open={editWorkflowOpen} selectedWorkflowId={workflowId} onClose={() => history.push("/org-settings/workflows")} />
            {!!error && <Alert severity="error">{error.message}</Alert>}
            <Stack direction="row" spacing={1} alignItems="center">
                <TextField value={searchQuery} size="small" placeholder="Filter results"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FilterListOutlinedIcon />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => setSearchQuery(e.target.value)}></TextField>
                {(!!searchQuery) &&
                    <div style={{ color: theme.palette.text.secondary }}>
                        <Button onClick={() => { setSearchQuery(""); }} variant="outlined" startIcon={<BackspaceIcon />} color="inherit">Clear applied search filters</Button>
                    </div>}
                <div style={{ flexGrow: 1 }} />
                <Button size="small" startIcon={<AddIcon />} color="secondary" variant="contained" onClick={() => {
                    setNewWorkflowOpen(true);
                }}>New workflow</Button>
            </Stack>
            <Stack direction="column" style={{ minHeight: 700 }}>
                <NoScrollZenDataGrid
                    style={{ height: "100%" }}
                    disableColumnPinning
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    onSortModelChange={(model) => {
                        if (model.length > 0) {
                            const field = model[0];
                            const sort = field.sort;
                            if (!!sort) {
                                switch (field.field) {
                                    case "name": {
                                        setSort({ name: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                    case "privateWorkflow": {
                                        setSort({ permissions: sort.toUpperCase() as "ASC" | "DESC" });
                                        break;
                                    }
                                }
                            } else {
                                setSort(undefined);
                            }
                        } else {
                            setSort(undefined);
                        }
                        setSortModel(model);
                    }}
                    components={{
                        BaseCheckbox: Checkbox,
                        NoResultsOverlay: () => (<div />),
                    }}
                    disableColumnFilter
                    disableColumnReorder
                    disableColumnSelector
                    rows={rows}
                    columns={columns}
                    loading={!data}
                    page={page}
                    onPageChange={(newPage) => {
                        if (loading) {
                            return;
                        }
                        if (newPage > page) {
                            navigateToAfter(data!.workflows.pageInfo.endCursor, newPage);
                        } else {
                            navigateToBefore(data!.workflows.pageInfo.startCursor, newPage);
                        }
                    }}
                    rowHeight={55}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    rowCount={!!(data?.workflows) ? data.workflows.pageInfo.totalCount : 0}
                    paginationMode="server"
                    sortingMode="server"
                    pagination
                    disableSelectionOnClick
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                    onRowClick={(row) => {
                        history.push(`/org-settings/workflows?edit=${row.id}`);
                    }}
                />
            </Stack>
        </Stack>
    );
}

export default WorkflowsGrid;
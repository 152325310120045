import { gql } from "@apollo/client";
import Template from "types/Template";
import TemplateFragment from "../fragments/TemplateFragment";

export interface CreateTemplateInput {
  input: {
    name: string;
    subject: string;
    shortcut: string;
    contentHtml: string;
    layoutId: string;
    privateTemplate: boolean;
  };
}

export interface CreateTemplatePayload {
  createTemplate: {
    errors: string[];
    template?: Template;
  };
}

const CREATE_TEMPLATE = gql`
  mutation CreateTemplate($input: CreateTemplateMutationInput!) {
    createTemplate(input: $input) {
      errors
      template {
        ...TemplateFields
      }
    }
  }
  ${TemplateFragment}
`;

export default CREATE_TEMPLATE;

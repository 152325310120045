import { gql } from "@apollo/client";
import Workflow from "types/Workflow";
import WorkflowFragment from "../fragments/WorkflowFragment";
import TemplateFragment from "../fragments/TemplateFragment";

export interface CreateWorkflowInput {
    input: {
        name: string;
        privateWorkflow: boolean;
        resolvesConversation: boolean;
        templateId: string;
    };
}

export interface CreateWorkflowPayload {
    createWorkflow: {
        workflow?: Workflow;
    };
}

const CREATE_WORKFLOW = gql`
  mutation CreateWorkflow($input: CreateWorkflowMutationInput!) {
    createWorkflow(input: $input) {
      workflow {
        ...WorkflowFields
        template {
            ...TemplateFields
        }
      }
    }
  }
  ${WorkflowFragment},
  ${TemplateFragment}
`;

export default CREATE_WORKFLOW;

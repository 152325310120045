import { gql } from "@apollo/client";
import EmailDomain from "types/EmailDomain";
import EmailDomainFragment from "graphql/fragments/EmailDomainFragment";

export interface CreateEmailDomainInput {
  input: {
    address: string;
    customDomain?: string;
  };
}

export interface CreateEmailDomainPayload {
  createEmailDomain: {
    emailDomain?: EmailDomain;
  };
}

const CREATE_EMAIL_DOMAIN = gql`
  mutation CreateEmailDomain($input: CreateEmailDomainMutationInput!) {
    createEmailDomain(input: $input) {
      emailDomain {
        ...EmailDomainFields
      }
    }
  }
  ${EmailDomainFragment}
`;

export default CREATE_EMAIL_DOMAIN;
import { gql } from "@apollo/client";
import Layout from "types/Layout";

export interface LayoutsSortInput {
  name?: "ASC" | "DESC",
}

export interface LayoutsInput {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
  filters: LayoutsFilterInput;
}
export interface LayoutsFilterInput {
  searchQuery?: string;
  sort?: LayoutsSortInput;
}

export interface LayoutsPayload {
  layouts: {
    pageInfo: {
      totalCount: number;
      startCursor: string;
      endCursor: string;
    };
    nodes: Layout[];
  };
}

const LAYOUTS = gql`
  query Layouts(
    $after: String
    $before: String
    $last: Int
    $first: Int,
    $filters: LayoutFilterInput!
  ) {
    layouts(
      first: $first
      last: $last
      after: $after
      before: $before,
      filters: $filters,
    ) {
      pageInfo {
        totalCount
        startCursor
        endCursor
      }
      nodes {
        id
        createdAt
        deletable
        name
      }
    }
  }
`;

export default LAYOUTS;
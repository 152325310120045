import gql from "graphql-tag";

const LayoutFragment = gql`
  fragment LayoutFields on Layout {
    id
    createdAt
    layoutHtml
    layoutPlain
    deletable
    name
  }
`;

export default LayoutFragment;

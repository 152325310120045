import { lazy, Suspense } from "react";
import Box from "@mui/material/Box";

const HelpdeskMenu = lazy(() => import("components/articles/HelpdeskMenu"));

function AddressBook() {
    return (
        <Box>
            <Suspense fallback={<div></div>}>
                <HelpdeskMenu />
            </Suspense>
        </Box>
    );
}

export default AddressBook;

import gql from "graphql-tag";

const WorkflowFragment = gql`
  fragment WorkflowFields on Workflow {
    id
    createdAt
    name
    privateWorkflow
    resolvesConversation
  }
`;

export default WorkflowFragment;

import { useState, useEffect } from "react";
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from 'theme/Button';
import Dialog from '@mui/material/Dialog';
import TextField from 'theme/TextField';
import { useMutation } from "@apollo/client";
import CREATE_CONVERSATION_TYPE, { CreateConversationTypeInput, CreateConversationTypePayload } from "graphql/mutations/CreateConversationTypeMutation";
import Typography from "@mui/material/Typography";
import { useForm, Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import useTheme from "@mui/material/styles/useTheme";

interface Props {
    open: boolean;
    onClose: () => void;
}

export default function NewConversationTypeDialog(props: Props) {

    const { onClose, open } = props;

    const [alertError, setAlertError] = useState<string | undefined>(undefined);
    const theme = useTheme();

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const [createConversationType, { loading: createConversationTypeLoading, error }] = useMutation<CreateConversationTypePayload, CreateConversationTypeInput>(CREATE_CONVERSATION_TYPE, {
        refetchQueries: ["ConversationTypes", "SideBarQuery"]
    });

    useEffect(() => {
        if (!!error) {
            setAlertError(error.message);
        } else {
            setAlertError(undefined);
        }
    }, [error]);

    const handleClose = () => {
        onClose();
        setTimeout(() => {
            reset();
            setAlertError(undefined);
        }, 200);
    };

    const onSubmitCreateConversationType = async (formData: { [x: string]: any; }) => {
        try {
            const { data } = await createConversationType({
                variables: {
                    input: {
                        title: formData.conversation_type_title,
                        descriptionHtml: "<p></p>"
                    }
                }
            });

            if (!!data) {
                if (data.createConversationType.errors.length > 0) {
                    setAlertError(data.createConversationType.errors[0]);
                } if (!!data.createConversationType.conversationType) {
                    handleClose();
                }
            }
        } catch {
            setAlertError('There was a problem creating this conversation type');
        }
    };

    return (
        <Dialog onClose={() => handleClose()} open={open} scroll="paper" maxWidth="xs" fullWidth>
            <DialogTitle>
                <Typography variant="h5">New conversation type</Typography>
            </DialogTitle>
            <DialogContent dividers sx={{ backgroundColor: theme.palette.background.default }}>
                <Stack direction="column" spacing={1}>
                    {!!alertError && <Alert severity="error" icon={false}>{alertError}</Alert>}
                    <Stack direction="column" spacing={2}>
                        <form onSubmit={handleSubmit(onSubmitCreateConversationType)}>
                            <Stack direction="row" spacing={2}>
                                <FormControl variant="outlined" fullWidth>
                                    <Controller
                                        name="conversation_type_title"
                                        rules={{
                                            required: true,
                                            maxLength: 150,
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                id="conversation_type_title"
                                                type="conversation_type_title"
                                                sx={{ backgroundColor: theme.palette.background.paper }}
                                                fullWidth
                                                size="small"
                                                helperText={errors.conversation_type_title ? errors.conversation_type_title.message : undefined}
                                                variant="outlined"
                                                placeholder="Type"
                                                error={!!errors.conversation_type_title}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                        control={control}
                                    />
                                </FormControl>
                            </Stack>
                        </form>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <Button size="small" onClick={() => handleClose()} color="inherit">Close</Button>
                <Button onClick={handleSubmit(onSubmitCreateConversationType)} size="small" disabled={createConversationTypeLoading} variant="contained" color="success">Create</Button>
            </DialogActions>
        </Dialog>
    );
}
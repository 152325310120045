import { gql } from "@apollo/client";
import OrgFile from "types/OrgFile";
import OrgFileFragment from "../fragments/OrgFileFragment";

export interface OrgFileSortInput {
  givenFilename?: "ASC" | "DESC",
  shortcut?: "ASC" | "DESC",
  privateFile?: "ASC" | "DESC",
}

export interface OrgFileFilterInput {
  searchQuery?: string;
  privateFile?: boolean;
}

export interface OrgFilesInput {
  filters: OrgFileFilterInput;
}

export interface OrgFilesPayload {
  orgFiles: {
    nodes: OrgFile[];
  };
}

const ORG_FILES = gql`
  query OrgFiles($filters: OrgFileFilterInput!) {
    orgFiles(filters: $filters) {
      nodes {
          ...OrgFileFields
      }
    }
  }
  ${OrgFileFragment}
`;

export default ORG_FILES;
import { gql } from "@apollo/client";
import Conversation from "types/Conversation";
import CompleteConversationFragment from "../fragments/CompleteConversationFragment";

export interface ConversationInput {
  id: string;
}

export interface ConversationPayload {
  conversation: Conversation;
}

const CONVERSATION = gql`
  query Conversation($id: ID!) {
    conversation(id: $id) {
      ...CompleteConversationFields
    }
  }
  ${CompleteConversationFragment}
`;

export default CONVERSATION;

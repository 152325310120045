import { useState } from "react";
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from 'theme/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CancelIcon from "@mui/icons-material/Cancel";
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FileDropzone from 'components/file_dropzone/FileDropzone';
import Checkbox from "theme/Checkbox";
import Typography from "@mui/material/Typography";

export interface Props {
    open: boolean;
    onClose: () => void;
}

export default function UploadFileDialog(props: Props) {

    const [sharedFile, setSharedFile] = useState<string>("shared");
    const [step, setStep] = useState<number>(0);

    const [alertError, setAlertError] = useState<string | undefined>(undefined);

    const { onClose, open } = props;

    const handleClose = () => {
        onClose();
        setTimeout(() => {
            setAlertError(undefined);
            setStep(0);
        }, 200);
    };

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
            <DialogTitle sx={{ m: 0, p: 2, pr: 8, pl: 3 }}>
                {step !== 2 && "Upload new file"}
                {step === 2 && "File uploaded"}
                {step !== 2 && <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 20,
                        top: 12,
                        color: "text.secondary",
                    }}
                >
                    <CancelIcon />
                </IconButton>}
            </DialogTitle>
            <DialogContent dividers>
                <Stack spacing={2}>
                    {(step === 1) && <Typography variant="body2">Please attach the file for upload.</Typography>}
                    {step === 2 &&
                        <Stack spacing={2} direction="column" alignItems="center">
                            <Stack direction="column" alignItems="center" spacing={1}>
                                <Typography variant="body2" align="center">Your file will be ready to use shortly.</Typography>
                                <Typography variant="body2" align="center">We&apos;re just doing some additional checks before making it available.</Typography>
                            </Stack>
                            <Button onClick={() => handleClose()} color="success" variant="contained" size="small">Close</Button>
                        </Stack>}
                    {!!alertError && <Alert severity="error" title={alertError} />}
                    {step === 0 &&
                        <FormControl>
                            <Typography variant="body2" color="text.secondary" mb={1}>Permissions</Typography>
                            <RadioGroup sx={{ ml: 2 }} defaultValue={sharedFile} onChange={(e) => setSharedFile(e.target.value)}>
                                <FormControlLabel onClick={() => setSharedFile("shared")} value="shared" control={<Checkbox checked={sharedFile === "shared"} />} label={<Typography variant="subtitle2">Team (your team can use this file)</Typography>} />
                                <FormControlLabel onClick={() => setSharedFile("private")} value="private" control={<Checkbox checked={sharedFile === "private"} />} label={<Typography variant="subtitle2">Private (only you can use this file)</Typography>} />
                            </RadioGroup>
                        </FormControl>}
                    {step === 1 &&
                        <>
                            <FileDropzone exclusive={true} temporaryFile={false} shortcut="" privateFile={sharedFile === "private"} didUploadFile={(error) => {
                                if (!!error) {
                                    setAlertError(error.message);
                                } else {
                                    setStep(2);
                                }
                            }} />
                        </>}
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                {step === 0 && <Button size="small" variant="contained" color="success" onClick={() => setStep(1)}>Next</Button>}
                {step === 1 && <><Button size="small" variant="text" color="inherit" onClick={() => setStep(0)}>Back</Button><div style={{ flexGrow: 1 }} /></>}
            </DialogActions>
        </Dialog >
    );
}
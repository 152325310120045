import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { LeftAlignedButton } from "theme/Button";
import useTheme from "@mui/material/styles/useTheme";
import { useParams, useHistory, Redirect } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Divider from "theme/Divider";
import Button from "theme/Button";
import TemplatesGrid from "./TemplatesGrid";
import LayoutsGrid from "./LayoutsGrid";
import HelpdeskMessengersGrid from "./HelpdeskMessengersGrid";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import SettingsMain from "./SettingsMain";
import LabelsGrid from "./LabelsGrid";
import WorkflowsGrid from "./WorkflowsGrid";
import ConversationTypesGrid from "./ConversationTypesGrid";
import TeamGrid from "components/team/TeamGrid";
import PaymentCard from "components/payment/PaymentCard";
import DeleteOrganization from "components/delete_organization/DeleteOrganization";
import EmailDomainsGrid from "./email_domains/EmailDomainsGrid";
import ShopifySettings from "./shopify/ShopifySettings";
import DiscordSettings from "./discord/DiscordSettings";
import MessageBirdSettings from "./message_bird/MessageBirdSettings";

enum Selection {
    templates = "templates",
    layouts = "layouts",
    helpdesk_messengers = "helpdesk-messengers",
    helpdesk_pages = "helpdesk-pages",
    helpdesk_articles = "helpdesk-articles",
    labels = "labels",
    workflows = "workflows",
    types = "types",
    discord = "discord",
    shopify = "shopify",
    messagebird = "messagebird",
    email = "email",
    plan = "plan",
    delete = "delete",
    team = "team",
}

type Params = {
    selection: Selection;
};

function OrgSettings() {

    const theme = useTheme();
    const history = useHistory();
    const { selection } = useParams<Params>();

    if (!selection) {
        return <Redirect to="/org-settings/templates" />
    }

    const renderPage = (selection: Selection) => {
        switch (selection) {
            case Selection.layouts: {
                return <LayoutsGrid />;
            }
            case Selection.templates: {
                return <TemplatesGrid />;
            }
            case Selection.helpdesk_messengers: {
                return <HelpdeskMessengersGrid />
            }
            case Selection.labels: {
                return <LabelsGrid />
            }
            case Selection.types: {
                return <ConversationTypesGrid />
            }
            case Selection.team: {
                return <TeamGrid />
            }
            case Selection.plan: {
                return <PaymentCard />
            }
            case Selection.delete: {
                return <DeleteOrganization />
            }
            case Selection.email: {
                return <EmailDomainsGrid />
            }
            case Selection.shopify: {
                return <ShopifySettings />
            }
            case Selection.discord: {
                return <DiscordSettings />
            }
            case Selection.workflows: {
                return <WorkflowsGrid />
            }
            case Selection.messagebird: {
                return <MessageBirdSettings />
            }
            default: {
                return <SettingsMain />
            }
        }
    }

    return (
        <Stack direction="column">
            <Breadcrumbs sx={{ p: 1, color: theme.palette.text.secondary }}>
                <Button color="inherit" onClick={() => history.push("/org-settings")}>
                    Organization settings
                </Button>
                {selection === Selection.templates && <Button color="inherit" onClick={() => history.push("/org-settings/templates")}>
                    Templates
                </Button>}
                {selection === Selection.layouts && <Button color="inherit" onClick={() => history.push("/org-settings/layouts")}>
                    Email layouts
                </Button>}
                {selection === Selection.helpdesk_pages && <Button color="inherit" onClick={() => history.push("/org-settings/helpdesk-pages")}>
                    Helpdesk pages
                </Button>}
                {selection === Selection.helpdesk_articles && <Button color="inherit" onClick={() => history.push("/org-settings/helpdesk-articles")}>
                    Helpdesk articles
                </Button>}
                {selection === Selection.helpdesk_messengers && <Button color="inherit" onClick={() => history.push("/org-settings/helpdesk-messengers")}>
                    Helpdesk messengers
                </Button>}
                {selection === Selection.labels && <Button color="inherit" onClick={() => history.push("/org-settings/labels")}>
                    Labels
                </Button>}
                {selection === Selection.types && <Button color="inherit" onClick={() => history.push("/org-settings/types")}>
                    Conversation types
                </Button>}
                {selection === Selection.email && <Button color="inherit" onClick={() => history.push("/org-settings/email")}>
                    Email
                </Button>}
                {selection === Selection.delete && <Button color="inherit" onClick={() => history.push("/org-settings/delete")}>
                    Delete Organization
                </Button>}
                {selection === Selection.plan && <Button color="inherit" onClick={() => history.push("/org-settings/plan")}>
                    Plan
                </Button>}
                {selection === Selection.discord && <Button color="inherit" onClick={() => history.push("/org-settings/discord")}>
                    Discord integration
                </Button>}
                {selection === Selection.shopify && <Button color="inherit" onClick={() => history.push("/org-settings/shopify")}>
                    Shopify integration
                </Button>}
                {selection === Selection.team && <Button color="inherit" onClick={() => history.push("/org-settings/team")}>
                    Team
                </Button>}
                {selection === Selection.workflows && <Button color="inherit" onClick={() => history.push("/org-settings/workflows")}>
                    Workflows
                </Button>}
            </Breadcrumbs>
            <Divider />
            <Box sx={{ display: "flex" }}>
                <Box sx={{
                    width: 300, p: theme.spacing(2), display: {
                        xs: "none",
                        sm: "none",
                        md: "none",
                        lg: "block",
                    },
                }}>
                    <Stack spacing={1}>
                        <Typography variant="caption" color="text.secondary">Automation</Typography>
                        <LeftAlignedButton
                            onClick={() => history.push("/org-settings/templates")}
                            color={
                                selection === Selection.templates ? "primary" : "secondary"
                            }
                            variant={
                                selection === Selection.templates ? "contained" : "text"
                            }
                        >
                            <span style={{ width: "100%", textAlign: "left" }}>Templates</span>
                        </LeftAlignedButton>
                        <LeftAlignedButton
                            onClick={() => history.push("/org-settings/workflows")}
                            color={
                                selection === Selection.workflows ? "primary" : "secondary"
                            }
                            variant={
                                selection === Selection.workflows ? "contained" : "text"
                            }
                        >
                            <span style={{ width: "100%", textAlign: "left" }}>Workflows</span>
                        </LeftAlignedButton>
                        <Typography variant="caption" color="text.secondary">Inbox Management</Typography>
                        <LeftAlignedButton
                            onClick={() => history.push("/org-settings/labels")}
                            color={
                                selection === Selection.labels ? "primary" : "secondary"
                            }
                            variant={
                                selection === Selection.labels ? "contained" : "text"
                            }
                        >
                            <span style={{ width: "100%", textAlign: "left" }}>Labels</span>
                        </LeftAlignedButton>
                        <LeftAlignedButton
                            onClick={() => history.push("/org-settings/types")}
                            color={
                                selection === Selection.types ? "primary" : "secondary"
                            }
                            variant={
                                selection === Selection.types ? "contained" : "text"
                            }
                        >
                            <span style={{ width: "100%", textAlign: "left" }}>Conversation Types</span>
                        </LeftAlignedButton>
                        <Typography variant="caption" color="text.secondary">Mailboxes</Typography>
                        <LeftAlignedButton
                            onClick={() => history.push("/org-settings/email")}
                            color={
                                selection === Selection.email ? "primary" : "secondary"
                            }
                            variant={
                                selection === Selection.email ? "contained" : "text"
                            }
                        >
                            <span style={{ width: "100%", textAlign: "left" }}>Mailboxes</span>
                        </LeftAlignedButton>
                        <LeftAlignedButton
                            onClick={() => history.push("/org-settings/layouts")}
                            color={
                                selection === Selection.layouts ? "primary" : "secondary"
                            }
                            variant={
                                selection === Selection.layouts ? "contained" : "text"
                            }
                        >
                            <span style={{ width: "100%", textAlign: "left" }}>Layouts</span>
                        </LeftAlignedButton>
                        <Typography variant="caption" color="text.secondary">Integrations</Typography>
                        <LeftAlignedButton
                            onClick={() => history.push("/org-settings/shopify")}
                            color={
                                selection === Selection.shopify ? "primary" : "secondary"
                            }
                            variant={
                                selection === Selection.shopify ? "contained" : "text"
                            }
                        >
                            <span style={{ width: "100%", textAlign: "left" }}>Shopify</span>
                        </LeftAlignedButton>
                        {/* <LeftAlignedButton
                            onClick={() => history.push("/org-settings/messagebird")}
                            color={
                                selection === Selection.messagebird ? "primary" : "secondary"
                            }
                            variant={
                                selection === Selection.messagebird ? "contained" : "text"
                            }
                        >
                            <span style={{ width: "100%", textAlign: "left" }}>MessageBird</span>
                        </LeftAlignedButton> */}
                        <LeftAlignedButton
                            onClick={() => history.push("/org-settings/discord")}
                            color={
                                selection === Selection.discord ? "primary" : "secondary"
                            }
                            variant={
                                selection === Selection.discord ? "contained" : "text"
                            }
                        >
                            <span style={{ width: "100%", textAlign: "left" }}>Discord</span>
                        </LeftAlignedButton>
                        <Typography variant="caption" color="text.secondary">Account & Billing</Typography>
                        <LeftAlignedButton
                            onClick={() => history.push("/org-settings/team")}
                            color={
                                selection === Selection.team ? "primary" : "secondary"
                            }
                            variant={
                                selection === Selection.team ? "contained" : "text"
                            }
                        >
                            <span style={{ width: "100%", textAlign: "left" }}>Team</span>
                        </LeftAlignedButton>
                        <LeftAlignedButton
                            onClick={() => history.push("/org-settings/plan")}
                            color={
                                selection === Selection.plan ? "primary" : "secondary"
                            }
                            variant={
                                selection === Selection.plan ? "contained" : "text"
                            }
                        >
                            <span style={{ width: "100%", textAlign: "left" }}>Plan</span>
                        </LeftAlignedButton>
                        <LeftAlignedButton
                            onClick={() => history.push("/org-settings/delete")}
                            color={
                                selection === Selection.delete ? "primary" : "secondary"
                            }
                            variant={
                                selection === Selection.delete ? "contained" : "text"
                            }
                        >
                            <span style={{ width: "100%", textAlign: "left" }}>Delete organization</span>
                        </LeftAlignedButton>
                    </Stack>
                </Box>
                <Stack direction="column" spacing={2} sx={{
                    p: theme.spacing(2),
                    pl: {
                        xs: 2,
                        sm: 2,
                        md: 2,
                        lg: 0,
                    },
                    width: {
                        xs: "100%",
                        sm: "100%",
                        md: "100%",
                        lg: "calc(100% - 300px)",
                    },
                }}>
                    {renderPage(selection)}
                </Stack>
            </Box>
        </Stack>
    );
}

export default OrgSettings;
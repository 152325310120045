import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Button, { LoadingButton } from "theme/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "theme/TextField";
import FormControl from "@mui/material/FormControl";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Alert from "@mui/material/Alert";
import DialogTitle from "theme/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "theme/DialogContent";
import { useMutation, useSubscription, useQuery } from "@apollo/client";
import CREATE_EMAIL_DOMAIN, {
    CreateEmailDomainInput,
    CreateEmailDomainPayload
} from "graphql/mutations/CreateEmailDomainMutation";
import useTheme from "@mui/material/styles/useTheme";
import EMAIL_DOMAIN, { EmailDomainInput, EmailDomainPayload } from "graphql/queries/EmailDomainQuery";
import EMAIL_DOMAIN_UPDATED, { EmailDomainUpdatedInput, EmailDomainUpdatedPayload } from "graphql/subscriptions/EmailDomainUpdatedSubscription";
import CircularProgress from "@mui/material/CircularProgress";
import Chip from '@mui/material/Chip';
import { motion } from "framer-motion/dist/framer-motion";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SELECTED_ORG, {
    SelectedOrgInput,
    SelectedOrgPayload,
} from "graphql/queries/SelectedOrgQuery";
import { CustomEmailDomainDialog } from "components/org_settings/email_domains/NewEmailDomainDialog";

interface Form {
    sub_domain?: string;
}

function EmailPreparing(props: { emailId: string }) {
    const { emailId } = props;

    const history = useHistory();

    const { data: domainData } = useQuery<EmailDomainPayload, EmailDomainInput>(EMAIL_DOMAIN, { variables: { id: emailId }, pollInterval: 2500 });
    const { data } = useSubscription<EmailDomainUpdatedPayload, EmailDomainUpdatedInput>(EMAIL_DOMAIN_UPDATED, { variables: { emailDomainId: emailId } });

    const [ready, setReady] = useState<boolean>(false);

    useEffect(() => {
        if (!!(data?.emailDomainUpdated?.emailDomain) && data.emailDomainUpdated.emailDomain.created) {
            setReady(true);
        } else if (!!(domainData?.emailDomain) && domainData.emailDomain.created) {
            setReady(true);
        }
    }, [data, domainData]);

    if (ready) {
        return (
            <Box sx={{ maxWidth: 400 }}>
                <Card elevation={5}>
                    <DialogTitle>Your your mailbox is ready</DialogTitle>
                    <DialogContent dividers>
                        <Stack spacing={2}>
                            <Typography color="text.secondary" variant="subtitle2">
                                Congratulations, the mailbox is now ready.
                            </Typography>
                            <Typography color="text.secondary" variant="subtitle2">
                                Lets continue and invite team members.
                            </Typography>
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ p: 2 }}>
                        <Button startIcon={<ArrowForwardIcon />} size="small" variant="contained" color="secondary" onClick={() => {
                            history.push("/getting-started-team-members");
                        }}>
                            Invite team members
                        </Button>
                    </DialogActions>

                </Card>
            </Box>
        );
    } else {
        return (
            <Box sx={{ maxWidth: 400 }}>
                <Card elevation={5}>
                    <DialogTitle>Preparing your mailbox</DialogTitle>
                    <DialogContent dividers>
                        <Stack spacing={2}>
                            <Typography color="text.secondary" variant="subtitle2">
                                Your mailbox will take about 2-3 minutes to prepare before its ready.
                            </Typography>
                            <Typography color="text.secondary" variant="subtitle2">
                                In the mean time, you can continue and start inviting your team members.
                            </Typography>
                            {ready && <Chip size="small" color="success" label="Ready to use"></Chip>}
                            {!ready && <Chip size="small" avatar={<CircularProgress size={15} color="inherit" />} color="default" label="Preparing mailbox"></Chip>}
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ p: 2 }}>
                        <Button startIcon={<ArrowForwardIcon />} size="small" variant="contained" color="secondary" onClick={() => {
                            history.push("/getting-started-team-members");
                        }}>
                            Invite team members
                        </Button>
                    </DialogActions>

                </Card>
            </Box>
        );
    }
}

function GettingStartedEmailCard() {

    const { data: selctedOrgData } = useQuery<SelectedOrgPayload, SelectedOrgInput>(SELECTED_ORG, {});

    const {
        control,
        handleSubmit,
        setValue: setFormValue,
        formState: { errors },
    } = useForm();

    const theme = useTheme();

    const history = useHistory();

    const [createEmailDomain, { error: createEmailDomainError }] = useMutation<
        CreateEmailDomainPayload,
        CreateEmailDomainInput
    >(CREATE_EMAIL_DOMAIN, { refetchQueries: ["EmailDomains"] });

    const [custom, setCustom] = useState<boolean>(false);
    const [address, setAddress] = useState<string>("support");
    const [subdomain, setSubdomain] = useState<string>("company-name");
    const [emailDomainId, setEmailDomainId] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | undefined>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!!selctedOrgData?.selectedOrg?.zenMailCompanyName) {
            if (custom) {
                setSubdomain("");
                setFormValue("sub_domain", "");
            } else {
                setSubdomain(selctedOrgData.selectedOrg.zenMailCompanyName);
                setFormValue("sub_domain", selctedOrgData.selectedOrg.zenMailCompanyName);
            }
        }
    }, [selctedOrgData, custom]);

    const onSubmit = async (data: Form) => {

        let payload: CreateEmailDomainPayload | null | undefined;

        try {
            setLoading(true);

            const { data: r } = await createEmailDomain({
                variables: {
                    input: {
                        address: address,
                        customDomain: custom ? subdomain : undefined,
                    },
                },
            });

            payload = r;
        } catch (e: unknown) {
            if (e instanceof Error) {
                setError(e.message);
            }
        } finally {
            setLoading(false);

            if (!!payload) {

                let response = payload.createEmailDomain;

                if (!!response.emailDomain) {
                    setEmailDomainId(response.emailDomain.id)
                }
            }
        }
    };

    useEffect(() => {
        if (!!createEmailDomainError) {
            setError(createEmailDomainError.message);
        }
    }, [createEmailDomainError]);

    if (!!emailDomainId) {
        if (custom) {
            return (
                <Box sx={{ maxWidth: 700, width: "100%" }}>
                    <Card elevation={10}>
                        <CustomEmailDomainDialog id={emailDomainId} closeButtonTitle="Invite team members" onClose={() => {
                            history.push("/getting-started-team-members");
                        }} open />
                    </Card>
                </Box>);
        } else {
            return <EmailPreparing emailId={emailDomainId} />
        }
    }

    return (
        <Box sx={{ maxWidth: 600, width: "100%" }}>
            <motion.div initial={{ opacity: 0, scale: 0 }} viewport={{ once: true }} whileInView={{ opacity: 1, scale: 1 }} transition={{ delay: .5 }}>
                <Card elevation={10}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <DialogTitle>Setup your Mailbox</DialogTitle>
                        <DialogContent dividers>
                            <Stack spacing={2}>
                                <Typography color="text.secondary" variant="subtitle2">
                                    Create a mailbox to send & receive email with zenshop.
                                </Typography>
                                {!!error && <Alert severity="error" icon={false}>{error}</Alert>}
                                <Stack direction="column" spacing={2}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <FormControl variant="outlined">
                                                <Controller
                                                    name="address"
                                                    rules={{
                                                        required: true,
                                                        minLength: 3,
                                                        maxLength: 150,
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <TextField
                                                            id="address"
                                                            type="address"
                                                            sx={{ backgroundColor: theme.palette.background.paper }}
                                                            size="small"
                                                            helperText={errors.address ? errors.address.message : undefined}
                                                            variant="outlined"
                                                            placeholder="support"
                                                            error={!!errors.address}
                                                            onChange={(e) => {
                                                                const v = e.currentTarget.value.replace(/[^\da-z.-]/g, '');
                                                                setAddress(v);
                                                                onChange(v);
                                                            }}
                                                            value={value}
                                                        />
                                                    )}
                                                    control={control}
                                                />
                                            </FormControl>
                                            {custom && <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 600 }}>
                                                @
                                            </Typography>}
                                            {custom && <FormControl variant="outlined">
                                                <Controller
                                                    name="sub_domain"
                                                    rules={{
                                                        required: true,
                                                        minLength: 3,
                                                        maxLength: 150,
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <TextField
                                                            id="sub_domain"
                                                            type="sub_domain"
                                                            sx={{ backgroundColor: theme.palette.background.paper }}
                                                            size="small"
                                                            helperText={errors.sub_domain ? errors.sub_domain.message : undefined}
                                                            variant="outlined"
                                                            placeholder="company-name.com"
                                                            error={!!errors.sub_domain}
                                                            onChange={(e) => {
                                                                const v = e.currentTarget.value.replace(/[^\da-z.-]/g, '');
                                                                setSubdomain(v);
                                                                onChange(v);
                                                            }}
                                                            value={value}
                                                        />
                                                    )}
                                                    control={control}
                                                />
                                            </FormControl>}
                                            {!custom && <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 600 }}>
                                                @{subdomain}.zen-mail.com
                                            </Typography>}
                                        </Stack>
                                    </form>
                                </Stack>
                                <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" flexWrap="wrap" sx={{ gap: 1 }}>
                                    {custom && <Typography textAlign="center" variant="subtitle2" color="text.secondary" fontWeight={600}>
                                        {address || "support"}@{subdomain || (custom ? "company-name.com" : selctedOrgData?.selectedOrg?.zenMailCompanyName)}{custom ? "" : ".zen-mail.com"}
                                    </Typography>}
                                    <div>
                                        <Button color="secondary" variant="outlined" size="small" onClick={() => {
                                            if (custom) {
                                                setSubdomain("company-name");
                                            } else {
                                                setSubdomain("company-name.com");
                                            }
                                            setCustom(!custom)
                                        }}>{custom ? "Use zenshop domain" : "Use your own domain"} </Button>
                                    </div>
                                </Stack>
                            </Stack>
                        </DialogContent>
                        <DialogActions sx={{ p: 2 }}>
                            <LoadingButton loading={loading} size="small" variant="contained" color="success" type="submit" onClick={handleSubmit(onSubmit)}>
                                Create
                            </LoadingButton>
                        </DialogActions>
                    </form>
                </Card>
            </motion.div>
        </Box>
    );
}

export default GettingStartedEmailCard;
import BaseObject from "./Base";
import User from "./User";

interface Note extends BaseObject {
  noteHtml: string;
  author: User;
}

export function instanceOfNote(object: any): object is Note {
  return "noteHtml" in object;
}

export default Note;

import { gql } from "@apollo/client";
import Template from "types/Template";

export interface TemplatesSortInput {
  name?: "ASC" | "DESC",
  shortcut?: "ASC" | "DESC",
  permissions?: "ASC" | "DESC",
  layout?: "ASC" | "DESC",
}

export interface TemplatesInput {
  after?: string;
  before?: string;
  first?: number;
  last?: number;
  filters: TemplatesFilterInput;
}

export interface TemplatesFilterInput {
  sort?: TemplatesSortInput;
  searchQuery?: string;
  privateTemplate?: boolean;
}

export interface TemplatesPayload {
  templates: {
    pageInfo: {
      totalCount: number;
      startCursor: string;
      endCursor: string;
    };
    nodes: Template[];
  };
}

const TEMPLATES = gql`
  query Templates(
    $after: String
    $before: String
    $last: Int
    $first: Int,
    $filters: TemplateFilterInput!
  ) {
    templates(
      first: $first
      last: $last
      after: $after
      before: $before,
      filters: $filters,
    ) {
      pageInfo {
        totalCount
        startCursor
        endCursor
      }
      nodes {
          id
          createdAt
          name
          shortcut
          subject
          privateTemplate
          layout {
            id
            createdAt
            deletable
            name
          }
      }
    }
  }
`;

export default TEMPLATES;
import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import { GridRowsProp, GridColDef } from "@mui/x-data-grid-pro";
import { NoScrollZenDataGrid } from "theme/DataGrid";
import Button from "theme/Button";
import AddIcon from '@mui/icons-material/Add';
import EMAIL_DOMAINS, { EmailDomainsInput, EmailDomainsPayload } from "graphql/queries/EmailDomainsQuery";
import RESPONDER_EMAIL_ADDRESSES, { ResponderEmailAddressesInput, ResponderEmailAddressesPayload } from "graphql/queries/ResponderEmailAddressesQuery";
import { useQuery } from "@apollo/client";
import Alert from "@mui/material/Alert";
import NewEmailDomainDialog from "./NewEmailDomainDialog";
import { renderDomainLive } from "../renderers/RenderDomainLive";
import Typography from "@mui/material/Typography";
import Card from "theme/Card";
import NewEmailAddressDialog from "./NewEmailAddressDialog";
import Link from '@mui/material/Link';
import EditCustomEmailDomainDialog from "./EditCustomEmailDomainDialog";
import EmailDomain from "types/EmailDomain";

export default function EmailDomainsGrid() {

    const [createEmailDomainOpen, setCreateEmailDomainOpen] = useState<boolean>(false);
    const [createEmailAddressOpen, setCreateEmailAddressOpen] = useState<boolean>(false);
    const [selectedEmailDomain, setSelectedEmailDomain] = useState<EmailDomain | undefined>(undefined);

    const customEmailDomainId = !!(selectedEmailDomain?.isCustomDomain) ? selectedEmailDomain.id : undefined

    const { error: domainsError, data: domainsData, refetch: refetchDomains } = useQuery<EmailDomainsPayload, EmailDomainsInput>(EMAIL_DOMAINS, {});

    const { error: emailsError, data: emailsData, refetch: refetchEmails } = useQuery<ResponderEmailAddressesPayload, ResponderEmailAddressesInput>(RESPONDER_EMAIL_ADDRESSES, {});

    const domainsRows: GridRowsProp = (!!domainsData && !!domainsData.emailDomains?.nodes)
        ? domainsData.emailDomains.nodes.map((row) => {
            return {
                id: row.id,
                address: `${row.address}@${row.subDomain}`,
                created: row,
                forwardingAddress: `${row.forwardingAddress}@${row.subDomain}`,
                emailDomain: row,
            };
        })
        : [];

    const domainsColumns: GridColDef[] = [
        {
            field: "address",
            headerName: "Mailbox",
            flex: 0.5
        },
        {
            field: "forwardingAddress",
            headerName: "Forwarding Address",
            flex: 0.5
        },
        {
            field: "created",
            headerName: "Ready",
            flex: 0.2,
            renderCell: renderDomainLive
        },

    ];

    const emailRows: GridRowsProp = (!!emailsData && !!emailsData.responderEmailAddresses?.nodes)
        ? emailsData.responderEmailAddresses.nodes.map((row) => {
            return {
                id: row.id,
                address: `${row.address}@${row.emailDomain.subDomain}`,
                user: row.user.name,
            };
        })
        : [];

    const emailColumns: GridColDef[] = [
        {
            field: "user",
            headerName: "Team Member",
            flex: 1,
        },
        {
            field: "address",
            headerName: "Mailbox",
            flex: 1,
        },
    ];

    const newTeamAddressDisabled = (!!domainsData && domainsData?.emailDomains?.nodes.length === 0) || !domainsData

    useEffect(() => {
        refetchDomains();
        refetchEmails();
    }, []);

    return (
        <>
            <EditCustomEmailDomainDialog open={!!customEmailDomainId} customEmailDomainId={customEmailDomainId} onClose={() => setSelectedEmailDomain(undefined)} />
            <Card variant="outlined" elevation={0} sx={{ maxWidth: 900 }}>
                <Stack spacing={2} direction="column" p={2}>
                    <NewEmailDomainDialog open={createEmailDomainOpen} onClose={() => setCreateEmailDomainOpen(false)} />
                    <NewEmailAddressDialog open={createEmailAddressOpen} onClose={() => setCreateEmailAddressOpen(false)} />
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                        <Typography variant="h5">Mailboxes</Typography> <Button size="small" startIcon={<AddIcon />} color="secondary" variant="contained" onClick={() => { setCreateEmailDomainOpen(true); }}>New mailbox</Button>
                    </Stack>
                    <Typography variant="subtitle2">Create a mailbox to let customers contact the entire team.</Typography>
                    <Stack direction="row" spacing={0.5}>
                        <Typography variant="subtitle2">Read our guide on</Typography>
                        <Link href="https://docs.zenshop.app/getting-started/setting-up-email-forwarding" variant="body2">getting started with mailboxes</Link>
                        <Typography variant="subtitle2">to learn more.</Typography>
                    </Stack>
                    {!!domainsError && <Alert severity="error">{domainsError.message}</Alert>}
                    <NoScrollZenDataGrid
                        autoHeight
                        disableColumnPinning
                        sortingOrder={['asc', 'desc']}
                        components={{
                            NoResultsOverlay: () => (<div />),
                        }}
                        disableColumnFilter
                        disableColumnReorder
                        disableColumnSelector
                        hideFooter
                        rows={domainsRows}
                        columns={domainsColumns}
                        loading={!domainsData}
                        rowHeight={45}
                        onRowClick={(row) => {
                            setSelectedEmailDomain(row.row.emailDomain as EmailDomain);
                        }}
                        rowsPerPageOptions={[1]}
                        rowCount={!!(domainsData?.emailDomains) ? domainsData.emailDomains.pageInfo.totalCount : 0}
                        disableSelectionOnClick
                    />
                </Stack>
            </Card>
            <Card variant="outlined" elevation={0} sx={{ maxWidth: 900 }}>
                <Stack spacing={2} direction="column" p={2}>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                        <Typography variant="h5">Personal mailboxes</Typography> <Button disabled={newTeamAddressDisabled} size="small" startIcon={<AddIcon />} color="secondary" variant="contained" onClick={() => { setCreateEmailAddressOpen(true); }}>New personal mailbox</Button>
                    </Stack>
                    <Typography variant="subtitle2">Every team member can have their own mailbox to receive email.</Typography>
                    <Stack direction="row" spacing={0.5}>
                        <Typography variant="subtitle2">Read our guide on</Typography>
                        <Link href="https://docs.zenshop.app/getting-started/setting-up-email-forwarding" variant="body2">using personal mailboxes</Link>
                        <Typography variant="subtitle2">to learn more.</Typography>
                    </Stack>
                    {!!emailsError && <Alert severity="error">{emailsError.message}</Alert>}
                    <NoScrollZenDataGrid
                        autoHeight
                        disableColumnPinning
                        sortingOrder={['asc', 'desc']}
                        components={{
                            NoResultsOverlay: () => (<div />),
                        }}
                        disableColumnFilter
                        disableColumnReorder
                        disableColumnSelector
                        hideFooter
                        rows={emailRows}
                        columns={emailColumns}
                        loading={!domainsData}
                        rowHeight={45}
                        rowsPerPageOptions={[1]}
                        rowCount={!!(emailsData?.responderEmailAddresses) ? emailsData.responderEmailAddresses.pageInfo.totalCount : 0}
                        disableSelectionOnClick
                    />
                </Stack>
            </Card>
        </>

    );
}
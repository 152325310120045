import { gql } from "@apollo/client";
import Customer from "types/Customer";
import CustomerFragment from "../fragments/CustomerFragment";
import FieldFragment from "graphql/fragments/FieldFragment";
import MailingAddressFragment from "graphql/fragments/MailingAddressFragment";

export interface CustomerInput {
  id: string;
}

export interface CustomerPayload {
  customer: Customer;
}

const CUSTOMER = gql`
  query Customer($id: ID!) {
    customer(id: $id) {
        ...CustomerFields
        fields {
          nodes {
            ...FieldFields
          }
        }
        mailingAddresses {
          nodes {
            ...MailingAddressFields
          }
        }
        orders {
          nodes {
            ... on ShopifyOrder {
              id
              createdAt
            }
          }
        }
    }
  }
  ${CustomerFragment},
  ${FieldFragment},
  ${MailingAddressFragment}
`;

export default CUSTOMER;
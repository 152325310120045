import { useState, useEffect } from "react";
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button, { LoadingButton } from 'theme/Button';
import Dialog from '@mui/material/Dialog';
import TextField from 'theme/TextField';
import { useMutation } from "@apollo/client";
import UPDATE_LABEL, { UpdateLabelInput, UpdateLabelPayload } from "graphql/mutations/UpdateLabelMutation";
import DELETE_LABEL, { DeleteLabelInput, DeleteLabelPayload } from "graphql/mutations/DeleteLabelMutation";
import Typography from "@mui/material/Typography";
import { useForm, Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import useTheme from "@mui/material/styles/useTheme";
import ColorPickerModal from "../org_settings/helpdesk_messengers/ColorPickerModal";
import Label from "types/Label";

interface Props {
    open: boolean;
    label?: Label;
    onClose: () => void;
}

export default function EditLabelDialog(props: Props) {

    const { onClose, open, label } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [alertError, setAlertError] = useState<string | undefined>(undefined);
    const theme = useTheme();

    const {
        control,
        handleSubmit,
        reset,
        trigger,
        setValue: setFormValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            "label_title": label?.title,
            "label_color_hex": label?.colorHex
        }
    });

    const [updateLabel, { error: updateError }] = useMutation<UpdateLabelPayload, UpdateLabelInput>(UPDATE_LABEL, { refetchQueries: ["Labels", "Conversation", "Conversations"] });
    const [deleteLabel, { error: deleteError }] = useMutation<DeleteLabelPayload, DeleteLabelInput>(DELETE_LABEL, { refetchQueries: ["Labels", "Conversation", "Conversations"] });


    useEffect(() => {
        if (!!updateError) {
            setAlertError("Unable to update this label");
        } else if (!!deleteError) {
            setAlertError("Unable to delete this label");
        } else {
            setAlertError(undefined);
        }
    }, [updateError, deleteError]);

    useEffect(() => {
        if (!!label) {
            reset();
            setFormValue("label_title", label.title);
            trigger("label_title");
            setFormValue("label_color_hex", label.colorHex);
            trigger("label_color_hex");
        }
    }, [label]);

    const handleClose = () => {
        onClose();
        setTimeout(() => {
            setAlertError(undefined);
        }, 200);
    };

    const onSubmitDeleteLabel = async (label: Label) => {
        try {
            setLoading(true);

            const { data } = await deleteLabel({
                variables: {
                    input: {
                        id: label.id
                    }
                }
            });

            if (!!(data?.deleteLabel)) {
                if (data.deleteLabel.errors.length > 0) {
                    setAlertError(data.deleteLabel.errors[0]);
                } if (!!data.deleteLabel.ok) {
                    handleClose();
                }
            }
        } catch {
            setAlertError('There was a problem updating this label');
        } finally {
            setLoading(false);
        }
    };

    const onSubmitUpdateLabel = async (formData: { [x: string]: any; }) => {
        try {
            setLoading(true);

            const { data } = await updateLabel({
                variables: {
                    input: {
                        id: label!.id,
                        title: formData.label_title,
                        colorHex: formData.label_color_hex || "#000"
                    }
                }
            });

            if (!!data) {
                if (data.updateLabel.errors.length > 0) {
                    setAlertError(data.updateLabel.errors[0]);
                } if (!!data.updateLabel.label) {
                    handleClose();
                }
            }
        } catch {
            setAlertError('There was a problem updating this label');
        } finally {
            setLoading(false);
        }
    };

    const busy = loading;

    return (
        <Dialog onClose={() => handleClose()} open={open} scroll="paper" maxWidth="xs" fullWidth>
            <DialogTitle>
                <Typography variant="h5" fontWeight={600}>Edit label</Typography>
            </DialogTitle>
            <DialogContent dividers sx={{ backgroundColor: theme.palette.background.default }}>
                <Stack direction="column" spacing={1}>
                    {!!alertError && <Alert severity="error" icon={false}>{alertError}</Alert>}
                    <Stack direction="column" spacing={2}>
                        <form onSubmit={handleSubmit(onSubmitUpdateLabel)}>
                            <Stack direction="column" spacing={2}>
                                <FormControl variant="outlined" fullWidth style={{ backgroundColor: theme.palette.background.paper }}>
                                    <Controller
                                        name="label_title"
                                        defaultValue={label?.title}
                                        rules={{
                                            required: true,
                                            maxLength: 150,
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                style={{ backgroundColor: theme.palette.background.paper }}
                                                id="label_title"
                                                type="label_title"
                                                defaultValue={label?.title}
                                                fullWidth
                                                size="small"
                                                helperText={errors.label_title ? errors.label_title.message : undefined}
                                                variant="outlined"
                                                placeholder="Label name"
                                                error={!!errors.label_title}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                        control={control}
                                    />
                                </FormControl>
                                <ColorPickerModal id="label-color-picker" defaultValue={"#000"} value={label?.colorHex} onChange={(color) => {
                                    setFormValue("label_color_hex", color);
                                    trigger("label_color_hex");
                                }} />
                            </Stack>
                        </form>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <LoadingButton loading={busy} onClick={() => { if (!!label) { onSubmitDeleteLabel(label); } }} size="small" disabled={busy || !label} variant="contained" color="error">Delete</LoadingButton>
                <div style={{ flexGrow: 1 }} />
                <Button size="small" onClick={() => handleClose()} color="inherit">Close</Button>
                <LoadingButton loading={busy} onClick={handleSubmit(onSubmitUpdateLabel)} size="small" disabled={busy} variant="contained" color="success">Update</LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
import { useState } from "react";
import Stack from "@mui/material/Stack";
import useTheme from "@mui/material/styles/useTheme";
import Box from "@mui/material/Box";
import { LeftAlignedButton } from "theme/Button";
import OrderPanel from "./OrderPanel";
import BillingPanel from "./BillingPanel";
import DiscountsPanel from "./DiscountsPanel";
import ShippingPanel from "./ShippingPanel";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from "theme/Button";
import Divider from "theme/Divider";
import SHOPIFY_ORDER, {
    ShopifyOrderInput,
    ShopifyOrderPayload,
} from "graphql/queries/ShopifyOrderQuery";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

enum Filter {
    details,
    billing,
    shipping,
    discounts
}

type Params = {
    id: string;
};

function ShopifyOrderDetail() {

    const theme = useTheme();

    const { id } = useParams<Params>();
    const [filter, setFilter] = useState<Filter>(Filter.details);

    const { data } = useQuery<ShopifyOrderPayload, ShopifyOrderInput>(SHOPIFY_ORDER, {
        variables: {
            id: id
        },
    });

    const order = data?.shopifyOrder;

    return (
        <Stack direction="column">
            <Breadcrumbs sx={{ p: 1, color: theme.palette.text.secondary }}>
                <Button color="inherit">
                    {order?.name || ""}
                </Button>
            </Breadcrumbs>
            <Divider />
            <Stack direction="row">
                <Box sx={{
                    width: 300, p: theme.spacing(2), display: {
                        xs: "none",
                        sm: "none",
                        md: "none",
                        lg: "block",
                    },
                }}>
                    <Stack spacing={1}>
                        <LeftAlignedButton
                            onClick={() => setFilter(Filter.details)}
                            color={
                                filter === Filter.details ? "primary" : "secondary"
                            }
                            variant={
                                filter === Filter.details ? "contained" : "text"
                            }
                        >
                            <span style={{ width: "100%", textAlign: "left" }}>Details</span>
                        </LeftAlignedButton>
                    </Stack>
                </Box>
                <Stack direction="column" spacing={2} sx={{
                    p: theme.spacing(2),
                    pl: {
                        xs: 2,
                        sm: 2,
                        md: 2,
                        lg: 0,
                    },
                    width: {
                        xs: "100%",
                        sm: "100%",
                        md: "100%",
                        lg: "calc(100% - 300px)",
                    },
                }}>
                    {filter === Filter.details && <OrderPanel />}
                    {filter === Filter.shipping && <ShippingPanel />}
                    {filter === Filter.billing && <BillingPanel />}
                    {filter === Filter.discounts && <DiscountsPanel />}
                </Stack>
            </Stack>
        </Stack>
    );
}

export default ShopifyOrderDetail;
import { useState, useEffect, useCallback, useMemo } from "react";
import { useDropzone, FileRejection, DropEvent } from 'react-dropzone';
import Stack from "@mui/material/Stack";
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { v4 as uuidv4 } from 'uuid';
import { useMutation } from "@apollo/client";
import SIGN_FILE_UPLOAD, {
    SignFileUploadInput,
    SignFileUploadPayload,
} from "graphql/mutations/SignFileUploadMutation";
import FormData from "form-data";
import axios from "axios";
import OrgFile from "types/OrgFile";
import useTheme from "@mui/material/styles/useTheme";

export interface FileUpload {
    key: string;
    name: string;
    uploading: boolean;
    completed: boolean;
    file: File;
    orgFile?: OrgFile;
    errors?: string[];
    uploadUrl?: string;
    uploadFields?: [key: string];
}

function FileDropArea(props: { temporaryFile: boolean, uploads: FileUpload[], deleted: string[], setUploads: React.Dispatch<React.SetStateAction<FileUpload[]>>, exclusive?: boolean, didUploadFile?: (error?: Error) => void }) {

    const { exclusive, didUploadFile, uploads, setUploads, deleted, temporaryFile } = props;
    const isExclusive = exclusive || false;
    const [needsUpload, setNeedsUpload] = useState<string[]>([]);
    const [fileAlert, setFileAlert] = useState<string | undefined>(undefined);
    const theme = useTheme();

    const baseStyle = {
        flex: 1,
        minHeight: 300,
        borderRadius: 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderColor: theme.palette.border.main,
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const focusedStyle = {
        borderColor: '#2196f3',
        color: '#2196f3',
    };

    const acceptStyle = {
        borderColor: '#00e676',
        color: '#00e676'
    };

    const rejectStyle = {
        borderColor: '#ff1744',
        color: '#ff1744'
    };

    const [signFile, { }] = useMutation<SignFileUploadPayload, SignFileUploadInput>(SIGN_FILE_UPLOAD, {});

    const doUpload = useCallback(async (uploadFile: FileUpload, file: File) => {
        try {
            if (!!uploadFile.uploadFields && !!uploadFile.uploadUrl && !uploadFile.uploading && !uploadFile.completed) {
                uploadFile.uploading = true;
                setUploads([...uploads.filter((o) => deleted.indexOf(o.key) === -1)]);
                const form = new FormData();
                Object.entries(uploadFile.uploadFields).forEach(([field, value]) => {
                    form.append(field, value);
                });
                form.append("file", file);
                const response = await axios
                    .post(uploadFile.uploadUrl, form, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                uploadFile.completed = true;
                if (response.status == 201) {
                    uploadFile.uploading = false;
                    if (!!didUploadFile) {
                        didUploadFile(undefined)
                    }
                    setUploads([...uploads.filter((o) => deleted.indexOf(o.key) === -1)]);
                } else {
                    uploadFile.uploading = false;
                    uploadFile.errors = ['There was a problem uploading this file'];
                    if (!!didUploadFile) {
                        didUploadFile(new Error('There was a problem uploading this file'))
                    }
                    setUploads([...uploads.filter((o) => deleted.indexOf(o.key) === -1)]);
                }
            }
        } catch (error) {
            uploadFile.completed = true;
            uploadFile.uploading = false;
            uploadFile.errors = ['There was a problem uploading this file'];
            if (!!didUploadFile) {
                didUploadFile(new Error('There was a problem uploading this file'))
            }
            setUploads([...uploads.filter((o) => deleted.indexOf(o.key) === -1)]);
        }
    }, [uploads, deleted]);

    const onDrop = useCallback(async (acceptedFiles: File[], rejectedFiles: FileRejection[], _: DropEvent) => {
        if (rejectedFiles.length > 0) {
            setFileAlert('Some files were rejected because they were too large (50MB limit)');
        } else {
            setFileAlert(undefined);
        }

        if (acceptedFiles.length == 0) {
            return;
        }

        let newUploads = acceptedFiles.map((file) => {
            let upload: FileUpload = { name: file.name, uploading: false, completed: false, key: uuidv4(), file: file };
            return upload;
        });

        for (let i = 0; i < newUploads.length; i++) {
            let upload = newUploads[i];

            try {
                const { data, errors } = await signFile({
                    variables: {
                        input: {
                            userAvatar: false,
                            fileName: upload.name,
                            privateFile: false,
                            temporaryFile: temporaryFile
                        }
                    }
                });

                if (!!errors) {
                    upload.errors = errors.map((error) => error.message);
                }

                if (!!data) {
                    if (data.signFileUpload.errors.length > 0) {
                        upload.errors = data.signFileUpload.errors;
                        upload.uploading = false;
                    } else {
                        upload.orgFile = data.signFileUpload.provisionalOrgFile;
                        upload.uploadUrl = data.signFileUpload.uploadUrl;
                        upload.uploadFields = data.signFileUpload.uploadFields;
                    }
                }
            } catch (error) {
                upload.errors = ['There was a problem uploading this file'];
                upload.uploading = false;
            }
        }

        let update = [...uploads, ...newUploads];
        let toBeUploaded = newUploads.map((o) => o.key);
        setUploads(update);
        setNeedsUpload(toBeUploaded);
    }, [uploads, deleted, temporaryFile]);

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        open
    } = useDropzone({
        noClick: true,
        noKeyboard: true,
        onDrop: onDrop,
        maxSize: 52428800,
        multiple: !isExclusive
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    useEffect(() => {
        for (let upload of uploads) {
            if (!!upload.errors) {
                setFileAlert(upload.errors[0]);
            }
        }
    }, [uploads]);

    useEffect(() => {
        for (let key of needsUpload) {
            let toBeUploaded = uploads.find((o) => o.key === key);
            if (!!toBeUploaded && deleted.indexOf(key) === -1) {
                doUpload(toBeUploaded, toBeUploaded.file);
            }
        }
    }, [needsUpload, uploads, deleted]);

    return (
        <Stack direction="column" spacing={1}>
            {!!fileAlert && <Alert color="error">{fileAlert}</Alert>}
            <div {...getRootProps({ style })} onClick={open}>
                <input {...getInputProps()} />
                <Typography variant="body2" fontWeight={700}>Drop your files here</Typography>
            </div>
        </Stack>
    );
}

export default FileDropArea;
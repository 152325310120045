import { styled } from "@mui/material/styles";
import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";

export const ZenAccordionSummary = styled(AccordionSummary)<AccordionSummaryProps>(({ theme }) => ({
    "&.MuiAccordionSummary-content, .Mui-expanded": {
        margin: 0,
    },
}));

const ZenAccordion = styled(Accordion)<AccordionProps>(({ theme }) => ({
    '& .MuiExpansionPanel-root:before': {
        display: 'none',
    },
    "& .MuiPaper-root-MuiAccordion-root:before": {
        display: 'none',
    },
    "&.MuiAccordion-root": {
        margin: 0,
        backgroundColor: theme.palette.background.default,
        borderRadius: 0,
    },
    "&.MuiExpanded": {
        margin: 0,
    },
}));

export default ZenAccordion;

import { useState } from "react";
import Stack from '@mui/material/Stack';
import DialogTitle from "theme/DialogTitle";
import DialogContent from 'theme/DialogContent';
import Dialog from '@mui/material/Dialog';
import Alert from "@mui/material/Alert";
import ReplyBox from "components/conversation/ReplyBox";

export interface Props {
    open: boolean;
    email?: string;
    onClose: (completed: boolean) => void;
}

export default function NewConversationMessageDialog(props: Props) {

    const { onClose, open, email } = props;
    const [alertError, setAlertError] = useState<string | undefined>(undefined);

    const handleClose = (completed: boolean) => {
        onClose(completed);
        setTimeout(() => {
            setAlertError(undefined);
        }, 200);
    };

    return (
        <Dialog onClose={() => handleClose(false)} open={open} fullWidth={true} maxWidth="md">
            <Stack direction="column" spacing={2}>
                {!!alertError && <Alert severity="error">{alertError}</Alert>}
                <ReplyBox variant="new_message_to_email" email={email} onClose={() => handleClose(true)} />
            </Stack>
        </Dialog>
    );
}
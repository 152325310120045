import OTPCard from "components/login/OTPCard";
import Box from "@mui/material/Box";

function VerifyLogin() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "rgb(0, 0, 0)",
        backgroundImage: "linear-gradient(to right, rgb(0, 0, 0), rgb(17, 24, 28))"
      }}>
      <OTPCard />
    </Box >
  );
}

export default VerifyLogin;

import { gql } from "@apollo/client";
import ResponderEmailAddress from "types/ResponderEmailAddress";

export interface ResponderEmailAddressesInput {
    after?: string;
    before?: string;
    first?: number;
    last?: number;
}

export interface ResponderEmailAddressesPayload {
    responderEmailAddresses: {
        pageInfo: {
            totalCount: number;
            startCursor: string;
            endCursor: string;
        };
        nodes: ResponderEmailAddress[];
    };
}

const RESPONDER_EMAIL_ADDRESSES_QUERY = gql`
  query ResponderEmailAddresses(
    $after: String
    $before: String
    $last: Int
    $first: Int
  ) {
    responderEmailAddresses(
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      pageInfo {
        totalCount
        startCursor
        endCursor
      }
      nodes {
        id
        createdAt
        address
        emailDomain {
            id
            subDomain
        }
        user {
            id
            name
        }
      }
    }
  }
`;

export default RESPONDER_EMAIL_ADDRESSES_QUERY;
import BaseObject from "./Base";
import Org from "types/Org";
import PageInfo from "types/PageInfo";
import Avatar, { AvatarBadge } from "theme/Avatar";
import { getInitials } from "types/Conversation";
import PersonIcon from '@mui/icons-material/Person';

interface User extends BaseObject {
  name: string;
  email: string;
  avatarUrl: string;
  avatarProcessing: boolean;
  recentlyActive: boolean;
  selectedOrg?: Org;
  role?: string;
  locked: boolean;
  banned: boolean;
  passwordNeedsSetting: boolean;
  avatarRequired: boolean;
  twoFactorRequired: boolean;
  verifiedPhoneNumber: boolean;
  verifiedEmail: boolean;
  planRequired: boolean;
  onboardingNag: boolean;
  featureDropAlert: boolean;
  memberships: {
    pageInfo: PageInfo;
    nodes: Org[];
  };
}

export const avatar = (user: User, size?: number) => {

  const mostlyAvatar = <Avatar sx={{ width: size || 40, height: size || 40 }} variant="rounded" src={user.avatarUrl}>{!!user.name ? getInitials(user.name ?? "") : <PersonIcon />}</Avatar>;

  if (user.recentlyActive) {
    return (
      <AvatarBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
        {mostlyAvatar}
      </AvatarBadge>
    )
  } else {
    return mostlyAvatar;
  }
}

export default User;

import { gql } from "@apollo/client";
import Conversation from "types/Conversation";
import CompleteConversationFragment from "../fragments/CompleteConversationFragment";

export interface ResolveConversationInput {
  input: {
    id: string;
    resolved: boolean;
  };
}

export interface ResolveConversationPayload {
  resolveConversation: {
    errors: string[];
    conversation: Conversation;
  };
}

const RESOLVE_CONVERSATION = gql`
  mutation ResolveConversation($input: ResolveConversationMutationInput!) {
    resolveConversation(input: $input) {
      errors
      conversation {
        ...CompleteConversationFields
      }
    }
  }
  ${CompleteConversationFragment}
`;

export default RESOLVE_CONVERSATION;

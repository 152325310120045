import { gql } from "@apollo/client";
import User from "types/User";
import UserFragment from "../fragments/UserFragment";

export interface UpdateUserNameInput {
  input: {
    name: string;
  };
}

export interface UpdateUserNamePayload {
  updateUserName: {
    user?: User;
  }
}

const UPDATE_USER_NAME = gql`
  mutation UpdateUserName(
    $input: UpdateUserNameMutationInput!
  ) {
    updateUserName(input: $input) {
      user {
        ...UserFields
      }
    }
  }
  ${UserFragment}
`;

export default UPDATE_USER_NAME;
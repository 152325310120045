import { gql } from "@apollo/client";
import { HelpdeskMessengersFilterInput } from "./HelpdeskMessengersQuery";

export interface HelpdeskMessengersCountInput {
  filters: HelpdeskMessengersFilterInput;
}

export interface HelpdeskMessengersCountPayload {
  helpdeskMessengersCount: number;
}

const HELPDESK_MESSENGERS_COUNT = gql`
  query HelpdeskMessengersCount {
    helpdeskMessengersCount 
  }
`;

export default HELPDESK_MESSENGERS_COUNT;
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import { LoadingButton } from "theme/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "theme/TextField";
import FormControl from "@mui/material/FormControl";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import Alert from "@mui/material/Alert";
import DialogTitle from "theme/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "theme/DialogContent";
import AccountAvatarDropArea from "components/account_settings/profile/AccountAvatarDropArea";
import ME, {
    MeInput,
    MePayload,
} from "graphql/queries/MeQuery";
import { useQuery } from "@apollo/client";
import UPDATE_USER_NAME, {
    UpdateUserNameInput,
    UpdateUserNamePayload,
} from "graphql/mutations/UpdateUserNameMutation";
import { motion } from "framer-motion/dist/framer-motion";

interface Form {
    name?: string;
}

function GettingStartedCard() {

    const { data: meData } = useQuery<MePayload, MeInput>(ME, {
        variables: {}, fetchPolicy: "no-cache", pollInterval: 1000,
    });

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors, isDirty },
    } = useForm();

    let history = useHistory();

    const [updateName, { error: updateNameError }] = useMutation<
        UpdateUserNamePayload,
        UpdateUserNameInput
    >(UPDATE_USER_NAME, { refetchQueries: ["Me"] });

    const [error, setError] = useState<string | undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const [avatarSet, setAvatarSet] = useState<boolean>(false);
    const [showAvatarError, setShowAvatarError] = useState<boolean>(false);

    const onSubmit = async (data: Form) => {

        if (!avatarSet) {
            setShowAvatarError(true);
            return;
        }

        let payload: UpdateUserNamePayload | null | undefined;

        try {
            setLoading(true);

            const { data: r } = await updateName({
                variables: {
                    input: {
                        name: data.name!,
                    },
                },
            });

            payload = r;
        } catch (e: unknown) {
            if (e instanceof Error) {
                setError(e.message);
            }
        } finally {
            setLoading(false);

            if (!!payload) {

                let response = payload.updateUserName;

                if (!!response.user) {
                    if (response.user.role == "admin") {
                        history.push("/getting-started-email");
                    } else {
                        history.push("/getting-started-join-community");
                    }
                }
            }
        }
    };

    useEffect(() => {
        if (!!meData && !!meData.me && !!meData.me.avatarUrl) {
            setAvatarSet(true);
            setShowAvatarError(false);
        }
        if (!!meData && !!meData.me && !isDirty) {
            setValue("name", meData.me.name);
        }
    }, [meData, isDirty]);

    useEffect(() => {
        if (!!updateNameError) {
            setError(updateNameError.message);
        }
    }, [updateNameError]);

    return (
        <Box sx={{ maxWidth: 400 }}>
            <motion.div initial={{ opacity: 0, scale: 0 }} viewport={{ once: true }} whileInView={{ opacity: 1, scale: 1 }} transition={{ delay: .5 }}>
                <Card elevation={10}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <DialogTitle>Show the world your beautiful smile 🤩</DialogTitle>
                        <DialogContent dividers>
                            <Stack spacing={2} alignItems="center">
                                {!!error && <Alert severity="error">{error}</Alert>}
                                <AccountAvatarDropArea avatarSet={() => setAvatarSet(true)} />
                                {showAvatarError && <Typography variant="caption" color="error">Please upload your avatar</Typography>}
                                <FormControl fullWidth variant="outlined">
                                    <Controller
                                        name="name"
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                id="name"
                                                size="small"
                                                helperText={errors.name ? errors.name.message : null}
                                                variant="outlined"
                                                placeholder="Your name"
                                                error={!!errors.name}
                                                onChange={onChange}
                                                value={value}
                                            />
                                        )}
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: "Please enter your name",
                                            minLength: 3,
                                            maxLength: 500,
                                        }}
                                    />
                                </FormControl>
                            </Stack>
                        </DialogContent>
                        <DialogActions sx={{ p: 2 }}>
                            <LoadingButton loading={loading} size="small" variant="contained" color="success" type="submit" onClick={handleSubmit(onSubmit)}>
                                Continue
                            </LoadingButton>
                        </DialogActions>
                    </form>
                </Card>
            </motion.div>
        </Box>
    );
}

export default GettingStartedCard;
import { gql } from "@apollo/client";
import Conversation from "types/Conversation";
import CompleteConversationFragment from "../fragments/CompleteConversationFragment";

export interface MergeConversationsInput {
    input: {
        parentId: string;
        duplicateIds: string[];
    };
}

export interface MergeConversationsPayload {
    mergeConversations: {
        errors: string[];
        conversation: Conversation;
    };
}

const MERGE_CONVERSATIONS = gql`
  mutation MergeConversations($input: MergeConversationsMutationInput!) {
    mergeConversations(input: $input) {
      errors
      conversation {
        ...CompleteConversationFields
      }
    }
  }
  ${CompleteConversationFragment}
`;

export default MERGE_CONVERSATIONS;

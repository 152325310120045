import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Button from "theme/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useHistory } from "react-router-dom";
import Alert from "@mui/material/Alert";
import DialogTitle from "theme/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "theme/DialogContent";
import { useSubscription, useQuery } from "@apollo/client";
import EMAIL_DOMAIN_UPDATED, { EmailDomainUpdatedInput, EmailDomainUpdatedPayload } from "graphql/subscriptions/EmailDomainUpdatedSubscription";
import CircularProgress from "@mui/material/CircularProgress";
import EMAIL_DOMAINS, { EmailDomainsInput, EmailDomainsPayload } from "graphql/queries/EmailDomainsQuery";
import EmailDomain from "types/EmailDomain";
import Chip from '@mui/material/Chip';
import { motion } from "framer-motion/dist/framer-motion";
import { useSnackbar } from 'notistack';
import EMAIL_DOMAIN, { EmailDomainInput, EmailDomainPayload } from "graphql/queries/EmailDomainQuery";

function EmailForwarding(props: { emailDomain: EmailDomain }) {
    const { enqueueSnackbar } = useSnackbar();

    const { emailDomain } = props;

    const { data: domainData } = useQuery<EmailDomainPayload, EmailDomainInput>(EMAIL_DOMAIN, { variables: { id: emailDomain.id }, pollInterval: 2500 });
    const { data } = useSubscription<EmailDomainUpdatedPayload, EmailDomainUpdatedInput>(EMAIL_DOMAIN_UPDATED, { variables: { emailDomainId: emailDomain.id } });

    const [ready, setReady] = useState<boolean>(false);

    useEffect(() => {
        if (!!(data?.emailDomainUpdated?.emailDomain) && data.emailDomainUpdated.emailDomain.forwardingEnabled) {
            setReady(true);
        } else if (!!(domainData?.emailDomain) && domainData.emailDomain.forwardingEnabled) {
            setReady(true);
        }
    }, [data, domainData]);

    if (ready) {
        return (
            <>
                <Typography color="text.secondary" variant="subtitle2">
                    We found your email. Awesome. 🔥
                </Typography>
                <Typography color="text.secondary" variant="subtitle2">
                    Continue to setup your zenshop.
                </Typography>
            </>
        );
    } else {
        return (
            <>
                <Typography variant="h6">Do you already have an email address?</Typography>
                <Typography color="text.secondary" variant="subtitle2">
                    Lets get mail to zenshop (learn more about mail forwarding <a target="_blank" href="https://docs.zenshop.app/role-specific-guides/for-admins/what-is-mail-forwarding">here</a>). To configure mail forwarding, follow the instructions <a target="_blank" href={`https://docs.zenshop.app/getting-started/setting-up-email-forwarding#forward-email-from-your-mail-provider`}>here</a>.
                </Typography>
                <Stack direction="column" spacing={1} alignItems="center">
                    <Typography color="text.primary" variant="subtitle2">
                        Your forwarding address
                    </Typography>
                    <Button size="small" variant="outlined" color="secondary"
                        onClick={() => {
                            try {
                                var copiedData = [new ClipboardItem({ "text/plain": new Blob([`${emailDomain.forwardingAddress}@${emailDomain.subDomain}`], { type: "text/plain" }) })];
                                navigator.clipboard.write(copiedData);
                            }
                            catch {
                                enqueueSnackbar("Something went wrong", { variant: "error", anchorOrigin: { horizontal: "center", vertical: "bottom" } })
                            }
                            finally {
                                enqueueSnackbar("Copied", { variant: "success", anchorOrigin: { horizontal: "center", vertical: "bottom" } })
                            }
                        }}
                    >{emailDomain.forwardingAddress}@{emailDomain.subDomain}</Button>
                </Stack>
                <Typography variant="h6">Check everything is working...</Typography>
                <Typography color="text.secondary" variant="subtitle2">
                    Send mail to your support address to see if zenshop is receiving it. We&apos;ll let you know if everything is working.
                </Typography>
                <Chip size="small" avatar={<CircularProgress size={15} color="inherit" />} color="default" label="Looking for your test email"></Chip>
                <Typography color="text.secondary" variant="subtitle2">
                    If nothing happens, reach out to <a target="_blank" href={process.env.REACT_APP_DISCORD_URL!}>customer support</a>.
                </Typography>
            </>
        );
    }
}

function GettingStartedForwardEmailCard() {

    const history = useHistory();

    const { data, error: emailDomainsError } = useQuery<EmailDomainsPayload, EmailDomainsInput>(EMAIL_DOMAINS, {
        variables: {},
    });

    const [emailDomain, setEmailDomain] = useState<EmailDomain | undefined>(undefined);
    const [error, setError] = useState<string | undefined>();

    useEffect(() => {
        if (!!emailDomainsError) {
            setError(emailDomainsError.message);
        }
    }, [emailDomainsError]);

    useEffect(() => {
        if (!!(data?.emailDomains.nodes) && data.emailDomains.pageInfo.totalCount > 0) {
            setEmailDomain(data.emailDomains.nodes[0]);
        }
    }, [data]);

    return (
        <Box sx={{ maxWidth: 600 }}>
            <motion.div initial={{ opacity: 0, scale: 0 }} viewport={{ once: true }} whileInView={{ opacity: 1, scale: 1 }} transition={{ delay: .5 }}>
                <Card elevation={5}>
                    {!!emailDomain && !emailDomain.forwardingEnabled && <DialogTitle>Setup mail forwarding</DialogTitle>}
                    {!!emailDomain && !!emailDomain.forwardingEnabled && <DialogTitle>Success</DialogTitle>}
                    <DialogContent dividers>
                        <Stack spacing={2}>
                            {!!error && <Alert severity="error">{error}</Alert>}
                            {!!emailDomain ? <EmailForwarding emailDomain={emailDomain} /> : <Stack alignItems="center" justifyContent="center"> <CircularProgress /></Stack>}
                        </Stack>
                    </DialogContent>
                    <DialogActions sx={{ p: 2 }}>
                        {!!emailDomain && !emailDomain.forwardingEnabled && <Button size="small" variant="text" color="inherit" type="submit" onClick={() => {
                            history.push("/getting-started-join-community");
                        }}>
                            Skip
                        </Button>}
                        {!!emailDomain && !!emailDomain.forwardingEnabled && <Button size="small" variant="contained" color="secondary" type="submit" onClick={() => {
                            history.push("/getting-started-join-community");
                        }}>
                            Continue
                        </Button>}
                    </DialogActions>
                </Card>
            </motion.div>
        </Box >
    );
}

export default GettingStartedForwardEmailCard;
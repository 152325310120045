import { useState, useEffect } from "react";
import DialogContent from 'theme/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { LoadingButton } from 'theme/Button';
import Dialog from '@mui/material/Dialog';
import Conversation from "types/Conversation";
import { useMutation } from "@apollo/client";
import IconButton from '@mui/material/IconButton';
import CancelIcon from "@mui/icons-material/Cancel";
import UPDATE_CONVERSATION_ASSOCIATED_ORDER, { UpdateConversationAssociatedOrderInput, UpdateConversationAssociatedOrderPayload } from "graphql/mutations/UpdateConversationAssociatedOrderMutation";
import AssociateOrdersGrid from './AssociateOrdersGrid';
import { useSnackbar } from 'notistack';
import AppBar from "theme/AppBar";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useTheme from "@mui/material/styles/useTheme";

export interface ChangeConversationTypeDialogProps {
    open: boolean;
    conversation: Conversation;
    onClose: () => void;
}

export default function ChangeAssociatedOrderDialog(props: ChangeConversationTypeDialogProps) {

    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState<boolean>(false);

    const [selection, setSelection] = useState<string | undefined>(undefined);
    const [alertError, setAlertError] = useState<string | undefined>(undefined);

    const isValid = Boolean(selection);

    const [updateConversationAssociatedOrder, { }] = useMutation<
        UpdateConversationAssociatedOrderPayload,
        UpdateConversationAssociatedOrderInput
    >(UPDATE_CONVERSATION_ASSOCIATED_ORDER, { refetchQueries: ["Conversations", "Conversation", "SideBarQuery"] });

    const { onClose, open, conversation } = props;

    const handleClose = () => {
        if (loading) {
            return;
        }
        onClose();
        setAlertError(undefined);
        setSelection(undefined);
    };

    const handleConfirm = async () => {
        try {
            setLoading(true);

            const { data, errors } = await updateConversationAssociatedOrder({
                variables: {
                    input: {
                        id: conversation.id,
                        orderId: selection!
                    }
                }
            });

            if (!!(data?.updateConversationAssociatedOrder?.conversation)) {
                enqueueSnackbar(`Updated associated order`);
                handleClose();
            }

            if (!!errors && errors.length > 0) {
                setAlertError(errors[0].message);
            } else if (!!(data?.updateConversationAssociatedOrder.errors) && data.updateConversationAssociatedOrder.errors.length > 0) {
                setAlertError(data.updateConversationAssociatedOrder.errors[0]);
            }

        }
        catch (error) {
            setAlertError((error as any).message as string);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        setAlertError(undefined);
        setSelection(undefined);
    }, [conversation])

    return (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth="lg">
            <AppBar position="relative" elevation={0} color="transparent">
                <Toolbar sx={{ color: theme.palette.text.secondary }}>
                    <Typography sx={{ ml: 0, flex: 1 }} variant="h5">
                        Change associated order
                    </Typography>
                    <div style={{ flexGrow: 0 }} />
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CancelIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent dividers>
                <Stack spacing={2}>
                    {!!alertError && <Alert severity="error">{alertError}</Alert>}
                    <AssociateOrdersGrid selection={selection} setSelection={setSelection} customer={conversation.customer} />
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <LoadingButton size="small" disabled={!isValid || loading} variant="contained" color="success" onClick={() => handleConfirm()}>Confirm</LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
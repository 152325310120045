import { gql } from "@apollo/client";
import Customer from "types/Customer";
import CustomerFragment from "../fragments/CustomerFragment";
import FieldFragment from "../fragments/FieldFragment";
import CompleteShopifyOrderFragment from "../fragments/CompleteShopifyOrderFragment";
import MailingAddressFragment from "graphql/fragments/MailingAddressFragment";

export interface UpdateCustomerDetailsInput {
  input: {
    id: string;
    fieldUpdates: {
      id: string;
      value: string;
    }[];
    fieldsRemoved: string[];
    firstName?: string;
    lastName?: string;
    customerNotes?: string;
  };
}

export interface UpdateCustomerDetailsPayload {
  updateCustomerDetails: {
    errors: string[];
    customer?: Customer;
  };
}

const UPDATE_CUSTOMER_DETAILS = gql`
  mutation UpdateCustomerDetails($input: UpdateCustomerDetailsMutationInput!) {
    updateCustomerDetails(input: $input) {
      errors
      customer {
        ...CustomerFields
        fields {
          nodes {
            ...FieldFields
          }
        }
        mailingAddresses {
          nodes {
            ...MailingAddressFields
          }
        }
        orders {
          nodes {
            ...CompleteShopifyOrderFields
          }
        }
      }
    }
  }
  ${CustomerFragment},
  ${FieldFragment},
  ${CompleteShopifyOrderFragment},
  ${MailingAddressFragment}
`;

export default UPDATE_CUSTOMER_DETAILS;

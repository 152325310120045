import gql from "graphql-tag";

const ShopifyFulfillmentFragment = gql`
  fragment ShopifyFulfillmentFields on ShopifyFulfillment {
    id
    createdAt
    name
    requiresShipping
    status
    displayStatus
    totalQuantity
    trackingInfoUrl
    trackingInfoNumber
    trackingInfoCompany
    deliveredAt
    estimatedDeliveryAt
    inTransitAt
  }
`;

export default ShopifyFulfillmentFragment;

import { useState } from 'react';
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { LoadingButton } from "theme/Button";
import Typography from '@mui/material/Typography';
import { useQuery, useMutation } from "@apollo/client";
import DELETE_ORG, {
    DeleteOrgInput,
    DeleteOrgPayload
} from "graphql/mutations/DeleteOrgMutation";
import Alert from "@mui/material/Alert";
import SELECTED_ORG, {
    SelectedOrgInput,
    SelectedOrgPayload,
} from "graphql/queries/SelectedOrgQuery";
import { useHistory } from "react-router-dom";
import Stack from "@mui/material/Stack";

export interface DeleteOrgDialogProps {
    open: boolean;
    onClose: () => void;
}

export default function DeleteOrgDialog(props: DeleteOrgDialogProps) {

    const history = useHistory();
    const { onClose, open } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [alertError, setAlertError] = useState<string | undefined>(undefined);

    const { data: orgData } = useQuery<SelectedOrgPayload, SelectedOrgInput>(SELECTED_ORG, {
        variables: {},
    });

    const [deleteOrg, { }] = useMutation<DeleteOrgPayload, DeleteOrgInput>(DELETE_ORG, { refetchQueries: ["Me", "SelectedOrg"] });

    const handleClose = () => {
        if (loading) {
            return;
        }
        onClose();
    };

    const handleDeleteOrganization = async () => {
        setLoading(true);

        try {
            await deleteOrg({ variables: { input: { orgId: orgData?.selectedOrg?.id || "" } } });

            history.push("/login");
        } catch (e: unknown) {
            if (e instanceof Error) {
                setAlertError(e.message);
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <Dialog fullWidth maxWidth="sm" onClose={handleClose} open={open}>
            <DialogTitle>
                Confirm delete Organization
            </DialogTitle>
            <DialogContent dividers>
                <Stack direction="column" spacing={2}>
                    {!!alertError && <Alert severity='error'>{alertError}</Alert>}
                    <Typography variant="body2">Your Organization will be scheduled for deletion in 30 days time.</Typography>
                    <Typography variant="body2">You won&apos;t be able to reply to customer messages during this period.</Typography>
                    <Typography variant="body2">We reccomend you also uninstall zenshop from your Shopify store settings.</Typography>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <LoadingButton loading={loading} disabled={loading} size="small" variant="contained" color="error" onClick={handleDeleteOrganization}>Delete Organization</LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
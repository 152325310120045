import { useState, useEffect } from "react";
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from 'theme/Button';
import Dialog from '@mui/material/Dialog';
import TextField from 'theme/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { useQuery } from "@apollo/client";
import TEAM, {
    TeamInput,
    TeamPayload
} from "graphql/queries/TeamQuery";
import { GridColDef, GridSelectionModel, GridCellParams } from '@mui/x-data-grid-pro';
import DataGrid from "theme/DataGrid";
import Typography from "@mui/material/Typography";
import Checkbox from "theme/Checkbox";
import useTheme from "@mui/material/styles/useTheme";
import { styled } from "@mui/material/styles";
import { DataGridProProps } from "@mui/x-data-grid-pro";
import User, { avatar } from "types/User";

const HiddenColumnGrid = styled(DataGrid)<DataGridProProps>(({ }) => ({
    "& .MuiDataGrid-columnHeaders": {
        display: "none",
    },
    "&& > *": {
        borderRadius: "4px",
    }
}));

export function renderUser(params: GridCellParams) {
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            {avatar(params.value as User, 25)}
            <Typography variant="body2">{params.value["name"]}</Typography>
        </Stack>);
}

interface Props {
    open: boolean;
    selectedUserIds: string[];
    onClose: (selectedUserIds: string[]) => void;
}

const columns: GridColDef[] = [
    {
        field: 'user',
        headerName: '',
        renderCell: renderUser,
        flex: 1,
        sortable: false
    }
];

export default function ChooseUsersDialog(props: Props) {

    const { onClose, open, selectedUserIds } = props;

    const [searchInput, setSearchInput] = useState<string>("");
    const [alertError, setAlertError] = useState<string | undefined>(undefined);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>(selectedUserIds);
    const theme = useTheme();

    const { data, error, loading } = useQuery<TeamPayload, TeamInput>(TEAM, {});

    useEffect(() => {
        if (!!error) {
            setAlertError("Unable to fetch");
        } else {
            setAlertError(undefined);
        }
    }, [error]);

    useEffect(() => {
        setSelectionModel(selectedUserIds);
    }, [selectedUserIds]);

    const handleClose = (selection?: string[]) => {
        if (!!selection) {
            onClose(selection);
        } else {
            onClose(selectionModel as string[]);
        }
        setTimeout(() => {
            setAlertError(undefined);
            setSearchInput("");
        }, 200);
    };

    const team: User[] = data?.team?.nodes ?? [];

    let rows = team.map((user) => {
        return {
            id: user.id,
            name: user.name,
            user: user,
        }
    });

    if (searchInput.length > 0) {
        rows = rows.filter((obj) => obj.name.toLowerCase().includes(searchInput.toLowerCase()))
    }

    const renderTable = () => {
        return (
            <Stack direction="column" spacing={2}>
                <TextField
                    fullWidth
                    sx={{ backgroundColor: theme.palette.background.paper }}
                    size="small"
                    placeholder="Filter team"
                    onChange={(e) => setSearchInput(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <FilterListOutlinedIcon />
                            </InputAdornment>
                        ),
                    }} />
                {!loading && <div style={{ height: 300, width: '100%' }}>
                    <HiddenColumnGrid
                        density="compact"
                        rows={rows}
                        selectionModel={selectionModel}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        columns={columns}
                        disableColumnMenu
                        disableColumnReorder
                        disableColumnResize
                        disableChildrenSorting
                        checkboxSelection
                        disableColumnSelector
                        hideFooter
                        headerHeight={0}
                        components={{
                            Panel: () => (<div />),
                            BaseCheckbox: Checkbox,
                            Header: () => (<div />),
                            Toolbar: () => (<div />)
                        }}
                    />
                </div>}
            </Stack>
        );
    }

    return (
        <Dialog onClose={() => handleClose()} open={open} scroll="paper" maxWidth="sm" fullWidth>
            <DialogTitle>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h5" fontWeight={600}>Select assignees</Typography>
                </Stack>
            </DialogTitle>
            <DialogContent dividers sx={{ backgroundColor: theme.palette.background.default }}>
                <Stack direction="column" spacing={1}>
                    {!!alertError && <Alert severity="error" icon={false}>{alertError}</Alert>}
                    {renderTable()}
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <div style={{ flexGrow: 1 }} />
                <Button size="small" onClick={() => handleClose()} color="inherit">Close</Button>
            </DialogActions>
        </Dialog>
    );
}
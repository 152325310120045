import { gql } from "@apollo/client";
import { OrdersFilterInput } from "./OrdersQuery";

export interface OrdersCountInput {
  filters: OrdersFilterInput;
}

export interface OrdersCountPayload {
  ordersCount: number;
}

const ORDERS_COUNT = gql`
  query OrdersCount($filters: OrderFilterInput!) {
    ordersCount(filters: $filters) 
  }
`;

export default ORDERS_COUNT;
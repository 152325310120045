import { gql } from "@apollo/client";
import DiscordIntegration from "types/DiscordIntegration";
import DiscordIntegrationFragment from "../fragments/DiscordIntegrationFragment";
import PageInfo from "types/PageInfo";

export interface DiscordIntegrationsInput { }

export interface DiscordIntegrationsPayload {
    discordIntegrations: {
        pageInfo: PageInfo;
        nodes: DiscordIntegration[];
    };
}

const DISCORD_INTEGRATIONS = gql`
  query DiscordIntegrations {
    discordIntegrations {
      pageInfo {
        startCursor
        endCursor
        totalCount
      }
      nodes {
          ...DiscordIntegrationFields
      }
    }
  }
  ${DiscordIntegrationFragment}
`;

export default DISCORD_INTEGRATIONS;
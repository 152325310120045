import { gql } from "@apollo/client";
import Template from "types/Template";
import TemplateFragment from "../fragments/TemplateFragment";

export interface TemplateInput {
  id: string;
}

export interface TemplatePayload {
  template?: Template;
}

const TEMPLATE = gql`
  query Template($id: ID!) {
    template(id: $id) {
        ...TemplateFields
        layout {
          id
          createdAt
          deletable
          name
        }
    }
  }
  ${TemplateFragment},
`;

export default TEMPLATE;
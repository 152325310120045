import { useState } from "react";
import { useQuery } from "@apollo/client";
import ME, { MeInput, MePayload } from "graphql/queries/MeQuery";
import TEAM, { TeamInput, TeamPayload } from "graphql/queries/TeamQuery";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import Card from "theme/Card";
import Button from "theme/Button";
import CardActionArea from "@mui/material/CardActionArea";
import useTheme from "@mui/material/styles/useTheme";
import Typography from "@mui/material/Typography";
import Avatar from "theme/Avatar";
import MemberDialog from "./MemberDialog";
import User from "types/User";
import InviteMemberDialog from "./InviteMemberDialog";
import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';

enum Filter {
    all,
    admin,
    responder
}

function TeamGrid() {

    const theme = useTheme();

    const { data: meData, error: meError } = useQuery<MePayload, MeInput>(ME, {});
    const { data: teamData, error: teamError } = useQuery<TeamPayload, TeamInput>(TEAM, {});
    const [filter, setFilter] = useState<Filter>(Filter.all);
    const [inviteMemberOpen, setInviteMemberOpen] = useState<boolean>(false);
    const [selectedMember, setSelectedMember] = useState<User | undefined>(undefined);
    const memberOpen = Boolean(selectedMember);

    let team = teamData?.team.nodes.filter((o) => {
        switch (filter) {
            case Filter.all:
                return true;
            case Filter.admin:
                return o.role === "admin"
            case Filter.responder:
                return o.role === "responder";
            default:
                return false
        }
    }) ?? []

    return (
        <Stack direction="column">
            <InviteMemberDialog open={inviteMemberOpen} onClose={() => { setInviteMemberOpen(false) }} />
            <MemberDialog open={memberOpen} onClose={() => { setSelectedMember(undefined) }} member={selectedMember} />
            <Stack direction="row" spacing={1}>
                <Button
                    sx={{ pl: 1, pr: 1 }}
                    size="small"
                    onClick={() => setFilter(Filter.all)}
                    color={
                        filter === Filter.all ? "primary" : "secondary"
                    }
                    variant={
                        filter === Filter.all ? "contained" : "text"
                    }
                >
                    All
                </Button>
                <Button
                    sx={{ pl: 1, pr: 1 }}
                    size="small"
                    onClick={() => setFilter(Filter.admin)}
                    color={
                        filter === Filter.admin ? "primary" : "secondary"
                    }
                    variant={
                        filter === Filter.admin ? "contained" : "text"
                    }
                >
                    Admin
                </Button>
                <Button
                    sx={{ pl: 1, pr: 1 }}
                    size="small"
                    onClick={() => setFilter(Filter.responder)}
                    color={
                        filter === Filter.responder ? "primary" : "secondary"
                    }
                    variant={
                        filter === Filter.responder ? "contained" : "text"
                    }
                >
                    Responder
                </Button>

            </Stack>
            <Stack direction="column" spacing={2} sx={{ p: theme.spacing(2), pl: 0 }}>
                {!!meError && <Alert severity="error">{meError.message}</Alert>}
                {!!teamError && <Alert severity="error">{teamError.message}</Alert>}
                <Stack direction="row" flexWrap="wrap" alignItems="center">
                    {team.map((member) =>
                        <Card key={member.id} variant="outlined" sx={{ mr: 2, pb: 2, width: 200, height: "100%" }} onClick={() => setSelectedMember(member)}>
                            <Stack direction="column" pt={2} alignItems="center" spacing={1}>
                                <Avatar src={member.avatarUrl} variant="rounded"></Avatar>
                                <Typography variant="h5" textAlign="center">{member.name}</Typography>
                                <Chip size="small" color={member.role === 'admin' ? "success" : "default"} label={member.role}></Chip>
                            </Stack>
                        </Card>)}
                    <Box>
                        {meData?.me.role === "admin" && <Button startIcon={<AddIcon />} size="small" variant="contained" color="secondary" onClick={() => setInviteMemberOpen(true)}>Invite another team member</Button>}
                    </Box>
                </Stack>

            </Stack>
        </Stack >
    );
}

export default TeamGrid;
import gql from "graphql-tag";

const TemplateFragment = gql`
  fragment TemplateFields on Template {
    id
    createdAt
    contentHtml
    name
    shortcut
    subject
    privateTemplate
  }
`;

export default TemplateFragment;

import { useState, useEffect } from "react";
import { GridRowsProp, GridColDef, GridSelectionModel } from "@mui/x-data-grid-pro";
import DataGridPro from "theme/DataGrid";
import { renderTimeLeft } from "components/conversations/renderers/RenderTimeLeft";
import { renderLabel } from "components/conversations/renderers/RenderLabel";
import { renderAssignee } from "components/conversations/renderers/RenderAssignee";
import { renderMessage } from "components/conversations/renderers/RenderMessage";
import Checkbox from "theme/Checkbox";
import { parseDateString } from "helpers/DateFormatters";
import Conversation from "types/Conversation";
import Stack from "@mui/material/Stack";

interface Props {
    disableMultipleSelection: boolean;
    options: Conversation[];
    setSelection: React.Dispatch<React.SetStateAction<Conversation[]>>;
}

function MergeConversationsGrid(props: Props) {

    const { options, setSelection, disableMultipleSelection } = props;
    const [data, setData] = useState<Conversation[]>(options);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

    useEffect(() => {
        setData(options);
    }, [options]);

    useEffect(() => {
        let ids = data.map((o) => o.id);
        setSelection(selectionModel.map((o) => data[ids.indexOf(o as string)]));
    }, [selectionModel, data]);

    const rows: GridRowsProp = !!data
        ? data.map((row) => {
            return {
                id: row.id,
                type: row.type.title,
                message: row.latestMessage ?? "",
                labels: row.labels.nodes ?? [],
                time_left: !!row.timeLeft ? parseDateString(row.timeLeft) : undefined,
                assignee: row.assignees.nodes ?? [],
                message_count: row.messageCount,
                total_order_value: row.totalOrderValue
            };
        })
        : [];

    const columns: GridColDef[] = [
        {
            field: "type",
            headerName: "Type",
            width: 150,
        },
        {
            field: "labels",
            headerName: "Labels",
            width: 250,
            renderCell: renderLabel,
        },
        {
            field: "message",
            headerName: "Latest Message",
            width: 300,
            renderCell: renderMessage,
        },
        {
            field: "assignee",
            headerName: "Assignee",
            width: 150,
            renderCell: renderAssignee,
        },
        {
            field: "time_left",
            headerName: "Time Left",
            width: 150,
            renderCell: renderTimeLeft,
        },
        {
            field: "total_order_value",
            headerName: "Order value",
            width: 150,
        },
        {
            field: "message_count",
            headerName: "Messages",
            width: 100,
        }
    ];

    return (
        <Stack direction="column" style={{ minHeight: 55 * 10 }}>
            <DataGridPro
                style={{ height: "100%" }}
                autoHeight={false}
                disableColumnPinning
                disableMultipleSelection={disableMultipleSelection}
                components={{
                    BaseCheckbox: Checkbox,
                    NoResultsOverlay: () => (<div />),
                }}
                disableColumnFilter
                disableColumnReorder
                disableColumnSelector
                rows={rows}
                columns={columns}
                rowHeight={55}
                pageSize={10}
                rowsPerPageOptions={[10]}
                pagination
                checkboxSelection={!disableMultipleSelection}
                onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
            />
        </Stack>
    );
}

export default MergeConversationsGrid;
import { useState, useEffect } from "react";
import { GridRowsProp, GridColDef, GridSelectionModel, GridSortModel, GridSortDirection } from "@mui/x-data-grid-pro";
import { NoScrollZenDataGrid } from "theme/DataGrid";
import Checkbox from "theme/Checkbox";
import { useQuery } from "@apollo/client";
import CUSTOMERS, {
    CustomersInput,
    CustomersPayload,
    CustomersFilter,
    CustomersSortInput,
} from "graphql/queries/CustomersQuery";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { renderCustomer } from "components/conversations/renderers/RenderCustomer";
import { renderDate } from "components/conversations/renderers/RenderDate";
import TextField from "theme/TextField";
import useTheme from "@mui/material/styles/useTheme";
import { LeftAlignedButton } from "theme/Button";
import { useHistory } from "react-router-dom";
import Button from "theme/Button";
import BackspaceIcon from '@mui/icons-material/Backspace';
import qs from 'query-string';
import useQueryParams from "hooks/useQueryParams";
import InputAdornment from '@mui/material/InputAdornment';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';

function AddressBookGrid() {

    const theme = useTheme();
    const query = useQueryParams();

    const history = useHistory();
    const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [filter, setFilter] = useState<CustomersFilter>(CustomersFilter.all);
    const [page, setPage] = useState(Number(query.get("page")) ?? 0);
    const after = query.get("after") ?? undefined;
    const before = query.get("before") ?? undefined;
    const [sort, setSort] = useState<CustomersSortInput | undefined>({ name: 'ASC' });

    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'customer',
            sort: 'asc' as GridSortDirection,
        },
    ]);

    const { data, error, loading, refetch } = useQuery<
        CustomersPayload,
        CustomersInput
    >(CUSTOMERS, {
        variables: {
            after: after,
            before: before,
            first: !after && !before ? 15 : !!after ? 15 : undefined,
            last: !!before ? 15 : undefined,
            filters: {
                searchQuery: searchQuery,
                filter: filter,
                sort: sort,
            },
        },
    });

    const rows: GridRowsProp = !!data
        ? data.customers.nodes.map((row) => {
            return {
                id: row.id,
                customer: row,
                email: row.email,
                total_spend: row.totalSpend,
                orders: row.numberOfOrders,
                conversations: row.numberOfConversations,
                last_order_date: row.latestOrderDate,
            };
        })
        : [];

    const columns: GridColDef[] = [
        {
            field: "customer",
            headerName: "Name",
            width: 200,
            renderCell: renderCustomer
        },
        {
            field: "email",
            headerName: "Email",
            width: 200,
        },
        {
            field: "total_spend",
            headerName: "Total Spend",
            width: 200,
        },
        {
            field: "orders",
            headerName: "Orders",
            width: 200,
        },
        {
            field: "last_order_date",
            headerName: "Last Order",
            width: 200,
            renderCell: renderDate
        },
        {
            field: "conversations",
            headerName: "Conversations",
            width: 200,
        },
    ];

    function navigateToAfter(after: string, page: number) {
        const query = { page: page, after: after, filter: filter.toString() };
        history.push({ pathname: "/address-book", search: qs.stringify(query) });
    };

    function navigateToBefore(before: string, page: number) {
        const query = { page: page, before: before, filter: filter.toString() };
        history.push({ pathname: "/address-book", search: qs.stringify(query) });
    };

    function navigateStart() {
        const query = { page: 0, filter: filter.toString() };
        history.push({ pathname: "/address-book", search: qs.stringify(query) });
    }

    useEffect(() => {
        navigateStart();
        setPage(0);
        refetch();
    }, [sort, searchQuery, filter]);

    return (
        <Box sx={{ display: "flex" }}>
            <Box sx={{ width: 300, p: theme.spacing(2) }}>
                <Stack spacing={1}>
                    <LeftAlignedButton
                        onClick={() => setFilter(CustomersFilter.all)}
                        color={
                            filter === CustomersFilter.all ? "primary" : "secondary"
                        }
                        variant={
                            filter === CustomersFilter.all ? "contained" : "text"
                        }
                    >
                        <span style={{ width: "100%", textAlign: "left" }}>All</span>
                    </LeftAlignedButton>
                    <LeftAlignedButton
                        onClick={() => setFilter(CustomersFilter.active)}
                        color={
                            filter === CustomersFilter.active ? "primary" : "secondary"
                        }
                        variant={
                            filter === CustomersFilter.active ? "contained" : "text"
                        }
                    >
                        <span style={{ width: "100%", textAlign: "left" }}>Active conversations</span>
                    </LeftAlignedButton>
                    <LeftAlignedButton
                        onClick={() => setFilter(CustomersFilter.recentlyResolved)}
                        color={
                            filter === CustomersFilter.recentlyResolved ? "primary" : "secondary"
                        }
                        variant={
                            filter === CustomersFilter.recentlyResolved ? "contained" : "text"
                        }
                    >
                        <span style={{ width: "100%", textAlign: "left" }}>Recently resolved</span>
                    </LeftAlignedButton>
                    <LeftAlignedButton
                        onClick={() => setFilter(CustomersFilter.recentlyOrdered)}
                        color={
                            filter === CustomersFilter.recentlyOrdered ? "primary" : "secondary"
                        }
                        variant={
                            filter === CustomersFilter.recentlyOrdered ? "contained" : "text"
                        }
                    >
                        <span style={{ width: "100%", textAlign: "left" }}>Recently ordered</span>
                    </LeftAlignedButton>
                </Stack>
            </Box>
            <Stack direction="column" spacing={2} p={2} sx={{ p: theme.spacing(2), pl: 0, width: "calc(100% - 300px)", height: "min-content" }}>
                {!!error && <Alert severity="error">{error.message}</Alert>}
                <Stack direction="row" spacing={1} alignItems="center">
                    <TextField value={searchQuery} size="small" placeholder="Filter results"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FilterListOutlinedIcon />
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => setSearchQuery(e.target.value)}></TextField>
                    {(!!searchQuery) &&
                        <div style={{ color: theme.palette.text.secondary }}>
                            <Button size="small" onClick={() => { setSearchQuery(""); }} variant="outlined" startIcon={<BackspaceIcon />} color="inherit">Clear applied search filters</Button>
                        </div>}
                </Stack>
                <Stack direction="column" style={{ minHeight: 635 }}>
                    <NoScrollZenDataGrid
                        style={{ height: "100%" }}
                        disableColumnPinning
                        sortingOrder={['asc', 'desc']}
                        sortModel={sortModel}
                        onSortModelChange={(model) => {
                            if (model.length > 0) {
                                const field = model[0];
                                const sort = field.sort;
                                if (!!sort) {
                                    switch (field.field) {
                                        case "customer": {
                                            setSort({ name: sort.toUpperCase() as "ASC" | "DESC" });
                                            break;
                                        }
                                        case "email": {
                                            setSort({ email: sort.toUpperCase() as "ASC" | "DESC" });
                                            break;
                                        }
                                        case "total_spend": {
                                            setSort({ totalSpend: sort.toUpperCase() as "ASC" | "DESC" });
                                            break;
                                        }
                                        case "orders": {
                                            setSort({ orders: sort.toUpperCase() as "ASC" | "DESC" });
                                            break;
                                        }
                                        case "last_order_date": {
                                            setSort({ lastOrder: sort.toUpperCase() as "ASC" | "DESC" });
                                            break;
                                        }
                                        case "conversations": {
                                            setSort({ conversations: sort.toUpperCase() as "ASC" | "DESC" });
                                            break;
                                        }
                                    }
                                } else {
                                    setSort({ name: 'ASC' });
                                }
                            } else {
                                setSort({ name: 'ASC' });
                            }
                            setSortModel(model);
                        }}
                        components={{
                            BaseCheckbox: Checkbox,
                            NoResultsOverlay: () => (<div />),
                        }}
                        disableColumnFilter
                        disableColumnReorder
                        disableColumnSelector
                        rows={rows}
                        columns={columns}
                        loading={!data}
                        page={page}
                        onPageChange={(newPage) => {
                            if (loading) {
                                return;
                            }
                            if (newPage > page) {
                                navigateToAfter(data!.customers.pageInfo.endCursor, newPage);
                            } else {
                                navigateToBefore(data!.customers.pageInfo.startCursor, newPage);
                            }
                            setPage(newPage);
                        }}
                        rowHeight={35}
                        pageSize={15}
                        rowsPerPageOptions={[15]}
                        rowCount={!!(data?.customers) ? data.customers.pageInfo.totalCount : 0}
                        sortingMode="server"
                        paginationMode="server"
                        pagination
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        selectionModel={selectionModel}
                        onRowClick={(row) => history.push(`/customer/${row.id}`)}
                    />
                </Stack>
            </Stack>
        </Box>
    );
}

export default AddressBookGrid;
import { gql } from "@apollo/client";

export interface DeleteLabelInput {
    input: {
        id: string;
    };
}

export interface DeleteLabelPayload {
    deleteLabel?: {
        errors: string[];
        ok?: boolean;
    };
}

const DELETE_LABEL = gql`
  mutation DeleteLabel($input: DeleteLabelMutationInput!) {
    deleteLabel(input: $input) {
      errors
      ok
    }
  }
`;

export default DELETE_LABEL;

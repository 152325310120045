import { useState, useMemo, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Divider from "theme/Divider";
import Card from "@mui/material/Card";
import useTheme from "@mui/material/styles/useTheme";
import { useMutation } from "@apollo/client";
import ADD_COMMENT, {
  AddCommentInput,
  AddCommentPayload,
} from "graphql/mutations/AddCommentMutation";
import START_EMAIL_CONVERSATION, {
  StartEmailConversationInput,
  StartEmailConversationPayload,
} from "graphql/mutations/StartEmailConversationMutation";
import BULK_REPLY, {
  BulkReplyInput,
  BulkReplyPayload,
} from "graphql/mutations/BulkReplyMutation";
import ADD_NOTE, {
  AddNoteInput,
  AddNotePayload,
} from "graphql/mutations/AddNoteMutation";
import TEMPLATES, {
  TemplatesInput,
  TemplatesPayload
} from "graphql/queries/TemplatesQuery";
import TEMPLATE_AUTOFILL_VALUES, {
  TemplateAutofillValuesInput,
  TemplateAutofillValuesPayload
} from "graphql/queries/TemplateAutofillValuesQuery";
import TEAM, {
  TeamInput,
  TeamPayload,
} from "graphql/queries/TeamQuery";
import TEMPLATE, {
  TemplateInput,
  TemplatePayload,
} from "graphql/queries/TemplateQuery";
import { useSnackbar } from 'notistack';
import Alert from "@mui/material/Alert";
import { useQuery } from "@apollo/client";
import Template from "types/Template";
import User from "types/User";
import Chip from '@mui/material/Chip';
import Typography from "@mui/material/Typography";
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Select from '@mui/material/Select';
import OrgFile from "types/OrgFile";
import AttachFilesDialog from "components/files/AttachFilesDialog";
import Conversation from "types/Conversation";
import { SuggestExtension } from "remirror";
import {
  BoldExtension,
  CalloutExtension,
  ItalicExtension,
  UnderlineExtension,
  MarkdownExtension,
  PlaceholderExtension,
  StrikeExtension,
  HeadingExtension,
  LinkExtension,
  BlockquoteExtension,
  BulletListExtension,
  OrderedListExtension,
  MentionAtomExtension,
} from 'remirror/extensions';
import {
  EditorComponent,
  Remirror,
  useRemirror,
} from '@remirror/react';
import { prosemirrorNodeToHtml } from 'remirror';
import { LoadingButton } from 'theme/Button';
import { IdentifierSchemaAttributes } from 'remirror';
import Button from "theme/Button";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { alpha } from "@mui/material/styles";
import ReplyIcon from '@mui/icons-material/Reply';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import MenuComponent from "components/zen_remirror/MenuComponent";
import MentionComponent from "components/zen_remirror/MentionComponent";
import LinkComponent from "components/zen_remirror/LinkComponent";
import TemplateSuggestorComponent from "components/zen_remirror/TemplateSuggestorComponent";
import { useHistory } from "react-router-dom";
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import MessageShadowRoot from "./MessageShadowRoot";
import TextField from "@mui/material/TextField";
import useSound from "use-sound";
import notification from './send_message.wav';
import Field from "types/Field";

type Variant = "conversation" | "bulk_reply" | "new_message_to_email"

interface ReplyBoxWrapperProps {
  variant: Variant;
  conversation?: Conversation;
  conversationIds?: string[];
  email?: string;
  onClose?: () => void;
}

export default function ReplyBoxWrapper(props: ReplyBoxWrapperProps) {

  const { variant, conversation, conversationIds, email, onClose } = props;

  switch (variant) {
    case "bulk_reply": {
      return (
        <ReplyBoxForConversationIds
          conversationIds={conversationIds!}
          onClose={onClose}
          variant={variant} />
      );
    }
    case "conversation": {
      return (
        <ReplyBoxForConversation
          conversation={conversation!}
          onClose={onClose}
          variant={variant} />
      );
    }
    case "new_message_to_email": {
      return (
        <ReplyBoxForEmail
          onClose={onClose}
          email={email!}
          variant={variant} />
      );
    }
  }
}

function ReplyBoxForEmail(props: { email: string, variant: Variant, onClose?: () => void; }) {

  const { email, variant, onClose } = props;

  const { data: templatesData } = useQuery<TemplatesPayload, TemplatesInput>(TEMPLATES, {
    variables: { filters: { sort: { "shortcut": "ASC" } } }, fetchPolicy: "no-cache",
  });

  const { data: autofillData, refetch } = useQuery<TemplateAutofillValuesPayload, TemplateAutofillValuesInput>(TEMPLATE_AUTOFILL_VALUES, {
    variables: {}, fetchPolicy: "no-cache",
  });

  const { data: teamData } = useQuery<TeamPayload, TeamInput>(TEAM, {
    variables: {},
  });

  useEffect(() => {
    refetch();
  }, [email]);

  return (
    <ReplyBox
      onClose={onClose}
      team={!!(teamData?.team) ? teamData.team.nodes : []}
      templates={!!(templatesData?.templates) ? templatesData.templates.nodes : []}
      templateAutofillValues={!!(autofillData?.templateAutofillValues) ? autofillData.templateAutofillValues : {} as any}
      email={email}
      variant={variant} />
  );
}

function ReplyBoxForConversationIds(props: { conversationIds: string[], variant: Variant, onClose?: () => void; }) {

  const { variant, conversationIds, onClose } = props;

  const { data: autofillData } = useQuery<TemplateAutofillValuesPayload, TemplateAutofillValuesInput>(TEMPLATE_AUTOFILL_VALUES, {
    variables: {}, fetchPolicy: "no-cache",
  });

  const { data: templatesData } = useQuery<TemplatesPayload, TemplatesInput>(TEMPLATES, {
    variables: { filters: { sort: { "shortcut": "ASC" } } }, fetchPolicy: "no-cache",
  });

  const { data: teamData } = useQuery<TeamPayload, TeamInput>(TEAM, {
    variables: {},
  });

  return (
    <ReplyBox
      onClose={onClose}
      team={!!(teamData?.team) ? teamData.team.nodes : []}
      templates={!!(templatesData?.templates) ? templatesData.templates.nodes : []}
      templateAutofillValues={!!(autofillData?.templateAutofillValues) ? autofillData.templateAutofillValues : {} as any}
      conversationIds={conversationIds}
      variant={variant} />
  );
}

function ReplyBoxForConversation(props: { conversation: Conversation, variant: Variant, onClose?: () => void; }) {

  const { conversation, variant, onClose } = props;

  const { data: templatesData } = useQuery<TemplatesPayload, TemplatesInput>(TEMPLATES, {
    variables: { filters: { sort: { "shortcut": "ASC" } } }, fetchPolicy: "no-cache",
  });

  const { data: autofillData, refetch } = useQuery<TemplateAutofillValuesPayload, TemplateAutofillValuesInput>(TEMPLATE_AUTOFILL_VALUES, {
    variables: { conversationId: conversation.id }, fetchPolicy: "no-cache",
  });

  const { data: teamData } = useQuery<TeamPayload, TeamInput>(TEAM, {
    variables: {},
  });

  useEffect(() => {
    refetch();
  }, [conversation]);

  return (
    <ReplyBox
      onClose={onClose}
      team={!!(teamData?.team) ? teamData.team.nodes : []}
      templates={!!(templatesData?.templates) ? templatesData.templates.nodes : []}
      templateAutofillValues={!!(autofillData?.templateAutofillValues) ? autofillData.templateAutofillValues : {} as any}
      conversation={conversation}
      variant={variant} />
  );
}

interface ReplyBoxProps extends ReplyBoxWrapperProps {
  team: User[];
  templates: Template[];
  templateAutofillValues: [key: string];
}

function ReplyBox(props: ReplyBoxProps) {

  const { team, conversation, conversationIds, templates, templateAutofillValues, variant, email, onClose } = props;
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const [play] = useSound(notification);

  const history = useHistory();
  const [attachments, setAttachments] = useState<OrgFile[]>([]);
  const [error, setError] = useState<string | undefined>();
  const [attachFilesDialogOpen, setAttachFilesDialogOpen] = useState<boolean>(false);
  const [subject, setSubject] = useState<string>("");
  const [asPreview, setAsPreview] = useState<boolean>(false);
  const [asNote, setAsNote] = useState<boolean>(false);

  const [linkOpen, setLinkOpen] = useState<boolean>(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>("0");
  const customerFields = conversation?.customer.fields.nodes || [];

  let emails = !!email ? [{ isDefault: true, value: email, id: "" }] as Field[] : (customerFields.filter((o) => o.type === "email") ?? []);
  let phoneNumbers = (customerFields.filter((o) => o.type === "phone_number") ?? []);

  const contactableFields = [...emails, ...phoneNumbers];

  const defaultEmail = emails.filter((o) => !!o.isDefault);

  const [selectedField, setSelectedField] = useState<Field | undefined>(defaultEmail.length > 0 ? defaultEmail[0] : emails.length > 0 ? emails[0] : undefined);

  const { data: templateData, refetch } = useQuery<TemplatePayload, TemplateInput>(TEMPLATE, {
    variables: { id: selectedTemplateId },
  });

  const [addNote, { loading: addNoteLoading }] = useMutation<AddNotePayload, AddNoteInput>(ADD_NOTE, {
    refetchQueries: ["Conversation", "Conversations", "SideBarQuery"]
  });

  const [addComment, { loading: addCommentLoading }] = useMutation<AddCommentPayload, AddCommentInput>(ADD_COMMENT, {
    refetchQueries: ["Conversation", "Conversations", "SideBarQuery", "Customers"]
  });

  const [startEmailConversation, { loading: startEmailConversationLoading }] = useMutation<StartEmailConversationPayload, StartEmailConversationInput>(START_EMAIL_CONVERSATION, {
    refetchQueries: ["Conversation", "Conversations", "SideBarQuery", "Customers"]
  });

  const [bulkReply, { loading: bulkReplyLoading }] = useMutation<BulkReplyPayload, BulkReplyInput>(BULK_REPLY, {
    refetchQueries: ["Conversation", "Conversations", "SideBarQuery", "Customers"]
  });

  const linkExtension = useMemo(() => {

    const extension = new LinkExtension({
      autoLink: true,
      defaultTarget: "_blank",
      defaultProtocol: "http:"
    });

    return extension;
  }, [linkOpen]);

  const extraAttributes: IdentifierSchemaAttributes[] = [
    { identifiers: ['mention', 'emoji'], attributes: { role: { default: 'presentation' } } },
    { identifiers: ['mention'], attributes: { href: { default: null } } },
  ];

  const mentionExtension = useMemo(() => {

    const extension = new MentionAtomExtension({
      matchers: [
        { name: 'at', char: '@', appendText: ' ' },
        { name: 'tag', char: '#', appendText: ' ' },
      ],
    });

    return extension;
  }, []);

  const { manager, state, setState, onChange } = useRemirror({

    extensions: () => [
      new SuggestExtension(),
      linkExtension,
      new OrderedListExtension(),
      new BulletListExtension(),
      new BlockquoteExtension(),
      new HeadingExtension(),
      new PlaceholderExtension({ placeholder: "Write something..." }),
      new MarkdownExtension(),
      new UnderlineExtension(),
      new BoldExtension(),
      new ItalicExtension(),
      new CalloutExtension({ defaultType: 'warn' }),
      new StrikeExtension(),
      mentionExtension,
    ],

    extraAttributes: extraAttributes,
    selection: 'start',
    stringHandler: 'html',
    content: "",
  });


  function getText(html: string) {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || "";
  }

  const handleAttachmentDelete = (attachment: OrgFile) => {
    setAttachments((attachments) => attachments.filter((file) => file.id !== attachment.id));
  }

  const resetForm = () => {
    setError(undefined);
    setState(manager.createState());
    setAsNote(false);
    setSelectedTemplateId("0");
    setAttachments([]);
    setSubject("");
  }

  function getContentReady(): string {

    let noteHtml = prosemirrorNodeToHtml(state.doc);

    if (!conversation) {
      return noteHtml;
    }

    for (const [k, v] of Object.entries(templateAutofillValues)) {
      noteHtml = noteHtml.replaceAll(k, v);
    }

    return noteHtml;
  }

  async function makeNote(conversation: Conversation) {
    try {
      let noteHtml = getContentReady();

      const { data, errors } = await addNote({
        variables: {
          input: {
            id: conversation.id,
            noteHtml: noteHtml,
          },
        },
      });

      if (!!errors && errors.length > 0) {
        setError(errors[0].message);
      } else if (!!data && data.addNote.errors.length > 0) {
        setError(data.addNote.errors[0]);
      } else {
        enqueueSnackbar('Note added');
        resetForm();
        play();

        if (!!onClose) {
          onClose();
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  }

  async function singleComment(done: boolean, conversation: Conversation) {
    try {
      let commentHtml = getContentReady();

      const { errors } = await addComment({
        variables: {
          input: {
            id: conversation.id,
            commentHtml: commentHtml,
            subject: subject,
            done: done,
            toAddressId: selectedField!.id,
            attachedFileIds: attachments.map((o) => o.id),
          },
        },
      });

      if (!!errors && errors.length > 0) {
        setError(errors[0].message);
      } else {
        enqueueSnackbar('Message sent');
        resetForm();
        play();

        if (!!onClose) {
          onClose();
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  }

  async function doBulkReply(done: boolean, conversationIds: string[]) {
    try {
      let commentHtml = getContentReady();

      const { errors } = await bulkReply({
        variables: {
          input: {
            ids: conversationIds,
            commentHtml: commentHtml,
            subject: subject,
            done: done,
            attachedFileIds: attachments.map((o) => o.id),
          },
        },
      });

      if (!!errors && errors.length > 0) {
        setError(errors[0].message);
      } else {
        enqueueSnackbar('Bulk reply sent');
        resetForm();
        play();

        if (!!onClose) {
          onClose();
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  }

  async function newMessageToEmail(email: string) {
    try {
      let messageHtml = getContentReady();

      const { errors } = await startEmailConversation({
        variables: {
          input: {
            messageHtml: messageHtml,
            subject: subject,
            email: email!,
            attachedFileIds: attachments.map((o) => o.id),
          },
        },
      });

      if (!!errors && errors.length > 0) {
        setError(errors[0].message);
      } else {
        enqueueSnackbar('Message sent');
        resetForm();
        play();

        if (!!onClose) {
          onClose();
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(e.message);
      }
    }
  }

  async function handleSubmit(done: boolean) {
    switch (variant) {
      case "new_message_to_email": {
        await newMessageToEmail(email!);

        break;
      }
      case "bulk_reply": {
        await doBulkReply(done, conversationIds!);

        break;
      }
      case "conversation": {
        if (asNote) {
          await makeNote(conversation!);
        } else {
          await singleComment(done, conversation!);
        }
        break;
      }
    }
  }

  const loading = addNoteLoading || addCommentLoading || startEmailConversationLoading || bulkReplyLoading;
  const hasText = getText(prosemirrorNodeToHtml(state.doc)).length > 0;

  useEffect(() => {
    if (!!customerFields) {
      const emails = customerFields.filter((o) => o.type === "email" && !!o.isDefault);
      setSelectedField(emails.length > 0 ? emails[0] : undefined);
    }
  }, [customerFields]);

  useEffect(() => {
    if (!!(templateData?.template)) {
      setSubject(templateData.template.subject);
      setState(manager.createState({ content: templateData.template.contentHtml }));
    }
  }, [templateData]);

  useEffect(() => {
    refetch({ id: selectedTemplateId });
  }, [selectedTemplateId]);

  return (
    <>
      <AttachFilesDialog open={attachFilesDialogOpen} onClose={() => setAttachFilesDialogOpen(false)} onAttach={(files) => {
        setAttachments([...files, ...attachments]);
      }} />
      <Card variant={variant == "conversation" ? "outlined" : "elevation"} elevation={0} sx={{ p: variant == "conversation" ? 0 : 1 }} style={{
        borderColor: asNote ? theme.palette.attentionBorder.main : theme.palette.border.main,
        backgroundColor: asNote ? alpha(theme.palette.attention.main, 0.2) : theme.palette.background.paper,

      }}>
        {!!error && <Alert severity="error">{error}</Alert>}
        <Stack direction="row" alignItems="center" p={1} spacing={1} sx={{
          color: theme.palette.text.secondary,
        }}>
          {!!asNote && <Typography fontWeight={600} color="text.secondary" variant="caption">This note will not be shared with the customer</Typography>}
          {!asNote && !asPreview && (<>
            <Typography color="text.secondary" variant="caption">Template:</Typography>
            <FormControl variant="outlined">
              <NativeSelect input={<Select size="small" variant="outlined" sx={{ height: 32 }} />} value={selectedTemplateId} defaultValue="0" onChange={(event: { target: { value: string } }) => {
                try {
                  const id = event.target.value;
                  setSelectedTemplateId(id);
                  if (id === "1") {
                    history.push("/org-settings/templates?page=0")
                  }
                } catch { }
              }}>
                <option value="1">New template</option>
                <option value="0">-</option>
                {templates.map((template) => <option key={template.id} value={template.id}>{template.shortcut}</option>)}
              </NativeSelect>
            </FormControl>
            {variant === "new_message_to_email" && <>
              <Typography color="text.secondary" variant="caption">To:</Typography>
              <FormControl variant="outlined">
                <NativeSelect input={<Select size="small" variant="outlined" sx={{ height: 32 }} />} value={selectedField?.id} defaultValue={selectedField?.id} onChange={(event: { target: { value: string } }) => {
                  try {
                    const id = event.target.value;
                    setSelectedField(customerFields.filter((field) => field.id === id)[0]);
                  } catch { }
                }}>
                  {emails.map((email) => <option key={email.id} value={email.id}>{email.value}</option>)}
                </NativeSelect>
              </FormControl>
            </>}
            {!!conversation && <Typography color="text.secondary" variant="caption">To:</Typography>}
            {!!conversation && <FormControl variant="outlined">
              <NativeSelect input={<Select size="small" variant="outlined" sx={{ height: 32 }} />} value={selectedField?.id} defaultValue={selectedField?.id} onChange={(event: { target: { value: string } }) => {
                try {
                  const id = event.target.value;
                  setSelectedField(customerFields.filter((field) => field.id === id)[0]);
                } catch { }
              }}>
                {contactableFields.map((email) => <option key={email.id} value={email.id}>{email.value}</option>)}
              </NativeSelect>
            </FormControl>}
          </>)}
          {variant !== "new_message_to_email" && !asNote && !asPreview && <Button disabled={loading} startIcon={<NoteAddIcon />} onClick={() => setAsNote(true)} size="small" color="inherit" variant="text">As note</Button>}
          {!asNote && asPreview && <Button disabled={loading} startIcon={<FormatColorTextIcon />} onClick={() => setAsPreview(false)} size="small" color="inherit" variant="text">Editor</Button>}
          {!asNote && !asPreview && <Button disabled={!hasText || loading} startIcon={<VisibilityIcon />} onClick={() => setAsPreview(true)} size="small" color="inherit" variant="text">Preview</Button>}
          {asNote && <Button disabled={loading} startIcon={<ReplyIcon />} onClick={() => setAsNote(false)} size="small" color="inherit">As reply</Button>}
        </Stack>
        {asPreview && <TextField value={subject} disabled variant="outlined" fullWidth sx={{ paddingLeft: 1, paddingRight: 1, paddingBottom: 1 }} size="small" placeholder="Subject" onChange={(e) => setSubject(e.currentTarget.value)} />}
        {!asNote && !asPreview && <TextField value={subject} variant="outlined" fullWidth sx={{ paddingLeft: 1, paddingRight: 1, paddingBottom: 1 }} size="small" placeholder="Subject" onChange={(e) => setSubject(e.currentTarget.value)} />}
        {!asPreview && <div className='remirror-theme' style={{ minHeight: variant === "conversation" ? 150 : 300, marginLeft: variant === "conversation" ? 0 : 8, marginRight: variant === "conversation" ? 0 : 8, borderLeft: variant === "conversation" ? "none" : `1px solid ${theme.palette.border.main}`, borderRight: variant === "conversation" ? "none" : `1px solid ${theme.palette.border.main}` }}>
          <Remirror manager={manager} initialContent={state} state={state} onChange={onChange}>
            <MenuComponent
              setLinkOpen={setLinkOpen} />
            <LinkComponent
              open={linkOpen}
              onClose={() => setLinkOpen(false)} />
            <EditorComponent />
            <TemplateSuggestorComponent
              templates={templates}
              selectedTemplate={(template) => {
                setSelectedTemplateId(template.id);
              }} />
            <MentionComponent
              users={team.map((user) => { return { id: user.id, label: user.name } })}
              tags={Object.keys(templateAutofillValues)} />
          </Remirror>
        </div>}
        {!!asPreview && <Stack style={{ marginLeft: variant === "conversation" ? 0 : 8, marginRight: variant === "conversation" ? 0 : 8, }} sx={{ p: 2, borderTop: `1px solid ${theme.palette.border.main}`, borderLeft: variant === "conversation" ? "none" : `1px solid ${theme.palette.border.main}`, borderRight: variant === "conversation" ? "none" : `1px solid ${theme.palette.border.main}`, minHeight: variant === "conversation" ? 150 : 300 }}>
          <MessageShadowRoot messageHtml={getContentReady()} />
        </Stack>}
        <Divider sx={{ ml: variant === "conversation" ? 0 : 1, mr: variant === "conversation" ? 0 : 1, mb: variant === "conversation" ? 0 : 1 }} />
        <Stack p={1} direction="row" spacing={1} alignItems="flex-start" sx={{ backgroundColor: theme.palette.background.paper }}>
          {!asNote && (variant === "bulk_reply" || variant === "new_message_to_email" || selectedField?.type === "email") && <LoadingButton
            loadingPosition="center"
            loading={loading}
            variant={loading ? "contained" : "outlined"}
            color={loading ? "success" : "inherit"}
            size="small"
            onClick={() => setAttachFilesDialogOpen(true)}>Attach files {attachments.length > 0 ? `(${attachments.length})` : ''}
          </LoadingButton>}
          <div style={{ flexGrow: 1 }} />
          <Stack direction="row" spacing={1}>
            {!asNote && variant !== "new_message_to_email" && <LoadingButton
              loadingPosition="center"
              loading={loading}
              disabled={!hasText}
              variant={loading ? "contained" : "outlined"}
              color={loading ? "success" : "inherit"}
              size="small"
              onClick={() => handleSubmit(true)}
            >
              Resolve and comment
            </LoadingButton>}
            <LoadingButton
              loadingPosition="center"
              loading={loading}
              disabled={!hasText || loading}
              variant="contained"
              color="success"
              size="small"
              onClick={() => handleSubmit(false)}
            >
              {asNote ? "Create note" : "Comment"}
            </LoadingButton>
          </Stack>
        </Stack>
        {attachments.length > 0 && <Stack p={theme.spacing(1)} pt={0} direction="row" alignItems="flex-start" flexWrap="wrap">
          {attachments.map((attachment) => {
            return (
              <Chip
                sx={{ mr: 1, mb: 0.5 }}
                key={attachment.id}
                label={attachment.givenFilename}
                size="small"
                onDelete={() => handleAttachmentDelete(attachment)}
              />
            );
          })}
        </Stack>}
      </Card>
    </>
  );
};
import { gql } from "@apollo/client";
import EmailDomain from "types/EmailDomain";
import EmailDomainFragment from "../fragments/EmailDomainFragment";

export interface EmailDomainInput {
    id: string;
}

export interface EmailDomainPayload {
    emailDomain?: EmailDomain;
}

const EMAIL_DOMAIN = gql`
  query EmailDomain($id: ID!) {
    emailDomain(id: $id) {
        ...EmailDomainFields
    }
  }
  ${EmailDomainFragment}
`;

export default EMAIL_DOMAIN;
import { gql } from "@apollo/client";

export interface ShopifyRedirectInput {
    shopDomain: string;
}

export interface ShopifyRedirectPayload {
    shopifyRedirectUrl?: string;
}

const SHOPIFY_REDIRECT_URL = gql`
  query ShopifyRedirectUrl($shopDomain: String!) {
    shopifyRedirectUrl(shopDomain: $shopDomain) 
  }
`;

export default SHOPIFY_REDIRECT_URL;
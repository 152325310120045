import { gql } from "@apollo/client";
import Customer from "types/Customer";
import CustomerFragment from "graphql/fragments/CustomerFragment";
import FieldFragment from "graphql/fragments/FieldFragment";
import MailingAddressFragment from "graphql/fragments/MailingAddressFragment";

export interface CreateMailingAddressInput {
  input: {
    customerId: string;
    address1?: string;
    address2?: string;
    company?: string;
    city?: string;
    countryCode?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    province?: string;
    zip?: string;
  };
}

export interface CreateMailingAddressPayload {
  createMailingAddress: {
    customer?: Customer;
  };
}

const CREATE_MAILING_ADDRESS = gql`
  mutation CreateMailingAddress($input: CreateMailingAddressMutationInput!) {
    createMailingAddress(input: $input) {
      customer {
        ...CustomerFields
        fields {
          nodes {
            ...FieldFields
          }
        }
        mailingAddresses {
          nodes {
            ...MailingAddressFields
          }
        }
      }
    }
  }
  ${CustomerFragment},
  ${FieldFragment},
  ${MailingAddressFragment}
`;

export default CREATE_MAILING_ADDRESS;

import React from "react";
import {
  Route,
  Switch,
  BrowserRouter,
  Redirect
} from "react-router-dom";
import Login from "./Login"
import VerifyLogin from "./VerifyLogin";
import SelectOrg from "./SelectOrg";
import Orders from "./Orders";
import Conversations from "./Conversations";
import Conversation from "./Conversation";
import Dashboard from "./Dashboard";
import Team from "./Team";
import AddressBook from "./AddressBook";
import { useQuery } from "@apollo/client";
import ME, {
  MeInput,
  MePayload,
} from "graphql/queries/MeQuery";
import useApp from "hooks/useApp";
import AdminAppBar from "components/chrome/AdminAppBar";
import Errors from "./Errors";
import AcceptInvite from "./AcceptInvite";
import Required2FA from "./Required2FA";
import VerifyEmail from "./VerifyEmail";
import VerifyPhoneNumber from "./VerifyPhoneNumber";
import Customer from "./Customer";
import LogRocket from 'logrocket';
import ConnectToShopify from "./ConnectToShopify";
import SetPassword from "./SetPassword";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import VerifyShopifyEmail from "./VerifyShopifyEmail";
import OrgSettings from "./OrgSettings";
import EditTemplate from "./EditTemplate";
import EditLayout from "./EditLayout";
import Files from "./Files";
import Articles from "./Articles";
import Order from "./Order";
import AccountSettings from "./AccountSettings";
import GettingStarted from "./GettingStarted";
import GettingStartedEmail from "./GettingStartedEmail";
import GettingStartedInviteTeam from "./GettingStartedInviteTeam";
import GettingStartedEmailForwarding from "./GettingStartedEmailForwarding";
import GettingStartedJoinCommunity from "./GettingStartedJoinCommunity";
import GettingStartedOrgPlan from "./GettingStartedOrgPlan";
import WelcomeToPaidPlan from "./WelcomeToPaidPlan";

type AdminComponentProps = {
  children?: React.ReactNode;
  hideAdminBar?: boolean;
};

function AdminComponent(props: AdminComponentProps) {
  const app = useApp();

  const { children } = props;

  const { data, error, loading } = useQuery<MePayload, MeInput>(ME, {
    variables: {},
  });

  if (!!error) {

    if (error.message === "This account has been banned." && window.location.pathname !== "/banned") {
      return <Errors errors={["This account has been banned."]} />;
    } else if (error.message === "This account has been locked." && window.location.pathname !== "/locked") {
      return <Errors errors={["This account has been locked."]} />;
    } else if (error.message === "PASSWORD_REQUIRED" && window.location.pathname !== "/set-password") {
      return <SetPassword />;
    } else if (error.message === "VERIFY_EMAIL" && window.location.pathname !== "/verify-email") {
      return <VerifyEmail />;
    } else if (error.message === "2FA_REQUIRED" && window.location.pathname !== "/upgrade-2fa") {
      return <Required2FA />;
    } else if (error.message === "VERIFY_PHONE_NUMBER" && window.location.pathname !== "/verify-phone-number") {
      return <VerifyPhoneNumber />;
    } else if (error.message === "PLAN_REQUIRED" && window.location.pathname !== "/getting-started-plan") {
      return <GettingStartedOrgPlan />;
    } else if (error.message === "AVATAR_REQUIRED" && window.location.pathname !== "/getting-started") {
      return <GettingStarted />;
    }

    if (error.message !== "2FA_REQUIRED" && error.message !== "PASSWORD_REQUIRED" && error.message !== "VERIFY_EMAIL" && error.message !== "VERIFY_PHONE_NUMBER" && error.message !== "PLAN_REQUIRED" && error.message !== "AVATAR_REQUIRED") {
      return <Errors errors={[error.message]} />;
    }
  }

  if (!!data && !!data.me) {

    LogRocket.identify(data.me.id, {
      name: data.me.name,
      email: data.me.email
    });

    if (data.me.banned) {
      return <Errors errors={["Your account has been banned. Please contact zenshop staff"]} />;
    } else if (data.me.locked) {
      return <Errors errors={["Your account has been locked. Please contact zenshop staff"]} />;
    } else if (data.me.passwordNeedsSetting) {
      return <SetPassword />;
    } else if (!data.me.verifiedEmail) {
      return <VerifyEmail />;
    } else if (data.me.twoFactorRequired) {
      return <Required2FA />;
    } else if (!data.me.verifiedPhoneNumber) {
      return <VerifyPhoneNumber />;
    } else if (data.me.planRequired) {
      return <GettingStartedOrgPlan />;
    } else if (data.me.avatarRequired) {
      return <GettingStarted />;
    }

    let selectedOrg = data.me?.selectedOrg;

    if (!!selectedOrg) {
      app.updateSelectedOrgID(selectedOrg.id);

      if (!selectedOrg.hasEmailDomain && window.location.pathname !== "/getting-started") {
        return <GettingStartedEmail />;
      } else {
        return <AdminAppBar me={data.me}>{children}</AdminAppBar>;
      }
    } else {
      window.location.href = "https://admin.zenshop.app/select-org";
      return <div />;
    }
  }

  if (loading) {
    return <div />;
  }

  return <div />;
}

const AdminRoute = ({ component, ...rest }: any) => {

  const routeComponent = () => {
    const screen = React.createElement(component, {});
    return <AdminComponent {...rest}>{screen}</AdminComponent>;
  };

  return <Route {...rest} render={routeComponent} />;
};

function AuthorizedComponent(props: AdminComponentProps) {
  const app = useApp();
  const { children, hideAdminBar } = props;

  const { data, error, loading } = useQuery<MePayload, MeInput>(ME, {
    variables: {},
  });

  if (!!error) {

    if (error.message === "This account has been banned." && window.location.pathname !== "/banned") {
      return <Errors errors={["This account has been banned."]} />;
    } else if (error.message === "This account has been locked." && window.location.pathname !== "/locked") {
      return <Errors errors={["This account has been locked."]} />;
    } else if (error.message === "PASSWORD_REQUIRED" && window.location.pathname !== "/set-password") {
      return <SetPassword />;
    } else if (error.message === "VERIFY_EMAIL" && window.location.pathname !== "/verify-email") {
      return <VerifyEmail />;
    } else if (error.message === "2FA_REQUIRED" && window.location.pathname !== "/upgrade-2fa") {
      return <Required2FA />;
    } else if (error.message === "VERIFY_PHONE_NUMBER" && window.location.pathname !== "/verify-phone-number") {
      return <VerifyPhoneNumber />;
    } else if (error.message === "PLAN_REQUIRED" && window.location.pathname !== "/getting-started-plan") {
      return <GettingStartedOrgPlan />;
    } else if (error.message === "AVATAR_REQUIRED" && window.location.pathname !== "/getting-started") {
      return <GettingStarted />;
    }

    if (error.message !== "2FA_REQUIRED" && error.message !== "PASSWORD_REQUIRED" && error.message !== "VERIFY_EMAIL" && error.message !== "VERIFY_PHONE_NUMBER" && error.message !== "PLAN_REQUIRED" && error.message !== "AVATAR_REQUIRED") {
      return <Errors errors={[error.message]} />;
    }
  }

  if (!!data && !!data.me) {

    LogRocket.identify(data.me.id, {
      name: data.me.name,
      email: data.me.email
    });

    let selectedOrg = data.me?.selectedOrg;

    if (!!selectedOrg) {
      app.updateSelectedOrgID(selectedOrg.id);
    }

    if (data.me.banned) {
      return <Errors errors={["Your account has been banned. Please contact zenshop staff"]} />;
    } else if (data.me.locked) {
      return <Errors errors={["Your account has been locked. Please contact zenshop staff"]} />;
    } else if (data.me.passwordNeedsSetting) {
      return <SetPassword />;
    } else if (!data.me.verifiedEmail) {
      return <VerifyEmail />;
    } else if (data.me.twoFactorRequired) {
      return <Required2FA />;
    } else if (!data.me.verifiedPhoneNumber) {
      return <VerifyPhoneNumber />;
    } else if (data.me.planRequired) {
      return <GettingStartedOrgPlan />;
    } else if (data.me.avatarRequired) {
      return <GettingStarted />;
    }

    if (!!selectedOrg && !selectedOrg.hasEmailDomain && window.location.pathname !== "/getting-started") {
      return <GettingStartedEmail />;
    }

    if (!!hideAdminBar) {
      return <>{children}</>
    } else {
      return <AdminAppBar me={data.me}>{children}</AdminAppBar>;
    }
  }

  if (loading) {
    return <div />;
  }

  return <div />;
}

const AuthorizedRoute = ({ component, ...rest }: any) => {

  const routeComponent = () => {
    const screen = React.createElement(component, {});

    return <AuthorizedComponent {...rest}>{screen}</AuthorizedComponent>;
  };

  return <Route {...rest} render={routeComponent} />;
};

function Router() {

  const app = useApp();
  const isLoggedIn = !!app.token;
  const selectedOrg = !!app.selectedOrgID;

  return (
    <BrowserRouter>
      <Switch>

        /* Handle redirect if user needs login/select org */

        <Route exact path="/"
          render={() => {
            if (selectedOrg) {
              return <Redirect to={{ pathname: "/conversations" }} />;
            } else if (isLoggedIn) {
              return <Redirect to={{ pathname: "/select-org" }} />;
            } else {
              return <Redirect to={{ pathname: "/login" }} />;
            }
          }}
        />

        /* External redirect URLS */

        <Route path='/terms' component={() => {
          window.location.href = process.env.REACT_APP_TERMS!; return null;
        }} />
        <Route path='/privacy_policy' component={() => {
          window.location.href = process.env.REACT_APP_PRIVACY_POLICY!; return null;
        }} />
        <Route path='/protect_deliverability' component={() => {
          window.location.href = process.env.REACT_APP_DOCS_EMAIL_DELIVERABILITY!; return null;
        }} />

        /* Open Routes */

        <Route path="/login" exact component={Login} />
        <Route path="/set-password" exact component={SetPassword} />
        <Route path="/verify-login" component={VerifyLogin} />
        <Route path="/verify-email" component={VerifyEmail} />
        <Route path="/verify-phone-number" component={VerifyPhoneNumber} />
        <Route path="/upgrade-2fa" component={Required2FA} />
        <Route path="/accept-invite" component={AcceptInvite} />
        <Route path="/error" component={Errors} />
        <Route path="/connect-to-shopify" component={ConnectToShopify} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/welcome-to-paid-plan" component={WelcomeToPaidPlan} />
        <Route path="/verify-shop-email" component={VerifyShopifyEmail} />

        <AuthorizedRoute path="/getting-started" component={GettingStarted} hideAdminBar />
        <AuthorizedRoute path="/getting-started-plan" component={GettingStartedOrgPlan} hideAdminBar />
        <AuthorizedRoute path="/getting-started-email" component={GettingStartedEmail} hideAdminBar />
        <AuthorizedRoute path="/getting-started-team-members" component={GettingStartedInviteTeam} hideAdminBar />
        <AuthorizedRoute path="/getting-started-email-forwarding" component={GettingStartedEmailForwarding} hideAdminBar />
        <AuthorizedRoute path="/getting-started-join-community" component={GettingStartedJoinCommunity} hideAdminBar />

        /* Handle authorized (but not org selected) */

        <AuthorizedRoute path="/select-org" component={SelectOrg} />
        <AuthorizedRoute path="/account-settings/:selection" component={AccountSettings} />
        <AuthorizedRoute path="/account-settings" component={AccountSettings} />

        /* Handle authorized and org selected */

        <AdminRoute path="/dashboard" component={Dashboard} />
        <AdminRoute path="/address-book" component={AddressBook} />
        <AdminRoute path="/customer/:id" component={Customer} />
        <AdminRoute path="/conversations" component={Conversations} />
        <AdminRoute path="/orders" component={Orders} />
        <AdminRoute path="/order/:id" component={Order} />
        <AdminRoute path="/conversation/:id" component={Conversation} />
        <AdminRoute path="/team" component={Team} />
        <AdminRoute path="/org-settings/:selection" component={OrgSettings} />
        <AdminRoute path="/org-settings" component={OrgSettings} />
        <AdminRoute path="/template/:id" component={EditTemplate} />
        <AdminRoute path="/layout/:id" component={EditLayout} />
        <AdminRoute path="/files" component={Files} />
        <AdminRoute path="/articles" component={Articles} />

      </Switch>
    </BrowserRouter>
  );
}

export default Router;

import { gql } from "@apollo/client";
import MailingAddress from "types/MailingAddress";
import MailingAddressFragment from "../fragments/MailingAddressFragment";

export interface UpdateMailingAddressToDefaultInput {
    input: {
        id: string;
    };
}

export interface UpdateMailingAddressToDefaultPayload {
    updateMailingAddressToDefault: {
        mailingAddress?: MailingAddress;
    };
}

const UPDATE_MAILING_ADDRESS_TO_DEFAULT = gql`
  mutation UpdateMailingAddressToDefault($input: UpdateMailingAddressToDefaultMutationInput!) {
    updateMailingAddressToDefault(input: $input) {
      mailingAddress {
        ...MailingAddressFields
      }
    }
  }
  ${MailingAddressFragment},
`;

export default UPDATE_MAILING_ADDRESS_TO_DEFAULT;

import gql from "graphql-tag";

const OrgFragment = gql`
  fragment OrgFields on Org {
    id
    createdAt
    name
    plan
    isPaidPlan
    trialDaysRemaining
    numberOfDaysToClearData
    zenMailCompanyName
  }
`;

export default OrgFragment;

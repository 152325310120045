import { gql } from "@apollo/client";
import Workflow from "types/Workflow";

export interface WorkflowsSortInput {
    name?: "ASC" | "DESC",
    permissions?: "ASC" | "DESC",
    template?: "ASC" | "DESC",
}

export interface WorkflowsInput {
    after?: string;
    before?: string;
    first?: number;
    last?: number;
    filters: WorkflowsFilterInput;
}

export interface WorkflowsFilterInput {
    sort?: WorkflowsSortInput;
    searchQuery?: string;
    privateWorkflow?: boolean;
}

export interface WorkflowsPayload {
    workflows: {
        pageInfo: {
            totalCount: number;
            startCursor: string;
            endCursor: string;
        };
        nodes: Workflow[];
    };
}

const WORKFLOWS = gql`
  query Workflows(
    $after: String
    $before: String
    $last: Int
    $first: Int,
    $filters: WorkflowFilterInput!
  ) {
    workflows(
      first: $first
      last: $last
      after: $after
      before: $before,
      filters: $filters,
    ) {
      pageInfo {
        totalCount
        startCursor
        endCursor
      }
      nodes {
          id
          name
          privateWorkflow
          resolvesConversation
          template {
            id
            createdAt
            name
          }
      }
    }
  }
`;

export default WORKFLOWS;
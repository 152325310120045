import { gql } from "@apollo/client";
import Conversation from "types/Conversation";

export interface UpdateConversationSpamInput {
  input: {
    id: string;
    spam: boolean;
    blockSender: boolean;
  };
}

export interface UpdateConversationSpamPayload {
  updateConversationSpam: {
    conversation: Conversation;
  };
}

const UPDATE_CONVERSATION_SPAM = gql`
  mutation UpdateConversationSpam($input: UpdateConversationSpamMutationInput!) {
    updateConversationSpam(input: $input) {
      conversation {
        id
        spam
      }
    }
  }
`;

export default UPDATE_CONVERSATION_SPAM;

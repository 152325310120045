import { useState, useEffect } from "react";
import { LeftAlignedButton } from "theme/Button";
import Divider from "theme/Divider";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import InboxIcon from "@mui/icons-material/Inbox";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import LabelIcon from "@mui/icons-material/Label";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import DeleteIcon from "@mui/icons-material/Delete";
import TimerIcon from "@mui/icons-material/Timer";
import { useQuery, useSubscription } from "@apollo/client";
import SIDE_BAR, {
  SideBarInput,
  SideBarPayload,
} from "graphql/queries/SideBarQuery";
import MY_NOTIFICATIONS_UPDATED, {
  MyNotificationsUpdatedInput,
  MyNotificationsUpdatedPayload
} from "graphql/subscriptions/MyNotificationsUpdatedSubscription";
import ME, {
  MeInput,
  MePayload,
} from "graphql/queries/MeQuery";
import LABELS, {
  LabelsInput,
  LabelsPayload,
} from "graphql/queries/LabelsQuery";
import CONVERSATION_TYPES, {
  ConversationTypesInput,
  ConversationTypesPayload,
} from "graphql/queries/ConversationTypesQuery";
import { ConversationsFilter } from "graphql/queries/ConversationsQuery";
import CategoryIcon from '@mui/icons-material/Category';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DoneIcon from '@mui/icons-material/Done';
import { useStoreon } from 'storeon/react';
import State from "store/State";
import { useHistory } from "react-router-dom";
import Chip from "theme/Chip";

interface TypesNavigatorProps {
  conversationTypeId?: string;
  navigateToConversationType: (type: string | undefined) => void;
}

function TypesNavigator(props: TypesNavigatorProps) {

  const { conversationTypeId, navigateToConversationType } = props;

  const { data } = useQuery<ConversationTypesPayload, ConversationTypesInput>(CONVERSATION_TYPES, {
    variables: { filters: { sort: { title: "ASC" } } },
  });

  const types = data?.conversationTypes?.nodes ?? [];
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <>
      <LeftAlignedButton
        color="secondary"
        variant="text"
        startIcon={<CategoryIcon />}
        endIcon={<ExpandMoreIcon />}
        onClick={() => setExpanded(!expanded)}
      >
        <span style={{ width: "100%", textAlign: "left" }}>Type</span>
      </LeftAlignedButton>
      {!!expanded && <Stack direction="column" spacing={0.5} pl={1}>
        {types.map((type) =>
          <LeftAlignedButton
            onClick={() => {
              if (conversationTypeId === type.id) {
                navigateToConversationType(undefined);
              } else {
                navigateToConversationType(type.id);
              }
            }}
            variant={"text"}
            color={"secondary"}
            key={type.id}
            endIcon={<Stack alignItems="center" direction="row" spacing={1}>{conversationTypeId === type.id && <DoneIcon />}<EndIcon value={""} /></Stack>}>
            <span style={{ width: "100%", textAlign: "left" }}>{type.title}</span>
          </LeftAlignedButton>)}
      </Stack>}
    </>
  );
}

interface LabelsNavigatorProps {
  labelIds: string[];
  navigateToLabelIds: (type: string[]) => void;
}

function LabelsNavigator(props: LabelsNavigatorProps) {

  const { labelIds, navigateToLabelIds } = props;

  const { data } = useQuery<LabelsPayload, LabelsInput>(LABELS, {
    variables: { filters: { sort: { title: "ASC" } } },
  });

  const labels = data?.labels?.nodes ?? [];
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <>
      <LeftAlignedButton
        color="secondary"
        variant="text"
        startIcon={<LabelIcon />}
        endIcon={<ExpandMoreIcon />}
        onClick={() => setExpanded(!expanded)}
      >
        <span style={{ width: "100%", textAlign: "left" }}>Labels</span>
      </LeftAlignedButton>
      {!!expanded && <Stack direction="column" spacing={0.5} pl={1}>
        {labels.map((label) => {
          let idx = labelIds.indexOf(label.id);
          return (
            <LeftAlignedButton
              variant="text"
              color="secondary"
              onClick={() => {
                if (idx > -1) {
                  navigateToLabelIds([
                    ...labelIds.slice(0, idx),
                    ...labelIds.slice(idx + 1)
                  ]);
                } else {
                  navigateToLabelIds([...labelIds, label.id]);
                }
              }}
              key={label.id}
              startIcon={<Box
                component="span"
                sx={{
                  width: 14,
                  height: 14,
                  flexShrink: 0,
                  borderRadius: "3px",
                }}
                style={{ backgroundColor: label.colorHex }}
              />}
              endIcon={<Stack alignItems="center" direction="row" spacing={1}>{idx >= 0 && <DoneIcon />}<EndIcon value={""} /></Stack>}>
              <span style={{ width: "100%", textAlign: "left" }}>{label.title}</span>
            </LeftAlignedButton>);
        })}
      </Stack>}
    </>

  );
}

type EndIconProps = {
  value: number | string;
};

function EndIcon(props: EndIconProps) {
  const { value } = props;
  return (
    <>
      {!!value && <Stack sx={{ pl: 2 }}>
        <Chip variant="outlined" size="small" label={value} />
      </Stack>}
    </>
  );
}

export default function SideBar() {

  const history = useHistory();

  const { dispatch, conversationsGridFilter, conversationsGridCoversationTypeId, conversationsGridLabelIds } = useStoreon<State>('conversationsGridFilter', 'conversationsGridCoversationTypeId', 'conversationsGridLabelIds');

  const { data: meData } = useQuery<MePayload, MeInput>(ME, {
    variables: {},
  })

  const { data: notificationsUpdatedData } = useSubscription<MyNotificationsUpdatedPayload, MyNotificationsUpdatedInput>(MY_NOTIFICATIONS_UPDATED, {
    variables: { userId: meData?.me?.id || "" },
  });

  const { data, refetch } = useQuery<SideBarPayload, SideBarInput>(SIDE_BAR, {
    variables: {},
  });

  function navigateToFilter(filter: ConversationsFilter) {
    dispatch("conversations/setFilter", filter);
    refetch();
    history.push("/conversations");
  };

  function navigateToConversationType(conversationTypeId: string | undefined) {
    dispatch("conversations/setType", conversationTypeId);
    refetch();
    history.push("/conversations");
  };

  function navigateToLabelIds(labelIds: string[]) {
    dispatch("conversations/setLabels", labelIds);
    refetch();
    history.push("/conversations");
  };

  useEffect(() => {
    if (!!notificationsUpdatedData?.myNotificationsUpdated) {
      refetch();
    }
  }, [notificationsUpdatedData]);

  return (
    <Stack spacing={1}>
      <LeftAlignedButton
        startIcon={<InboxIcon />}
        color={
          conversationsGridFilter === ConversationsFilter.mine ? "primary" : "secondary"
        }
        variant={
          conversationsGridFilter === ConversationsFilter.mine ? "contained" : "text"
        }
        endIcon={<EndIcon value={data?.myConversationsCount || ""} />}
        onClick={() => navigateToFilter(ConversationsFilter.mine)}
      >
        <span style={{ width: "100%", textAlign: "left" }}>My Conversations</span>
      </LeftAlignedButton>
      <LeftAlignedButton
        color={
          conversationsGridFilter === ConversationsFilter.mentioned ? "primary" : "secondary"
        }
        variant={
          conversationsGridFilter === ConversationsFilter.mentioned ? "contained" : "text"
        }
        startIcon={<AlternateEmailIcon />}
        endIcon={<EndIcon value={data?.mentioningMeCount || ""} />}
        onClick={() => navigateToFilter(ConversationsFilter.mentioned)}
      >
        <span style={{ width: "100%", textAlign: "left" }}>Mentioned</span>
      </LeftAlignedButton>
      <LeftAlignedButton
        color={conversationsGridFilter === ConversationsFilter.waiting ? "primary" : "secondary"}
        variant={conversationsGridFilter === ConversationsFilter.waiting ? "contained" : "text"}
        startIcon={<TimerIcon />}
        endIcon={<EndIcon value={data?.waitingConversationsCount || ""} />}
        onClick={() => navigateToFilter(ConversationsFilter.waiting)}
      >
        <span style={{ width: "100%", textAlign: "left" }}>Waiting</span>
      </LeftAlignedButton>
      <LeftAlignedButton
        color={conversationsGridFilter === ConversationsFilter.saved ? "primary" : "secondary"}
        variant={conversationsGridFilter === ConversationsFilter.saved ? "contained" : "text"}
        startIcon={<BookmarkIcon />}
        endIcon={<EndIcon value={data?.savedConversationsCount || ""} />}
        onClick={() => navigateToFilter(ConversationsFilter.saved)}
      >
        <span style={{ width: "100%", textAlign: "left" }}>Saved</span>
      </LeftAlignedButton>
      <LeftAlignedButton
        color={conversationsGridFilter === ConversationsFilter.done ? "primary" : "secondary"}
        variant={conversationsGridFilter === ConversationsFilter.done ? "contained" : "text"}
        startIcon={<DoneIcon />}
        onClick={() => navigateToFilter(ConversationsFilter.done)}
      >
        <span style={{ width: "100%", textAlign: "left" }}>Done</span>
      </LeftAlignedButton>
      <Divider />
      <LeftAlignedButton
        color={conversationsGridFilter === ConversationsFilter.inbox ? "primary" : "secondary"}
        variant={conversationsGridFilter === ConversationsFilter.inbox ? "contained" : "text"}
        startIcon={<InboxIcon />}
        endIcon={<EndIcon value={data?.inboxCount || ""} />}
        onClick={() => navigateToFilter(ConversationsFilter.inbox)}
      >
        <span style={{ width: "100%", textAlign: "left" }}>Inbox</span>
      </LeftAlignedButton>
      <LeftAlignedButton
        color={conversationsGridFilter === ConversationsFilter.spam ? "primary" : "secondary"}
        variant={conversationsGridFilter === ConversationsFilter.spam ? "contained" : "text"}
        startIcon={<DeleteIcon />}
        onClick={() => navigateToFilter(ConversationsFilter.spam)}
      >
        <span style={{ width: "100%", textAlign: "left" }}>Spam</span>
      </LeftAlignedButton>
      <Divider />
      <TypesNavigator navigateToConversationType={navigateToConversationType} conversationTypeId={conversationsGridCoversationTypeId} />
      <LabelsNavigator navigateToLabelIds={navigateToLabelIds} labelIds={conversationsGridLabelIds ?? []} />
    </Stack>
  );
}

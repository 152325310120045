import gql from "graphql-tag";

const ShopifyOrderFragment = gql`
  fragment ShopifyOrderFields on ShopifyOrder {
    id
    createdAt
    lineItemsCount
    lineItemsFormatted
    financialStatus
    fulfillmentStatus
    fulfilled
    processedAt
    cancelledAt
    closedAt
    discountCode
    name
    email
    note
    phone
    fullyPaid
    fulfillable
    estimatedTaxes
    taxesIncluded
    refundable
    requiresShipping
    restockable
    riskLevel
    subtotalLineItemsQuantity
    tags
    clientIp
    customerLocale
    unpaid
    test
    edited
    customerAcceptsMarketing
    currentTotalWeight
    currencyCode
    presentmentCurrencyCode
    confirmed
    closed
    capturable
    canNotifyCustomer
    billingAddressMatchesShippingAddress
  }
`;

export default ShopifyOrderFragment;

import { useState, useEffect } from "react";
import DialogContent from 'theme/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { LoadingButton } from 'theme/Button';
import Dialog from '@mui/material/Dialog';
import { useMutation } from "@apollo/client";
import IconButton from '@mui/material/IconButton';
import CancelIcon from "@mui/icons-material/Cancel";
import UPDATE_CONVERSATION_SPAM, {
    UpdateConversationSpamInput,
    UpdateConversationSpamPayload,
} from "graphql/mutations/UpdateConversationSpamMutation";
import { useSnackbar } from 'notistack';
import AppBar from "theme/AppBar";
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useTheme from "@mui/material/styles/useTheme";
import Checkbox from "theme/Checkbox";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

export interface ConfirmMarkAsSpamDialogProps {
    open: boolean;
    id: string;
    onClose: () => void;
}

export default function ConfirmMarkAsSpamDialog(props: ConfirmMarkAsSpamDialogProps) {

    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState<boolean>(false);
    const [blockSender, setBlockSender] = useState<boolean>(false);

    const [alertError, setAlertError] = useState<string | undefined>(undefined);

    const [updateConversationSpam, { }] = useMutation<
        UpdateConversationSpamPayload,
        UpdateConversationSpamInput
    >(UPDATE_CONVERSATION_SPAM, { refetchQueries: ["Conversation", "Conversations", "SideBarQuery", "Customers"] });

    const { onClose, open, id } = props;

    const handleClose = () => {
        if (loading) {
            return;
        }
        onClose();
        setAlertError(undefined);
    };

    const handleConfirm = async () => {
        try {
            setLoading(true);

            const { data, errors } = await updateConversationSpam({
                variables: {
                    input: {
                        id: id,
                        spam: true,
                        blockSender: blockSender
                    }
                }
            });

            if (!!(data?.updateConversationSpam?.conversation)) {
                enqueueSnackbar(`Updated`);
                handleClose();
            }

            if (!!errors && errors.length > 0) {
                setAlertError(errors[0].message);
            }
        }
        catch (error) {
            setAlertError((error as any).message as string);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        setAlertError(undefined);
    }, [id])

    return (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xs">
            <AppBar position="relative" elevation={0} color="transparent">
                <Toolbar sx={{ color: theme.palette.text.secondary }}>
                    <Typography sx={{ ml: 0, flex: 1 }} variant="h5">
                        Mark as spam
                    </Typography>
                    <div style={{ flexGrow: 0 }} />
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CancelIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent dividers>
                <Stack spacing={1}>
                    {!!alertError && <Alert severity="error">{alertError}</Alert>}
                    <Typography variant="body2">Would you also like to block this sender from future messages?</Typography>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox value={blockSender} onChange={() => setBlockSender(!blockSender)} />} label={<Typography variant="body2">Block sender</Typography>} />
                    </FormGroup>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                <LoadingButton size="small" loading={loading} variant="contained" color="success" onClick={() => handleConfirm()}>Confirm</LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
import * as React from "react";
import clsx from "clsx";
import { GridCellParams } from "@mui/x-data-grid-pro";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import * as styles from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import isAfter from "date-fns/isAfter";
import differenceInHours from "date-fns/differenceInHours";
import differenceInMinutes from "date-fns/differenceInMinutes";
function createTheme(): styles.Theme {
  if ((styles as any)?.createTheme) {
    return (styles as any)?.createTheme();
  }
  return (styles as any)?.createMuiTheme();
}

const defaultTheme = createTheme()

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        maxWidth: 120,
        borderRadius: "3px",
        marginTop: "3px",
        position: "relative",
        overflow: "hidden",
        height: 10,
        backgroundColor: theme.palette.divider,
      },
      value: {
        position: "absolute",
        lineHeight: "24px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      },
      bar: {
        width: 120,
        borderRadius: "3px",
        height: "100%",
        "&.low": {
          backgroundColor: theme.palette.error.main,
        },
        "&.medium": {
          backgroundColor: theme.palette.warning.main,
        },
        "&.high": {
          backgroundColor: theme.palette.success.main,
        },
      },
    }),
  { defaultTheme }
);

interface ProgressBarProps {
  value?: Date;
}

export const ProgressBar = React.memo(function ProgressBar(props: ProgressBarProps) {
  const { value } = props;
  const classes = useStyles();

  if (!value) {
    return <Typography fontWeight={600} variant="caption" color="text.secondary">n/a</Typography>;
  }

  const after = isAfter(Date.now(), value!);

  if (after) {
    return (
      <Stack direction="row" alignItems="center" justifyContent="center">
        <span style={{ width: 5, height: 5, borderRadius: 5, backgroundColor: "red", marginRight: 5, }} />
        <Typography fontWeight={700} variant="caption" color="error">Overdue</Typography>
      </Stack>
    );
  }

  const hours = differenceInHours(value!, Date.now());
  const minutes = differenceInMinutes(value!, Date.now());

  const valueInPercent = (hours / 24) * 100;

  return (
    <Stack spacing={0.2} sx={{ alignItems: "center" }}>
      <div className={classes.root}>
        <div
          className={clsx(classes.bar, {
            low: valueInPercent < 30,
            medium: valueInPercent >= 30 && valueInPercent <= 70,
            high: valueInPercent > 70,
          })}
          style={{ maxWidth: `${valueInPercent}%` }}
        />
      </div>
      {hours > 0 && <Typography lineHeight={"19px"} fontWeight={700} variant="caption">{hours} hours left</Typography>}
      {hours == 0 && <Typography lineHeight={"19px"} fontWeight={700} variant="caption">{minutes} minutes left</Typography>}
    </Stack>
  );
});

export function renderTimeLeft(params: GridCellParams) {
  return <ProgressBar value={params.value as Date | undefined} />;
}
import { gql } from "@apollo/client";

export interface PingUserActivityInput {
    input: {
        availableForChat: boolean;
    };
}

export interface PingUserActivityPayload {
    pingUserActivity: {
        ok: boolean;
    };
}

const MERGE_CONVERSATIONS = gql`
  mutation PingUserActivity($input: PingUserActivityMutationInput!) {
    pingUserActivity(input: $input) {
      ok
    }
  }
`;

export default MERGE_CONVERSATIONS;

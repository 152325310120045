import { gql } from "@apollo/client";

export interface BulkAssignToConversationInput {
  input: {
    ids: string[];
    assigneeIds: string[];
  };
}

export interface BulkAssignToConversationPayload {
  bulkAssignToConversation: {
    errors: string[];
    ok: boolean;
  };
}

const BULK_ASSIGN_TO_CONVERSATION = gql`
  mutation BulkAssignToConversation($input: BulkAssignToConversationMutationInput!) {
    bulkAssignToConversation(input: $input) {
      errors
      ok
    }
  }
`;

export default BULK_ASSIGN_TO_CONVERSATION;

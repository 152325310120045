import root from 'react-shadow';

const reset = `
*:where(:not(iframe, canvas, img, svg, video):not(svg *)) {
  all: unset;
  display: revert;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
}

table {
  border-collapse: collapse;
}

textarea {
  white-space: revert;
}`

const styles = `
:host {
  --zen-color-background: #ffffff;
  --zen-color-border: rgba(0, 0, 0, 0.25);
  --zen-color-foreground: #000000;
  --zen-color-muted: #f1f3f5;
  --zen-color-primary: #7963d2;
  --zen-color-secondary: #bcd263;
  --zen-color-primary-text: #fff;
  --zen-color-secondary-text: #fff;
  --zen-color-text: #252103;
  --zen-color-faded: hsla(0, 0%, 13%, 0.9);
  --zen-color-active-background: hsla(0, 0%, 85%, 1);
  --zen-color-active-border: hsla(0, 0%, 0%, 0.25);
  --zen-color-active-foreground: hsla(0, 0%, 0%, 1);
  --zen-color-active-muted: hsla(210, 17%, 80%, 1);
  --zen-color-active-primary: hsla(252, 55%, 46%, 1);
  --zen-color-active-secondary: hsla(72, 55%, 46%, 1);
  --zen-color-active-primary-text: #fff;
  --zen-color-active-secondary-text: #000;
  --zen-color-active-text: #000;
  --zen-color-active-faded: hsla(0, 0%, 13%, 0.9);
  --zen-color-hover-background: hsla(0, 0%, 93%, 1);
  --zen-color-hover-border: hsla(0, 0%, 0%, 0.25);
  --zen-color-hover-foreground: hsla(0, 0%, 0%, 1);
  --zen-color-hover-muted: hsla(210, 17%, 88%, 1);
  --zen-color-hover-primary: hsla(252, 55%, 53%, 1);
  --zen-color-hover-secondary: hsla(72, 55%, 53%, 1);
  --zen-color-hover-primary-text: #fff;
  --zen-color-hover-secondary-text: #000;
  --zen-color-hover-text: #000;
  --zen-color-hover-faded: hsla(0, 0%, 13%, 0.9);
  --zen-color-shadow-1: rgba(10, 31, 68, 0.08);
  --zen-color-shadow-2: rgba(10, 31, 68, 0.1);
  --zen-color-shadow-3: rgba(10, 31, 68, 0.12);
  --zen-color-backdrop: rgba(0, 0, 0, 0.9);
  --zen-color-outline: rgba(73, 75, 255, 1.0);
  --zen-color-selection-background: Highlight;
  --zen-color-selection-shadow: inherit;
  --zen-color-selection-text: HighlightText;
  --zen-color-selection-caret: inherit;
  --zen-color-table-default-border: hsla(0, 0%, 80%, 1);
  --zen-color-table-default-cell: hsla(0, 0%, 40%, 1);
  --zen-color-table-default-controller: #dee2e6;
  --zen-color-table-selected-border: #1c7ed6;
  --zen-color-table-selected-cell: #d0ebff;
  --zen-color-table-selected-controller: #339af0;
  --zen-color-table-preselect-border: #1c7ed6;
  --zen-color-table-preselect-cell: hsla(0, 0%, 40%, 1);
  --zen-color-table-preselect-controller: #339af0;
  --zen-color-table-predelete-border: #f03e3e;
  --zen-color-table-predelete-cell: #ffe3e3;
  --zen-color-table-predelete-controller: #ff6b6b;
  --zen-color-table-mark: #91919196;
  --zen-hue-gray-0: #f8f9fa;
  --zen-hue-gray-1: #f1f3f5;
  --zen-hue-gray-2: #e9ecef;
  --zen-hue-gray-3: #dee2e6;
  --zen-hue-gray-4: #ced4da;
  --zen-hue-gray-5: #adb5bd;
  --zen-hue-gray-6: #868e96;
  --zen-hue-gray-7: #495057;
  --zen-hue-gray-8: #343a40;
  --zen-hue-gray-9: #212529;
  --zen-hue-red-0: #fff5f5;
  --zen-hue-red-1: #ffe3e3;
  --zen-hue-red-2: #ffc9c9;
  --zen-hue-red-3: #ffa8a8;
  --zen-hue-red-4: #ff8787;
  --zen-hue-red-5: #ff6b6b;
  --zen-hue-red-6: #fa5252;
  --zen-hue-red-7: #f03e3e;
  --zen-hue-red-8: #e03131;
  --zen-hue-red-9: #c92a2a;
  --zen-hue-pink-0: #fff0f6;
  --zen-hue-pink-1: #ffdeeb;
  --zen-hue-pink-2: #fcc2d7;
  --zen-hue-pink-3: #faa2c1;
  --zen-hue-pink-4: #f783ac;
  --zen-hue-pink-5: #f06595;
  --zen-hue-pink-6: #e64980;
  --zen-hue-pink-7: #d6336c;
  --zen-hue-pink-8: #c2255c;
  --zen-hue-pink-9: #a61e4d;
  --zen-hue-grape-0: #f8f0fc;
  --zen-hue-grape-1: #f3d9fa;
  --zen-hue-grape-2: #eebefa;
  --zen-hue-grape-3: #e599f7;
  --zen-hue-grape-4: #da77f2;
  --zen-hue-grape-5: #cc5de8;
  --zen-hue-grape-6: #be4bdb;
  --zen-hue-grape-7: #ae3ec9;
  --zen-hue-grape-8: #9c36b5;
  --zen-hue-grape-9: #862e9c;
  --zen-hue-violet-0: #f3f0ff;
  --zen-hue-violet-1: #e5dbff;
  --zen-hue-violet-2: #d0bfff;
  --zen-hue-violet-3: #b197fc;
  --zen-hue-violet-4: #9775fa;
  --zen-hue-violet-5: #845ef7;
  --zen-hue-violet-6: #7950f2;
  --zen-hue-violet-7: #7048e8;
  --zen-hue-violet-8: #6741d9;
  --zen-hue-violet-9: #5f3dc4;
  --zen-hue-indigo-0: #edf2ff;
  --zen-hue-indigo-1: #dbe4ff;
  --zen-hue-indigo-2: #bac8ff;
  --zen-hue-indigo-3: #91a7ff;
  --zen-hue-indigo-4: #748ffc;
  --zen-hue-indigo-5: #5c7cfa;
  --zen-hue-indigo-6: #4c6ef5;
  --zen-hue-indigo-7: #4263eb;
  --zen-hue-indigo-8: #3b5bdb;
  --zen-hue-indigo-9: #364fc7;
  --zen-hue-blue-0: #e7f5ff;
  --zen-hue-blue-1: #d0ebff;
  --zen-hue-blue-2: #a5d8ff;
  --zen-hue-blue-3: #74c0fc;
  --zen-hue-blue-4: #4dabf7;
  --zen-hue-blue-5: #339af0;
  --zen-hue-blue-6: #228be6;
  --zen-hue-blue-7: #1c7ed6;
  --zen-hue-blue-8: #1971c2;
  --zen-hue-blue-9: #1864ab;
  --zen-hue-cyan-0: #e3fafc;
  --zen-hue-cyan-1: #c5f6fa;
  --zen-hue-cyan-2: #99e9f2;
  --zen-hue-cyan-3: #66d9e8;
  --zen-hue-cyan-4: #3bc9db;
  --zen-hue-cyan-5: #22b8cf;
  --zen-hue-cyan-6: #15aabf;
  --zen-hue-cyan-7: #1098ad;
  --zen-hue-cyan-8: #0c8599;
  --zen-hue-cyan-9: #0b7285;
  --zen-hue-teal-0: #e6fcf5;
  --zen-hue-teal-1: #c3fae8;
  --zen-hue-teal-2: #96f2d7;
  --zen-hue-teal-3: #63e6be;
  --zen-hue-teal-4: #38d9a9;
  --zen-hue-teal-5: #20c997;
  --zen-hue-teal-6: #12b886;
  --zen-hue-teal-7: #0ca678;
  --zen-hue-teal-8: #099268;
  --zen-hue-teal-9: #087f5b;
  --zen-hue-green-0: #ebfbee;
  --zen-hue-green-1: #d3f9d8;
  --zen-hue-green-2: #b2f2bb;
  --zen-hue-green-3: #8ce99a;
  --zen-hue-green-4: #69db7c;
  --zen-hue-green-5: #51cf66;
  --zen-hue-green-6: #40c057;
  --zen-hue-green-7: #37b24d;
  --zen-hue-green-8: #2f9e44;
  --zen-hue-green-9: #2b8a3e;
  --zen-hue-lime-0: #f4fce3;
  --zen-hue-lime-1: #e9fac8;
  --zen-hue-lime-2: #d8f5a2;
  --zen-hue-lime-3: #c0eb75;
  --zen-hue-lime-4: #a9e34b;
  --zen-hue-lime-5: #94d82d;
  --zen-hue-lime-6: #82c91e;
  --zen-hue-lime-7: #74b816;
  --zen-hue-lime-8: #66a80f;
  --zen-hue-lime-9: #5c940d;
  --zen-hue-yellow-0: #fff9db;
  --zen-hue-yellow-1: #fff3bf;
  --zen-hue-yellow-2: #ffec99;
  --zen-hue-yellow-3: #ffe066;
  --zen-hue-yellow-4: #ffd43b;
  --zen-hue-yellow-5: #fcc419;
  --zen-hue-yellow-6: #fab005;
  --zen-hue-yellow-7: #f59f00;
  --zen-hue-yellow-8: #f08c00;
  --zen-hue-yellow-9: #e67700;
  --zen-hue-orange-0: #fff4e6;
  --zen-hue-orange-1: #ffe8cc;
  --zen-hue-orange-2: #ffd8a8;
  --zen-hue-orange-3: #ffc078;
  --zen-hue-orange-4: #ffa94d;
  --zen-hue-orange-5: #ff922b;
  --zen-hue-orange-6: #fd7e14;
  --zen-hue-orange-7: #f76707;
  --zen-hue-orange-8: #e8590c;
  --zen-hue-orange-9: #d9480f;
  --zen-radius-border: 0.25rem;
  --zen-radius-extra: 0.5rem;
  --zen-radius-circle: 50%;
  --zen-font-family-default: Inter, sans-serif;
  --zen-font-family-heading: inherit;
  --zen-font-family-mono: Menlo, monospace;
  --zen-font-size-0: 12px;
  --zen-font-size-1: 14px;
  --zen-font-size-2: 14px;
  --zen-font-size-3: 16px;
  --zen-font-size-4: 20px;
  --zen-font-size-5: 24px;
  --zen-font-size-6: 48px;
  --zen-font-size-7: 64px;
  --zen-font-size-8: 96px;
  --zen-font-size-default: 14px;
  --zen-space-1: 4px;
  --zen-space-2: 8px;
  --zen-space-3: 16px;
  --zen-space-4: 32px;
  --zen-space-5: 64px;
  --zen-space-6: 128px;
  --zen-space-7: 256px;
  --zen-space-8: 512px;
  --zen-font-weight-bold: 600;
  --zen-font-weight-default: 400;
  --zen-font-weight-heading: 600;
  --zen-letter-spacing-tight: -1px;
  --zen-letter-spacing-default: normal;
  --zen-letter-spacing-loose: 1px;
  --zen-letter-spacing-wide: 3px;
  --zen-line-height-heading: 1.25em;
  --zen-line-height-default: 1.3em;
  --zen-box-shadow-1: 0 1px 1px rgba(10, 31, 68, 0.08);
  --zen-box-shadow-2: 0 1px 1px rgba(10, 31, 68, 0.1);
  --zen-box-shadow-3: 0 1px 1px rgba(10, 31, 68, 0.12);

  font-family: var(--zen-font-family-default);
  font-size: var(--zen-font-size-default);
  line-height: var(--zen-line-height-default);
  font-weight: var(--zen-font-weight-default);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--zen-font-family-heading);
  line-height: var(--zen-line-height-heading);
  font-weight: var(--zen-font-weight-heading);
}

h1 {
  font-size: var(--zen-font-size-5);
}

h2 {
  font-size: var(--zen-font-size-4);
}

h3 {
  font-size: var(--zen-font-size-3);
}

h4 {
  font-size: var(--zen-font-size-2);
}

h5 {
  font-size: var(--zen-font-size-1);
}

h6 {
  font-size: var(--zen-font-size-0);
}

p {
  font-size: var(--zen-font-size-default)
}

blockquote {
  border-left: 3px solid var(--zen-hue-gray-3);
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
}

blockquote p {
  color: rgb(87, 96, 106);
}

ul ul + ul {
  -webkit-margin-before: 1em;
  margin-block-start: 1em;
}

ol {
  margin-left: 32px;
}

ul {
  margin-left: 32px;
}

p:empty {
  height: var(--zen-line-height-default);
}

s {
  text-decoration: line-through;
}

u {
  text-decoration: underline;
}

em {
  font-style: italic;
}

strong {
  font-weight: 700;
}

blockquote {
  margin-top: 16px;
  margin-bottom: 16px;
}

ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

`;

export default function MessageShadowRoot(props: { messageHtml: string }) {
  const { messageHtml } = props;

  return (
    <root.div>
      <style>{reset}</style>
      <style>{styles}</style>
      <div style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: messageHtml }} />
    </root.div>
  );
}
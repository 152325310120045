import * as React from "react";
import { GridCellParams } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import { formatDateStringToDDMMYYYY } from "helpers/DateFormatters";

interface ProgressBarProps {
    value: string | undefined;
}

const DateCell = React.memo(function ProgressBar(props: ProgressBarProps) {
    const { value } = props;

    if (!value) {
        return null;
    }

    return (
        <Typography variant="body2">
            {formatDateStringToDDMMYYYY(value)}
        </Typography>
    );
});

export function renderDate(params: GridCellParams) {
    return <DateCell value={params.value as string | undefined} />;
}

import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import { lazy, Suspense } from "react";
import useTheme from "@mui/material/styles/useTheme";
const AcceptInviteCard = lazy(() => import("components/accept_invite/AcceptInviteCard"));

function AcceptInvite() {
    const theme = useTheme();
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: theme.palette.prominent.main
        }}>
            <Backdrop open={true}>
                <Suspense fallback={<div></div>}>
                    <AcceptInviteCard />
                </Suspense>
            </Backdrop>
        </Box>
    );
}

export default AcceptInvite;

import { useState, useMemo } from "react";
import Typography from "@mui/material/Typography";
import {
    MentionAtomNodeAttributes
} from 'remirror/extensions';
import {
    MentionAtomState,
    MentionAtomPopupComponent
} from '@remirror/react';

function ZeroItemsComponent() {
    return <Typography variant="body2" sx={{ p: 1, width: "250px", color: "rgb(36, 24, 91)", fontWeight: 500 }}>Couldn&apos;t find any matches</Typography>
}

interface MentionComponentProps<UserData extends MentionAtomNodeAttributes = MentionAtomNodeAttributes> {
    users?: UserData[];
    tags?: string[];
}

function MentionComponent({ users, tags }: MentionComponentProps) {
    const [mentionState, setMentionState] = useState<MentionAtomState | null>();

    const tagItems = useMemo(
        () => (tags ?? []).map((tag) => ({ id: tag, label: `${tag}` })),
        [tags],
    );

    const mentionedUsers = useMemo(
        () => (users ?? []).map((user) => ({ id: user.id, label: `@${user.label}` })),
        [users],
    );

    const items = useMemo(() => {
        if (!mentionState) {
            return [];
        }

        let allItems: MentionAtomNodeAttributes[] = [];

        if (mentionState.name === 'at') {
            allItems = mentionedUsers;
        } else if (mentionState.name === "tag") {
            allItems = tagItems;
        }

        const query = mentionState.query.full.toLowerCase() ?? '';

        return allItems.filter((item) => item.label.toLowerCase().includes(query)).sort();

    }, [mentionState, mentionedUsers, tagItems]);

    return <MentionAtomPopupComponent
        onChange={setMentionState}
        items={items}
        ZeroItemsComponent={ZeroItemsComponent} />;
}

export default MentionComponent;
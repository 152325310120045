import * as React from "react";
import { GridCellParams } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";

interface MessageProps {
  value: string;
}

const MessageCell = React.memo(function CustomerFunction(props: MessageProps) {
  const { value } = props;

  return <Typography variant="subtitle2">{value}</Typography>;
});

export function renderMessage(params: GridCellParams) {
  return <MessageCell value={params.value as string} />;
}

import { styled } from "@mui/material/styles";
import AvatarGroup, { AvatarGroupProps } from "@mui/material/AvatarGroup";

const ZenAvatarGroup = styled(AvatarGroup)<AvatarGroupProps>(({ }) => ({
    "&& > *": {
        borderRadius: "6px",
    }
}));

export default ZenAvatarGroup;

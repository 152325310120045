import { useState } from "react";
import { ConversationPayload } from "graphql/queries/ConversationQuery";
import Stack from "@mui/material/Stack";
import Card, { InnerCard } from "theme/Card";
import Typography from "@mui/material/Typography";
import Avatar from "theme/Avatar";
import Button from "theme/Button";
import Accordion from "theme/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { formatDateStringToDDMMYYYY } from "helpers/DateFormatters";
import Chip from "theme/Chip";
import useTheme from "@mui/material/styles/useTheme";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import StepperConnector from "theme/StepperConnector";
import { eventToDisplayStatus } from "types/ShopifyFulfillmentEvent";
import ChangeAssociatedOrderDialog from "./ChangeAssociatedOrderDialog";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import ShopifyOrder from "types/ShopifyOrder";
import Divider from "theme/Divider";
import Link from '@mui/material/Link';

function ConversationOrdersCard(props: ConversationPayload) {
    const theme = useTheme();

    const [changeAssociatedOrderDialogOpen, setChangeAssociatedOrderDialogOpen] = useState<boolean>(false);

    const { conversation } = props;

    const hasMentionedOrder = conversation.mentionedOrders.nodes.length > 0;
    const hasLatestOrder = !!conversation.customer.latestOrder;
    const hasAssociatedOrder = (hasMentionedOrder || hasLatestOrder);

    function renderOrder(shopOrder: ShopifyOrder) {
        let riskLevelColor;

        switch (shopOrder.riskLevel) {
            case "high":
                riskLevelColor = theme.palette.error.main;
                break;
            case "medium":
                riskLevelColor = theme.palette.warning.main;
                break;
            case "low":
                riskLevelColor = undefined;
                break;
        }

        return (
            <Stack key={shopOrder.id} direction="column" spacing={1}>
                <Typography variant="caption" color="text.secondary">{formatDateStringToDDMMYYYY(shopOrder.createdAt)}</Typography>
                {!!shopOrder.closedAt && <Typography variant="caption" color="text.secondary">Closed at: {formatDateStringToDDMMYYYY(shopOrder.closedAt)}</Typography>}
                {!!shopOrder.cancelledAt && <Typography variant="caption" color="text.secondary">Cancelled at: {formatDateStringToDDMMYYYY(shopOrder.cancelledAt)}</Typography>}
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <Typography variant="caption" color="text.secondary">{shopOrder.name}</Typography>
                    <Typography variant="caption" fontWeight={700} color="text.secondary">{shopOrder.currentTotalPriceSet.shopAmount}</Typography>
                </Stack>
                <Stack
                    direction="row"
                    flexWrap="wrap"
                    sx={{
                        justifyContent: "flex-start",
                        alignContent: "space-evenly",
                        height: "100%",
                    }}
                >
                    <Chip
                        size="small"
                        label={`${shopOrder.financialStatus.replace(/_/g, " ")}`}
                        sx={{
                            bgcolor: `${shopOrder.fullyPaid ? undefined : theme.palette.warning.main}`,
                            color: shopOrder.fullyPaid ? undefined : theme.palette.getContrastText(theme.palette.warning.main),
                            mr: theme.spacing(0.5),
                        }} />
                    <Chip
                        size="small"
                        label={`${shopOrder.fulfillmentStatus.replace(/_/g, " ")}`}
                        sx={{
                            bgcolor: `${shopOrder.closed ? undefined : theme.palette.warning.main}`,
                            color: shopOrder.closed ? undefined : theme.palette.getContrastText(theme.palette.warning.main),
                            mr: theme.spacing(0.5),
                        }} />
                    <Chip
                        size="small"
                        label={`${shopOrder.riskLevel} risk`}
                        sx={{
                            bgcolor: riskLevelColor,
                            color: !!riskLevelColor ? theme.palette.getContrastText(riskLevelColor) : undefined,
                            mr: theme.spacing(0.5),
                        }} />
                </Stack>
                <Stack spacing={0}>
                    <Card elevation={0}>
                        <Accordion elevation={0} disableGutters defaultExpanded={shopOrder.lineItems.nodes.length <= 5}>
                            <AccordionSummary sx={{ p: 0 }} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="caption" color="text.secondary">
                                    <Stack direction="row" alignItems="center">
                                        <ShoppingCartIcon color="inherit" />
                                        <Typography fontWeight={700} variant="caption" color="text.secondary" sx={{ ml: 1 }}>
                                            Items
                                        </Typography>
                                    </Stack>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ pt: 0, pb: 0 }}>
                                <Stack direction="column" spacing={1}>
                                    {shopOrder.lineItems.nodes.map((lineItem) =>
                                        <Stack key={lineItem.id} direction="row" spacing={2} alignItems="center">
                                            <Avatar
                                                variant="rounded"
                                                src={lineItem.imageSrc}
                                            ></Avatar>
                                            <Stack direction="column" spacing={0.5}>
                                                <Typography variant="caption" color="text.secondary">{lineItem.name}</Typography>
                                                <Typography variant="caption" color="text.secondary">{lineItem.currentQuantity} x {lineItem.originalUnitPriceSet.shopAmount} = {lineItem.originalTotalSet.shopAmount}</Typography>
                                            </Stack>
                                        </Stack>
                                    )}
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </Card>
                    {shopOrder.fulfillments.nodes.length > 0 && <Card elevation={0}>
                        <Accordion elevation={0} disableGutters>
                            <AccordionSummary sx={{ p: 0 }} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="caption" color="text.secondary">
                                    <Stack direction="row" alignItems="center">
                                        <Inventory2Icon color="inherit" />
                                        <Typography fontWeight={700} variant="caption" color="text.secondary" sx={{ ml: 1 }}>
                                            Fulfillments
                                        </Typography>
                                    </Stack>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ pt: 0, pb: 0 }}>
                                <Stack direction="column" spacing={0.5}>
                                    {shopOrder.fulfillments.nodes.map((fulfillment, findex) => <>
                                        <Stack direction="column" spacing={0.5} key={fulfillment.id}>
                                            <Typography variant="caption" color="text.secondary">
                                                {fulfillment.name} - {fulfillment.displayStatus}
                                            </Typography>
                                            {!!fulfillment.trackingInfoUrl && <Link href={fulfillment.trackingInfoUrl} target="_blank" rel="noopener" variant="caption" underline="hover">{fulfillment.trackingInfoUrl}</Link>}
                                            {!!fulfillment.trackingInfoNumber && <Typography variant="caption" color="text.secondary" fontWeight={600}>
                                                {fulfillment.trackingInfoNumber}
                                            </Typography>}
                                            {!!fulfillment.trackingInfoCompany && <Typography variant="caption" color="text.secondary">
                                                {fulfillment.trackingInfoCompany}
                                            </Typography>}
                                        </Stack>
                                        {findex < shopOrder.fulfillments.nodes.length - 1 && <Divider />}
                                    </>)}
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </Card>}
                    <Card elevation={0}>
                        <Accordion elevation={0} disableGutters>
                            <AccordionSummary sx={{ p: 0 }} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="caption" color="text.secondary">
                                    <Stack direction="row" alignItems="center">
                                        <LocalShippingIcon color="inherit" />
                                        <Typography fontWeight={700} variant="caption" color="text.secondary" sx={{ ml: 1 }}>
                                            Shipping
                                        </Typography>
                                    </Stack>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ pt: 0, pb: 0 }}>
                                <Stack direction="column" spacing={0.5}>
                                    {shopOrder.shippingLines.nodes.map((shippingLine) => <Typography key={shippingLine.id} variant="caption" color="text.secondary">
                                        {shippingLine.title} - {shippingLine.discountedPriceSet.shopAmount}
                                    </Typography>)}
                                    <Typography fontWeight={700} variant="caption" color="text.secondary">
                                        Address:
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary">
                                        {shopOrder.shippingAddress.formatted}
                                    </Typography>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </Card>
                    <Card elevation={0}>
                        <Accordion elevation={0} disableGutters>
                            <AccordionSummary sx={{ p: 0 }} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="caption" color="text.secondary">
                                    <Stack direction="row" alignItems="center">
                                        <ReceiptIcon color="inherit" />
                                        <Typography fontWeight={700} variant="caption" color="text.secondary" sx={{ ml: 1 }}>
                                            Billing
                                        </Typography>
                                    </Stack>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ pt: 0, pb: 0 }}>
                                <Stack direction="column" spacing={0.5}>
                                    <Typography fontWeight={700} variant="caption" color="text.secondary">
                                        Address:
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary">
                                        {shopOrder.billingAddress.formatted}
                                    </Typography>
                                    <Typography fontWeight={700} variant="caption" color="text.secondary">
                                        Phone:
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary">
                                        {!!shopOrder.phone ? shopOrder.phone : "Not on file"}
                                    </Typography>
                                    <Typography fontWeight={700} variant="caption" color="text.secondary">
                                        Email:
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary">
                                        {!!shopOrder.email ? shopOrder.email : "Not on file"}
                                    </Typography>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </Card>
                    <Card elevation={0}>
                        <Accordion elevation={0} disableGutters>
                            <AccordionSummary sx={{ p: 0 }} expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="caption" color="text.secondary">
                                    <Stack direction="row" alignItems="center">
                                        <LocalOfferIcon color="inherit" />
                                        <Typography fontWeight={700} variant="caption" color="text.secondary" sx={{ ml: 1 }}>
                                            Discounts
                                        </Typography>
                                    </Stack>
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ pt: 0, pb: 0 }}>
                                <Stack direction="column" spacing={0.5}>
                                    {!!shopOrder.discountCode && <Typography variant="caption" color="text.secondary">Discount code applied: {shopOrder.discountCode}</Typography>}
                                    {!shopOrder.discountCode && <Typography variant="caption" color="text.secondary">No discount code applied</Typography>}
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </Card>
                </Stack>
                <Stepper orientation="vertical" connector={<StepperConnector />}>
                    {shopOrder.fulfillmentEvents.nodes.map((event, index) =>
                        <Step key={event.id} expanded active={shopOrder.fulfillmentEvents.nodes.length - 1 == index}>
                            <StepLabel color="text.secondary">
                                <Typography variant="caption">{formatDateStringToDDMMYYYY(event.happenedAt)}</Typography>
                            </StepLabel>
                            <StepContent>
                                <InnerCard variant="outlined">
                                    <Stack p={1}>
                                        <Typography color="text.secondary" variant="caption">{eventToDisplayStatus(event)}</Typography>
                                    </Stack>
                                </InnerCard>
                            </StepContent>
                        </Step>
                    )}
                </Stepper>
            </Stack>);
    }

    function renderMentionedOrders() {
        return (conversation.mentionedOrders.nodes.map((shopOrder, _) => {
            return renderOrder(shopOrder);
        }));
    }

    return (
        <Stack direction="column" spacing={2} pl={1} pt={1}>
            <Stack pl={1} direction="column" spacing={1}>
                <Typography variant="caption" color="text.secondary" fontWeight={700}>
                    {hasMentionedOrder && "Associated order"}
                    {hasLatestOrder && !hasMentionedOrder && "Latest order"}
                    {!hasAssociatedOrder && "Order info"}
                </Typography>
                {!hasAssociatedOrder && <Typography variant="caption" color="text.secondary">
                    There&apos;s no associated order
                </Typography>}
                {hasMentionedOrder && renderMentionedOrders()}
                {hasLatestOrder && !hasMentionedOrder && renderOrder(conversation.customer.latestOrder!)}
                <Stack pt={hasAssociatedOrder ? 1 : 0} width="100%" direction="column" alignItems="flex-start" sx={{ color: theme.palette.text.secondary }}>
                    <div>
                        <Button startIcon={hasAssociatedOrder ? <ShoppingCartRoundedIcon /> : <ShoppingCartOutlinedIcon />} size="small" color="inherit" variant="outlined" onClick={() => setChangeAssociatedOrderDialogOpen(true)}>{hasAssociatedOrder ? "Change associated order" : "Associate an order"}</Button>
                    </div>
                </Stack>
            </Stack>
            <ChangeAssociatedOrderDialog open={changeAssociatedOrderDialogOpen} onClose={() => setChangeAssociatedOrderDialogOpen(false)} conversation={conversation} />
        </Stack >
    );
}

export default ConversationOrdersCard;
import { gql } from "@apollo/client";
import Label from "types/Label";
import LabelFragment from "../fragments/LabelFragment";

export interface UpdateLabelInput {
    input: {
        id: string;
        title: string;
        colorHex: string;
    };
}

export interface UpdateLabelPayload {
    updateLabel: {
        errors: string[];
        label?: Label;
    };
}

const UPDATE_LABEL = gql`
  mutation UpdateLabel($input: UpdateLabelMutationInput!) {
    updateLabel(input: $input) {
      errors
      label {
        ...LabelFields
      }
    }
  }
  ${LabelFragment}
`;

export default UPDATE_LABEL;
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import DialogContent from "theme/DialogContent";
import Button, { LoadingButton } from "theme/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "theme/TextField";
import FormControl from "@mui/material/FormControl";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "@apollo/client";
import VERIFY_LOGIN, {
  VerifyLoginInput,
  VerifyLoginPayload,
} from "graphql/mutations/VerifyLoginMutation";
import { useHistory, Redirect } from "react-router-dom";
import Alert from "@mui/material/Alert";
import useQueryParams from "hooks/useQueryParams";
import useApp from "hooks/useApp";
import DialogTitle from "theme/DialogTitle";

interface Form {
  code?: string;
}

function OTPCard() {

  const [loading, setLoading] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  let history = useHistory();

  let app = useApp();

  const query = useQueryParams();

  const [verifyLogin, { error: loginError }] = useMutation<
    VerifyLoginPayload,
    VerifyLoginInput
  >(VERIFY_LOGIN, {});

  const [error, setError] = useState<string | undefined>();

  const onSubmit = async (data: Form) => {

    let payload: VerifyLoginPayload | null | undefined;

    try {

      setLoading(true);

      const { data: r } = await verifyLogin({
        variables: {
          input: {
            email: query.get("email")!,
            code: data.code!,
          },
        },
      });

      payload = r;

    }
    catch (e: unknown) {
      if (e instanceof Error) {
        setError(e.message);
      }
    }
    finally {
      setLoading(false);
    }
    if (!!payload) {
      let response = payload.verifyLogin;
      if (!!response.token && !!response.cubeToken) {
        app.updateToken(response.token);
        app.updateCubeToken(response.cubeToken);
        history.push("/conversations");
      } else if (response.errors.length > 0) {
        setError(response.errors[0]);
      }
    }
  };

  useEffect(() => {
    if (!!loginError) {
      setError(loginError.message);
    }
  }, [loginError]);

  if (!query.get("email")) {
    return <Redirect to="/login" />;
  }

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Card elevation={5}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Enter your 2FA code 📱</DialogTitle>
          <DialogContent dividers>
            <Stack spacing={2}>
              <Typography gutterBottom variant="body2" component="div">
                For your security we&apos;ve sent you a 6 digit code to your mobile number.
              </Typography>
              {!!error && <Alert severity="error">{error}</Alert>}
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="code"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="code"
                      size="small"
                      helperText={errors.code ? errors.code.message : null}
                      variant="outlined"
                      placeholder="Code"
                      error={!!errors.code}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Please enter your code",
                    pattern: {
                      value: /^\S{6,}/i,
                      message: "Minimum six characters",
                    },
                  }}
                />
              </FormControl>
              <Button size="small" onClick={() => { window.open(process.env.REACT_APP_DISCORD_URL, "_blank"); }}>Contact support</Button>
            </Stack>
          </DialogContent>
          <CardActions>
            <Stack sx={{ p: 1, width: "100%" }} direction="row" spacing={1}>
              <div style={{ flexGrow: 1 }} />
              <Button size="small" onClick={() => history.push("/login")}>
                Cancel
              </Button>
              <LoadingButton loading={loading} size="small" variant="contained" color="success" type="submit" onClick={handleSubmit(onSubmit)}>
                Continue
              </LoadingButton>
            </Stack>
          </CardActions>
        </form>
      </Card>
    </Box>
  );
}

export default OTPCard;

import { useState, useEffect } from "react";
import { ConversationPayload } from "graphql/queries/ConversationQuery";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ConversationOrdersCard from "./ConversationOrdersCard";
import { useHistory } from "react-router-dom";
import CardActionArea from "@mui/material/CardActionArea";
import Divider from "theme/Divider";
import Fab from "@mui/material/Fab";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useTheme from "@mui/material/styles/useTheme";
import AddFieldDialog, { FieldType } from "components/customer/AddFieldDialog";
import Box from "@mui/material/Box";
import { LoadingButton } from "theme/Button";
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import SUBSCRIBE_TO_CONVERSATION_NOTIFICATIONS, {
  SubscribeToConversationNotificationsInput,
  SubscribeToConversationNotificationsPayload
} from "graphql/mutations/SubscribeToConversationNotificationsMutation";
import { useMutation } from "@apollo/client";

function ConversationRightSidePanel(props: ConversationPayload) {
  const { conversation } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [fieldDialogOpen, setFieldDialogOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [alertError, setAlertError] = useState<string | undefined>(undefined);
  const [watching, setWatching] = useState<boolean>(conversation.watching);

  const [subscribeToNotifications, { }] = useMutation<SubscribeToConversationNotificationsPayload, SubscribeToConversationNotificationsInput>(SUBSCRIBE_TO_CONVERSATION_NOTIFICATIONS, { refetchQueries: ["Conversation"] });

  const handleFieldTypeDialogClose = () => {
    setFieldDialogOpen(false);
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubscribe = async () => {
    try {
      setLoading(true);
      await subscribeToNotifications({ variables: { input: { conversationId: conversation.id } } });
    } catch (e: unknown) {
      if (e instanceof Error) {
        setAlertError(e.message);
      }
    } finally {
      setWatching(!watching);
      setLoading(false);
    }
  }

  useEffect(() => {
    setWatching(conversation.watching);
  }, [conversation]);

  const history = useHistory();
  const theme = useTheme();

  return (
    <Box sx={{ position: "relative" }}>
      {false && <Fab color="secondary" sx={{ position: "absolute", right: 0, top: -50, zIndex: 1, display: { xs: "none", sm: "none", md: "none", lg: "flex" } }} onClick={handleClick}><LocalPhoneIcon /></Fab>}
      <Stack direction="column" spacing={1} >
        <AddFieldDialog open={fieldDialogOpen} onClose={() => handleFieldTypeDialogClose()} customer={conversation.customer} type={FieldType.phone_number} />
        <Menu
          variant="menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              width: 200,
              outline: "none",
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: theme.spacing(3),
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {conversation.customer.fields.nodes.filter((field) => field.type == "phone_number").map((field) => <MenuItem key={field.id} onClick={() => {
            setAnchorEl(null);
            window.open(`tel:${field.value}`);
          }}>
            <Typography variant="body2">Call {field.value}</Typography>
          </MenuItem>)}
          <MenuItem onClick={() => {
            setAnchorEl(null);
            setFieldDialogOpen(true);
          }}>
            <Typography variant="body2">Add phone number</Typography>
          </MenuItem>
        </Menu>
        <Stack direction="column" sx={{ pl: 2, pb: 1, pr: 2 }} spacing={1}>
          <Typography variant="caption" color="text.secondary" fontWeight={700}>
            Notifications
          </Typography>
          {watching && <Typography variant="caption" color="text.secondary">You are part of this conversation</Typography>}
          <div>
            <LoadingButton loading={loading} onClick={handleSubscribe} startIcon={watching ? <NotificationsActiveIcon /> : <NotificationsNoneOutlinedIcon />} size="small" color="inherit" variant="outlined">{watching ? "Unsubscribe" : "Subscribe"}</LoadingButton>
          </div>
        </Stack>
        <Divider />
        <CardActionArea onClick={() => history.push(`/customer/${conversation.customer.id}`)} sx={{ p: 1, zIndex: 0 }}>
          <Stack pl={1} direction="column" spacing={1} sx={{ position: "relative" }}>
            <Typography variant="caption" color="text.secondary" fontWeight={700}>
              Customer Info
            </Typography>
            {!!conversation.customer.name && <Typography color="text.secondary" variant="h5">{conversation.customer.name}</Typography>}
            {conversation.customer.fields.nodes
              .filter((field) => field.type === "email")
              .map((field) => (
                <Typography color="text.secondary" key={field.id} variant="caption">
                  {field.value}
                </Typography>
              ))}
            {conversation.customer.fields.nodes
              .filter((field) => field.type === "phone_number")
              .map((field) => (
                <Typography color="text.secondary" key={field.id} variant="caption">
                  {field.value}
                </Typography>
              ))}
            {conversation.customer.fields.nodes
              .filter((field) => field.type.includes("c_"))
              .sort((field1, field2) => (field1.type > field2.type ? -1 : 1))
              .map((field) => (
                <Typography color="text.secondary" key={field.id} variant="caption">
                  {field.value}
                </Typography>
              ))}
          </Stack>
        </CardActionArea>
        <Divider />
        <CardActionArea onClick={() => history.push(`/customer/${conversation.customer.id}`)} sx={{ p: 1 }}>
          <Stack pl={1} direction="column" spacing={1}>
            <Typography color="text.secondary" variant="caption" fontWeight={700}>
              History
            </Typography>
            <Typography color="text.secondary" variant="caption">
              Total Spend <strong>{conversation.customer.totalSpend}</strong>
            </Typography>
            <Typography color="text.secondary" variant="caption">
              <strong>{conversation.customer.numberOfOrders}</strong> Order{conversation.customer.numberOfOrders === 1 ? '' : 's'}
            </Typography>
            <Typography color="text.secondary" variant="caption">
              <strong>{conversation.customer.numberOfActiveConversations}</strong> Active conversation
              {conversation.customer.numberOfActiveConversations > 1 ? 's ' : ' '}
              (<strong>{conversation.customer.numberOfResolvedConversations}</strong> resolved)
            </Typography>
          </Stack>
        </CardActionArea>
        <Divider />
        <ConversationOrdersCard conversation={conversation} />
      </Stack >
    </Box>
  );
}

export default ConversationRightSidePanel;

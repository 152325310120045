import { gql } from "@apollo/client";
import OrgFile from "types/OrgFile";
import OrgFileFragment from "../../fragments/OrgFileFragment";

export interface OrgFileSortInput {
    givenFilename?: "ASC" | "DESC",
    shortcut?: "ASC" | "DESC",
    privateFile?: "ASC" | "DESC",
}

export interface OrgFileFilterInput {
    searchQuery?: string;
    privateFile: boolean;
}


export interface OrgFilesInput {
    privateFilters: OrgFileFilterInput;
    teamFilters: OrgFileFilterInput;
}

export interface OrgFilesPayload {
    private: {
        nodes: OrgFile[];
    };
    team: {
        nodes: OrgFile[];
    };
}

const PRIVATE_AND_TEAM_ORG_FILES = gql`
  query OrgFiles($privateFilters: OrgFileFilterInput!, $teamFilters: OrgFileFilterInput!) {
    private: orgFiles(filters: $privateFilters) {
      nodes {
          ...OrgFileFields
      }
    }
    team: orgFiles(filters: $teamFilters) {
        nodes {
            ...OrgFileFields
        }
      }
  }
  ${OrgFileFragment}
`;

export default PRIVATE_AND_TEAM_ORG_FILES;
import Box from "@mui/material/Box";
import ConversationTimeline from "components/conversation/ConversationTimeline";

function Conversation() {
  return (
    <Box>
      <ConversationTimeline />
    </Box>
  );
}

export default Conversation;

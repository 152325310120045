import { useEffect, useState } from "react";
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import Stack from '@mui/material/Stack';
import Button, { LoadingButton } from 'theme/Button';
import Dialog from '@mui/material/Dialog';
import { useMutation, useQuery } from "@apollo/client";
import IconButton from '@mui/material/IconButton';
import CancelIcon from "@mui/icons-material/Cancel";
import { useSnackbar } from 'notistack';
import { useForm, Controller } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TEMPLATES, { TemplatesInput, TemplatesPayload } from "graphql/queries/TemplatesQuery";
import TextField from 'theme/TextField';
import Alert from '@mui/material/Alert';
import Select from "theme/Select";
import useTheme from "@mui/material/styles/useTheme";
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from "theme/Checkbox";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import WORKFLOW, { WorkflowInput, WorkflowPayload } from "graphql/queries/WorkflowQuery";
import UPDATE_WORKFLOW, { UpdateWorkflowInput, UpdateWorkflowPayload } from "graphql/mutations/UpdateWorkflowMutation";

export interface EditWorkflowDialogProps {
    open: boolean;
    selectedWorkflowId?: string;
    onClose: () => void;
}

export default function EditWorkflowDialogWrapper(props: EditWorkflowDialogProps) {

    const { selectedWorkflowId, open, onClose } = props;

    const [workflowId, setWorkflowId] = useState<string>(selectedWorkflowId || "");
    const [alertError, setAlertError] = useState<string | undefined>(undefined);
    const [busy, setBusy] = useState<boolean>(false);
    const [updateWorkflow, { }] = useMutation<UpdateWorkflowPayload, UpdateWorkflowInput>(UPDATE_WORKFLOW, {});
    const { data, refetch } = useQuery<WorkflowPayload, WorkflowInput>(WORKFLOW, { variables: { id: workflowId } });
    const { data: templatesData } = useQuery<TemplatesPayload, TemplatesInput>(TEMPLATES, { variables: { filters: {} } });

    const theme = useTheme();

    useEffect(() => {
        if (!!selectedWorkflowId) {
            setWorkflowId(selectedWorkflowId);
            refetch({ id: selectedWorkflowId });
        }
    }, [selectedWorkflowId]);

    const {
        control,
        trigger,
        handleSubmit,
        reset,
        setValue: setFormValue,
        formState: { errors, isValid },
    } = useForm();

    const [sharedWorkflow, setSharedWorkflow] = useState<"shared" | "private">("shared");
    const [resolvesConversation, setResolvesConversation] = useState<boolean>(false);

    const { enqueueSnackbar } = useSnackbar();

    const handleClose = () => {
        if (busy) {
            return;
        }
        onClose();
    };

    const onSubmit = async (formData: { [x: string]: any; }) => {
        try {
            setBusy(true);

            const { data } = await updateWorkflow({
                variables: {
                    input: {
                        id: workflowId,
                        name: formData.workflow_name,
                        resolvesConversation: resolvesConversation,
                        templateId: formData.template_id,
                        privateWorkflow: sharedWorkflow === "private",
                    }
                }
            });

            if (!!data?.updateWorkflow.workflow) {
                enqueueSnackbar(`Updated Workflow`);
                onClose();
            }

        } catch (e: unknown) {
            if (e instanceof Error) {
                setAlertError(e.message);
            }
        } finally {
            setBusy(false);
        }
    };

    let options = templatesData?.templates.nodes.map((template) => <option key={template.id} value={template.id}>{template.name}</option>) ?? [];

    useEffect(() => {
        if (!open) {
            reset();
        }
    }, [open]);

    useEffect(() => {
        if (!!data?.workflow && open) {
            setFormValue("workflow_name", data.workflow.name);
            setFormValue("template_id", data.workflow.template.id);
            setResolvesConversation(data.workflow.resolvesConversation);
            setSharedWorkflow(!!data.workflow.privateWorkflow ? "private" : "shared");
        }
    }, [data, open]);

    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
            <DialogTitle sx={{ m: 0, p: 2, pl: 3, pr: 12 }}>Edit workflow
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 16,
                        top: 12,
                        color: "text.secondary",
                    }}
                >
                    <CancelIcon />
                </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent dividers sx={{ backgroundColor: theme.palette.background.default }}>
                    <Stack direction="column" spacing={2}>
                        {!!alertError && <Alert severity="error">{alertError}</Alert>}
                        <FormControl variant="outlined" sx={{ pt: 1 }}>
                            <Controller
                                name="workflow_name"
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        id="workflow_name"
                                        type="workflow_name"
                                        size="small"
                                        helperText={errors.workflow_name ? errors.workflow_name.message : null}
                                        variant="outlined"
                                        placeholder="Name of workflow"
                                        error={!!errors.workflow_name}
                                        onChange={(e) => { onChange(e); trigger("workflow_name"); }}
                                        value={value}
                                    />
                                )}
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: "Please enter a name for the workflow",
                                }}
                            />
                        </FormControl>
                        <Typography variant="body2" color="text.secondary" mb={1}>Customers will be sent a message with this template when the workflow is actioned.</Typography>
                        <FormControl fullWidth variant="outlined">
                            <Controller
                                name="template_id"
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        labelId="template-label"
                                        id="template_id"
                                        value={value}
                                        onChange={onChange}
                                        native
                                        size="small"
                                    >
                                        {options}
                                    </Select>
                                )}
                                control={control}
                            />
                            <FormHelperText>The template sent to Customer.</FormHelperText>
                        </FormControl>
                        <FormControl>
                            <Typography variant="body2" color="text.secondary" mb={1}>Resolves conversation</Typography>
                            <RadioGroup sx={{ ml: 2 }} defaultValue={resolvesConversation} onChange={(e) => setResolvesConversation(!resolvesConversation)}>
                                <FormControlLabel onClick={() => setResolvesConversation(!resolvesConversation)} value={resolvesConversation} control={<Checkbox checked={resolvesConversation} />} label={<Typography onClick={() => setResolvesConversation(!resolvesConversation)} variant="subtitle2">If the conversation is marked resolved from an otherwise unresolved state</Typography>} />
                            </RadioGroup>
                        </FormControl>
                        <FormControl>
                            <Typography variant="body2" color="text.secondary" mb={1}>Permissions</Typography>
                            <RadioGroup sx={{ ml: 2 }} defaultValue={sharedWorkflow} onChange={(e) => setSharedWorkflow(e.target.value as any)}>
                                <FormControlLabel onClick={() => setSharedWorkflow("shared")} value="shared" control={<Checkbox checked={sharedWorkflow == "shared"} />} label={<Typography onClick={() => setSharedWorkflow("shared")} variant="subtitle2">Team (your team can use this workflow)</Typography>} />
                                <FormControlLabel onClick={() => setSharedWorkflow("private")} value="private" control={<Checkbox checked={sharedWorkflow == "private"} />} label={<Typography onClick={() => setSharedWorkflow("private")} variant="subtitle2">Private (only you can use this workflow)</Typography>} />
                            </RadioGroup>
                        </FormControl>
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <LoadingButton loading={busy} onClick={() => { }} size="small" disabled={busy} variant="contained" color="error">Delete</LoadingButton>
                    <div style={{ flexGrow: 1 }} />
                    <Button disabled={busy} size="small" color="inherit" variant="text">Close</Button>
                    <LoadingButton loading={busy} size="small" type="submit" disabled={!isValid} color="success" variant="contained">Update</LoadingButton>
                </DialogActions>
            </form>
        </Dialog >
    );
}
import {
    FloatingWrapper,
    useSuggest,
    useMenuNavigation,
} from "@remirror/react";
import {
    useState,
    useEffect
} from "react";
import Typography from "@mui/material/Typography";
import Template from "types/Template";
import { cx } from '@remirror/core';

function TemplateSuggestorComponent(props: { templates: Template[], selectedTemplate: (template: Template) => void }) {

    const { templates, selectedTemplate } = props;

    const [submitted, setSubmitted] = useState<boolean>(false);

    const { change } = useSuggest({
        startOfLine: true,
        caseInsensitive: true,
        char: "/",
        name: "command"
    });

    useEffect(() => {
        if (!!change) {
            setSubmitted(false);
        }
    }, [change]);

    const filteredTemplates = !!change ? templates.filter((template) => template.shortcut.toLowerCase().includes(change.text.full.toLowerCase().replaceAll("/", ""))) : []
    const enabled = !!change && filteredTemplates.length > 0 && !submitted;

    const { getMenuProps, getItemProps, indexIsHovered, indexIsSelected, index } = useMenuNavigation<Template>({
        items: templates,
        isOpen: enabled,
        onSubmit: () => {
            selectedTemplate(filteredTemplates[index]);
            setSubmitted(true);
            return true;
        },
        onDismiss: () => {
            return true;
        }
    });

    const renderTemplateSuggestion = (template: Template, index: number) => {
        const isHighlighted = indexIsSelected(index);
        const isHovered = indexIsHovered(index);
        return (
            <div {...getItemProps({ item: template, index: index })} className={cx(
                "remirror-template-popup-item",
                isHighlighted && "remirror-template-popup-highlight",
                isHovered && "remirror-template-popup-hovered",
            )}>
                <Typography key={template.id} variant="body2">
                    {template.shortcut}
                </Typography>
            </div>
        )
    }

    return (
        <FloatingWrapper positioner='cursor' placement='auto-end' enabled={enabled} renderOutsideEditor>
            <div {...getMenuProps()} className="remirror-template-popup-wrapper">
                {filteredTemplates.map((template, idx) => renderTemplateSuggestion(template, idx))}
            </div>
        </FloatingWrapper>
    );
}

export default TemplateSuggestorComponent;
import { useEffect, useState } from "react";
import DialogTitle from 'theme/DialogTitle';
import DialogContent from 'theme/DialogContent';
import Stack from '@mui/material/Stack';
import Button from 'theme/Button';
import Dialog from '@mui/material/Dialog';
import { useQuery } from "@apollo/client";
import IconButton from '@mui/material/IconButton';
import CancelIcon from "@mui/icons-material/Cancel";
import GLOBAL_SEARCH, {
    GlobalSearchInput,
    GlobalSearchPayload
} from "graphql/queries/GlobalSearchQuery";
import TextField from 'theme/TextField';
import Alert from '@mui/material/Alert';
import useTheme from "@mui/material/styles/useTheme";
import useDebounce from "hooks/useDebounce";
import Customer from "types/Customer";
import Typography from "@mui/material/Typography";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import CircularProgress from "@mui/material/CircularProgress";
import * as EmailValidator from "email-validator";
import Card from "theme/Card";
import NewConversationMessageDialog from "./NewConversationMessageDialog";

export interface NewConversationDialogProps {
    open: boolean;
    onClose: () => void;
}

function CustomersResults(props: { input: string, customers: Customer[], selectEmail: (email: string) => void, }) {
    const { customers, selectEmail, input } = props;

    if (customers.length === 0) {
        return (
            <Stack height="100%" spacing={2} alignItems="center" justifyContent="center">
                {EmailValidator.validate(input) ? <Button size="small" onClick={() => selectEmail(input)} variant="contained" color="secondary">Email {input.toLocaleLowerCase()}</Button> : <Typography variant="caption">Please enter a valid email address</Typography>}
            </Stack>
        );
    }

    return (
        <>
            <Typography variant="caption">Potential matches:</Typography>
            <Card variant="outlined">
                <List disablePadding sx={{ width: '100%' }}>
                    {customers.map((customer, index) => {
                        return (
                            <ListItemButton divider={index < customers.length - 1} key={customer.id} onClick={() => {
                                selectEmail(customer.email!);
                            }}>
                                <ListItem disableGutters disablePadding>
                                    <Stack direction="row" spacing={1}>
                                        {!!customer.name && <Typography variant="subtitle2">{customer.name}</Typography>}
                                        {!!customer.email && <Typography variant="subtitle2">{customer.email}</Typography>}
                                        <Typography variant="subtitle2" color="text.secondary">{customer.totalSpend}</Typography>
                                    </Stack>
                                </ListItem>
                            </ListItemButton>
                        );
                    })}
                </List>
            </Card>
            {EmailValidator.validate(input) && <Button size="small" onClick={() => selectEmail(input)} variant="contained" color="secondary">Email {input.toLocaleLowerCase()}</Button>}
        </>
    );
}

export default function NewConversationDialog(props: NewConversationDialogProps) {

    const [alertError, setAlertError] = useState<string | undefined>(undefined);
    const theme = useTheme();

    const { onClose, open } = props;

    const [newConversationMessageOpen, setNewConversationMessageOpen] = useState<boolean>(false);

    const [email, setEmail] = useState<string>("");

    const [searchInput, setSearchInput] = useState<string>("");
    const debouncedSearchQuery = useDebounce<string>(searchInput, 500);

    const handleClose = () => {
        onClose();
    };

    const { data, loading } = useQuery<GlobalSearchPayload, GlobalSearchInput>(GLOBAL_SEARCH, {
        variables: { searchQuery: debouncedSearchQuery },
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (!open) {
            setSearchInput("");
        }
    }, [open]);

    return (
        <>
            <NewConversationMessageDialog open={newConversationMessageOpen} onClose={() => setNewConversationMessageOpen(false)} email={email} />

            <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="xs">
                <DialogTitle sx={{ m: 0, p: 2, pl: 3, pr: 12 }}>Start conversation
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 16,
                            top: 12,
                            color: "text.secondary",
                        }}
                    >
                        <CancelIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers sx={{ backgroundColor: theme.palette.background.default }}>
                    <Stack direction="column" spacing={2}>
                        {!!alertError && <Alert severity="error">{alertError}</Alert>}
                        <TextField
                            autoComplete='off'
                            inputProps={{
                                autocomplete: 'off',
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                            type='text'
                            size="small"
                            variant="outlined"
                            helperText={(searchInput.length > 0 && searchInput.length < 3) ? "Type at least 3 characters" : ""}
                            placeholder="Find customer"
                            onChange={(e) => { setSearchInput(e.currentTarget.value); }}
                            value={searchInput}
                        />
                        {!!loading && searchInput.length >= 3 && <Stack height={"100%"} spacing={2} alignItems="center" justifyContent="center" p={2}>
                            <Typography variant="caption">Searching</Typography>
                            <CircularProgress />
                        </Stack>}
                        {!loading && searchInput.length >= 3 && <CustomersResults input={searchInput} customers={data?.globalSearch?.customers?.nodes || []} selectEmail={(newEmail) => {
                            handleClose();
                            setEmail(newEmail);
                            setNewConversationMessageOpen(true);
                        }} />}
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    );
}
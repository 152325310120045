import { gql } from "@apollo/client";
import ShopifyOrder from "types/ShopifyOrder";
import CompleteShopifyOrderFragment from "graphql/fragments/CompleteShopifyOrderFragment";

export interface ShopifyOrderInput {
  id: string;
}

export interface ShopifyOrderPayload {
  shopifyOrder?: ShopifyOrder;
}

const SHOPIFY_ORDER = gql`
  query Order($id: ID!) {
    shopifyOrder(id: $id) {
        ...CompleteShopifyOrderFields
    }
  }
  ${CompleteShopifyOrderFragment}
`;

export default SHOPIFY_ORDER;
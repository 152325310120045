import gql from "graphql-tag";

const CustomerFragment = gql`
  fragment CustomerFields on Customer {
    id
    createdAt
    numberOfConversations
    numberOfActiveConversations
    numberOfResolvedConversations
    numberOfOrderItems
    numberOfMessages
    numberOfOrders
    mostOrderedItem
    mostExpensiveItem
    name
    firstName
    lastName
    totalSpend
    email
    avatarUrl
    notes
    detectedLanguages
  }
`;

export default CustomerFragment;

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "theme/Avatar";
import { formatDateStringToTimeAgo } from "helpers/DateFormatters";
import Note from "types/Note";
import useTheme from "@mui/material/styles/useTheme";
import { getInitials } from "types/Conversation";
import { alpha } from "@mui/material/styles";
import MessageShadowRoot from "./MessageShadowRoot";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

type NoteCardProps = {
  note: Note;
};

function NoteCard(props: NoteCardProps) {
  const theme = useTheme();
  const { note } = props;

  return (
    <Card
      elevation={0}
      variant="outlined"
      style={{
        borderColor: theme.palette.attentionBorder.main,
        backgroundColor: alpha(theme.palette.attention.main, 0.2),
      }}
    >
      <CardHeader
        sx={{ pl: 2, pr: 2, pt: 1, pb: 1, borderBottom: `1px solid ${theme.palette.attentionBorder.main}` }}
        avatar={<Avatar variant="rounded" src={note.author.avatarUrl}>{getInitials(note.author.name)}</Avatar>}
        title={<Stack direction="row" spacing={0.5}><Typography variant="body2" fontWeight={700}>{note.author.name}</Typography><Typography variant="body2">wrote a private note</Typography></Stack>}
        subheader={<Typography variant="caption">{formatDateStringToTimeAgo(note.createdAt)}</Typography>}
      />
      <CardContent sx={{ pb: `${theme.spacing(2)} !important`, pt: 2 }}>
        <MessageShadowRoot messageHtml={note.noteHtml || ""} />
      </CardContent>
    </Card>
  );
}

export default NoteCard;
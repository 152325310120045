import * as React from "react";
import { GridCellParams } from "@mui/x-data-grid-pro";
import Customer from "types/Customer";
import Typography from "@mui/material/Typography";

interface CustomerProps {
  value?: Customer;
}

const CustomerCell = React.memo(function CustomerFunction(
  props: CustomerProps
) {
  const { value } = props;

  return <Typography variant="subtitle2">{value?.name ?? value?.email}</Typography>;
});

export function renderCustomer(params: GridCellParams) {
  return <CustomerCell value={params.value as Customer | undefined} />;
}

import * as React from "react";
import { GridCellParams } from "@mui/x-data-grid-pro";
import Chip from "@mui/material/Chip";
import useTheme from "@mui/material/styles/useTheme";

interface OrderStatusProps {
    value: [status: string, ok: boolean];
}

const OrderStatusCell = React.memo(function OrderStatusFunction(
    props: OrderStatusProps
) {
    const { value } = props;
    const theme = useTheme();

    return <Chip
        size="small"
        label={`${value[0]?.replace(/_/g, " ")}`}
        sx={{
            bgcolor: `${value[1] ? undefined : theme.palette.warning.main}`,
            color: value[1] ? undefined : theme.palette.getContrastText(theme.palette.warning.main),
            mr: theme.spacing(0.5),
        }} />;
});

export function renderOrderStatus(params: GridCellParams) {
    return <OrderStatusCell value={params.value as [status: string, ok: boolean]} />;
}
import gql from "graphql-tag";

const UserFragment = gql`
  fragment UserFields on User {
    id
    createdAt
    name
    email
    avatarUrl
    role
    avatarProcessing
    recentlyActive
  }
`;

export default UserFragment;
